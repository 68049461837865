<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button [statesToSkip]="['app.expense.camera']"></back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              *ngIf="expense && !expense.is_locked && showDeleteBtn"
              [disabled]="errorMessage || loading"
              (click)="deleteExpense()">
        <i class="ion ion-md-trash"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<slider-button *ngIf="expense && !expense.is_locked && showSaveBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="false"
               [btnText]="'Save Expense'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveExpense()">
</slider-button>

<slider-button *ngIf="expense && !expense.is_locked && showApproveBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="showDeclineBtn"
               [btnText]="'Approve Expense'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveExpense(true)">
</slider-button>

<slider-button *ngIf="expense && !expense.is_locked && showDeclineBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="showApproveBtn"
               [btnText]="'Decline Expense'"
               [btnIconName]="'ios-close'"
               [btnType]="'danger'"
               (btnClicked)="saveExpense(false, true)">
</slider-button>

<ion-content>

  <div class="ph-pageHeaderRow">
    <div class="ph-pageHeaderText">
      {{pageTitle}}
    </div>
  </div>

  <coloured-item-selector *ngIf="expense"
                          [allItems]="allProjects"
                          [itemType]="'Work'"
                          [itemKeyProperty]="'project_key'"
                          [itemNameProperty]="'project_title'"
                          [itemColourProperty]="'project_colour'"
                          [disabled]="expense.is_locked"
                          [selectedItem]="expense.ph_project"
                          (itemSelected)="projectSelected($event)">
  </coloured-item-selector>

  <div class="app-expenseEdit-documentContainer"
       *ngIf="expense"
       (click)="expandImage()"
       [ngStyle]="{ 'height': imageFullSize ? imageHeight + 'px' : '' }">

    <button class="-color-white-clear -type-icon app-expenseEdit-documentChange"
            *ngIf="!expense.is_locked"
            [hidden]="!imageUrl"
            (click)="changeDocument()">
      <i class="ion ion-md-camera"></i>
    </button>

    <button class="-color-white-clear -type-icon app-expenseEdit-documentRemove"
            *ngIf="!expense.is_locked"
            [hidden]="!imageUrl && !imageBlob"
            (click)="deleteDocument($event)">
      <i class="ion ion-md-close"></i>
    </button>

    <img *ngIf="imageUrl && isImage"
         id="app-expenseEdit-documentImage"
         (load)="documentUrlChanged()"
         [src]="imageUrl">

    <div class="ph-emptyMessage"
         *ngIf="imageUrl && !isImage">
      <i class="ion ion-md-paper"></i>
    <div>PDF File</div>
    </div>

    <div class="ph-emptyMessage"
         (click)="changeDocument()"
         *ngIf="!imageUrl">
      <i class="ion ion-md-paper"></i>
      <div>{{expense.is_locked ? "No receipt" : "Tap to add receipt"}}</div>
    </div>

  </div>

  <ion-list class="app-expenseEdit-detailsList"
            *ngIf="expense">

    <div class="ph-errorMessage"
         [ngClass]="{ 'ph-errorMessageHidden': !errorMessage }">
      {{errorMessage}}
    </div>

    <div class="ph-formItem"
         *ngIf="isManager || isAdmin">
      <div class="ph-formItem-label">
        Employee
      </div>
      <button class="ph-formItem-inputButton"
              [ngClass]="{ 'ph-inputLocked': expense.is_locked || expense.expense_key !== null }"
              (click)="openEmployeeList()">
        {{expense.employee.employee_code}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        {{expense.ph_project.expense_pay_code_unit}}
      </div>
      <input class="ph-formItem-input"
             type="number"
             [ngClass]="{ 'ph-inputLocked': expense.is_locked }"
             [(ngModel)]="expense.expense_amount"
             min="1"
             step="1"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="expenseAmountUpdated()">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Date
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDDD, D MMM YYYY"
                    max="2030"
                    [ngClass]="{ 'ph-inputLocked': expense.is_locked }"
                    [(ngModel)]="expenseDateString"
                    (ngModelChange)="expenseDateStringUpdated()">
      </ion-datetime>
    </div>

    <textarea class="text-input ph-textArea app-expenseEdit-description"
              [(ngModel)]="expense.description"
              [ngClass]="{ 'ph-inputLocked': expense.is_locked }"
              placeholder="{{expense.is_locked ? 'No Description' : 'Tap to Add Description...'}}">
    </textarea>



  </ion-list>

</ion-content>
