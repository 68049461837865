
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './../../components/components.module';
import { PipesModule } from './../../pipes/pipes.module';

import { InvApprovalComponent } from './inv-approval.component';
import { InvApprovalRequestComponent } from './inv-approval-request/inv-approval-request.component';
import { InvApprovalManagerComponent } from './inv-approval-manager/inv-approval-manager.component';
import { InvApprovalViewComponent } from './inv-approval-view/inv-approval-view.component';

export const INV_APPROVAL_CONFIG: any[] = [
  {
    name: 'app.inv.approval',
    url: '/approval',
    abstract: true,
    component: InvApprovalComponent
  },
  {
    name: 'app.inv.approval.request',
    url: '/request?{user_key:int}',
    component: InvApprovalRequestComponent,
    params: {
      user_key: null,
      segments: null
    }
  },
  {
    name: 'app.inv.approval.manager',
    url: '/manager',
    component: InvApprovalManagerComponent
  },
  {
    name: 'app.inv.approval.view',
    url: '/view',
    component: InvApprovalViewComponent,
    params: {
      approval_project: null,
      approval_segments: null
    }
  }
];

@NgModule({
  declarations: [
    InvApprovalComponent,
    InvApprovalRequestComponent,
    InvApprovalManagerComponent,
    InvApprovalViewComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    ComponentsModule,
    FormsModule,
    UIRouterModule.forChild({ states: INV_APPROVAL_CONFIG })
  ]
})
export class InvApprovalModule { }
