import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { InvCompanyService } from './../inv-company/inv-company.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InvOnboardService {

  incompleteOnboards: any[];
  completeOnboards: any[];
  onboardsMap: any;

  private _hasIncompleteOnboards: boolean = false;
  private _hasMandatoryOnboards: boolean = false;
  private _blobSASKey: string;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public invCompanyService: InvCompanyService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadOnboards()
          .then(() => {
            this.loadBlobSASKey()
              .finally(() => {
                this.serviceSetup = true;
                resolve();
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.incompleteOnboards = null;
    this.completeOnboards = null;
    this.onboardsMap = null;
    this._hasIncompleteOnboards = false;
    this.serviceSetup = false;
  }

  get hasIncompleteOnboards(): boolean {
    return this._hasIncompleteOnboards;
  }
  get hasMandatoryOnboards(): boolean {
    return this._hasMandatoryOnboards;
  }

  get blobSASKey(): string {
    return this._blobSASKey;
  }

  getIncompleteOnboards(): any[] {
    return _.cloneDeep(this.incompleteOnboards);
  }

  getSingleOnboard(onboard_key: number): any {
    return _.cloneDeep(this.onboardsMap[onboard_key]) || null;
  }

  loadOnboards() {
    return new Promise<void>((resolve, reject) => {
      const params = {
        user_key: this.invCompanyService.getUserKey()
      };

      this.apiService.invGet('onboard', params)
        .then((onboards) => {
          this.incompleteOnboards = [];
          this.completeOnboards = [];
          this.onboardsMap = {};
          this._hasIncompleteOnboards = false;
          this._hasMandatoryOnboards = false;

          const userIsResource = this.invCompanyService.isResource();

          for (let onboard of onboards) {
            onboard = InvOnboardService.setupOnboard(onboard);

            this.onboardsMap[onboard.onboard_key] = onboard;

            if (!!onboard.completed_date) {
              this.completeOnboards.push(onboard);
            }
            else {
              this.incompleteOnboards.push(onboard);
              this._hasIncompleteOnboards = true;

              if (onboard.mandatory_flag && userIsResource) {
                this._hasMandatoryOnboards = true;
              }
            }
          }

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadBlobSASKey() {
    return new Promise<void>((resolve, reject) => {

    const params = {
        container: 'wbsgc4xdxsqe'
    };
  
      this.apiService.invGet('document/sas', params)
        .then((blobSASKey) => {
          this._blobSASKey = blobSASKey;
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  finishOnboard(onboard_key: number) {
    return new Promise<void>((resolve, reject) => {
      const data = {
        onboard_key,
        completed_flag: true
      };

      this.apiService.invPost('/onboard', data)
        .then(() => {
          this.loadOnboards()
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postOnboardStep(step: any) {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invPost('/onboard/step', step)
        .then(() => {
          this.loadOnboards()
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postUserOnboardDocument(doc: any) {
    return new Promise<any>((resolve, reject) => {

      const fd = new FormData();
      fd.append('data', doc.data);
      fd.append('user_key', doc.user_key || this.invCompanyService.getUserKey());
      fd.append('description', doc.description || '');

      this.apiService.invPost('document', fd)
        .then((documents) => {
          resolve(InvOnboardService.setupOnboardDocument(documents[0]));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteOnboardDocument(document_key: number) {
    return new Promise<void>((resolve, reject) => {
      const data = {
        document_key,
        deleted_flag: true
      };

      this.apiService.invPost('document', data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  static setupOnboard(onboard: any): any {
    onboard.created_date = TimeUtilService.dateTimeStringToDate(onboard.created_date);
    onboard.completed_date = TimeUtilService.dateTimeStringToDate(onboard.completed_date);
    onboard.approved_date = TimeUtilService.dateTimeStringToDate(onboard.approved_date);

    for (const step_answer of onboard.step_answers) {
      InvOnboardService._setupStepAnswer(step_answer);
    }
    for (const step_template of onboard.step_templates) {
      InvOnboardService._setupStepTemplate(step_template);
    }
    InvOnboardService.sortStepTemplates(onboard.step_templates);

    return onboard;
  }

  private static _setupStepAnswer(step_answer: any) {
    step_answer.created_date = TimeUtilService.dateTimeStringToDate(step_answer.created_date);

    if (step_answer.signature_data) {
      step_answer.signature_data.signed_date = TimeUtilService.dateTimeStringToDate(step_answer.signature_data.signed_date);
    }
    if (step_answer.form_answer) {
      step_answer.form_answer = UtilService.parseJSON(step_answer.form_answer);

      if (step_answer.step_type === 'ato') {
        step_answer.form_answer.PersonDemographicDetails_Birth = TimeUtilService.dateStringToDate(
          step_answer.form_answer.PersonDemographicDetails_Birth
        );
      }
      if (step_answer.step_type === 'form') {
        for (const answer of step_answer.form_answer) {
          if (answer.field_type === 'date') {
            answer.form_answer = TimeUtilService.dateStringToDate(answer.form_answer);
          }
        }
      }
    }
  }

  private static _setupStepTemplate(step_template: any) {
    if (!!step_template.form_definition) {
      step_template.form_definition = UtilService.parseJSON(step_template.form_definition);
    }
  }

  static sortStepTemplates(step_templates: any[]): any[] {
    return step_templates.sort((a, b) => {
      return a.step_number - b.step_number;
    });
  }

  static setupOnboardDocument(document: any) {
    document.created_date = TimeUtilService.dateTimeStringToDate(document.created_date);

    return document;
  }

}
