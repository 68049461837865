import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { DirectivesModule } from './../directives/directives.module';
import { PipesModule } from './../pipes/pipes.module';

import { OnboardComponent } from './onboard/onboard.component';
import { OnboardAtoComponent } from './onboard/onboard-ato/onboard-ato.component';
import { OnboardCheckboxComponent } from './onboard/onboard-checkbox/onboard-checkbox.component';
import { OnboardDownloadComponent } from './onboard/onboard-download/onboard-download.component';
import { OnboardFormComponent } from './onboard/onboard-form/onboard-form.component';
import { OnboardLinkComponent } from './onboard/onboard-link/onboard-link.component';
import { OnboardNzTaxComponent } from './onboard/onboard-nztax/onboard-nztax.component';
import { OnboardUploadComponent } from './onboard/onboard-upload/onboard-upload.component';
import { OnboardVideoComponent } from './onboard/onboard-video/onboard-video.component';

import { SliderButtonComponent } from './slider-button/slider-button.component';
import { SlideupListComponent } from './slideup-list/slideup-list.component';
import { CalendarSlidesComponent } from './calendar-slides/calendar-slides.component';
import { ColouredItemSelectorComponent } from './coloured-item-selector/coloured-item-selector.component';
import { PeriodSelectorComponent } from './period-selector/period-selector.component';
import { CalendarMonthGridComponent } from './calendar-month-grid/calendar-month-grid.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { PhCheckboxComponent } from './ph-checkbox/ph-checkbox.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { TimeClockComponent } from './time-clock/time-clock.component';

@NgModule({
  declarations: [
    OnboardComponent,
    OnboardAtoComponent,
    OnboardCheckboxComponent,
    OnboardDownloadComponent,
    OnboardFormComponent,
    OnboardLinkComponent,
    OnboardNzTaxComponent,
    OnboardUploadComponent,
    OnboardVideoComponent,
    SliderButtonComponent,
    SlideupListComponent,
    CalendarSlidesComponent,
    ColouredItemSelectorComponent,
    PeriodSelectorComponent,
    CalendarMonthGridComponent,
    PopupMenuComponent,
    PhCheckboxComponent,
    MenuButtonComponent,
    BackButtonComponent,
    TimeClockComponent
  ],
  imports: [
    NgxMaskModule,
    FormsModule,
    NgbModule,
    CommonModule,
    IonicModule,
    DirectivesModule,
    PipesModule
  ],
  exports: [
    OnboardComponent,
    SliderButtonComponent,
    SlideupListComponent,
    CalendarSlidesComponent,
    ColouredItemSelectorComponent,
    PeriodSelectorComponent,
    CalendarMonthGridComponent,
    PopupMenuComponent,
    PhCheckboxComponent,
    MenuButtonComponent,
    BackButtonComponent,
    TimeClockComponent
  ],
  entryComponents: [
    SlideupListComponent
  ]
})
export class ComponentsModule { }
