<div class="ph-groupedList-container">

  <div class="ph-groupedList-groupHeader">
    <div class="ph-groupedList-groupHeaderTitle">
      <div class="ph-groupedList-groupHeaderTimerIcon"
           [ngStyle]="{ 'opacity': !timerFlasher ? 0 : '' }"
           [ngClass]="{ '-onBreak': clock.is_on_break }">
      </div>
      {{clock.is_on_break ? 'On Break' : 'Clocked In'}}
    </div>

    <div class="ph-groupedList-groupHeaderLine"></div>

    <div class="ph-groupedList-groupHeaderTimer"
         [ngClass]="{ '-onBreak': clock.is_on_break }">
      <span *ngIf="clock.is_on_break">
        {{clockTimeData.breakTimerSeconds | clockTimerMainPipe}}
      </span>
      <span *ngIf="!clock.is_on_break">
        {{clockTimeData.clockTimerSeconds | clockTimerMainPipe}}
      </span>
    </div>
  </div>

  <div class="ph-groupedList-item -timerItem"
       (click)="viewClock()">

    <div class="ph-groupedList-itemColor"
         [ngStyle]="{'background-color': clock.project.project_colour || clock.task.task_colour }">
    </div>

    <div class="ph-groupedList-itemDescription -placementName"
         *ngIf="clock.project && clock.task">
      {{clock.project.project_name}}
    </div>

    <div class="ph-groupedList-itemTitle">
      <span>{{clock.project.project_title || clock.task.task_name}}</span>
    </div>

    <div class="ph-groupedList-itemDescription"
         *ngIf="clock.clock_breaks.length">
      {{clock.clock_breaks.length}} Break{{clock.clock_breaks.length !== 1 ? 's' : ''}}
    </div>

    <div class="ph-groupedList-itemDescription"
         *ngIf="clock.location_in || clock.address_in">
      <i class="ion ion-ios-pin"></i>
      <span *ngIf="clock.location_in">
        {{clock.location_in.location_name}}
      </span>
      <span *ngIf="!clock.location_in && clock.address_in">
        {{clock.address_in}}
      </span>
    </div>

    <div class="ph-groupedList-itemDetails">
      <span>Clocked In: </span>
      <div>{{clock.clock_in_time | date : 'shortTime'}}</div>
      <span *ngIf="clock.is_multiple_days">
        {{clock.clock_in_time | date : 'd MMM'}}
      </span>
    </div>

    <div class="ph-groupedList-itemTimerButtons"
         [ngStyle]="{ 'width': clock.is_on_break ? '70px' : '' }"
         (click)="$event.stopPropagation()">
      <button class="-color-success-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -play"
              *ngIf="clock.is_on_break"
              (click)="breakOut($event)">
        <i class="ion ion-md-play"></i>
      </button>

      <button class="-color-yellow-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -pause"
              *ngIf="!clock.is_on_break"
              (click)="breakIn($event)"
              [disabled]="clockTimeData.clockExceedsMaxSeconds">
        <i class="ion ion-md-pause"></i>
      </button>

      <button class="-color-danger-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -stop"
              *ngIf="!clock.is_on_break"
              (click)="clockOut($event)">
        <i class="ion ion-md-square"></i>
      </button>
    </div>

  </div>

</div>
