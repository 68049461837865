<button class="ph-toggleAddBtn"
        (click)="toggleMenu()"
        [ngClass]="{ '-open': menuOpen }">
  <i class="ion ion-md-add"></i>
</button>

<div class="ph-pageOverlay"
     (click)="closeMenu()"
     *ngIf="menuOpen">
</div>

<div class="ph-popupMenu-menuContainer"
     [ngClass]="{ 'ph-popupMenu-menuContainerClosed': !menuOpen }">

  <div *ngFor="let item of items">
    <button class="ph-popupMenu-menuItem"
            *ngIf="item !== null"
            [disabled]="item.disabled"
            [ngClass]="item.color_class || '-color-default-clear'"
            (click)="selectItem(item)">
      <i class="ion"
         *ngIf="item.icon_class"
         [ngClass]="item.icon_class">
      </i>
      {{item.label}}
    </button>

    <div class="ph-popupMenu-menuBreak"
         *ngIf="item === null">
    </div>
  </div>

</div>
