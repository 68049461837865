import { Component, OnInit, Input } from '@angular/core';

import { StateChangeService } from 'src/app/services/state-change/state-change.service';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {

  @Input() statesToSkip: string[];

  constructor(
    public stateChangeService: StateChangeService
  ) { }

  back() {
    this.stateChangeService.back(this.statesToSkip);
  }

}
