import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { DomService } from './../../../services/dom/dom.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { LeaveService } from '../../../services/leave/leave.service';
import { LeaveUtilService } from './../../../services/leave-util/leave-util.service';
import { EmployeeService } from '../../../services/employee/employee.service';
import { EmployeeUtilService } from '../../../services/employee-util/employee-util.service';
import { UtilService } from '../../../services/util/util.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { AlertService } from '../../../services/alert/alert.service';
import { CompanyService } from './../../../services/company/company.service';

import * as _ from 'lodash';
import * as moment from 'moment';

type ResponseType = (
  'APPROVE' | 'DECLINE'
);
@Component({
  selector: 'app-leave-edit',
  templateUrl: './leave-edit.component.html',
  styleUrls: ['./leave-edit.component.scss']
})
export class LeaveEditComponent implements OnInit {

  isAdmin: boolean = false;
  isManager: boolean = false;
  isAdminOrPayCycleAdmin: boolean = false;

  pageTitle: string;
  response: string;
  errorMessage: string;
  loading: boolean = true;

  leave_request_key: number = null;
  leaveRequest: any;
  leave_request_copy: any = null;

  start: any;
  startString: string;
  end: any;
  endString: string;

  notes: string;

  allLeaveTypes: any[] = [];
  selectedLeaveType: any;

  loggedInEmployeeKey: number;
  selectedEmployee: any;
  allEmployees: any[] = [];

  readonly partDayOptions: any[] = [
    { label: 'Full Day', value: 1 },
    { label: '3/4 Day', value: 0.75 },
    { label: '1/2 Day', value: 0.5 },
    { label: '1/4 Day', value: 0.25 }
  ];

  showPartDay: boolean;
  partDayOption: any;

  showLeaveHours: boolean = false;
  request_hours: number;
  weekDefinitionUnit: string;

  otherEmployeeLeave: any[] = [];
  overlappingLeave: any[] = [];
  overlappingAmount: number = 0;

  leaveInfo: any;
  leaveInfoLines: any[] = [];

  editingDisabled: boolean = false;
  endDateEditingDisabled: boolean = false;

  leaveInfoLoaded: boolean = false;

  showDeclineBtn: boolean = false;
  showDeleteBtn: boolean = false;
  showApproveBtn: boolean = false;
  showSaveBtn: boolean = false;

  isInactiveLeaveType: boolean = false;
  isAdminOnlyLeaveType: boolean = false;
  isPaid: boolean = false;
  isDeclined: boolean = false;
  isApproved: boolean = false;
  isPending: boolean = true;

  constructor(
    public stateService: StateService,
    public domService: DomService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public leaveService: LeaveService,
    public employeeService: EmployeeService,
    public utilService: UtilService,
    public companyService: CompanyService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.isAdmin = this.companyService.isAdmin();
    this.isManager = this.companyService.isManager();
    this.isAdminOrPayCycleAdmin = this.companyService.isAdminOrPayCycleAdmin();
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    this.leaveRequest = _.cloneDeep(this.stateService.params.leaveRequest) || null;
    this.leave_request_copy = _.cloneDeep(this.leaveRequest);
    const leave_request_key = this.stateService.params.leave_request_key;

    if (!!leave_request_key && !isNaN(leave_request_key) && !this.leaveRequest) {
      this.leave_request_key = leave_request_key;

      this.leaveService.loadLeaveRequest(this.leave_request_key)
        .then((leaveRequest) => {
          this.leaveRequest = leaveRequest;
          this.leave_request_copy = _.cloneDeep(this.leaveRequest);

          this.initialiseData();
        })
        .catch(() => {
          this.back();
        });
    }
    else {
      this.initialiseData();
    }
  }

  back() {
    this.stateChangeService.back();
  }

  initialiseData() {
    this.loading = true;

    // Load employees
    this.allEmployees = this.employeeService.getAllEmployees(false, false, true);

    if (this.allEmployees.length === 0) {
      this.utilService.toastMessage('At least one employee must be available to record leave');
      this.back();
      return;
    }

    // Load selected employee
    const request_employee_key = this.leaveRequest ? this.leaveRequest.employee_key : null;
    const employee_key = request_employee_key || this.stateService.params.employee_key || null;

    this.selectedEmployee = employee_key ?
      EmployeeUtilService.getEmployeeFromList(employee_key, this.allEmployees) :
      this.allEmployees[0];

    // Load leave types
    this.allLeaveTypes = this.leaveService.getLeaveTypes(false);

    if (this.allLeaveTypes.length === 0) {
      this.utilService.toastMessage('At least one leave type must be available to record leave');
      this.back();
      return;
    }

    if (this.leaveRequest) {
      this.leave_request_key = this.leaveRequest.leave_request_key;
      this.isInactiveLeaveType = !this.leaveRequest.leaveType.active_flag;
      this.isAdminOnlyLeaveType = this.leaveRequest.leaveType.admin_only_flag;

      if (this.isInactiveLeaveType || (!this.isAdminOrPayCycleAdmin && this.isAdminOnlyLeaveType)) {
        this.allLeaveTypes.push(this.leaveService.getSingleLeaveType(this.leaveRequest.leave_type_key));
      }
      this.selectedLeaveType = LeaveUtilService.getLeaveTypeFromList(this.leaveRequest.leave_type_key, this.allLeaveTypes);

      this.start = _.cloneDeep(this.leaveRequest.start_date);
      this.startString = TimeUtilService.dateToLocalTimeISOString(this.start);

      this.end = _.cloneDeep(this.leaveRequest.end_date);
      this.endString = TimeUtilService.dateToLocalTimeISOString(this.end);

      this.notes = _.cloneDeep(this.leaveRequest.notes);

      this.showPartDay = moment(this.start).isSame(moment(this.end), 'day');
      this.partDayOption = this.getLeaveRequestPartDayOption(this.leaveRequest);

      this.isPaid = this.leaveRequest.is_paid;
      this.isDeclined = this.leaveRequest.declined_flag;
      this.isApproved = this.leaveRequest.approved_flag;
      this.isPending = !this.isDeclined && !this.isApproved;
      this.request_hours = this.leaveRequest.request_hours;
    }
    else {
      this.start = this.stateService.params.start || new Date();
      this.startString = TimeUtilService.dateToLocalTimeISOString(this.start);

      this.end = this.stateService.params.end || _.cloneDeep(this.start);
      this.endString = TimeUtilService.dateToLocalTimeISOString(this.end);

      this.notes = '';

      this.showPartDay = true;
      this.partDayOption = this.partDayOptions[0];

      this.selectedLeaveType = null;

      // Try to default the selected leave type to annual leave
      for (const leaveType of this.allLeaveTypes) {
        if (leaveType.pay_code === 'ANHL') {
          this.selectedLeaveType = leaveType;
          break;
        }
      }

      // If we can't find annual leave, default to first leave type in the list
      if (!this.selectedLeaveType) {
        this.selectedLeaveType = this.allLeaveTypes[0];
      }
    }

    this.response = this.getLeaveResponse();
    this.pageTitle = this.getRequestTitle();

    this.checkEditingDisabled();
    this.setupModalButtonConfig();
    this.calcRequestInfo();

    this.loading = false;
  }

  saveLeaveRequest(response: ResponseType, toDelete: boolean) {
    if (this.showLeaveHours && (!this.request_hours || this.request_hours < 0)) {
      this.errorMessage = 'Request hours is required and must be greater than 0';
      return;
    }

    this.confirmChangesIfApprovedLeave()
      .then(() => {
        this.loading = true;

        const request: any = {
          leaveType: this.selectedLeaveType,
          employee: this.selectedEmployee,
          start_date: this.start,
          end_date: this.end,
          part_day: this.partDayOption.value,
          request_hours: this.request_hours,
          notes: this.notes,
          deleted_flag: toDelete
        };

        if (!!response && this.selectedEmployee.manageable_flag) {
          request.is_approved = response === 'APPROVE';
        }
        if (this.leaveRequest) {
          request.leave_request_key = this.leaveRequest.leave_request_key;
          request.row_version = this.leaveRequest.row_version;
        }

        this.leaveService.saveLeaveRequest(request)
          .then(() => {
            this.loading = false;
            this.back();
          })
          .catch(() => {
            this.loading = false;
          });
      })
      .catch((go_back) => {
        if (go_back) {
          this.back();
        }
      });
  }

  leaveIsApprovedAndHasUnsavedChanges() {
    if (!this.isApproved) {
      return false;
    }
    const copy = this.leave_request_copy;

    const changes = {
      start: !moment(this.start).isSame(moment(copy.start_date), 'day'),
      end: !moment(this.end).isSame(moment(copy.end_date), 'day'),
      request_hours: this.showLeaveHours && this.request_hours !== copy.request_hours,
      part_day: this.showPartDay && this.partDayOption.value !== copy.part_day,
      leave_type: !!this.selectedLeaveType && this.selectedLeaveType.leave_type_key !== copy.leaveType.leave_type_key,
      notes: this.notes !== copy.notes
    };

    for (const key of Object.keys(changes)) {
      if (changes[key] === true) {
        return true;
      }
    }

    return false;
  }

  confirmChangesIfApprovedLeave() {
    return new Promise<void>((resolve, reject) => {
      // Employee editing an approved leave request
      if (
        !this.selectedEmployee.manageable_flag &&
        this.isApproved
      ) {
        // Changes require the employee to confirm that they acknowledge
        // that editing approved leave will require it to be reapproved
        if (this.leaveIsApprovedAndHasUnsavedChanges()) {
          const message = 'This leave has already been approved. </br></br> Requesting additional changes will require it to be re-approved by a manager.';

          this.alertService.confirmAlert(
            'Request Changes to Approved Leave',
            message
          )
            .then(() => resolve())
            .catch(() => reject(false));
        }
        else {
          resolve();
        }
      }
      else {
        resolve();
      }
    });
  }

  deleteLeaveRequest() {
    this.alertService.confirmDeleteAlert('Are you sure you want to delete this leave request?')
      .then((toDelete) => {
        this.saveLeaveRequest(null, true);
      });
  }

  dateChanged() {
    this.start = new Date(this.startString);
    this.end = new Date(this.endString);

    if (moment(this.end).isBefore(moment(this.start), 'day')) {
      this.end = _.cloneDeep(this.start);
      this.endString = TimeUtilService.dateToLocalTimeISOString(this.end);
    }

    this.checkPartDayOption();
    this.calcRequestInfo();
  }

  checkPartDayOption() {
    this.showPartDay = moment(this.start).isSame(moment(this.end), 'day');

    if (!this.showPartDay) {
      this.partDayOption = this.partDayOptions[0];
    }
  }

  leaveTypeSelected(event: any) {
    this.selectedLeaveType = event.item;

    // Default parental leave to a period of one year
    if (this.selectedLeaveType.pay_code === 'PARENTAL' && moment(this.start).isSame(this.end)) {
      this.end = moment(this.end).add(1, 'years').subtract(1, 'days').toDate();
    }

    if (this.selectedLeaveType.pay_code === 'ANHL' && this.weekDefinitionUnit === 'hours') {
      this.request_hours = null;
      this.showLeaveHours = true;
      this.showPartDay = false;
    }
    else {
      this.request_hours = null;
      this.showLeaveHours = false;
      this.showPartDay = moment(this.start).isSame(moment(this.end), 'day');
    }

    this.calcRequestInfo();
  }

  setupModalButtonConfig() {
    this.showDeclineBtn = false;
    this.showDeleteBtn = false;
    this.showApproveBtn = false;
    this.showSaveBtn = false;

    if (!this.isInactiveLeaveType) {
      // Admin or Manager user
      if (this.selectedEmployee.manageable_flag || this.isAdmin) {
        // Existing leave request
        if (this.leave_request_key) {
          if (this.isPaid) {
            this.showApproveBtn = this.selectedEmployee.manageable_flag;
            this.showDeclineBtn = false;
          }
          else if (this.isApproved || this.isPending) {
            this.showApproveBtn = true;
            this.showDeclineBtn = true;
          }
        }
        // New leave request
        else {
          this.showApproveBtn = true;
          this.showDeclineBtn = false;
        }
      }
      // Employee user
      else {
        if (!this.editingDisabled) {
          this.showSaveBtn = true;
          this.showDeleteBtn = true;
        }
      }
    }
  }

  checkEditingDisabled() {
    this.editingDisabled = false;
    this.endDateEditingDisabled = false;

    if (
      this.isInactiveLeaveType ||
      this.isDeclined ||
      (this.isAdminOnlyLeaveType && !this.isAdminOrPayCycleAdmin)
    ) {
      this.editingDisabled = true;
      this.endDateEditingDisabled = true;
    }
    // Only admins/managers can edit the end date of leave once it's been paid
    // Needed to enable changing the end date of partly paid leave
    else if (this.isPaid) {
      this.editingDisabled = true;
      this.endDateEditingDisabled = !this.selectedEmployee.manageable_flag;
    }
    // Employees can't edit their leave if it's been approved, and is on or before today
    else if (
      !this.selectedEmployee.manageable_flag &&
      this.isApproved &&
      moment(this.leave_request_copy.start_date).isSameOrBefore(moment(), 'date')
    ) {
      this.editingDisabled = true;
      this.endDateEditingDisabled = true;
    }
  }

  getLeaveResponse() {
    if (this.isApproved) {
      return 'Approved';
    }
    else if (this.isDeclined) {
      return 'Declined';
    }
    return 'Pending';
  }

  getLeaveRequestPartDayOption(leaveRequest: any) {
    for (const opt of this.partDayOptions) {
      if (leaveRequest.part_day === opt.value) {
        return opt;
      }
    }
    return null;
  }

  calcRequestInfo() {
    this.leaveInfoLoaded = false;

    if (this.selectedLeaveType) {
      this.leaveService.loadLeaveRequestInfo(
        this.start,
        this.end,
        this.partDayOption.value,
        this.request_hours || 0,
        this.selectedLeaveType.leave_type_key,
        this.leave_request_key,
        this.selectedEmployee.employee_key
      ).then((info) => {
        this.leaveInfo = info;

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        this.weekDefinitionUnit = this.leaveInfo.week_definition_unit;

        if (this.weekDefinitionUnit === 'hours' && this.selectedLeaveType.pay_code === 'ANHL') {
          this.showPartDay = false;
          this.showLeaveHours = true;
        }

        this.generateLeaveInfoLines();
        this.updateOtherLeave();

        this.leaveInfoLoaded = true;
        this.updateErrorMessage(this.leaveInfo.invalid_request_dates);
        this.checkEditingDisabled();
      });
    }
  }

  updateOtherLeave() {
    this.otherEmployeeLeave = [];
    this.overlappingLeave = [];
    this.overlappingAmount = 0;

    for (const overlap of this.leaveInfo.overlapping_requests) {

      if (!this.isDeclined && overlap.employee_key === this.selectedEmployee.employee_key) {
        this.overlappingLeave.push(overlap);
        this.overlappingAmount += overlap.part_day;
      }
      else {
        this.otherEmployeeLeave.push(overlap);
      }
    }
  }

  employeeSelected(employee: any) {
    this.selectedEmployee = employee;
    this.setupModalButtonConfig();
    this.calcRequestInfo();
  }

  openEmployeeList() {
    if (!this.editingDisabled) {

      this.domService.openSlideUpList(
        this.allEmployees,
        this.selectedEmployee,
        'employee_code',
        null,
        (employee: any) => {
          this.employeeSelected(employee);
        }
      );
    }
  }

  partDayOptionSelected(partDayOption: any) {
    this.partDayOption = partDayOption;
    this.calcRequestInfo();
  }

  openPartDayOptionList() {
    if (!this.editingDisabled) {

      this.domService.openSlideUpList(
        this.partDayOptions,
        this.partDayOption,
        'label',
        null,
        (partDayOption: any) => {
          this.partDayOptionSelected(partDayOption);
        }
      );
    }
  }

  generateLeaveInfoLines() {
    const info = this.leaveInfo;
    this.leaveInfoLines = [];

    const unit = this.selectedLeaveType.pay_code === 'ANHL' ? this.weekDefinitionUnit : 'days';

    if (info[unit + '_requested'] !== null) {
      this.leaveInfoLines.push(
        info[unit + '_requested'] + ' ' + unit + ' requested'
      );
    }

    if (info['other_' + unit + '_requested'] !== null) {
      this.leaveInfoLines.push(
        info['other_' + unit + '_requested'] + ' ' + unit + ' of other leave requested'
      );
    }

    if (!info.holiday_pay_as_you_go_flag && info[unit + '_on_request_start'] !== null) {
      this.leaveInfoLines.push(
        info[unit + '_on_request_start'] + ' ' + unit + ' estimated at ' + moment(this.start).format('DD/MM/YYYY')
      );
    }

    if (info[unit + '_due'] !== null) {
      this.leaveInfoLines.push(
        info[unit + '_due'] + ' ' + unit + ' ' + (this.selectedLeaveType.pay_code === 'SICK' ? 'available' : 'due')
      );
    }
  }

  getRequestTitle() {
    if (!this.leave_request_key) {
      return 'New Leave Request';
    }
    else if (this.isPaid) {
      return 'Paid Leave Request';
    }
    else if (this.isApproved) {
      return 'Approved Leave Request';
    }
    else if (this.isDeclined) {
      return 'Declined Leave Request';
    }
    else {
      return 'Pending Leave Request';
    }
  }

  updateErrorMessage(invalid_request_dates: any) {
    const leaveAmount = this.partDayOption.value + this.overlappingAmount; // total requested & overlapping part_days

    if (this.allEmployees.length === 0) {
      this.errorMessage = 'At least one employee must be available for recording leave';
    }
    else if (this.allLeaveTypes.length === 0) {
      this.errorMessage = 'At least one leave type must be available for recording leave';
    }
    else if (this.isInactiveLeaveType && !this.isDeclined && !this.isPaid) {
      this.errorMessage = 'The leave type attached to this leave request is archived.' +
        this.isAdmin ? 'Restore the leave type to edit this leave request' : '';
    }
    else if (this.overlappingLeave.length && leaveAmount > 1) {
      this.errorMessage = 'This leave will overlap with currently recorded leave';
    }
    else if (invalid_request_dates) {
      this.errorMessage = 'Leave request dates cannot be moved into a paid period';
    }
    else {
      this.errorMessage = null;
    }
  }

}
