import { Component } from '@angular/core';

import { StateChangeService } from 'src/app/services/state-change/state-change.service';

@Component({
  selector: 'menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent {

  constructor(
    public stateChangeService: StateChangeService
  ) { }

  toggleMenu(){
    this.stateChangeService.toggleMenu();
  }

}
