<period-selector [currentMonth]="currentMonth"
                 [showYearSelector]="yearCalendarVisible"
                 [monthChanging]="monthChanging"
                 [yearChanging]="yearChanging"
                 (selectorToggled)="toggleYearCalendarVisibility()"
                 (goForward)="goForward()"
                 (goBack)="goBack()">
</period-selector>

<div class="calendarSlides-dayHeader"
     *ngIf="!yearCalendarVisible">
  <div class="calendarSlides-dayHeaderLabel"
       *ngFor="let label of dayLabels">
    {{label}}
  </div>
</div>

<div class="ph-spinner"
     *ngIf="loading">
  <ion-spinner></ion-spinner>
</div>

<ion-slides class="calendarSlides-slideMonths"
            #ionSlides
            *ngIf="calendarInitialised"
            [options]="slideOptions"
            [hidden]="yearCalendarVisible"
            (ionSlideDidChange)="slideChanged()">
  <ion-slide class="calendarSlides-slideMonth"
             *ngFor="let month of slideMonths; let i = index">
    <ng-container *ngIf="i === currentMonthIndex - 1 || i === currentMonthIndex || i === currentMonthIndex + 1">
      <div class="calendarSlides-slideMonthRow"
           *ngFor="let week of month.weeks">
        <div class="calendarSlides-slideMonthCell"
             *ngFor="let day of week; let i = index">

          <div class="calendarSlides-slideMonthCellInner"
               *ngIf="day.currentMonth && !day.leaveRequests.length">
            {{day.dateLabel}}
          </div>
          <div class="calendarSlides-slideMonthCellInner"
               (click)="editLeave(day)"
               [ngStyle]="{
                 'background-color': day.leaveRequests.length ? day.leaveRequests[0].leave_type_colour_light : '' }"
               [ngClass]="{
                'leaveStart': day.startOfLeave,
                'monthStart': day.startOfMonth,
                'leaveEnd': day.endOfLeave,
                'monthEnd': day.endOfMonth }"
               *ngIf="day.currentMonth && day.leaveRequests.length">
            {{day.dateLabel}}

            <div class="calendarSlides-slideMonthCellInner-leaveStart"
                 [ngStyle]="{ 'background-color': day.leaveRequests[0].leave_type_colour }"
                 *ngIf="day.startOfLeave">
              <div>{{day.dateLabel}}</div>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
  </ion-slide>
</ion-slides>

<div class="calendarSlides-yearCalendar"
     *ngIf="calendarInitialised && yearCalendarVisible">
  <div class="calendarSlides-yearCalendarMonth"
       (click)="showCalendarMonth(month.date)"
       *ngFor="let month of yearCalendar">

    <div class="calendarSlides-yearCalendarMonthName">
      {{month.monthLabel}}
    </div>

    <div class="calendarSlides-yearCalendarWeek">
      <div class="calendarSlides-yearCalendarDay"
           *ngFor="let dayLabel of dayLabels">
        {{dayLabel[0]}}
      </div>
    </div>

    <div class="calendarSlides-yearCalendarWeek"
         *ngFor="let week of month.weeks">

      <div class="calendarSlides-yearCalendarDay"
           *ngFor="let day of week">

        <div class="calendarSlides-yearCalendarLeaveDay"
             *ngIf="day.leaveRequests.length"
             [ngStyle]="{ 'background-color': day.currentMonth ? day.leaveRequests[0].leave_type_colour : '' }"
             [ngClass]="{
            'calendarSlides-yearCalendarLeaveDay': day.currentMonth,
            'leaveStart': day.startOfLeave || day.startOfMonth,
            'leaveEnd': day.endOfLeave || day.endOfMonth
           }">
          {{day.currentMonth ? day.dateLabel : ''}}
        </div>
        <div class="calendarSlides-yearCalendarNoLeaveDay"
             *ngIf="!day.leaveRequests.length">
          {{day.currentMonth ? day.dateLabel : ''}}
        </div>

      </div>
    </div>
  </div>
</div>
