import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { AuthService } from '../auth/auth.service';
import { ProjectService } from '../project/project.service';
import { EmployeeService } from '../employee/employee.service';
import { TeamService } from './../team/team.service';

import { PhExpense } from './../../models/expense/ph-expense';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public teamService: TeamService,
    public apiService: ApiService
  ) {

  }

  loadExpense(expense_key: number) {
    return new Promise<PhExpense>((resolve, reject) => {
      this.apiService.phGet('expense', { expense_key })
        .then((data) => {
          if (data.length) {
            resolve(this.setupExpenses(data)[0]);
          }
          else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadMonthOfExpenses(month: Date, employee_key: number = null) {
    const start = _.cloneDeep(month);
    const end = _.cloneDeep(month);

    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    return this.loadExpenses(start, end, employee_key);
  }

  loadYearOfExpenses(year: any, employee_key: number = null) {
    year = parseInt(year, null);

    const start = new Date();
    start.setFullYear(year, 0, 1);

    const end = _.cloneDeep(start);
    end.setFullYear(year + 1);
    end.setDate(0);

    return this.loadExpenses(start, end, employee_key);
  }

  loadExpenses(start_date: Date, end_date: Date, employee_key: number = null) {
    return new Promise<PhExpense[]>((resolve, reject) => {
      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key
      };

      if (!employee_key) {
        delete params.employee_key;
      }

      this.apiService.phGet('expense', params)
        .then((data) => {
          const expenses = this.setupExpenses(data);

          if (!employee_key) {
            resolve(this.filterExpensesForSelectedTeam(expenses));
          }
          else {
            resolve(expenses);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  saveExpense(expense: PhExpense, documentBlob: Blob) {
    return new Promise<any>((resolve, reject) => {

      const postExpense = (data: any) => {
        this.apiService.phPost('expense', data)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      };

      const expenseData = expense.formatExpenseForPosting();

      // If no document is present then expense can be saved directly
      if (!documentBlob) {
        postExpense(expenseData);
      }
      else {
          let fileType = ''
          if (documentBlob.type.includes('pdf')) {
            fileType = '.pdf';
          }
          else {
            fileType = '.jpeg';
          }
          const document_name = 'Expense_' + (expense.employee.employee_code.replace(/\s/g, '_')) + '_' + moment().format('YYYY-MM-DD') + fileType;
          const document_description = expense.description;

          this.employeeService.postEmployeeDocument(documentBlob, expenseData.employee_key, document_name, document_description)
            .then((document) => {
              expenseData.document_key = document.document_key;
              postExpense(expenseData);
            })
            .catch((err) => {
              reject(err);
            });
      }
    });
  }

  setupExpenses(expenses: any[]): PhExpense[] {
    for (let i = expenses.length - 1; i >= 0; i--) {
      const expense = expenses[i] ? this.setupExpense(expenses[i]) : null;

      // Valid expense
      if (expense !== null) {
        expenses[i] = expense;
      }
      // Remove invalid expenses
      else {
        expenses.splice(i, 1);
      }
    }

    return expenses;
  }

  setupExpense(expense: any): PhExpense {
    const employee = this.employeeService.getSingleEmployee(expense.employee_key);
    const project = this.projectService.getSingleProject(expense.project_key);

    // const sas_key = '?' + this.employeeService.blobSASKey; // not yet used for expenses as the attachments are

    if (employee && project) {
      return new PhExpense(
        expense.expense_key,
        employee,
        project,
        expense.document_key,
        expense.document_name,
        expense.document_url || null,
        expense.expense_amount,
        TimeUtilService.dateTimeStringToDate(expense.expense_date, null, false),
        expense.description,
        TimeUtilService.dateTimeStringToDate(expense.approval_date, null, false),
        TimeUtilService.dateTimeStringToDate(expense.declined_date, null, false),
        expense.paid_flag
      );
    }
    return null;
  }

  initNewExpense(
    employee_key: number, project_key: number, expense_date: Date,
    document_key: number = null, document_name: string = null, document_url: string = null
  ): PhExpense {
    const employee = this.employeeService.getSingleEmployee(employee_key);
    const project = this.projectService.getSingleProject(project_key);

    if (employee && project) {
      return new PhExpense(
        null,
        employee,
        project,
        document_key,
        document_name,
        document_url,
        0,
        expense_date
      );
    }
    return null;
  }

  filterExpensesForSelectedTeam(expenses: any[]): any[] {
    const teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    if (teamEmployeeMap === null) {
      return expenses;
    }
    else {
      const teamExpenses = [];

      for (const expense of expenses) {
        if (!!teamEmployeeMap[expense.employee.employee_key]) {
          teamExpenses.push(expense);
        }
      }

      return teamExpenses;
    }
  }

  static formatExpenseForPosting(expense: any) {
    expense = _.cloneDeep(expense);

    expense.expense_date = TimeUtilService.formatDateForPosting(expense.expense_date, false);
    expense.approval_date = TimeUtilService.formatDateForPosting(expense.approval_date, false);
    expense.declined_date = TimeUtilService.formatDateForPosting(expense.declined_date, false);

    expense.project_key = _.cloneDeep(expense.project.project_key);
    expense.employee_key = _.cloneDeep(expense.employee.employee_key);

    delete expense.project;
    delete expense.employee;

    return expense;
  }

  static groupExpensesByStatus(expenses: any[]): any[] {
    const groupedExpenses = [
      { status: 'Pending', expenses: [] },
      { status: 'Approved', expenses: [] },
      { status: 'Declined', expenses: [] }
    ];

    for (const exp of expenses) {
      // Pending
      if (exp.ph_pending_flag) {
        groupedExpenses[0].expenses.push(exp);
      }
      // Approved
      else if (exp.ph_approved_flag) {
        groupedExpenses[1].expenses.push(exp);
      }
      // Declined
      else if (exp.ph_declined_flag) {
        groupedExpenses[2].expenses.push(exp);
      }
    }

    ExpenseService.sortExpenses(groupedExpenses[0].expenses);
    ExpenseService.sortExpenses(groupedExpenses[1].expenses);
    ExpenseService.sortExpenses(groupedExpenses[2].expenses);

    return groupedExpenses;
  }

  static sortExpenses(expenses: any[]): any[] {
    return expenses.sort((a: any, b: any) => {
      if (a.expense_date.valueOf() < b.expense_date.valueOf()) {
        return 1;
      }
      else if (a.expense_date.valueOf() > b.expense_date.valueOf()) {
        return -1;
      }
      return 0;
    });
  }

}
