import { ApiService } from './../../../services/api/api.service';
import { StateService } from '@uirouter/angular';
import { DomSanitizer } from '@angular/platform-browser';

import { UtilService } from '../../../services/util/util.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { AlertService } from '../../../services/alert/alert.service';

export abstract class PageExpenseEdit {

  readonly errorMessages: any;
  readonly expenseAmountRequiredMessage: string = 'Expense amount must be greater than 0';

  defaultExpenseDate: Date = new Date();

  isAdmin: boolean = false;
  isManager: boolean = false;

  pageTitle: string;
  errorMessage: string;

  loading: boolean = true;
  expenseAmountTouched: boolean = false;

  expenseDateString: string;

  allProjects: any[] = [];

  expense: any = null;

  currencySymbol: string;

  imageBlob: any = null;
  imageHeight: number;
  imageUrl: any = null;
  isImage: boolean = true;
  imageFullSize: boolean = false;

  showDeclineBtn: boolean = false;
  showDeleteBtn: boolean = false;
  showApproveBtn: boolean = false;
  showSaveBtn: boolean = false;

  constructor(
    public stateService: StateService,
    public domSanitizer: DomSanitizer,
    public utilService: UtilService,
    public alertService: AlertService,
    public apiService: ApiService
  ) { }

  expenseAmountUpdated() {
    this.expenseAmountTouched = true;
    this.checkForErrors();
  }

  expenseDateStringUpdated() {
    this.expense.expense_date = TimeUtilService.dateStringToDate(this.expenseDateString, null, false);
    this.updateDateStrings();
  }

  initialiseExpenseImage() {
    const imageUrl = this.stateService.params.imageUrl || null;

    // Unsaved document
    if (imageUrl) {
      this.clearExistingDocument();

      this.apiService.getBlob(imageUrl)
        .then((imageBlob) => {
          this.imageBlob = imageBlob;

          if (this.imageBlob && imageUrl) {
            this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);
          }

          this.toggleFileType();
        })
        .catch();
    }
    // Existing document
    else {
      if (this.expense.is_ph_expense) {

        if (this.expense.document_url) {
          this.imageUrl = this.utilService.ph_blob_url + this.expense.document_url;
        }
      }
      else if (this.expense.is_inv_expense) {

        if (this.expense.timesheet_attachment_url) {
          this.imageUrl = this.expense.timesheet_attachment_url;
        }
      }
      this.toggleFileType();
    }
  }

  changeDocument() {
    if (!this.expense.is_locked) {
      this.stateService.go('app.expense.camera', {
        expense: this.expense
      });
    }
  }

  clearExistingDocument() {
    if (this.expense.is_ph_expense) {
      this.expense.document_key = null;
      this.expense.document_name = null;
      this.expense.document_url = null;
    }
    else if (this.expense.is_inv_expense) {
      this.expense.timesheet_attachment_key = null;
      this.expense.timesheet_attachment_name = null;
      this.expense.timesheet_attachment_url = null;
    }
  }

  deleteDocument(event: any) {
    event.stopPropagation();
    this.imageFullSize = false;

    this.imageBlob = null;
    this.imageUrl = null;

    this.clearExistingDocument();
    this.toggleFileType();
  }

  documentUrlChanged() {
    const image = document.getElementById('app-expenseEdit-documentImage');
    this.imageHeight = image.offsetHeight;
  }

  expandImage() {
    if (this.imageUrl) {
      this.imageFullSize = !this.imageFullSize;
    }
  }

  toggleFileType() {
    if (!this.imageUrl) {
      this.isImage = false;
    }
    else if (!this.imageBlob) {
      this.isImage = !this.imageUrl.endsWith('pdf');
    }
    else {
      this.isImage = !this.imageBlob.type.includes('pdf');
    }
  }

  updateDateStrings() {
    this.expenseDateString = TimeUtilService.dateToLocalTimeISOString(this.expense.expense_date);
  }

  clearErrorIfMatches(errorToMatch: string, partMatch: boolean = false) {
    if (this.errorMessage === errorToMatch) {
      this.errorMessage = '';
    }
    else if (partMatch && this.errorMessage && this.errorMessage.indexOf(errorToMatch) !== -1) {
      this.errorMessage = '';
    }
  }

  checkForErrors() {
    this._checkNonZeroExpenseAmount();
    this._checkAnyProjectsAvailable();
  }

  private _checkAnyProjectsAvailable() {
    this.clearErrorIfMatches(this.errorMessages.noProjects);
    if (this.allProjects.length === 0 && !this.expense.segment_key && !this.expense.expense_key) {
      this.errorMessage = this.errorMessages.noProjects;
    }
  }

  private _checkNonZeroExpenseAmount() {
    this.clearErrorIfMatches(this.expenseAmountRequiredMessage);

    if (this.expense.expense_amount === 0 && this.expenseAmountTouched) {
      this.errorMessage = this.expenseAmountRequiredMessage;
    }
  }

}
