import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from 'src/app/services/state-change/state-change.service';
import { ProjectService } from '../../../services/project/project.service';
import { CompanyService } from './../../../services/company/company.service';
import { EmployeeService } from './../../../services/employee/employee.service';

@Component({
  selector: 'app-time-work',
  templateUrl: './time-work.component.html',
  styleUrls: ['./time-work.component.scss']
})
export class TimeWorkComponent implements OnInit {

  loading: boolean = true;

  employee: any;
  selectedProject: any;
  allProjects: any = [];
  defaultProjects: any = [];

  constructor(public stateService: StateService,
              public stateAccessService: StateAccessService,
              public stateChangeService: StateChangeService,
              public companyService: CompanyService,
              public employeeService: EmployeeService,
              public projectService: ProjectService) {

  }

  ngOnInit(){
    this.loading = true;
    this.defaultProjects = [];

    const employee_key = this.stateService.params.employee_key || null;
    this.employee = employee_key ? this.employeeService.getSingleEmployee(employee_key) : this.employeeService.getLoggedInEmployee();

    this.allProjects = this.projectService.getAllTimesheetProjects(false);

    this.projectService.getSingleEmployeeDefaultProjectKeys(this.companyService.getEmployeeKey())
      .then((keys: Array<number>) => {

        for (const key of keys){
          this.defaultProjects.push(this.projectService.getSingleProject(key));
        }

        this.setDefaultSelectedProject();
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  back(){
    this.stateChangeService.back();
  }

  setDefaultSelectedProject(){
    if (this.defaultProjects.length){
      const defaultProject = this.defaultProjects[0];

      // Have to set direct references to items in allProjects
      // to ensure ionic dropdown lists project correctly
      for (const project of this.allProjects){

        if (project.project_key === defaultProject.project_key){
          this.selectedProject = project;
          this.defaultProjects.splice(0, 1);
        }
      }
    }

    if (!this.selectedProject){
      this.selectedProject = this.allProjects[0];
    }
  }

  newSegment(project_key: number){
    const segments = this.stateService.params.segments || [];
    this.stateService.go('app.time.edit', {
      project_key,
      employee_key: this.employee.employee_key,
      segments
     });
  }

  projectSelected(event: any){
    this.selectedProject = event.item;
  }

}
