<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="ph-payView-payDate"
       *ngIf="!loading">

    {{payEmployee.start_date | date : "dd"}} -
    {{payEmployee.end_date | date : "dd MMM,"}}
    <span>{{payEmployee.end_date | date : "yyyy"}}</span>
  </div>

  <div class="ph-statRow ph-payView-hoursDaysWorked"
       *ngIf="!loading && (payEmployee.days_worked || payEmployee.pay_hours)">
    <div class="ph-stat">
      <div class="ph-statTitle">
        Days:
      </div>
      <div class="ph-statValue">
        {{payEmployee.days_worked}}
      </div>
    </div>
    <div class="ph-stat">
      <div class="ph-statTitle">
        Hours:
      </div>
      <div class="ph-statValue">
        {{payEmployee.pay_hours}}
      </div>
    </div>
  </div>

  <div class="ph-payView-container">

    <div class="ph-payView-lineGroup"
         *ngFor="let lineTotal of payLineTotals">
      <div class="ph-payView-lineGroupName">
        {{lineTotal.type}}:
      </div>
      <div class="ph-payView-lineGroupTotal">
        {{lineTotal.value | currency}}
      </div>
    </div>
  </div>

  <button *ngIf="!loading"
          (click)="downloadPay()"
          class="-color-primary ph-payView-downloadPay">
    Download Payslip
  </button>

</ion-content>
