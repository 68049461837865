import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';

import { InvExpenseComponent } from './inv-expense.component';
import { InvExpenseResourceComponent } from './inv-expense-resource/inv-expense-resource.component';
import { InvExpenseEditComponent } from './inv-expense-edit/inv-expense-edit.component';
import { InvExpenseManagerComponent } from './inv-expense-manager/inv-expense-manager.component';

export const INV_EXPENSE_CONFIG: any[] = [
  {
    name: 'app.inv.expense',
    url: '/expense',
    abstract: true,
    component: InvExpenseComponent
  },
  {
    name: 'app.inv.expense.edit',
    url: '/edit?{segment_key:int}&{user_key:int}',
    component: InvExpenseEditComponent,
    params: {
      segment_key: null,
      user_key: null,
      expense: null,
      expense_date: null,
      imageUrl: null
    }
  },
  {
    name: 'app.inv.expense.resource',
    url: '/resource?{user_key:int}',
    component: InvExpenseResourceComponent,
    parms: {
      user_key: null,
      selected_month: null
    }
  },
  {
    name: 'app.inv.expense.manager',
    url: '/manager',
    component: InvExpenseManagerComponent,
    parms: {
      selected_month: null
    }
  }
];

@NgModule({
  declarations: [
    InvExpenseComponent,
    InvExpenseResourceComponent,
    InvExpenseEditComponent,
    InvExpenseManagerComponent
  ],
  imports: [
    ComponentsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    UIRouterModule.forChild({ states: INV_EXPENSE_CONFIG })
  ]
})
export class InvExpenseModule { }
