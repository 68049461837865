<ion-menu id="payheroMenu"
          content-id="ph-app"
          type="overlay">

  <ion-header no-border>
    <ion-toolbar>
      <ion-title>{{currentCompany ? currentCompany.company_name : ''}}</ion-title>
      <ion-buttons slot="end">
        <button class="-color-white-clear -type-icon"
                (click)="goToCompanySelect()">
          <i class="ion ion-md-swap"></i>
        </button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div class="ph-menu-itemsContainer">

    <div class="ph-menu-group"
         *ngFor="let group of nav; last as isLast"
         [ngStyle]="{ 'margin-bottom': isLast ? '60px' : '' }">
      <div class="ph-menu-groupName"
           (click)="goToState(group.state, group.params)">
        <span>{{group.name}}</span>

        <i class="ion"
           *ngIf="group.icon"
           [ngClass]="group.icon">
        </i>
      </div>
      <div class="ph-menu-item"
           *ngFor="let item of group.states"
           (click)="goToState(item.state, item.params)">
        <i class="ion"
           [ngClass]="item.icon">
        </i>
        {{item.name}}
        <i class="ion ion-ios-arrow-forward">
        </i>
      </div>
    </div>

    <div class="ph-menu-group -logout">
      <div class="ph-menu-item"
           (click)="manualLogout()">
        <i class="ion ion-md-log-out">
        </i>
        Sign Out
      </div>
      <div class="ph-menu-versionNo">
        v{{versionNumber}}
      </div>
    </div>
  </div>

</ion-menu>
<ui-view id="ph-app"></ui-view>
