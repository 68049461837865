<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content [hidden]="loading">

  <div class="ph-timeWork-selectedHeader">
    Record Time
  </div>

  <div class="ph-recentWork-selectorContainer">
    <coloured-item-selector [allItems]="allProjects"
                            [showConfirmButton]="true"
                            [itemType]="'Work'"
                            [itemKeyProperty]="'project_key'"
                            [itemNameProperty]="'project_title'"
                            [itemColourProperty]="'project_colour'"
                            [selectedItem]="selectedProject"
                            (itemSelected)="projectSelected($event)"
                            (itemConfirmed)="newSegment(selectedProject.project_key)">
    </coloured-item-selector>
  </div>


  <div class="ph-recentWork-header">
    <div>Recent Work</div>
    <div></div>
  </div>

  <div class="ph-recentWork-container"
       *ngFor="let project of defaultProjects"
       (click)="newSegment(project.project_key)"
       [ngStyle]="{'background-color': project.project_colour}">
    <div class="ph-recentWork-title">
      {{project.project_title}}
    </div>
    <i class="ion ion-ios-arrow-forward"></i>
  </div>

</ion-content>
