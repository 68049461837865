import { Component, OnInit } from '@angular/core';

import { PayViewComponent } from './pay-view/pay-view.component';
import { PayEmployeeComponent } from './pay-employee/pay-employee.component';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export const PAY_STATE_CONFIG = [
  {
    name: 'app.pay',
    url: '/pay',
    abstract: true,
    component: PayComponent
  },
  {
    name: 'app.pay.employee',
    url: '/employee',
    component: PayEmployeeComponent
  },
  {
    name: 'app.pay.view',
    url: '/view',
    component: PayViewComponent
  },
];
