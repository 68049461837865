<div class="onboard-step-type">
  ATO Tax File Number Declaration Form
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">
  <div class="onboard-step-ato-container">

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        What is your Tax File Number (TFN)?
      </div>

      <input class="ph-formItem-input"
             type="text"
             placeholder="Tax File Number"
             mask="000-000-000"
             [dropSpecialCharacters]="true"
             [disabled]="tfnExemptionOptions[0] || tfnExemptionOptions[1] || tfnExemptionOptions[2]"
             [(ngModel)]="tfnNumber"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="validateTfnNumber()"
             (blur)="validateTfnNumber()"
             (focus)="validateTfnNumber()">

      <div class="ph-inputErrorMessage"
           *ngIf="validationFlags.tfn_missing">
        <i class="ion ion-ios-warning"></i>
        Tax File Number Required
      </div>
      <div class="ph-inputErrorMessage"
           *ngIf="validationFlags.invalid_tfn">
        <i class="ion ion-ios-warning"></i>
        Invalid Tax File Number
      </div>
    </div>
    <div class="onboard-step-ato-divider">
      OR
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="tfnExemptionOptions[0]"
                   (click)="tfnExemptionOptionToggled(0)">
      </ph-checkbox>
      <span>I have made a separate application/enquiry to the ATO for a new or existing TFN</span>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="tfnExemptionOptions[1]"
                   (click)="tfnExemptionOptionToggled(1)">
      </ph-checkbox>
      <span>I am claiming an exemption because I am under 18 years of age and do no earn enough to pay tax</span>
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="tfnExemptionOptions[2]"
                   (click)="tfnExemptionOptionToggled(2)">
      </ph-checkbox>
      <span>I am claiming an exemption because I am in receipt of a pension, benefit or allowance</span>
    </div>

    <div class="ph-formItem -previousName">
      <div class="ph-formItem-label">
        If you have changed your name since you last dealt with the ATO, provide your previous family name
      </div>
      <input class="ph-formItem-input"
             type="text"
             placeholder="Previous Family Name"
             [(ngModel)]="previousSurname">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        On what basis are you paid?
      </div>
      <div class="ph-inputErrorMessage"
           *ngIf="validationFlags.pay_basis_missing">
        <i class="ion ion-ios-warning"></i>
        Payment Basis Required
      </div>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="payBasisOptions[0]"
                   (click)="payBasisOptionToggled(0)">
      </ph-checkbox>
      <span>{{payBasisLabels[0]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="payBasisOptions[1]"
                   (click)="payBasisOptionToggled(1)">
      </ph-checkbox>
      <span>{{payBasisLabels[1]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="payBasisOptions[2]"
                   (click)="payBasisOptionToggled(2)">
      </ph-checkbox>
      <span>{{payBasisLabels[2]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="payBasisOptions[3]"
                   (click)="payBasisOptionToggled(3)">
      </ph-checkbox>
      <span>{{payBasisLabels[3]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="payBasisOptions[4]"
                   (click)="payBasisOptionToggled(4)">
      </ph-checkbox>
      <span>{{payBasisLabels[4]}}</span>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Are you an Australian resident for tax purposes?
      </div>
      <div class="ph-inputErrorMessage"
           *ngIf="validationFlags.residency_status_missing">
        <i class="ion ion-ios-warning"></i>
        Residency Status Required
      </div>
    </div>
    <div class="onboard-step-ato-info">
      <a href="https://ato.gov.au/residency"
         target="_blank">Tap Here</a> for more information
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="residenceStatusOptions[0]"
                   (click)="residenceStatusOptionToggled(0)">
      </ph-checkbox>
      <span>{{residenceStatusLabels[0]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="residenceStatusOptions[1]"
                   (click)="residenceStatusOptionToggled(1)">
      </ph-checkbox>
      <span>{{residenceStatusLabels[1]}}</span>
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="residenceStatusOptions[2]"
                   (click)="residenceStatusOptionToggled(2)">
      </ph-checkbox>
      <span>{{residenceStatusLabels[2]}}</span>
    </div>

    <div class="ph-formItem"
         *ngIf="residenceStatusOptions[0]">
      <div class="ph-formItem-label">
        Do you want to claim the tax-free threshold from this payer?
      </div>
    </div>
    <div class="onboard-step-ato-info"
         *ngIf="residenceStatusOptions[0]">
      Only claim the tax-free threshold from one payer at a time, unless your total income from all sources for the
      financial year will be less than the tax-free threshold
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption"
         *ngIf="residenceStatusOptions[0]">
      <ph-checkbox [(ngModel)]="taxFreeThreshold"></ph-checkbox>
      <span [hidden]="!taxFreeThreshold">Yes</span>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Do you have any debt from a Higher Education Loan Program (HELP), Student Start-up Loan (SSL) or Trade Support
        Loan (TSL)?
      </div>
    </div>
    <div class="onboard-step-ato-info">
      Your payer will withhold the additional amounts to cover any compulsory repayment that may be raised on your
      notice of assessment
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="helpDebt"></ph-checkbox>
      <span [hidden]="!helpDebt">Yes</span>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Do you have a Financial Supplement debt?
      </div>
    </div>
    <div class="onboard-step-ato-info">
      Your payer will withhold the additional amounts to cover any compulsory repayment that may be raised on your
      notice of assessment
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="financialSupplementDebt"></ph-checkbox>
      <span [hidden]="!financialSupplementDebt">Yes</span>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Do you want to claim the seniors and pensioners tax offset by reducing the amount withheld from payments made to
        you?
      </div>
    </div>
    <div class="onboard-step-ato-checkboxOption -lastOption">
      <ph-checkbox [(ngModel)]="seniorsTaxOffset"></ph-checkbox>
      <span [hidden]="!seniorsTaxOffset">Yes</span>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Do you have an Approved Withholding Variation?
      </div>
    </div>
    <div class="onboard-step-ato-checkboxOption">
      <ph-checkbox [(ngModel)]="approvedWithholdingVariation"
                   (ngModelChange)="approvedWithholdingVariationToggled()">
      </ph-checkbox>
      <span [hidden]="!approvedWithholdingVariation">Yes</span>
    </div>

    <div class="ph-formItem -previousName"
         *ngIf="approvedWithholdingVariation">
      <div class="ph-formItem-label">
        Variation Percentage (%)
      </div>
      <input class="ph-formItem-input"
             type="number"
             placeholder="Variation Percentage"
             [(ngModel)]="approvedWithholdingVariationPercentage"
             (ngModelChange)="validateVariationPercentage()"
             (blur)="validateVariationPercentage()"
             (focus)="validateVariationPercentage()">
      <div class="ph-inputErrorMessage"
           *ngIf="validationFlags.variation_percentage_missing">
        <i class="ion ion-ios-warning"></i>
        Variation Percentage Required
      </div>
    </div>

  </div>
</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
