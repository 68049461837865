import { Component, OnInit, Input, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Component({
  selector: 'slideup-list',
  templateUrl: './slideup-list.component.html',
  styleUrls: ['./slideup-list.component.scss']
})
export class SlideupListComponent implements OnInit {

  @HostBinding('class.-overlayVisible') overlayVisible: boolean = false;

  @Input() allItems: any[];
  @Input() selectedItem: any;
  @Input() itemNameProperty: string;
  @Input() itemColourProperty: string;

  @Output() slideupListClosed = new EventEmitter();
  @Output() itemSelected = new EventEmitter();

  containerHeight: number = 0;

  @HostListener('click') onClick() {
    this.closeContainer();
  }

  constructor() { }

  ngOnInit() {
    this.openContainer();
  }

  openContainer() {
    setTimeout(() => {
      this.overlayVisible = true;
      this.containerHeight = 50 * this.allItems.length + 60 + 15;
    }, 50);
  }

  closeContainer() {
    this.containerHeight = 0;
    this.overlayVisible = false;

    setTimeout(() => {
      this.slideupListClosed.emit();
    }, 200);
  }

  selectItem(item: any){
    this.itemSelected.emit({ item });
    this.closeContainer();
  }

}
