import { Injectable } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { MenuController } from '@ionic/angular';

import { StateDataService } from './../state-data/state-data.service';
import { StateAccessService } from './../state-access/state-access.service';
import { AuthService } from './../auth/auth.service';
import { UtilService } from './../util/util.service';
import { AlertService } from './../alert/alert.service';
import { TokenService } from './../token/token.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  private readonly backStatesToIgnore: string[] = [
    'splash', 'companySelect'
  ];
  private readonly backStackSize: number = 50;
  private backStack: any[];

  constructor(
    private stateDataService: StateDataService,
    private stateAccessService: StateAccessService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private authService: AuthService,
    private alertService: AlertService,
    private tokenService: TokenService,
    public menuController: MenuController
  ) {

    this.backStack = this.stateDataService.backStack || [];
  }

  initTransitionListeners() {
    this.listenForAppInit();
    this.listenForStateChangeStart();
    this.listenForStateChangeSuccess();
    this.listenForNoAuthStateChangedSuccess();
  }

  get previousStateName() {
    return this.backStack.length ? this.backStack[0].name : null;
  }

  toggleMenu() {
    this.menuController.toggle();
  }

  guardUnsavedChanges(currentData: any, originalData: any, saveFunction: any) {
    const transitionHook = this.transitionService.onStart({}, () => {

      if (!UtilService.isLooselyEqual(currentData, originalData, null)) {
        return this.alertService.unsavedChangesAlert()
          .then((saveChanges) => {
            // Save
            if (saveChanges) {
              return saveFunction()
                .then(() => {
                  transitionHook();
                  return true;
                })
                .catch(() => {
                  return false;
                });
            }
            // Discard
            else {
              transitionHook();
              return true;
            }
          })
          // Cancel
          .catch(() => {
            return false;
          });
      }
      else {
        transitionHook();
        return true;
      }
    });

    return transitionHook;
  }

  back(statesToSkip: string[] = [], params: any = {}) {
    const stateIndex = this.getNextBackStateIndex(statesToSkip);

    if (stateIndex !== null) {
      const backState = this.backStack[stateIndex];

      setTimeout(() => {
        this.stateService.go(
          backState.name,
          StateChangeService.mergeOldAndNewParams(backState.params, params),
          { reload: true, custom: { discardFromBackStack: true } }
        )
          .then(() => {
            // Remove previous state from back stack along with any states to skip
            // that were in between the previous state and the new current state
            this.backStack = this.backStack.slice(stateIndex, backState.length);
          })
          .catch(() => {
            // Usually caused by a user cancelling a back action due to unsaved changes
          });
      });
    }
    else {
      return;
    }
  }

  getNextBackStateIndex(statesToSkip: string[]) {
    const skipStatesMap = {};

    if (statesToSkip) {
      for (const skipState of statesToSkip) {
        skipStatesMap[skipState] = true;
      }
    }

    if (!this.backStack.length) {
      return null;
    }
    else {
      for (let i = 0; i < this.backStack.length; i++) {
        const state = this.backStack[i];

        // Return state if it is different from the current state and isn't a skipState
        if (!skipStatesMap[state.name] &&
          state.name !== this.stateService.current.name) {
          return i;
        }
      }
      return null;
    }
  }

  pushToBackStack(stateName: string, stateParams: any) {
    if (this.backStatesToIgnore.indexOf(stateName) === -1) {
      // No point adding the same state multiple times in a row
      if (!this.backStack.length ||
        this.backStack[0].name !== stateName ||
        JSON.stringify(this.backStack[0].params) !== JSON.stringify(stateParams)) {

        if (this.backStack.length >= this.backStackSize) {
          this.backStack = this.backStack.slice(0, this.backStackSize - 1);
        }

        this.backStack.unshift({
          name: stateName,
          params: stateParams
        });

        this.stateDataService.backStack = this.backStack;
      }
    }
  }

  // Only triggered when app is refreshed.
  // Before loading state user was on before refresh,
  // first temporarily redirect them to the splash screen so the stateAccessService and
  // ensure all service data is loaded and user still has access to the previous state
  listenForAppInit() {
    this.transitionService.onStart({ from: StateChangeService.isRefreshState, to: 'app.**' }, (trans) => {
      const stateService = trans.router.stateService;
      const to_state = {
        name: trans.to().name,
        params: trans.params('to') || null
      };

      if (this.isAuthenticated()) {
        if (trans.to().name !== 'splash') {

          return stateService.target('splash', {
            refreshStateName: to_state.name,
            refreshStateParams: to_state.params
          });
        }
      }
      else {
        return this.tokenService.tryTokenLogin(to_state.name, to_state.params)
          .catch(() => {
            return stateService.target('login');
          });
      }
    });
  }

  // States starting with 'app.' require authentication to access.
  // The above function is triggered instead of this one if
  // the user has just refreshed the page
  listenForStateChangeStart() {
    this.transitionService.onStart({ from: StateChangeService.notRefreshState, to: 'app.**' }, (trans) => {
      const state = trans.router.stateService;

      if (this.isAuthenticated()) {

        return this.stateAccessService.ensureServicesInitialised()
          .then(() => {
            return true;
          })
          .catch(() => {
            this.authService.tryTokenLogin();
          });
      }
      else {
        return state.target('login');
      }
    });
  }

  isAuthenticated() {
    if (this.stateAccessService.isInvoxyUser) {
      return this.authService.hasSession('INV');
    }
    else {
      return this.authService.hasSession('PH');
    }
  }

  listenForStateChangeSuccess() {
    this.transitionService.onSuccess({}, (trans) => {
      const fromName = trans.from().name;

      const toName = trans.to().name;
      const toParams = trans.params('to');

      if (toName && toName !== fromName) {
        // This value will be true when transition is triggered by this.back()
        if (!trans.options().custom.discardFromBackStack) {
          this.pushToBackStack(toName, toParams);
        }
      }
    });
  }

  // Ensure service data is cleared when not logged in
  listenForNoAuthStateChangedSuccess() {
    this.transitionService.onSuccess({ to: StateChangeService.noAuthState }, () => {
      this.stateAccessService.clearServiceData();
    });
  }

  static isRefreshState(state: any) {
    return state.name === '';
  }

  static notRefreshState(state: any) {
    return state.name !== '';
  }

  static noAuthState(state: any) {
    return state.name.indexOf('app.') === -1;
  }

  /**
   * Merges two state param objects. Values in newParams take precedence
   * if both objects contain different values for the same key
   */
  static mergeOldAndNewParams(oldParams: any = {}, newParams: any = {}): any {
    const params = _.cloneDeep(newParams);

    for (const key of Object.keys(oldParams)) {
      if (!params[key]) {
        params[key] = oldParams[key];
      }
    }

    return params;
  }

}
