<div class="onboard-step-type">
  NZ Tax Details Form
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">
  <div class="onboard-step-nztax-container">

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Tax Code
      </div>

      <button class="ph-formItem-inputButton"
              (click)="openTaxCodeList()">
        {{taxCode}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        KiwiSaver Contribution Rate
      </div>

      <button class="ph-formItem-inputButton"
              (click)="openKiwiSaverRateList()">
        {{kiwiSaverRate ? kiwiSaverRate.label : ''}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

  </div>
</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
