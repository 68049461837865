import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { TeamService } from '../../../services/team/team.service';
import { EmployeeService } from '../../../services/employee/employee.service';
import { CompanyService } from '../../../services/company/company.service';
import { StateChangeService } from '../../../services/state-change/state-change.service';

@Component({
  selector: 'app-team-select',
  templateUrl: './team-select.component.html',
  styleUrls: ['./team-select.component.scss']
})
export class TeamSelectComponent implements OnInit {

  managerTeams: any[];
  allEmployees: any[];

  selectedTeam: any = null;
  currentCompany: any = null;

  multipleCompanyTeams: boolean;

  constructor(public stateService: StateService,
              public stateChangeService: StateChangeService,
              public stateAccessService: StateAccessService,
              public teamService: TeamService,
              public employeeService: EmployeeService,
              public companyService: CompanyService) {
  }

  ngOnInit(){
    this.currentCompany = this.companyService.getCurrentCompany();
    this.multipleCompanyTeams = this.currentCompany.multiple_company_teams_flag;

    this.managerTeams = this.teamService.getManagerTeams();
    this.allEmployees = this.employeeService.getAllEmployees(false, true);

    this.selectedTeam = this.teamService.getSelectedTeam();
  }

  setSelectedTeam(team: any){
    this.teamService.setSelectedTeam(team);
    this.selectedTeam = this.teamService.getSelectedTeam();

    this.stateChangeService.back();
  }

}
