import { InvSegment } from './../../models/segment/inv-segment';
import { Injectable } from '@angular/core';

import { TimeUtilService } from '../time-util/time-util.service';
import { UtilService } from '../util/util.service';
import { InvTimeService } from '../inv-time/inv-time.service';
import { InvProjectService } from '../inv-project/inv-project.service';
import { InvCompanyService } from '../inv-company/inv-company.service';
import { ClockService } from './../clock/clock.service';
import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';
import { InvUserUtilService } from './../inv-user-util/inv-user-util.service';
import { StateDataService } from './../state-data/state-data.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvClockService {

  public records: any = [];
  public activeRecord: any;
  public activeRecordPauseStart: any = null;
  public activeRecordTimer: any;

  constructor(
    public utilService: UtilService,
    public invTimeService: InvTimeService,
    public invCompanyService: InvCompanyService,
    public invProjectService: InvProjectService,
    public stateDataService: StateDataService
  ) {

  }

  loadClock(clock_key: number) {
    return new Promise<any>((resolve, reject) => {

      const params = clock_key ? { clock_key } : null;

      this._getClocks(params)
        .then((data) => {
          if (data.length) {
            resolve(this.setupClocks(data)[0]);
          }
          else {
            resolve(null);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadUserClock(user_key: number) {
    return new Promise<any>((resolve, reject) => {

      const params = user_key ? { user_key } : null;
      this._getClocks(params)
        .then((data) => {
          if (data.length) {
            resolve(this.setupClocks(data)[0]);
          }
          else {
            resolve(null);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadClocks(user_key: number = null) {
    return new Promise<any>((resolve, reject) => {

      const params = user_key ? { user_key } : null;

      this._getClocks(params)
        .then((data) => {
          this.setupClocks(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  clockIn(user_key: number, project_key: number = null, task_key: number = null) {
    if (!project_key && !task_key) {
      const recentClockProjectTasks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'recentClockProjectTasks') || {};

      if (recentClockProjectTasks[user_key]) {
        project_key = recentClockProjectTasks[user_key].project_key;
        task_key = recentClockProjectTasks[user_key].task_key;
      }
    }

    const data = {
      user_key,
      clock_in_time: TimeUtilService.dateToDateTimeString(new Date(), null),
      project_key,
      task_key
    };

    return this._setClock(data);
  }

  clockOut(clock: any) {
    const data = {
      clock_key: clock.clock_key,
      user_key: clock.user.user_key,
      clock_out_time: TimeUtilService.dateToDateTimeString(new Date(), null)
    };

    return this._setClock(data);
  }

  breakIn(clock: any) {
    const data = {
      clock_key: clock.clock_key,
      user_key: clock.user.user_key,
      start_time: TimeUtilService.dateToDateTimeString(new Date(), null)
    };

    return this._setClockBreak(data);
  }

  breakOut(clock: any) {
    let clock_break_key = null;

    for (const cb of clock.clock_breaks) {
      if (!cb.end_time) {
        clock_break_key = cb.clock_break_key;
        break;
      }
    }

    const data = {
      clock_key: clock.clock_key,
      user_key: clock.user.user_key,
      clock_break_key,
      end_time: TimeUtilService.dateToDateTimeString(new Date(), null)
    };

    return this._setClockBreak(data);
  }

  postClockProjectTask(clock_key: number, user_key: number, project_key: number, task_key: number) {
    const data = {
      clock_key,
      project_key,
      task_key
    };

    this.updateRecentClockProjectTask(user_key, project_key, task_key);
    return this._setClockProjectTask(data);
  }

  postClockNote(clock: any, note: string, clock_note_key: number = null, deleted_flag: boolean = false) {
    const data = {
      clock_key: clock.clock_key,
      user_key: clock.user.user_key,
      note,
      deleted_flag,
      clock_note_key
    };

    return this._setClockNote(data);
  }

  updateRecentClockProjectTask(user_key: number, project_key: number, task_key: number) {
    const recentClockProjectTasks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'recentClockProjectTasks') || {};

    recentClockProjectTasks[user_key] = {
      project_key,
      task_key
    };

    this.stateDataService.cacheComponentLocalData('InvClockService', 'recentClockProjectTasks', recentClockProjectTasks);
  }

  setupClocks(clocks: any[]): any[] {
    for (let i = clocks.length - 1; i >= 0; i--) {
      const clock = this.setupClock(clocks[i]);

      // Valid request
      if (clock !== null) {
        clocks[i] = clock;
      }
      // Remove invalid requests
      else {
        clocks.splice(i, 1);
      }
    }

    return clocks;
  }

  setupClock(clock: any): any {
    const project = this.invProjectService.getSingleProject(clock.project_key);

    if (project) {
      const user = InvUserUtilService.getUserFromList(clock.user_key, project.users);
      const task = InvTaskUtilService.getTaskFromList(clock.task_key, project.tasks);

      if (task && user) {
        clock.project = project;
        clock.task = task;
        clock.user = user;

        clock.clock_in_time = TimeUtilService.dateTimeStringToDate(clock.clock_in_time, null, false);
        clock.clock_out_time = TimeUtilService.dateTimeStringToDate(clock.clock_out_time, null, false);

        clock.is_multiple_days = moment(clock.clock_in_time).isBefore(moment(), 'days');
        clock.is_on_break = false;

        for (const cb of clock.clock_breaks) {
          cb.start_time = TimeUtilService.dateTimeStringToDate(cb.start_time, null, false);
          cb.end_time = TimeUtilService.dateTimeStringToDate(cb.end_time, null, false);

          if (cb.end_time === null) {
            clock.is_on_break = true;
          }
        }

        ClockService.sortClockBreaks(clock.clock_breaks);

        return clock;
      }
    }
    return null;
  }

  private _getClocks(params: any) {
    return new Promise<any>((resolve) => {
      const data = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
      const clocks = [];

      for (const clock of data) {
        const userKeyMatches = !params.user_key || clock.user_key === params.user_key;
        const clockKeyMatches = !params.clock_key || clock.clock_key === params.clock_key;

        if (userKeyMatches && clockKeyMatches) {
          clocks.push(clock);
        }
      }

      resolve(clocks);
    });
  }

  private _generateSegmentToPostFromClock(clock: any) {
    return new Promise<any>((resolve, reject) => {
      try {
        const segment_date = TimeUtilService.dateStringToDate(clock.clock_in_time);

        const start_time = TimeUtilService.dateTimeStringToDate(clock.clock_in_time);
        const end_time = TimeUtilService.dateTimeStringToDate(clock.clock_out_time);

        // Don't create segment if total duration is less than a minute
        if (moment(start_time).isSame(moment(end_time), 'minutes')) {
          resolve(null);
        }
        else {
          let break_duration = 0;
          for (const clock_break of clock.clock_breaks) {
            break_duration += clock_break.break_duration;
          }

          let duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(
            start_time, end_time
          );
          duration -= break_duration;

          let notes = '';
          for (const clock_note of clock.clock_notes) {
            notes += clock_note.note;
            notes += '\n';
          }

          const project = this.invProjectService.getSingleProject(clock.project_key);

          resolve(new InvSegment(
            null,
            false,
            null,
            false,
            false,
            segment_date,
            start_time,
            end_time,
            break_duration,
            0,
            clock.company_key,
            notes,
            project,
            clock.user_key,
            clock.task_key,
            this.invCompanyService.expensesEnabled()
          ));
        }
      }
      catch (err) {
        reject(err);
      }
    });
  }

  private _setClock(data: any) {
    return new Promise<any>((resolve, reject) => {
      // Clock in
      if (!!data.clock_in_time) {
        // Set default project_key and task_key if not provided
        if (!data.project_key || !data.task_key) {
          const today = new Date();
          const activeProjcts = this.invProjectService.getAllActiveProjects(
            today, today, true, true, false, data.user_key
          );
          const defaultKeys = InvClockService._getDefaultClockProjectAndTaskKey(activeProjcts);

          data.project_key = defaultKeys.project_key;
          data.task_key = defaultKeys.task_key;
        }

        const clock = {
          clock_key: this._generateUniqueClockKey(),
          company_key: this.invCompanyService.getCompanyKey(),
          clock_in_time: TimeUtilService.dateToDateTimeString(new Date(), null),
          clock_out_time: null,
          project_key: data.project_key,
          task_key: data.task_key,
          user_key: data.user_key,
          clock_breaks: [],
          clock_notes: []
        };

        this._updateClockInCache(clock);
        resolve();
      }
      // Clock out
      else if (!!data.clock_key && !!data.clock_out_time) {
        const clock = this._getExistingClockFromCache(data.clock_key);

        if (clock !== null) {
          clock.clock_out_time = data.clock_out_time;

          this._generateSegmentToPostFromClock(clock)
            .then((segment) => {

              // Clock was less than a minute so no need to post a segment for it
              if (segment === null) {
                this._removeClockFromCache(clock.clock_key);
                resolve();
              }
              else {
                this.invTimeService.saveSegments([segment])
                  .then(() => {
                    this._removeClockFromCache(clock.clock_key);
                    resolve();
                  })
                  .catch(() => {
                    reject('Failed to save segment from clock record');
                  });
              }
            })
            .catch(() => {
              reject('Failed to generate a valid segment from the clock record');
            });
        }
        else {
          reject('Couldn\'t find existing clock record to update');
        }
      }
      else {
        reject('Clock in time or clock out time required');
      }
    });
  }

  private _setClockProjectTask(data: any) {
    return new Promise<any>((resolve, reject) => {
      const clock = this._getExistingClockFromCache(data.clock_key);

      if (clock !== null) {
        clock.project_key = data.project_key;
        clock.task_key = data.task_key;

        this._updateClockInCache(clock);
        resolve();
      }
      else {
        reject('Couldn\'t find existing clock record to update');
      }
    });
  }

  private _setClockBreak(data: any) {
    return new Promise<any>((resolve, reject) => {
      const clock = this._getExistingClockFromCache(data.clock_key);

      if (clock !== null) {
        const activeClockBreakIndex = InvClockService._getActiveClockBreakIndex(clock);

        // Attempting to start a new break without finishing previous break
        if (activeClockBreakIndex !== null && !!data.start_time) {
          reject('Can\'t start a new break without first finishing the current break');
        }
        // clock_break_key provided doesn't match currently active break's clock_break_key
        else if (
          activeClockBreakIndex !== null &&
          clock.clock_breaks[activeClockBreakIndex].clock_break_key !== data.clock_break_key
        ) {
          reject('Invalid clock_break_key provided');
        }
        else {
          // Break in
          if (!!data.start_time) {
            clock.clock_breaks.push({
              clock_break_key: this._generateUniqueClockBreakKey(),
              clock_key: clock.clock_key,
              start_time: TimeUtilService.dateToDateTimeString(new Date(), null),
              end_time: null,
              break_duration: null
            });

            this._updateClockInCache(clock);
            resolve();
          }
          // Break out
          else if (!!data.clock_break_key && !!data.end_time) {
            const clock_break = clock.clock_breaks[activeClockBreakIndex];
            const start_time = TimeUtilService.dateTimeStringToDate(clock_break.start_time, null);
            const end_time = new Date();

            clock_break.end_time = TimeUtilService.dateToDateTimeString(end_time, null);
            clock_break.break_duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(start_time, end_time);

            // Clock breaks with a duration less than a minute should be ignored
            if (moment(start_time).isSame(moment(end_time), 'minutes')) {
              clock.clock_breaks.splice(activeClockBreakIndex, 1);
            }

            this._updateClockInCache(clock);
            resolve();
          }
          else {
            reject('Break start time or break end time required');
          }
        }
      }
      else {
        reject('Couldn\'t find existing clock record to update');
      }
    });
  }

  private _setClockNote(data: any) {
    return new Promise<any>((resolve, reject) => {
      const clock = this._getExistingClockFromCache(data.clock_key);

      if (clock !== null) {
        // Existing clock note
        if (data.clock_note_key !== null) {
          const noteIndex = InvClockService._getExistingClockNoteIndex(clock, data.clock_note_key);

          if (noteIndex !== null) {
            // Delete existing clock note
            if (data.deleted_flag === true) {
              clock.clock_notes.splice(noteIndex, 1);
            }
            // Update existing clock note
            else {
              clock.clock_notes[noteIndex].note = data.note;
            }

            this._updateClockInCache(clock);
            resolve();
          }
          else {
            reject('Couldn\'t find existing clock note to update');
          }
        }
        // New clock note
        else {
          clock.clock_notes.push({
            clock_note_key: this._generateUniqueClockNoteKey(),
            clock_key: clock.clock_key,
            note: data.note
          });

          this._updateClockInCache(clock);
          resolve();
        }
      }
      else {
        reject('Couldn\'t find existing clock record to update');
      }
    });
  }

  private _getExistingClockFromCache(clock_key: number): any {
    const clockData = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];

    for (const data of clockData) {
      if (data.clock_key === clock_key) {
        return data;
      }
    }
    return null;
  }

  private _updateClockInCache(clock: any) {
    const clocks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
    let clockIndex = null;

    for (let i = 0; i < clocks.length; i++) {
      if (clocks[i].clock_key === clock.clock_key) {
        clockIndex = i;
        break;
      }
    }

    if (clockIndex !== null) {
      clocks[clockIndex] = clock;
    }
    else {
      clocks.push(clock);
    }

    this.stateDataService.cacheComponentLocalData('InvClockService', 'clocks', clocks);
  }

  private _removeClockFromCache(clock_key: number) {
    const clocks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
    let clockIndex = null;

    for (let i = 0; i < clocks.length; i++) {
      if (clocks[i].clock_key === clock_key) {
        clockIndex = i;
        break;
      }
    }

    if (clockIndex !== null) {
      clocks.splice(clockIndex, 1);
    }

    this.stateDataService.cacheComponentLocalData('InvClockService', 'clocks', clocks);
  }

  private _generateUniqueClockKey(): number {
    const clocks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
    let clock_key = InvClockService._generateKey();
    let keyIsUnique = false;

    while (keyIsUnique !== true) {
      keyIsUnique = InvClockService._clockKeyIsUnique(clocks, clock_key);

      if (!keyIsUnique) {
        clock_key = InvClockService._generateKey();
      }
    }

    return clock_key;
  }

  private _generateUniqueClockNoteKey(): number {
    const clocks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
    let clock_note_key = InvClockService._generateKey();
    let keyIsUnique = false;

    while (keyIsUnique !== true) {
      keyIsUnique = InvClockService._clockNoteKeyIsUnique(clocks, clock_note_key);

      if (!keyIsUnique) {
        clock_note_key = InvClockService._generateKey();
      }
    }

    return clock_note_key;
  }

  private _generateUniqueClockBreakKey(): number {
    const clocks = this.stateDataService.getCachedComponentLocalData('InvClockService', 'clocks') || [];
    let clock_break_key = InvClockService._generateKey();
    let keyIsUnique = false;

    while (keyIsUnique !== true) {
      keyIsUnique = InvClockService._clockBreakKeyIsUnique(clocks, clock_break_key);

      if (!keyIsUnique) {
        clock_break_key = InvClockService._generateKey();
      }
    }

    return clock_break_key;
  }

  private static _generateKey(): number {
    return Math.floor(Math.random() * 10000000);
  }

  private static _clockKeyIsUnique(clocks: any, clock_key: number): boolean {
    for (const key of clocks) {
      if (key === clock_key) {
        return false;
      }
    }
    return true;
  }

  private static _clockNoteKeyIsUnique(clocks: any, clock_note_key: number): boolean {
    for (const clock of clocks) {
      for (const clock_note of clock.clock_notes) {
        if (clock_note.clock_note_key === clock_note_key) {
          return false;
        }
      }
    }
    return true;
  }

  private static _clockBreakKeyIsUnique(clocks: any, clock_break_key: number): boolean {
    for (const clock of clocks) {
      for (const clock_break of clock.clock_breaks) {
        if (clock_break.clock_break_key === clock_break_key) {
          return false;
        }
      }
    }
    return true;
  }

  private static _getExistingClockNoteIndex(clock: any, clock_note_key: number): number {
    for (let i = 0; i < clock.clock_notes.length; i++) {
      if (clock.clock_notes[i].clock_note_key === clock_note_key) {
        return i;
      }
    }
    return null;
  }

  private static _getActiveClockBreakIndex(clock: any): number {
    for (let i = 0; i < clock.clock_breaks.length; i++) {
      if (clock.clock_breaks[i].end_time === null) {
        return i;
      }
    }
    return null;
  }

  private static _getDefaultClockProjectAndTaskKey(projects: any[]) {
    for (const project of projects) {
      if (project.rate_type === 'hours') {
        for (const task of project.tasks) {
          if (!task.unit_flag && !task.archived_flag && !task.admin_only_flag) {
            return {
              project_key: project.project_key,
              task_key: task.task_key
            };
          }
        }
      }
    }
    return null;
  }

}
