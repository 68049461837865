import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent {

  @Input() items: {
    label: string,
    icon_class: string,
    color_variable: string,
    select_value: string,
    disabled: boolean
  };

  @Output() itemSelected = new EventEmitter();

  menuOpen: boolean = false;

  constructor(){

  }

  toggleMenu(){
    this.menuOpen = !this.menuOpen;
  }

  closeMenu(){
    this.menuOpen = false;
  }

  selectItem(item: any){
    this.itemSelected.emit({ select_value: item.select_value });
    this.closeMenu();
  }

}
