import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { CompanyService } from './../company/company.service';
import { LeaveService } from '../leave/leave.service';
import { TimeUtilService } from './../time-util/time-util.service';

import * as _ from 'lodash';
import * as moment from 'moment';

declare var saveAs;

@Injectable({
  providedIn: 'root'
})
export class PayService {

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService,
    public leaveService: LeaveService,
    public apiService: ApiService
  ) { }

  downloadPDF(payEmployee: any) {
    return new Promise<any[]>((resolve, reject) => {
      const params = {
        pay_key: payEmployee.pay_key,
        employee_key: payEmployee.employee_key || null
      };

      this.apiService.phGet('pay/pdf', params, { response_type: 'arraybuffer' })
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileName = 'Payslip - ' + (payEmployee ? (payEmployee.employee_code + ' - ') : '')
            + moment(payEmployee.pay_date).format('YYYY-MM-DD');

          saveAs(file, fileName + '.pdf');
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPayEmployee(pay_key: number) {
    return new Promise<any[]>((resolve, reject) => {

      const params = {
        pay_key,
        employee_key: this.companyService.getEmployeeKey()
      };

      this.apiService.phGet('pay/employee', params)
        .then((pay) => {
          resolve(this.setupPayEmployee(pay));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadMonthOfPays(month: Date) {
    return new Promise<any[]>((resolve, reject) => {
      const start = _.cloneDeep(month);
      const end = _.cloneDeep(month);

      start.setDate(1);
      end.setMonth(end.getMonth() + 1);
      end.setDate(0);

      this.loadPays(start, end)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadPays(start_date: Date, end_date: Date) {
    return new Promise<any[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key: this.companyService.getEmployeeKey()
      };

      this.apiService.phGet('pay/employee/history', params)
        .then((pays) => {

          for (let i = 0; i < pays.length; i++) {
            pays[i] = PayService.setupPay(pays[i]);
          }
          PayService.sortPays(pays);

          resolve(pays);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setupPayEmployee(employee: any) {
    let days;

    if (employee.start_date) {
      employee.start_date = TimeUtilService.dateStringToDate(employee.start_date, null, false);
    }
    if (employee.end_date) {
      employee.end_date = TimeUtilService.dateStringToDate(employee.end_date, null, false);
    }
    if (employee.next_sick_anniversary) {
      employee.next_sick_anniversary = TimeUtilService.dateStringToDate(employee.next_sick_anniversary, null, false);
    }
    if (employee.finish_date) {
      employee.finish_date = TimeUtilService.dateStringToDate(employee.finish_date, null, false);
    }
    if (employee.pay_date) {
      employee.pay_date = TimeUtilService.dateStringToDate(employee.pay_date, null, false);
    }

    if (employee.employee_lines) {
      for (const line of employee.employee_lines) {

        if (line.leave_type_key) {
          line.leave_type = this.leaveService.getSingleLeaveType(line.leave_type_key);
        }
        if (line.start_date) {
          line.start_date = TimeUtilService.dateStringToDate(line.start_date, null, false);
        }
        if (line.end_date) {
          line.end_date = TimeUtilService.dateStringToDate(line.end_date, null, false);
        }

        if (line.leave_request_days) {
          days = line.leave_request_days;

          for (const day of days) {
            day.leave_date = TimeUtilService.dateStringToDate(day.leave_date, null, false);
          }
        }
        if (line.public_holidays) {
          days = line.public_holidays;

          for (const day of days) {
            day.holiday_date = TimeUtilService.dateStringToDate(day.holiday_date, null, false);
          }
        }
      }
    }

    return employee;
  }

  // Most recent pay should be first
  static sortPays(pays: any[]) {
    pays.sort((a, b) => {
      if (a.pay_date > b.pay_date) {
        return -1;
      }
      else if (a.pay_date < b.pay_date) {
        return 1;
      }
      else {
        return 0;
      }
    });
  }

  static getPayLineGroupTotals(payLines: any[]) {
    const totalsMap = {};

    for (const line of payLines) {
      const type = line.pay_code_type;

      if (!!totalsMap[type]) {
        totalsMap[type] += line.total;
      }
      else {
        totalsMap[type] = line.total;
      }
    }

    const totals = [];
    if (totalsMap['Earnings'] || totalsMap['Earnings'] === 0) {
      totals.push({ type: 'Earnings', value: totalsMap['Earnings'] });
    }
    if (totalsMap['Reimbursements'] || totalsMap['Reimbursements'] === 0) {
      totals.push({ type: 'Reimbursements', value: totalsMap['Reimbursements'] });
    }
    if (totalsMap['Deductions'] || totalsMap['Deductions'] === 0) {
      totals.push({ type: 'Deductions', value: totalsMap['Deductions'] });
    }
    if (totalsMap['Superannuation'] || totalsMap['Superannuation'] === 0) {
      totals.push({ type: 'Superannuation', value: totalsMap['Superannuation'] });
    }
    if (totalsMap['Payments'] || totalsMap['Payments'] === 0) {
      totals.push({ type: 'Payments', value: totalsMap['Payments'] });
    }

    return totals;
  }

  static setupPay(pay: any) {
    if (pay.create_time) {
      pay.create_time = TimeUtilService.dateTimeStringToDate(pay.create_time, 'MMM DD YYYY  h:mmA', false);
    }
    if (!pay.pay_employees) {
      pay.pay_employees = [];
    }
    if (!pay.pay_messages) {
      pay.pay_messages = [];
    }

    pay.start_date = TimeUtilService.dateStringToDate(pay.start_date, null, false);
    pay.end_date = TimeUtilService.dateStringToDate(pay.end_date, null, false);
    pay.pay_date = TimeUtilService.dateStringToDate(pay.pay_date, null, false);
    pay.tax_period = TimeUtilService.dateStringToDate(pay.tax_period, null, false);
    pay.finalised_date = TimeUtilService.dateStringToDate(pay.finalised_date, null, false);
    pay.sent_date = TimeUtilService.dateStringToDate(pay.sent_date, null, false);

    if (pay.due_date) {
      pay.due_date = TimeUtilService.dateStringToDate(pay.due_date, null, false);
    }

    return pay;
  }

}
