import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { InvSegment } from './../../models/segment/inv-segment';
import { PhSegment } from './../../models/segment/ph-segment';

import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TimeUtilService {
  static readonly DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

  static dateIsValid(date) {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
  }

  static numberIsValid(number) {
    return !(number === null || number === undefined || number === '' || isNaN(Number(number)));
  }

  static getYearLabels() {
    return [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September', 'October',
      'November', 'December'
    ];
  }

  /**
   * Creates a copy of the provided date object, increments the
   * relevant portion of the date and returns the resulting value.
   *
   * Use these functions to ensure that Angular's change detection is
   * triggered properly because the standard setFullYear(), setMonth() etc
   * only modify the existing variable which means Angular won't detect a variable change
   *
   * @param {Date} date
   * @param {number} value
   */
  static incrementYear(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(newDate.getFullYear() + value);
    return newDate;
  }
  static incrementMonth(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(newDate.getMonth() + value);
    return newDate;
  }
  static incrementDate(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setDate(newDate.getDate() + value);
    return newDate;
  }
  static incrementHours(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setHours(newDate.getHours() + value);
    return newDate;
  }
  static incrementMinutes(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(newDate.getMinutes() + value);
    return newDate;
  }

  /**
   * Creates a copy of the provided date object, updates the
   * relevant portion of the date and returns the resulting value.
   *
   * Use these functions to ensure that Angular's change detection is
   * triggered properly because the standard setFullYear(), setMonth() etc
   * only modify the existing variable which means Angular won't detect a variable change
   *
   * @param {Date} date
   * @param {number} value
   */
  static updateYear(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(value);
    return newDate;
  }
  static updateMonth(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(value);
    return newDate;
  }
  static updateDate(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setDate(value);
    return newDate;
  }
  static updateHours(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setHours(value);
    return newDate;
  }
  static updateMinutes(date, value) {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(value);
    return newDate;
  }

  /**
   * Creates a start_time Date object and end_time Date object from the provided
   * start_time_string and end_time_string.
   *
   * The provided date object is then used to set year, month, date fields of
   * start_time and end_time
   *
   * @param {string} start_time_string - 'hh:mm'
   * @param {string} end_time_string - 'hh:mm'
   * @param {Date} date
   * @returns {any}
   */
  static setupStartAndEndTimesFromTimeStrings(start_time_string, end_time_string, date) {
    // Date objects with valid time values but invalid date values
    const start_time_only = TimeUtilService.timeStringToDate(start_time_string);
    const end_time_only = TimeUtilService.timeStringToDate(end_time_string);

    // Create a copy of date to get valid date values and then set valid time values
    const start_time = _.cloneDeep(date);
    start_time.setHours(start_time_only.getHours());
    start_time.setMinutes(start_time_only.getMinutes());

    const end_time = _.cloneDeep(date);
    end_time.setHours(end_time_only.getHours());
    end_time.setMinutes(end_time_only.getMinutes());

    // If end_time is before start_time then it must be on the next day
    if (end_time.valueOf() < start_time.valueOf()) {
      end_time.setDate(end_time.getDate() + 1);
    }

    return {
      start_time,
      end_time
    };
  }

  /**
   * Updates the date (dd/mm/yyyy) portion of a date object (such as a segment start or end time), using the provided dateToCopy
   */
  static updateDatePortionOfDateTime(dateTimeToUpdate: Date, dateToCopy: Date, overwriteReference: boolean = false): Date {
    if (overwriteReference) {
      dateTimeToUpdate = _.cloneDeep(dateTimeToUpdate);
    }

    dateTimeToUpdate.setFullYear(
      dateToCopy.getFullYear(),
      dateToCopy.getMonth(),
      dateToCopy.getDate()
    );

    return dateTimeToUpdate;
  }

  /**
   * Updates the time (hh:mm) portion of a date object (such as a segment start or end time), using the provided timeToCopy
   */
  static updateTimePortionOfDateTime(dateTimeToUpdate: Date, timeToCopy: Date, overwriteReference: boolean = false): Date {
    if (overwriteReference) {
      dateTimeToUpdate = _.cloneDeep(dateTimeToUpdate);
    }
    dateTimeToUpdate.setHours(
      timeToCopy.getHours(),
      timeToCopy.getMinutes()
    );

    return dateTimeToUpdate;
  }

  /**
   * Determines if the time portion of a end date is less than the time portion of an start date
   */
  static endTimeLessThanStartTime(start: Date, end: Date): boolean {
    const startMins = (start.getHours() * 60) + start.getMinutes();
    const endMins = (end.getHours() * 60) + end.getMinutes();

    return endMins < startMins;
  }

  /**
   * Converts a date object into an hours based duration in decimal format
   * eg. 01:30 -> 1.5
   *
   * @param {Date} date
   * @returns {number}
   */
  static dateAsHoursMinsDecimal(date) {
    const hours = date.getHours();
    const mins = date.getMinutes();

    return hours + (mins / 60);
  }

  /**
   * Converts an ISO date string to an hours based duration in decimal format
   *
   * @param {string} isoString
   * @returns {number}
   */
  static localTimeISOStringToHoursMinsDecimal(isoString) {
    return TimeUtilService.dateAsHoursMinsDecimal(new Date(isoString));
  }

  /**
   * Converts an hours based duration in decimal format to an ISO date string
   *
   * @param {number} dec
   * @returns {string}
   */
  static hoursDecimalToLocalTimeISOString(dec) {
    const date = this.hoursDecimalAsDate(dec);
    return this.dateToLocalTimeISOString(date);
  }

  /**
   * Converts an hours based duration in decimal format into a Date object
   * eg. 1.5 -> 01:30
   *
   * @param {number} dec
   * @returns {Date}
   */
  static hoursDecimalAsDate(dec: number): Date {
    const numbers = this.hoursDecimalAsHoursAndMinutes(dec);

    const date = new Date();
    date.setHours(numbers[0], numbers[1], 0, 0);

    return date;
  }

  /**
   * Converts an hours based duration in decimal format into an array with two numeric values.
   * The first value is the number of hours, the second being the number of minutes
   * eg. 1.75 -> [1, 45]
   *
   * @param {number} dec
   * @returns {[number, number]}
   */
  static hoursDecimalAsHoursAndMinutes(dec: number, absoluteNumbers: boolean = false): number[] {
    const hours = moment.duration(dec, 'hours').hours();
    let minutes = moment.duration(dec, 'hours').minutes();
    const seconds = moment.duration(dec, 'hours').seconds();

    if (seconds >= 30) {
      minutes++;
    }

    return [
      absoluteNumbers ? Math.abs(hours) : hours,
      absoluteNumbers ? Math.abs(minutes) : minutes
    ];
  }

  /**
   * Formats a date object into an ISO date string
   *
   * @param {Date} date
   * @returns {string}
   */
  static dateToLocalTimeISOString(date) {
    return moment(moment(date), moment.ISO_8601).format();
  }

  /**
   * Calculates the start time for a new segment using the provided segmentDate
   *
   * If a defaultStart is provided, it will try to use this as the start time unless it overlaps with existing segments,
   * in which case the start time will be pushed back to the end time of the last of the existing segments
   *
   * If no defaultStart is provided and there is no overlap with existing segments, it will fallback to 9:00am
   *
   * @param {Date} segmentDate
   * @param {Date} defaultStart
   * @param {Array<Segment>} existingSegments
   * @returns {Date}
   */
  static calculateStartTimeForNewSegment(segmentDate, defaultStart, existingSegments) {
    const startTime = _.cloneDeep(segmentDate);
    startTime.setSeconds(0, 0);

    // Filter to segments that match the segmentDate
    let daysSegments = TimeUtilService.getSegmentsForDay(existingSegments, segmentDate);
    // Sort the segments and remove any UnitSegmentsse
    daysSegments = TimeUtilService.orderSegments(daysSegments, true);

    // Get the last existing TimeSegment on the provided segmentDate
    const lastSegmentOnDay = TimeUtilService.findLastTimeSegmentInList(daysSegments);

    if (lastSegmentOnDay) {
      // If defaultStart is after lastSegmentOnDay.end_time, use defaultStart
      if (defaultStart && moment(defaultStart).isSameOrAfter(moment(lastSegmentOnDay.end_time), 'minutes')) {
        startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes());
      }
      // If defaultStart is before lastSegmentOnDay.end_time, use lastSegmentOnDay.end_time
      else {
        startTime.setHours(lastSegmentOnDay.end_time.getHours(), lastSegmentOnDay.end_time.getMinutes());
      }
    }
    else {
      if (defaultStart) {
        startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes());
      }
      // Default the start time to 9:00 if no defaultStart and no other segments exist on this day
      else {
        startTime.setHours(9, 0);
      }
    }

    return startTime;
  }

  /**
   * Takes a list of Segments, filters them to TimeSegments and extends or reduces their individual durations/times
   * so that the sum of their durations matches the totalDuration provided
   *
   * Returns an array of updated segments that need to be posted
   *
   * @param {Array<Segment>} segments
   * @param {Number} totalDuration
   * @returns {Array<Segment>}
   */
  static updateSegmentsFromEditedDuration(segments, totalDuration) {
    segments = TimeUtilService.orderSegments(segments, true);
    const currentTotalDuration = TimeUtilService.calculateTotalDurationOfSegments(segments);

    if (currentTotalDuration > 24) {
      throw new Error('The total time cannot be edited when it is greater than 24 hours. Please edit each time segment manually.');
    }
    else {
      if (totalDuration < 0) {
        totalDuration = 0;
      }
      // Update existing segments
      if (segments.length > 0) {
        // Increase duration of segments
        if (totalDuration > currentTotalDuration) {
          let lastSegNewDuration = totalDuration;

          // Calculate new duration of last segment
          for (let i = 0; i < segments.length - 1; i++) {
            lastSegNewDuration -= segments[i].duration;
          }

          const lastSegment = segments[segments.length - 1];
          lastSegment.end_time = lastSegNewDuration;
          // Updates the end_time using existing start_time, break_duration and new duration
          lastSegment.durationChanged();

          return [lastSegment];
        }
        // Reduce duration of segments
        else if (totalDuration < currentTotalDuration) {
          let durationDiffRemaining = currentTotalDuration - totalDuration;
          const updatedSegments = [];

          // Work backwards through segments,
          // calculating new duration values based on the remaining
          // difference between the current total duration and the new total duration
          for (let i = segments.length - 1; i <= 0; i--) {
            const seg = segments[i];

            if (seg.duration < durationDiffRemaining) {
              durationDiffRemaining -= seg.duration;
              seg.duration = 0;
            }
            else {
              seg.duration -= durationDiffRemaining;
              durationDiffRemaining = 0;
            }

            // Updates the end_time using existing start_time, break_duration and new duration
            seg.durationChanged();

            updatedSegments.push(seg);

            if (durationDiffRemaining === 0) {
              break;
            }
          }

          return updatedSegments;
        }
      }
    }
  }

  /**
   * Calculates a TimeSegment end time based on its start_time, duration and break_duration
   *
   * @param {Segment} segment
   * @returns {null|Date}
   */
  static calculcateEndTimeForSegment(segment) {
    if (!segment.unit_flag) {
      const totalDuration = segment.duration + segment.break_duration;
      const hours = Math.floor(totalDuration);
      const mins = Math.ceil((totalDuration - hours) * 60);

      const endTime = _.cloneDeep(segment.start_time);
      endTime.setHours(hours, mins, 0, 0);

      return endTime;
    }
    else {
      return null;
    }
  }

  /**
   * Determines the default start time for a new segment on a given day
   * Tries to use the provided default time provided unless there are other segments
   * on the day already, in which case the start time will be set to the end time of the last segment on the day
   *
   * @param {Date} segmentDate
   * @param {Array<Segment>} currentSegments
   * @param {Date} defaultStart
   */
  static getStartTimeForNewSegment(segmentDate, currentSegments, defaultStart) {
    const startTime = _.cloneDeep(segmentDate);

    if (defaultStart) {
      startTime.setHours(defaultStart.getHours(), defaultStart.getMinutes(), 0, 0);
    }
    else {
      startTime.setHours(9, 0, 0, 0);
    }

    const daysSegments = TimeUtilService.getSegmentsForDay(currentSegments, segmentDate);
    const lastSegmentOnDay = TimeUtilService.findLastTimeSegmentInList(daysSegments);

    // Set the start time to the end time of the current last segment on this day
    // if it's greater than the default start time
    if (lastSegmentOnDay &&
      lastSegmentOnDay.start_time.valueOf() > startTime.start_time.valueOf()) {

      startTime.setHours(lastSegmentOnDay.end_time.getHours(), lastSegmentOnDay.end_time.getMinutes());
    }
  }

  /**
   * Returns a subset of segments where the segment_date's match the given day
   *
   * @param {Array<Segment>} segments
   * @param {Date} day
   * @returns {Array<Segment>}
   */
  static getSegmentsForDay(segments, day) {
    const daysSegments = [];
    const date = moment(day);

    for (const seg of segments) {
      if (moment(seg.segment_date).isSame(date, 'day')) {
        daysSegments.push(seg);
      }
    }

    return daysSegments;
  }

  /**
   * Formats a date object into a string for posting to API
   *
   * @param {Date} date
   * @param {Boolean} includeTime
   * @returns {String}
   */
  static formatDateForPosting(date, includeTime) {
    if (includeTime) {
      return TimeUtilService.dateToDateTimeString(date, 'YYYYMMDD HH:mm');
    }
    else {
      return TimeUtilService.dateToDateString(date, 'YYYYMMDD');
    }
  }

  /**
   * Converts an ISO date string into a date string for posting to API
   */
  static formatISODateStringForPosting(dateString: string, includeTime: boolean): string {
    // Convert ISO date string to Date object
    const date = TimeUtilService.dateStringToDate(dateString, null, false);
    // Convert Date object to date string formatted for API
    return TimeUtilService.formatDateForPosting(date, includeTime);
  }

  /**
   * Converts date object to date string
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateString(date: Date, stringFormat: string = null): string {
    return TimeUtilService.dateToDateTimeString(date, (stringFormat || 'YYYY-MM-DD'));
  }

  /**
   * Converts date object to date time string
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD HH:mm'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateTimeString(date: Date, stringFormat: string = null): string {
    if (!TimeUtilService.dateIsValid(date)) {
      return null;
    }
    if (stringFormat) {
      return moment(date).format(stringFormat);
    }
    else {
      return moment(date).format('YYYY-MM-DD HH:mm');
    }
  }

  static utcDateTimeStringToDate(date_string: string, format: string = null): Date {
    return this.utcDateTimeStringToDateTime(date_string, format)?.toJSDate() || null;
  }

    /**
   * Timezone information included in date_string is ignored
   */
    static utcDateTimeStringToDateTime(date_string: string, format: string = null): DateTime {
      if (!date_string) return null;

      format = format || this.DEFAULT_DATETIME_FORMAT;

      date_string = this._parseDateTimeString(date_string, format);
      if (!!date_string) {
        return DateTime.fromFormat(date_string, format, { zone: 'utc' }).toLocal();
      }
      return null;
    }


    private static _parseDateTimeString(date_string: string, format: string): string {
      if (!date_string) return null;

      let datetime = DateTime.fromFormat(date_string, format, { zone: 'utc' });
      if (!datetime.isValid) {
        datetime = DateTime.fromISO(date_string, { zone: 'utc' });
      }
      if (!datetime.isValid) {
        datetime = DateTime.fromSQL(date_string, { zone: 'utc' });
      }

      return datetime.isValid ? datetime.toFormat(format) : null;
    }

  /**
   * Converts date object to time string
   * If no stringFormat is provided, defaults to 'HH:mm'
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToTimeString(date: Date, stringFormat: string = null) {
    if (!TimeUtilService.dateIsValid(date)) {
      return null;
    }
    if (stringFormat) {
      return moment(date).format(stringFormat);
    }
    else {
      return moment(date).format('HH:mm');
    }
  }

  /**
   * Coverts date string to date object with time set to midnight
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD'
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @param {Boolean} localise Whether or not to convert date to the local timezone (Used for converting GMT (system times) to local )
   * @returns {Date}
   */
  static dateStringToDate(dateString: string, stringFormat: string = null, localise: boolean = false) {
    return TimeUtilService.dateTimeStringToDate(dateString, (stringFormat || 'YYYY-MM-DD'), localise);
  }

  /**
   * Converts date string to date object
   * If no stringFormat is provided, defaults to 'YYYY-MM-DD HH:mm'
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @param {Boolean} localise Whether or not to convert date to the local timezone (Used for converting GMT (system times) to local )
   * @returns {Date}
   */
  static dateTimeStringToDate(dateString: string, stringFormat: string = null, localise: boolean = false) {
    let date;

    if (localise) {
      dateString = moment(dateString).isValid() ? moment.utc(dateString).local().format() : dateString;
    }

    if (stringFormat) {
      date = moment(dateString, stringFormat).toDate();
    }
    else {
      date = moment(dateString, 'YYYY-MM-DD HH:mm').toDate();
    }

    // Check for invalid dates
    return TimeUtilService.dateIsValid(date) ? date : null;
  }

  /**
   * Converts time string to date object
   * e.g 'HH:MM', '09:30'
   *
   * @param {String} timeString
   * @param {String} stringFormat
   * @returns {Date}
   */
  static timeStringToDate(timeString: string) {
    return TimeUtilService.dateTimeStringToDate(timeString, 'HH:mm', false);
  }

  /**
   * Converts a time string into a Date object
   * e.g 'HH:MM', '09:30'
   *
   * @param {String} time
   * @returns {Date}
   */
  static hoursMinsStringToDate(time: string) {
    return TimeUtilService.dateTimeStringToDate(time, 'HH:mm', false);
  }

  /**
   * Check if the given date is the last day in the month
   *
   * @param {Date} date
   * @returns {boolean}
   */
  static isLastDayInMonth(date) {
    const nextDay = _.cloneDeep(date);
    nextDay.setDate(nextDay.getDate() + 1);

    return date.getMonth() !== nextDay.getMonth();
  }

  /**
   * Returns the difference in duration between two date objects as an hours decimal value
   * eg Difference between 2:00pm and 4:45pm is 2.75 hours
   *
   * @param {Date} dateA
   * @param {Date} dateB
   * @returns {number}
   */
  static differenceBetweenTwoDatesAsHoursDecimal(dateA, dateB) {
    dateA.setSeconds(0);
    dateB.setSeconds(0);
    const totalMilliseconds = Math.abs(moment(dateA).diff(moment(dateB), 'milliseconds'));

    return moment.duration(totalMilliseconds, 'milliseconds').asHours();
  }

  /**
   * Returns the date a week after the provided date
   *
   * @param {Date} weekStart
   * @returns {Date}
   */
  static getWeekEnd(weekStart) {
    if (!weekStart) {
      throw new Error('week start required to calculate week end');
    }

    const end = _.cloneDeep(weekStart);
    end.setDate(end.getDate() + 6);
    return moment(end).endOf('d').toDate();
  }

  /**
   * Returns the Monday date that was prior to the given date
   *
   * @param {Date} d
   * @returns {Date}
   */
  static getMonday(d) {
    const date = _.cloneDeep(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

    date.setDate(diff);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Returns an index of the given day in the week
   *
   * @param {String} day
   * @returns {int}
   */
  static getWeekDayValue(day) {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day);
  }

  /**
   * Returns the index of the given day based on the given start date for the week
   *
   * @param {Date} date
   * @param {Date} weekStart
   * @returns {int}
   */
  // TODO check weekStart/weeksDates usage when moving function calls to this
  // TODO Old version of function uses weeksDates array instead of weekStart
  // TODO Check if functions expect a null or -1 returned for bad data

  // TODO New
  static getDayIndex(date, weekStart) {
    const weeksDates = TimeUtilService.generateWeeksDates(weekStart);

    if (!(date instanceof Date)) {
      return -1;
    }

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    for (let i = 0; i < 7; i++) {
      if (day === weeksDates[i].getDate() &&
        month === weeksDates[i].getMonth() &&
        year === weeksDates[i].getFullYear()) {
        return i;
      }
    }
    return -1;
  }

  // TODO Old
  // static getDayIndex(date, weeksDates){
  //     if (!(date instanceof Date)){
  //         return null;
  //     }
  //
  //     let day = date.getDate();
  //     let month = date.getMonth();
  //     let year = date.getFullYear();
  //
  //     for (var i = 0; i < 7; i++){
  //         if (day === weeksDates[i].getDate() &&
  //             month === weeksDates[i].getMonth() &&
  //             year === weeksDates[i].getFullYear()){
  //             return i;
  //         }
  //     }
  //     return null;
  // }

  /**
   * Generates an array of date objects for a week based on the given start date
   *
   * @param {Date} startDate
   * @returns {Array<Date>}
   */
  static generateWeeksDates(startDate) {
    const week = [];
    let date = _.cloneDeep(startDate);
    date.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      week.push(date);

      date = _.cloneDeep(date);
      date.setDate(date.getDate() + 1);
    }

    return week;
  }

  /**
   * Determines if provided date is today
   *
   * @param {Date} day
   * @returns {boolean}
   */
  static isToday(day) {
    return moment().isSame(moment(day), 'day');
  }

  // TODO New
  static calculateTotalUnitsOfSegments(segments) {
    let units = 0;

    for (const segment of segments) {
      if (segment.unit_flag) {
        units += segment.units;
      }
    }

    return units;
  }

  // TODO New
  static calculateTotalDurationOfSegments(segments) {
    let duration = 0;

    for (const segment of segments) {
      if (!segment.unit_flag) {
        duration += segment.duration;
      }
    }

    return duration;
  }

  // TODO Old
  // static recalculateDurationOfDaysSegments(day){
  //     day.duration = 0;
  //
  //     for (let i = 0; i < day.segments.length; i++){
  //
  //         if (day.segments[i].duration){
  //             day.duration += day.segments[i].duration;
  //         }
  //
  //         else if (day.segments[i].units) {
  //             day.duration += day.segments[i].units;
  //         }
  //     }
  // }

  /**
   * Sums the durations of all segments
   *
   * @param {Object} segments
   * @returns {number}
   */
  // TODO Old
  // static calcDurationOfSegments(segments){
  //     let duration = 0;
  //
  //     for (let i = 0; i < segments.length; i++){
  //         let seg = segments[i];
  //
  //         if (seg.duration){
  //             duration += seg.duration;
  //         }
  //     }
  //
  //     return duration;
  // }

  /**
   * Returns the segment with the latest end time
   *
   * @param {Array<Segment>} segments
   * @returns {Segment}
   */
  static findLastTimeSegmentInList(segments) {
    let lastSegment = null;

    for (const seg of segments) {
      if (
        lastSegment === null &&
        !seg.unit_flag
      ) {

        lastSegment = seg;
      }
      else if (
        lastSegment !== null &&
        !seg.unit_flag &&
        seg.end_time.valueOf() > lastSegment.end_time.valueOf()
      ) {

        lastSegment = seg;
      }
    }

    return lastSegment;
  }

  /**
   * Returns the segment with the earliest start time
   *
   * @param {Array<Segment>} segments
   * @returns {Segment}
   */
  static findFirstSegmentInList(segments) {
    let firstSegment = null;

    for (const seg of segments) {
      if (
        firstSegment === null &&
        !seg.unit_flag
      ) {

        firstSegment = seg;
      }
      else if (
        firstSegment !== null &&
        !seg.unit_flag &&
        seg.start_time.valueOf() < firstSegment.start_time.valueOf()
      ) {

        firstSegment = seg;
      }
    }

    return firstSegment;
  }

  /**
   * Determines if the given segments start_time / end_time will
   * cause it to overlap with another segment in the list
   *
   * @param {Segment} s
   * @param {Array<Segment>} segments
   * @returns {boolean}
   */
  static segmentOverlapsAnotherInList(segment: PhSegment | InvSegment, segments: (PhSegment | InvSegment)[]): boolean {
    if (segment.unit_flag) {
      return false;
    }
    else {
      const segmentDate = moment(segment.segment_date);

      for (const seg of segments) {
        const segKey = seg.segment_key;

        if (!seg.unit_flag) {
          if (!segment.segment_key || segment.segment_key && segment.segment_key !== segKey) {
            if (segmentDate.isSame(moment(seg.segment_date, 'days'))) {

              if (seg.start_time <= segment.start_time && seg.end_time > segment.start_time) {
                return true;
              }
              if (seg.start_time < segment.end_time && seg.end_time >= segment.end_time) {
                return true;
              }
              if (seg.start_time >= segment.start_time && seg.end_time <= segment.end_time) {
                return true;
              }
            }
          }
        }
      }
      return false;
    }
  }

  /**
   * Order Segments by their start_time
   * UnitSegments will be sorted to the end of the list or removed based on the discardUnitSegments param
   *
   * @param {Array<Segment>} segments
   * @param {Boolean} discardUnitSegments
   * @returns {Array<Segment>}
   */
  // TODO New
  static orderSegments(segments, discardUnitSegments) {
    segments = _.cloneDeep(segments);

    if (discardUnitSegments) {
      for (let i = segments.length - 1; i >= 0; i--) {
        if (segments[i].unit_flag) {
          segments.splice(i, 1);
        }
      }
    }

    segments.sort((a, b) => {
      if (a.unit_flag) {
        return 1;
      }
      else if (b.unit_flag) {
        return -1;
      }
      else {
        return a.start_time.valueOf() - b.start_time.valueOf();
      }
    });

    return segments;
  }

  // TODO Old
  // static orderDaysSegments(day){
  //     let segments = _.cloneDeep(day.segments);
  //
  //     segments.sort((a, b) => {
  //         return a.start_time - b.start_time;
  //     });
  //
  //     return segments;
  // }

  static incrementDecimalDurationQuarterHour(dec: number, positiveIncrement: boolean) {
    if (dec % 0.25 === 0) {
      // Decrement a quarter
      if (!positiveIncrement) {
        dec = dec <= 0 ? 0 : dec - 0.25;
      }
      // Increment a quarter
      else {
        dec = dec >= 24 ? 24 : dec + 0.25;
      }
    }
    else {
      // Round down to nearest quarter
      if (!positiveIncrement) {
        dec = parseFloat((Math.floor(dec * 4) / 4).toFixed(2));
      }
      // Round up to nearest quarter
      else {
        dec = parseFloat((Math.ceil(dec * 4) / 4).toFixed(2));
      }
    }
    return dec;
  }

  static setupGroupedHistory(timeHistory: any[], publicHolidaysMap: any): any[] {
    const history = _.sortBy(timeHistory, ['segment_date']);
    const groupedHistoryMap = [];
    const groupedTimeHistory = [];

    // Generate grouped map
    for (const hist of history) {

      const dayString = TimeUtilService.dateToDateString(hist.segment_date, null);
      const monthString = dayString.substring(0, 7);

      if (!(monthString in groupedHistoryMap)) {
        groupedHistoryMap[monthString] = {};
      }
      if (!(dayString in groupedHistoryMap[monthString])) {
        groupedHistoryMap[monthString][dayString] = [];
      }

      groupedHistoryMap[monthString][dayString].push(hist);
    }

    // Convert map to iterable 3 dimensional array
    for (const month of Object.keys(groupedHistoryMap)) {
      groupedTimeHistory.push({
        month: TimeUtilService.dateStringToDate(month + '-01', null, false),
        days: []
      });

      const groupedMonth = groupedTimeHistory[groupedTimeHistory.length - 1];

      for (const day in groupedHistoryMap[month]) {
        if (groupedHistoryMap[month].hasOwnProperty(day)) {
          const publicHoliday = publicHolidaysMap && publicHolidaysMap[day] ? publicHolidaysMap[day] : null;

          groupedMonth.days.push({
            day: TimeUtilService.dateStringToDate(day, null, false),
            segments: groupedHistoryMap[month][day],
            public_holiday: publicHoliday
          });
        }
      }
    }

    return TimeUtilService.sortGroupedHistory(groupedTimeHistory);
  }

  static sortGroupedHistory(groupedTimeHistory: any[]): any[] {
    // Sort months
    groupedTimeHistory.sort((a, b) => {
      if (a.month.valueOf() > b.month.valueOf()) { return -1; }
      else if (a.month.valueOf() < b.month.valueOf()) { return 1; }
      return 0;
    });

    // Sort days in each month
    for (const month of groupedTimeHistory) {

      month.days.sort((a, b) => {
        if (a.day.valueOf() > b.day.valueOf()) { return -1; }
        else if (a.day.valueOf() < b.day.valueOf()) { return 1; }
        return 0;
      });

      // Sort segments on each day
      for (const day of month.days) {

        day.segments.sort((a, b) => {
          if (a.segment_date.valueOf() < b.segment_date.valueOf()) { return -1; }
          else if (a.segment_date.valueOf() > b.segment_date.valueOf()) { return 1; }
          return 0;
        });
      }
    }

    return groupedTimeHistory;
  }
}
