import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { TimeUtilService } from './../time-util/time-util.service';
import { UtilService } from '../util/util.service';
import { StateDataService } from './../state-data/state-data.service';
import { CompanyService } from './../company/company.service';

import { env } from '../../../environments/environment';

import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InvCompanyService {

  company: any = null;
  user_company: any = null;

  nz_public_holidays = [];
  nz_public_holiday_map = {};

  service_setup: boolean = false;

  constructor(
    public utilService: UtilService,
    public stateDataService: StateDataService,
    public companyService: CompanyService,
    public apiService: ApiService
  ) {

  }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.service_setup) {
        resolve();
      }
      else {
        forkJoin([
          this.loadPublicHolidays(),
          this.loadCompany(),
          this.loadUserCompany()
        ])
          .toPromise()
          .then(() => {
            if (env.product_code === 'INV') {
              this.stateDataService.user_key = this.user_company.user_key;
            }
            this.service_setup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.company = null;
    this.user_company = null;
    this.service_setup = false;
  }

  getCurrentCompany(): any {
    return this.company;
  }

  getCurrentCompanyUser(): any {
    return this.user_company;
  }

  isResource(): boolean {
    return this.user_company?.resource_flag || false;
  }

  isConsultant(): boolean {
    return this.user_company.consultant_flag || false;
  }

  isAdminOrOwner(): boolean {
    return this.user_company?.admin_flag || this.user_company?.owner_flag || false;
  }

  isTimeManager(): boolean {
    return this.user_company?.manage_time_flag || false;
  }

  isApprover(): boolean {
    return this.user_company?.approver_flag || false;
  }

  isClient(): boolean {
    return this.user_company?.client_contact_flag || false;
  }

  getAuthValue(): number {
    return this.user_company?.auth_value || false;
  }

  getUserKey(): number {
    return this.user_company?.user_key || null;
  }

  getUserAccessCompanyKey(): number {
    return this.user_company?.user_access_company_key || null;
  }

  getDisplayName(): string {
    return this.user_company?.display_name || null;
  }

  getCompanyKey(): number {
    return this.company?.company_key || null;
  }

  getCompanyName(): string {
    return this.company?.company_name || null;
  }

  getSinglePublicHoliday(date: Date): any {
    const dateString = TimeUtilService.dateToDateString(date, null);
    return this.nz_public_holiday_map[dateString] || null;
  }

  expensesEnabled() {
    return InvCompanyService.getCompanySetting('Expenses Enabled', this.company) === true;
  }

  loadPublicHolidays(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invGet('company/publicHolidays')
        .then((res) => {
          this.nz_public_holidays = CompanyService.setupPublicHolidays(res);
          this.nz_public_holiday_map = InvCompanyService.setupPublicHolidaysMap(this.nz_public_holidays);
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  loadCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invGet('company')
        .then((res: any) => {
          this.company = InvCompanyService.setupCompany(res);

          const weekStart = InvCompanyService.getCompanySetting('Week Start', this.company);
          const startDate = moment().day(weekStart).toDate();
          this.stateDataService.selectedWeekStart = startDate;
          this.utilService.currencySymbol = this.company.money_symbol || '$';
          resolve();
        })
        .catch((err) => reject(err));
    })
  }

  loadUserCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invGet('useraccesscompany')
        .then((res: any) => {
          this.user_company = InvCompanyService.setupCompanyUser(res);
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  getPublicHolidaysMap(user: any = null): any {
    if (!!user && user.integrations) {
      for (const integration of user.integrations) {

        // Need to filter out provincial holidays that aren't
        if (integration.integration_type === 'PAYHERO' &&
          integration.additional_data &&
          integration.additional_data.public_holiday_key) {

          const userHolidayKey = integration.additional_data.public_holiday_key;
          const userHolidayDate = integration.additional_data.next_public_holiday_date;

          const publicHolidaysMap = _.cloneDeep(this.nz_public_holiday_map);

          for (const dateKey of Object.keys(publicHolidaysMap)) {
            const publicHoliday = publicHolidaysMap[dateKey];

            if (publicHoliday.anniversary_flag) {

              // Remove provincial holidays that don't match the user's public_holiday_key or their next_public_holiday_date
              if (publicHoliday.public_holiday_key !== userHolidayKey ||
                (userHolidayDate && !moment(userHolidayDate).isSame(moment(publicHoliday.holiday_date, 'date')))) {

                delete publicHolidaysMap[dateKey];
              }
            }
          }

          return publicHolidaysMap;
        }
      }
    }
    return this.nz_public_holiday_map;
  }

  static setupCompany(company: any): any {
    InvCompanyService._setupCompanySettings(company);

    company.trial_date = TimeUtilService.dateStringToDate(company.trial_date);
    company.created_on = TimeUtilService.dateTimeStringToDate(company.created_on, null, true);
    company.company_logo = InvCompanyService.getCompanySetting('Company Logo', company);
    company.user_role = InvCompanyService.getCompanyRole(company);

    return company;
  }

  private static _setupCompanySettings(company: any): void {
    for (const setting of company.company_settings) {
      switch (setting.setting_key) {
        case 'Commission Lead Days':
          setting.setting_value = parseFloat(setting.setting_value);
          break;
        case 'Expenses Enabled':
          setting.setting_value = !this._isFalsySettingValue(setting);
          break;
        case 'Invite Template Key':
          setting.setting_value = parseFloat(setting.setting_value);
          break;
        case 'Next Invoice Number':
          setting.setting_value = parseFloat(setting.setting_value);
          break;
        case 'Rounding Step':
          setting.setting_value = parseFloat(setting.setting_value);
          break;
        case 'Tax Rate':
          setting.setting_value = parseFloat(setting.setting_value);
          break;
        case 'Time Requires Approval':
          setting.setting_value = !this._isFalsySettingValue(setting);
          break;
        case 'Timesheet Attachments Enabled':
          setting.setting_value = !this._isFalsySettingValue(setting);
          break;
      }
    }
  }

  private static _isFalsySettingValue(setting: any): boolean {
    return !setting.setting_value ||
      setting.setting_value === 'false' ||
      setting.setting_value === '0';
  }

  static filterMobileAccessCompanies(companies: any[]): any[] {
    for (let i = companies.length - 1; i >= 0; i--) {
      const company = companies[i];

      if (company.highest_auth !== 'Resource' &&
        company.highest_auth !== 'Consultant') {
        companies.splice(i, 1);
      }
    }
    return companies;
  }

  static setupCompanyUser(companyUser: any): any {
    companyUser.updated_date = TimeUtilService.dateStringToDate(companyUser.updated_date, null, null);
    companyUser.created_date = TimeUtilService.dateStringToDate(companyUser.created_date, null, null);

    if (companyUser.resource_flag) {
      if (companyUser.default_start_time) {
        companyUser.default_start_time = TimeUtilService.dateTimeStringToDate(companyUser.default_start_time, 'HH:mm', null);
      }
      else {
        companyUser.default_start_time = new Date();
        companyUser.default_start_time.setHours(9, 0, 0, 0);
      }
    }
    else {
      companyUser.default_start_time = null;
    }

    return companyUser;
  }

  static sortCompanies(companies: any[]): any[] {
    return companies.sort((a, b) => {
      const compNameA = a.company_name.toUpperCase();
      const compNameB = b.company_name.toUpperCase();

      // Sort inactive companies to the bottom of the list
      if (!a.active_flag) {
        return 1;
      }
      else if (!b.active_flag) {
        return -1;
      }

      if (compNameA > compNameB) {
        return 1;
      }
      else if (compNameA < compNameB) {
        return -1;
      }
      return 0;
    });
  }

  static getCompanySetting(key: any, company: any): any {
    for (const setting of company.company_settings) {
      if (setting.setting_key === key) {
        return setting.setting_value;
      }
    }
    return null;
  }

  static getCompanyRole(company: any): string {
    let role = company.owner_flag ? 'Owner/' : '';

    if (company.approver_flag) {
      company += 'Approver/';
    }
    if (company.pending_flag) {
      role = 'Pending';
    }
    else if (company.resource_flag) {
      role += 'Resource';
    }
    else if (company.consultant_flag) {
      role += 'Consultant';
    }
    else if (company.client_contact_flag) {
      role += 'Contact';
    }
    else if (company.admin_flag) {
      role += 'Administrator';
    }
    return role;
  }

  static setupPublicHolidaysMap(publicHolidays: any[]): any {
    const publicHolidaysMap = {};

    for (const publicHoliday of publicHolidays) {
      const dateString = TimeUtilService.dateToDateString(publicHoliday.holiday_date, null);
      publicHolidaysMap[dateString] = publicHoliday;
    }

    return publicHolidaysMap;
  }

}
