import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './../../components/components.module';
import { PipesModule } from './../../pipes/pipes.module';

import { InvTimeComponent } from './inv-time.component';
import { InvTimeResourceComponent } from './inv-time-resource/inv-time-resource.component';
import { InvTimeWorkComponent } from './inv-time-work/inv-time-work.component';
import { InvTimeEditComponent } from './inv-time-edit/inv-time-edit.component';
import { InvTimeManagerComponent } from './inv-time-manager/inv-time-manager.component';
import { InvTimeClockComponent } from './inv-time-clock/inv-time-clock.component';

export const INV_TIME_CONFIG: any[] = [
  {
    name: 'app.inv.time',
    url: '/time',
    abstract: true,
    component: InvTimeComponent
  },
  {
    name: 'app.inv.time.clock',
    url: '/clock?{clock_key:int}',
    component: InvTimeClockComponent,
    params: {
      clock: null,
      clock_key: null
    }
  },
  {
    name: 'app.inv.time.resource',
    url: '/resource?{user_key:int}',
    component: InvTimeResourceComponent,
    params: {
      user_key: null
    }
  },
  {
    name: 'app.inv.time.work',
    url: '/work?{user_key:int}',
    component: InvTimeWorkComponent,
    params: {
      user_key: null,
      segments: null
    }
  },
  {
    name: 'app.inv.time.edit',
    url: '/edit?{segment_key:int}&{project_key:int}&{task_key:int}&{user_key:int}',
    component: InvTimeEditComponent,
    params: {
      segment_key: null,
      project_key: null,
      task_key: null,
      user_key: null,
      segment: null,
      segments: null
    }
  },
  {
    name: 'app.inv.time.manager',
    url: '/manager',
    component: InvTimeManagerComponent
  }
];

@NgModule({
  declarations: [
    InvTimeComponent,
    InvTimeResourceComponent,
    InvTimeWorkComponent,
    InvTimeEditComponent,
    InvTimeManagerComponent,
    InvTimeClockComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    ComponentsModule,
    FormsModule,
    UIRouterModule.forChild({ states: INV_TIME_CONFIG })
  ]
})
export class InvTimeModule { }
