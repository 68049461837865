import { TimeUtilService } from './../../services/time-util/time-util.service';

import { SegmentInterface } from '../../interfaces/segment.interface';
import { Segment } from './segment';

import * as moment from 'moment';
import * as _ from 'lodash';

export class PhSegment extends Segment implements SegmentInterface {

  company_code: string;
  paid: boolean;
  description: string;

  location_in: any;
  location_out: any;
  clock_in_time: Date;
  clock_out_time: Date;
  address_in: string;
  address_out: string;

  private _ph_project: any;
  private _employee: any;
  private _is_active_employee: boolean;

  constructor(
    segment_key: number = null, deleted_flag: boolean = false, row_version: string, unit_flag: boolean, credit_flag: boolean,
    segment_date: Date, start_time: Date = null, end_time: Date = null, break_duration: number = null, units: number = null,
    company_code: string, description: string = '', employee: any, ph_project: any,
    paid: boolean = false, location_in: any = null, location_out: any = null, address_in: string = null, address_out: string = null,
    clock_in_time: Date = null, clock_out_time: Date = null
  ) {
    if (start_time === null || end_time === null || break_duration === null) {
      const d = PhSegment._generatePhSegmentTimeDefaults(segment_date, ph_project);

      start_time = d.start_time;
      end_time = d.end_time;
      break_duration = d.break_duration;
    }

    super(
      segment_key, deleted_flag, row_version, unit_flag, credit_flag,
      segment_date, start_time, end_time, break_duration, units || 0
    );

    this.company_code = company_code;
    this.paid = paid;
    this.description = description;

    this.location_in = location_in;
    this.location_out = location_out;
    this.address_in = address_in;
    this.address_out = address_out;
    this.clock_in_time = clock_in_time;
    this.clock_out_time = clock_out_time;

    this._ph_project = ph_project;
    this._employee = employee;

    this.unit_flag = this._ph_project.pay_code_unit !== 'Hours' || this.credit_flag;

    this._updateIsActiveEmployee();
  }

  // Super class function extensions /////////////////////////////////
  get segment_date(): Date {
    return super.segment_date;
  }
  set segment_date(segment_date: Date) {
    super.segment_date = segment_date;
    this._updateIsActiveEmployee();
  }

  // Interface getters/setters //////////////////////////////////////
  get colour(): string {
    return this._ph_project.project_colour;
  }
  get work_name(): string {
    return this._ph_project.project_title;
  }
  get unit_type(): string {
    return this._ph_project.pay_code_unit;
  }
  get is_locked(): boolean {
    return this.credit_flag ||
      !this._ph_project.active_flag ||
      this._ph_project.non_team_time_project_flag ||
      this.paid;
  }
  get is_active_project(): boolean {
    return this._ph_project.active_flag;
  }

  get is_ph_segment(): boolean {
    return true;
  }
  get is_inv_segment(): boolean {
    return false;
  }

  // Class getters/setters //////////////////////////////////////
  get is_active_employee(): boolean {
    return this._is_active_employee;
  }
  get employee(): any {
    return this._employee;
  }
  set employee(employee: any) {
    this._employee = employee;

    this._updateIsActiveEmployee();
  }
  get ph_project(): any {
    return this._ph_project;
  }
  set ph_project(project: any) {
    this._ph_project = project;
    this.unit_flag = this._ph_project.pay_code_unit !== 'Hours' || this.credit_flag;
  }

  // Interface functions //////////////////////////////////////
  formatSegmentForPosting(): any {
    if (this.unit_flag) {
      return {
        segment_key: this.segment_key,
        row_version: this.row_version,
        deleted_flag: this.deleted_flag,
        segment_date: TimeUtilService.formatDateForPosting(this.segment_date, false),
        units: this.units,
        company_code: this.company_code,
        employee_key: this.employee.employee_key,
        project_key: this.ph_project.project_key,
        description: this.description
      };
    }
    else {
      return {
        segment_key: this.segment_key,
        row_version: this.row_version,
        deleted_flag: this.deleted_flag,
        segment_date: TimeUtilService.formatDateForPosting(this.segment_date, false),
        start_time: TimeUtilService.formatDateForPosting(this.start_time, true),
        end_time: TimeUtilService.formatDateForPosting(this.end_time, true),
        duration: this.duration,
        break_duration: this.break_duration,
        company_code: this.company_code,
        employee_key: this.employee.employee_key,
        project_key: this.ph_project.project_key,
        description: this.description
      };
    }
  }

  // Class functions //////////////////////////////////////
  private _updateIsActiveEmployee() {
    const segmentDate = moment(this.segment_date);
    const employeeStartDate = moment(this.employee.employment_start_date);
    const employeeEndDate = this.employee.employment_end_date ? moment(this.employee.employment_end_date) : null;

    this._is_active_employee = segmentDate.isSameOrAfter(employeeStartDate, 'day') &&
      (employeeEndDate === null || segmentDate.isSameOrBefore(employeeEndDate, 'day'));
  }

  private static _generatePhSegmentTimeDefaults(segment_date: Date, ph_project: any): any {
    // Init default start
    const start_time = _.cloneDeep(segment_date);
    if (ph_project.default_start_time instanceof Date) {
      start_time.setHours(ph_project.default_start_time.getHours(), ph_project.default_start_time.getMinutes(), 0, 0);
    }
    else {
      start_time.setHours(9, 0, 0, 0);
    }

    // Init default end
    let end_time = new Date();
    if (ph_project.default_end_time instanceof Date) {
      end_time.setHours(ph_project.default_end_time.getHours(), ph_project.default_end_time.getMinutes(), 0, 0);
    }
    else {
      end_time.setHours(17);
    }

    // Ensure end isn't same as start
    if (moment(start_time).isSame(moment(end_time), 'minute')) {
      end_time = TimeUtilService.incrementHours(end_time, 1);
    }
    // Ensure end isn't before start
    else if (moment(end_time).isBefore(moment(start_time), 'minute')) {
      end_time = TimeUtilService.incrementDate(end_time, 1);
    }

    // Init default break
    let break_duration = 0;

    if (ph_project.default_break_duration !== null) {
      break_duration = ph_project.default_break_duration;
    }

    return {
      start_time,
      end_time,
      break_duration
    };
  }

}
