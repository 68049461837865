<div class="onboard-step-type">
  Form
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">

  <div class="onboard-step-form-required"
       *ngIf="stepTemplate.required_flag">
    All Fields Required
  </div>

  <div class="onboard-step-form-container">
    <div class="ph-formItem"
         *ngFor="let formAnswer of formAnswers">
      <div class="ph-formItem-label">
        {{formAnswer.form_name}}
      </div>

      <!-- String -->
      <input class="ph-formItem-input"
             type="text"
             *ngIf="formAnswer.field_type === null || formAnswer.field_type === 'string'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)">

      <!-- Number -->
      <input class="ph-formItem-input"
             type="number"
             step="any"
             *ngIf="formAnswer.field_type === 'number'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)">

      <!-- Email -->
      <input class="ph-formItem-input"
             type="email"
             *ngIf="formAnswer.field_type === 'email'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)">

      <!-- Address -->
      <input class="ph-formItem-input"
             type="text"
             placeholder=""
             *ngIf="formAnswer.field_type === 'address'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)"
             GooglePlacesAutocomplete
             [country_key]="country_key">

      <!-- IRD Number -->
      <input class="ph-formItem-input"
             type="text"
             mask="000-000-000"
             [dropSpecialCharacters]="true"
             *ngIf="formAnswer.field_type === 'nzird'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)">

      <!-- NZ Bank Account -->
      <input class="ph-formItem-input"
             type="text"
             mask="00-0000-0000000-000"
             [dropSpecialCharacters]="false"
             *ngIf="formAnswer.field_type === 'nzbank'"
             [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
             [(ngModel)]="formAnswer.form_answer"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="formAnswerUpdated(formAnswer)"
             (blur)="formAnswerUpdated(formAnswer)"
             (focus)="formAnswerFocused(formAnswer)">

      <!-- Date -->
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDDD, D MMM YYYY"
                    *ngIf="formAnswer.field_type === 'date'"
                    [ngClass]="{ '-invalid': formAnswer.invalid_answer }"
                    [(ngModel)]="formAnswer.form_answer"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="formAnswerUpdated(formAnswer)">
      </ion-datetime>

      <div class="ph-inputErrorMessage"
           *ngIf="formAnswer.invalid_answer">
        <i class="ion ion-ios-warning"></i>
        Invalid {{formAnswer.form_name}}
      </div>

    </div>
  </div>
</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
