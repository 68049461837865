import { Injectable } from '@angular/core';

import { TimeUtilService } from './../time-util/time-util.service';

import { env } from '../../../environments/environment';

export type AppProduct = (
  'SUB' | 'PH' | 'INV'
);

export type SubAuth = {
  return_login_token: string,
  return_session_key: string,
  user_access_company_key: number
};

export type PhAuth = {
  session_key: string,
  company_code: string,
};

export type InvAuth = {
  session_key: string,
  company_key: number,
};

export type Auth = {
  SUB: SubAuth,
  PH: PhAuth,
  INV: InvAuth
};

@Injectable({
  providedIn: 'root'
})
export class StateDataService {

  localStorage: any = window.localStorage;
  sessionStorage: any = window.sessionStorage;

  private _selectedMonth: Date;
  private _selectedWeekStart: Date;

  private _productCode: string = null;
  private _sessionCachePrefix: string = null;
  private _localGlobalCachePrefix: string = null; // Prefixed with GLOBAL

  private _localCachePrefix: string = null; // Prefixed with user access company key. Set when we wet the global UAC key
  private _verifyLocalCache: boolean = false; // Whether or not we're ready to verify local cache access

  private _user_key: number = null;

  readonly dayIndices: any = { Mon: 0, Tue: 1, Wed: 2, Thu: 3, Fri: 4, Sat: 5, Sun: 6 };

  constructor() {
    this._productCode = env.product_code;
    this._sessionCachePrefix = this._productCode + '_mobile_';
    this._localGlobalCachePrefix = this._productCode + '_GLOBAL_mobile_';

    this._initGlobalUserAccessCompanyKey();

    this._selectedMonth = TimeUtilService.dateStringToDate(
      this._getSessionStorageData(this._sessionCachePrefix + 'selectedMonth'), null, false
    );
    this._selectedWeekStart = TimeUtilService.dateStringToDate(
      this._getSessionStorageData(this._sessionCachePrefix + 'selectedWeekStart'), null, false
    );
  }

  set user_key(user_key: number) {
    this._user_key = user_key;
  }

  ///////////////////////////////////////////////////////////
  // Use these functions for any component specific caching
  // For most data, session storage should be used
  ///////////////////////////////////////////////////////////

  // Session storage
  cacheComponentSessionData(componentName: string, dataName: string, data: any) {
    this._setSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName, data);
  }

  getCachedComponentSessionData(componentName: string, dataName: string): any {
    return this._getSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  clearCachedComponentSessionData(componentName: string, dataName: string) {
    this._removeSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  // Local storage
  cacheComponentLocalData(componentName: string, dataName: string, data: any) {
    this._setLocalStorageData(this._localCachePrefix + componentName + '_' + dataName, data);
  }

  getCachedComponentLocalData(componentName: string, dataName: string): any {
    return this._getLocalStorageData(this._localCachePrefix + componentName + '_' + dataName);
  }

  clearCachedComponentLocalData(componentName: string, dataName: string) {
    this._removeLocalStorageData(this._localCachePrefix + componentName + '_' + dataName);
  }

  clearAllCachedData() {
    for (const key of Object.keys(this.localStorage)) {
      this.localStorage.removeItem(key);
    }
    for (const key of Object.keys(this.sessionStorage)) {
      this.sessionStorage.removeItem(key);
    }
  }

  ///////////////////////////////////////////////////////////

  initSelectedWeekStart(companyWeekStart: string) {
    if (!this._selectedWeekStart) {
      const weekStart = TimeUtilService.getMonday(new Date());
      companyWeekStart = companyWeekStart || 'Mon';

      this._selectedWeekStart = TimeUtilService.incrementDate(weekStart, this.dayIndices[companyWeekStart]);
      this._setSessionStorageData(this._sessionCachePrefix + 'selectedWeekStart', TimeUtilService.dateToDateString(this._selectedWeekStart, null));
    }
  }

  get backStack(): any[] {
    return this._getSessionStorageData(this._sessionCachePrefix + 'backStack');
  }

  set backStack(stack: any[]) {
    this._setSessionStorageData(this._sessionCachePrefix + 'backStack', stack);
  }

  get selectedWeekStart(): Date {
    if (!!this._selectedWeekStart) {
      return this._selectedWeekStart;
    }
    else {
      this._selectedWeekStart = TimeUtilService.getMonday(new Date());
      this._setSessionStorageData(this._sessionCachePrefix + 'selectedWeekStart', TimeUtilService.dateToDateString(this._selectedWeekStart, null));
      return this._selectedWeekStart;
    }
  }
  set selectedWeekStart(selectedWeekStart: Date) {
    this._selectedWeekStart = selectedWeekStart;
    this._setSessionStorageData(this._sessionCachePrefix + 'selectedWeekStart', TimeUtilService.dateToDateString(this._selectedWeekStart, null));
  }

  get selectedMonth(): Date {
    if (!!this._selectedMonth) {
      return this._selectedMonth;
    }
    else {
      this._selectedMonth = new Date();
      this._selectedMonth.setDate(1);
      this._setSessionStorageData(this._sessionCachePrefix + 'selectedMonth', TimeUtilService.dateToDateString(this._selectedMonth, null));
      return this._selectedMonth;
    }
  }
  set selectedMonth(selectedMonth: Date) {
    this._selectedMonth = TimeUtilService.updateDate(selectedMonth, 1);
    this._setSessionStorageData(this._sessionCachePrefix + 'selectedMonth', TimeUtilService.dateToDateString(this._selectedMonth, null));
  }

  get selectedTeamKey(): number {
    return this._getSessionStorageData(this._sessionCachePrefix + this._user_key + '_teamKey');
  }
  set selectedTeamKey(team_key: number) {
    this._setSessionStorageData(this._sessionCachePrefix + this._user_key + '_teamKey', team_key);
  }

  ///////////////////////////////////////////////////////////
  // Auth Caching
  ///////////////////////////////////////////////////////////

  get auth(): Auth {
    const session_auth = this._getSessionStorageData(this._sessionCachePrefix + 'session_auth');
    const local_auth = this._getLocalStorageData(this._localCachePrefix + 'local_auth', true);

    return {
      SUB: {
        return_session_key: session_auth?.SUB?.return_session_key || null,
        return_login_token: local_auth?.SUB?.return_login_token || null,
        user_access_company_key: session_auth?.SUB?.user_access_company_key || null
      },
      PH: {
        session_key: session_auth?.PH?.session_key || null,
        company_code: session_auth?.PH?.company_code || null,
      },
      INV: {
        session_key: session_auth?.INV?.session_key || null,
        company_key: session_auth?.INV?.company_key || null,
      }
    };
  }
  set auth(auth: Auth) {
    if (!!auth) {
      const session_auth = {
        SUB: {
          return_session_key: auth.SUB.return_session_key,
          user_access_company_key: auth.SUB.user_access_company_key
        },
        PH: {
          session_key: auth.PH.session_key,
          company_code: auth.PH.company_code
        },
        INV: {
          session_key: auth.INV.session_key,
          company_key: auth.INV.company_key
        }
      };
      const local_auth = {
        SUB: {
          return_login_token: auth.SUB.return_login_token
        }
      };

      this.global_user_access_company_key = session_auth.SUB.user_access_company_key;

      this._setSessionStorageData(this._sessionCachePrefix + 'session_auth', session_auth);
      this._setLocalStorageData(this._localCachePrefix + 'local_auth', local_auth);
    }
    else {
      this._removeSessionStorageData(this._sessionCachePrefix + 'session_auth');
      this._removeLocalStorageData(this._localCachePrefix + 'local_auth');
    }
  }

  ///////////////////////////////////////////////////////////

  get global_user_access_company_key(): number {
    const key = this._localGlobalCachePrefix + 'global_user_access_company_key';
    return parseInt(this.localStorage.getItem(key), null) || null;
  }

  set global_user_access_company_key(value: number) {
    const key = this._localGlobalCachePrefix + 'global_user_access_company_key';
    this.localStorage.setItem(key, value);
    // When setting this value we want to ensure the localCachePrefix is updated
    this._setLocalCachePrefix(value);
  }

  get session_user_access_company_key(): number {
    return this._getSessionStorageData(this._sessionCachePrefix + 'session_auth')?.SUB?.user_access_company_key || null;
  }

  ///////////////////////////////////////////////////////////

  // In light of new usage of Local Cache we need to ensure that this session isn't accessing local storage data for a different session.
  // Our method for this is to compare our global UAC key stored in local storage against our session storage equivalent to ensure they match
  // If there is a mismatch, we direct back to the login app. This intentionally preserves the local storage data.
  verifyGlobalUserAccessCompanyKey(): boolean {
    // Only verify locla cache access if we've set our session UAC key as the global UAC key
    if (this._verifyLocalCache) {
      if (this.session_user_access_company_key === this.global_user_access_company_key) {
        return true;
      }
      else {
        // We don't want to clear all cached data here because we're likely in this situation because
        // they've logged in to another user in another tab.
        // Clearing cached data would break the other tab. We just want to send them back to the applet.
        // Even calling logout() here will interfere with
        // the local storage for the other tab.
        window.location.href = env.subscription.app_url + 'login';
        return false;
      }
    }
    else {
      return true;
    }
  }

  private _initGlobalUserAccessCompanyKey() {
    // If we have UAC Key in session storage then we're refreshing the page.
    // If this is the case, ensure our global UAC Key is set to this session's.
    // This will invalidate any other sessions.
    const session_uac_key = this.session_user_access_company_key;

    if (session_uac_key) {
      this.global_user_access_company_key = session_uac_key;
    }
    else if (!!this.global_user_access_company_key) {
      this._setLocalCachePrefix(this.global_user_access_company_key);
    }
  }

  ///////////////////////////////////////////////////////////

  private _getLocalStorageData(dataName: string, ignoreVerification: boolean = false): any {
    if (ignoreVerification || this.verifyGlobalUserAccessCompanyKey()) {
      const data = this.localStorage.getItem(dataName);
      return data ? StateDataService._parseJSON(data) : null;
    }
    return null;
  }

  private _setLocalStorageData(dataName: string, data: any): void {
    if (this.verifyGlobalUserAccessCompanyKey()) {
      this.localStorage.setItem(dataName, JSON.stringify(data));
    }
  }

  private _removeLocalStorageData(dataName: string): void {
    if (this.verifyGlobalUserAccessCompanyKey()) {
      this.localStorage.removeItem(dataName);
    }
  }

  private _getSessionStorageData(dataName: string) {
    const data = this.sessionStorage.getItem(dataName);
    return data ? StateDataService._parseJSON(data) : null;
  }

  private _setSessionStorageData(dataName: string, data: any) {
    this.sessionStorage.setItem(dataName, JSON.stringify(data));
  }

  private _removeSessionStorageData(dataName: string) {
    this.sessionStorage.removeItem(dataName);
  }

  private _setLocalCachePrefix(value: number): void {
    this._localCachePrefix = this._productCode + '_' + value + '_mobile_';
    this._verifyLocalCache = true;
  }

  private static _parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

}
