<div class="onboard-step-type">
  Upload
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">

  <a class="-color-payhero-clear -size-btn-lg -size-text-lg onboard-step-contentsLinkBtn"
     target="_blank"
     *ngIf="currentDocument !== null"
     [href]="inv_blob_url + currentDocument.document_url + '?' + invOnboardService.blobSASKey">
    <i class="ion ion-md-download"></i>
    {{currentDocument.file_name}}
  </a>

  <button class="onboard-step-upload-documentDelete -color-default-clear"
          *ngIf="currentDocument !== null"
          (click)="deleteClicked()">
    <i class="ion ion-md-close"></i>
    Delete Document
  </button>

  <input #onboardStepUploadInput
         class="onboard-step-upload-input"
         type="file"
         file-reader
         file-data="fileToUpload.data"
         (change)="documentChanged($event.target.files)" />

  <button class="-color-payhero-clear -size-btn-lg -size-text-lg onboard-step-contentsLinkBtn"
          (click)="uploadButtonClicked()"
          [hidden]="currentDocument !== null">
    <i class="ion ion-md-cloud-upload"></i>
    Select Document
  </button>

</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
