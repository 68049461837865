import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateChangeService } from './../../../services/state-change/state-change.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { InvTimeService } from './../../../services/inv-time/inv-time.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { UtilService } from './../../../services/util/util.service';
import { AlertService } from './../../../services/alert/alert.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { InvUserService } from './../../../services/inv-user/inv-user.service';
import { DomService } from './../../../services/dom/dom.service';
import { InvProjectUtilService } from 'src/app/services/inv-project-util/inv-project-util.service';

import { PageTimeEdit } from '../../../classes/abstract/page-time-edit/page-time-edit';

import { InvSegment } from './../../../models/segment/inv-segment';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-inv-time-edit',
  templateUrl: './inv-time-edit.component.html',
  styleUrls: ['./inv-time-edit.component.scss']
})
export class InvTimeEditComponent extends PageTimeEdit implements OnInit {

  readonly errorMessages: any = {
    noProjects: 'At least one placement must be active on the selected date to record time',
    inactiveProject: 'Time can\'t be recorded outside the period that the placement is active',
    noUsers: 'At least one resource must be active on the selected date to record time'
  };

  segment: InvSegment;

  multiDayUnitSegmentCheckTriggered: boolean = false;

  loggedInUserKey: any;
  userIsLabourHireOrClient: boolean;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public invTimeService: InvTimeService,
    public invProjectService: InvProjectService,
    public invCompanyService: InvCompanyService,
    public utilService: UtilService,
    public alertService: AlertService,
    public invUserService: InvUserService,
    public domService: DomService
  ) {
    super();
  }

  ngOnInit() {
    this.isAdmin = this.invCompanyService.isAdminOrOwner();
    this.isManager = this.invCompanyService.isTimeManager();
    this.currencySymbol = this.utilService.currencySymbol;
    this.loggedInUserKey = this.invCompanyService.getUserKey();

    this.segments = this.stateService.params.segments || [];
    this.segment = _.cloneDeep(this.stateService.params.segment) || null;
    const segment_key = this.stateService.params.segment_key || null;

    if (this.segment) {
      this.initialiseData();
    }
    else if (segment_key) {
      this.invTimeService.loadSegment(segment_key)
        .then((segment) => {
          this.segment = segment;
          this.initialiseData();
        })
        .catch(() => {
          this.back();
        });
    }
    else {
      const user_key = this.stateService.params.user_key || null;
      const project = this.getDefaultProject(user_key);

      if (project === null) {
        this.utilService.toastMessage('An active placement is required for recording time');
        this.back();
      }
      else {
        const user = this.getDefaultUser(project);
        const task = this.getDefaultTask(project);

        if (user !== null && task !== null) {
          this.segment = this.invTimeService.initNewSegment(
            user.user_key,
            project.project_key,
            task.task_key,
            this.defaultSegmentDate
          );
          this.initialiseData();
        }
        else {
          this.back();
        }

      }
    }
  }

  back() {
    this.stateChangeService.back(['app.inv.time.work']);
  }

  initialiseData() {
    this.loading = true;

    this.initEditingDisabled();

    this.initSegmentUnitsTouched();
    this.initPublicHolidays();
    this.initAllProjects();

    this.updateDateStrings();

    this.checkForErrors();
    this.checkPublicHoliday();

    this.updatePageTitle();

    this.loading = false;
  }

  getDefaultProject(user_key: number = null): any {
    let project_key: number = null;

    if (this.stateService.params.project_key) {
      project_key = this.stateService.params.project_key;
    }
    else {
      // Get all projects active on default date
      let allProjects = this.invProjectService.getAllActiveProjects(
        this.defaultSegmentDate, this.defaultSegmentDate, true, false, false, user_key
      );

      if (allProjects.length) {
        project_key = allProjects[0].project_key;
      }
      else {
        // If no projects are active on default date, get all projects available
        // A warning will be provided to the user
        allProjects = this.invProjectService.getAllActiveProjects(
          null, null, true, false, false, user_key
        );

        if (allProjects.length) {
          project_key = allProjects[0].project_key;
        }
      }
    }

    return this.invProjectService.getSingleProject(project_key);
  }

  getDefaultUser(project: any): any {
    const user_key = this.stateService.params.user_key;
    const projectUser = project.projectUsersMap[user_key] || null;

    if (projectUser !== null && projectUser.resource_flag) {
      return projectUser;
    }
    // Default to first resource in project.users
    else {
      for (const pu of project.users) {
        if (pu.resource_flag) {

          return pu;
        }
      }
    }

    return null;
  }

  getDefaultTask(project: any): any {
    const task_key = this.stateService.params.task_key;
    const projectTask = project.projectTasksMap[task_key] || null;

    if (projectTask !== null) {
      return projectTask;
    }
    // Default to first task in project.tasks
    else {
      const expensesEnabled = this.invCompanyService.expensesEnabled();
      const activeTasks = InvProjectUtilService.getActiveTimesheetTasks(project.tasks, expensesEnabled);

      for (const pt of activeTasks) {
        return pt;
      }
    }

    return null;
  }

  /**
   * Clients and labour hire resources can't edit segments
   */
  initEditingDisabled() {
    const isClient = this.invCompanyService.isClient();
    const highestAuthIsResource = this.invCompanyService.getAuthValue() === 3;

    this.editingDisabled = this.segment.credit_flag ||
      isClient ||
      (highestAuthIsResource && this.segment.inv_project.project_type === 'Labour Hire');
    this.userIsLabourHireOrClient = this.editingDisabled;
  }

  initPublicHolidays() {
    this.publicHolidaysMap = this.invCompanyService.getPublicHolidaysMap(this.segment.user);
  }

  initAllProjects() {
    this.allProjects = this.invProjectService.getAllActiveProjects(
      this.segment.segment_date, this.segment.segment_date, true
    );

    this.checkForErrors();
  }

  projectSelected(event: any) {
    this.segment.inv_project = event.item;
    this.segmentUnitsTouched = true;

    this.checkForErrors();
    this.checkMultiDayUnitSegment();
    this.updateDateStrings();
  }

  projectTaskSelected(event: any) {
    this.segment.task = event.item;
    this.segmentUnitsTouched = true;

    this.checkForErrors();
    this.checkMultiDayUnitSegment();
    this.updateDateStrings();
  }

  userSelected(user: any) {
    this.segment.user = user;
    this.segmentUnitsTouched = true;

    this.checkForErrors();
    this.updateDateStrings();
    this.checkPublicHoliday();
  }

  openUserList() {
    if (!this.segment.is_locked && !this.segment.segment_key) {

      this.domService.openSlideUpList(
        this.segment.project_users,
        this.segment.user,
        'display_name',
        null,
        (user: any) => {
          this.userSelected(user);
        }
      );
    }
  }

  updatePageTitle() {
    let prefix = '';

    if (this.segment.credit_flag){
      prefix += 'Credited '
    }
    else if (this.segment.task.admin_only_flag) {
      prefix += 'View';
    }
    else if (this.isAdmin || this.isManager) {
      prefix += this.segment.status;
    }
    else {
      prefix += this.segment.resource_status;
    }

    this.pageTitle = prefix + (this.segment.unit_flag ? ' Work' : ' Time');
  }

  segmentDateStringUpdated() {
    super.segmentDateStringUpdated();
    this.initAllProjects();
    this.checkPublicHoliday();
  }

  unitsUpdated() {
    super.unitsUpdated();
    this.checkMultiDayUnitSegment();
  }

  deleteSegment() {
    this.alertService.confirmDeleteAlert('Are you sure you want to delete this time record?')
      .then(() => {
        this.saveSegment(true);
      });
  }

  saveSegment(toDelete: boolean = false) {
    this.loading = true;
    this.segment.deleted_flag = toDelete;

    this.invTimeService.saveSegments([this.segment])
      .then(() => {
        this.loading = false;
        this.back();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  async test() {

  }

  checkMultiDayUnitSegment() {
    if (!this.multiDayUnitSegmentCheckTriggered) {
      if (this.segment.unit_flag &&
        this.segment.unit_type === 'days' && this.segment.units > 1) {

        this.multiDayUnitSegmentCheckTriggered = true;

        const message = '<p> It looks like you\'re trying to record more than 1 day for <b>' +
          moment(this.segment.segment_date).format('dddd') + '.</b></p>' +
          '<p> Please confirm that <b>' + this.segment.units + ' days</b> is correct.</p>';

        this.alertService.confirmAlert(null, message)
          .catch(() => {
            this.segment.units = 1;
          });
      }
    }
  }

  checkPublicHoliday() {
    const dateString = TimeUtilService.dateToDateString(this.segment.segment_date, null);
    this.currentPublicHoliday = this.publicHolidaysMap[dateString] || null;
  }

  checkForErrors() {
    // No need to show errors if the segment is locked
    // due to the user being a client or labour hire resource
    if (this.userIsLabourHireOrClient) {
      this.errorMessage = null;
    }
    else {
      super.checkForErrors();
      this._checkIsActiveProject();
    }
  }

  private _checkIsActiveProject() {
    this.clearErrorIfMatches(this.errorMessages.inactiveProject);
    if (!this.segment.is_active_project) {
      this.errorMessage = this.errorMessages.inactiveProject;

      for (const project of this.allProjects) {
        if (project.project_key === this.segment.inv_project.project_key) {
          return;
        }
      }
      this.allProjects.unshift(this.invProjectService.getSingleProject(this.segment.inv_project.project_key));
    }
  }
}
