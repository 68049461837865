<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Team Clocks</ion-title>

    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              (click)="openFiltersPopup()">
        <i class="ion ion-md-options"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="ph-popover"
     *ngIf="filtersOpen">

  <div class="ph-timeManagerClocks-filterHeader">
    Filter Employees
  </div>

  <div class="ph-timeManagerClocks-filter"
       (click)="toggleFilterFlag('active')">
    Clocked In
    <div class="ph-toggle"
         [ngClass]="filterFlags.active ? 'ph-toggled' : ''">
      <div class="ph-toggle-track">
        <div class="ph-toggle-icon"></div>
      </div>
    </div>

  </div>
  <div class="ph-timeManagerClocks-filter"
       (click)="toggleFilterFlag('onBreak')">
    On Break
    <div class="ph-toggle"
         [ngClass]="filterFlags.onBreak ? 'ph-toggled' : ''">
      <div class="ph-toggle-track">
        <div class="ph-toggle-icon"></div>
      </div>
    </div>

  </div>
  <div class="ph-timeManagerClocks-filter"
       (click)="toggleFilterFlag('inactive')">
    Clocked Out
    <div class="ph-toggle"
         [ngClass]="filterFlags.inactive ? 'ph-toggled' : ''">
      <div class="ph-toggle-track">
        <div class="ph-toggle-icon"></div>
      </div>
    </div>

  </div>
</div>

<div class="ph-pageOverlay"
     (click)="closeFilterPopup()"
     *ngIf="filtersOpen">
</div>

<ion-content [ngStyle]="{'pointer-events': filtersOpen ? 'none' : ''}">

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshEmployeeClocks($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-spinner"
       *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-search">
    <i class="ion ion-ios-search"></i>
    <input type="search"
           placeholder="Search employees or locations..."
           [(ngModel)]="search"
           (ngModelChange)="searchUpdated()">
    <i class="ion ion-ios-close"
       *ngIf="search.length"
       (click)="clearSearch()">
    </i>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && allEmployees.length === 0">
    <i class="ion ion-ios-contacts"></i>
    <div>No active employees in your team</div>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && allEmployees.length !== 0 && filteredEmployees.length === 0">
    <i class="ion ion-ios-search"></i>
    <div>No employees match your search</div>
  </div>

  <div class="ph-timeManagerClocks-clockContainer"
       *ngIf="!loading">

    <div class="ph-groupedList-container -timeManagerClocks"
         *ngFor="let employee of filteredEmployees">

      <div class="ph-groupedList-groupHeader">
        <div class="ph-groupedList-groupHeaderTitle">
          <div class="ph-groupedList-groupHeaderTimerIcon"
               *ngFor="let clock of clocksMap[employee.employee_key]"
               [ngStyle]="{ 'opacity': !timerFlasher ? 0 : '' }"
               [ngClass]="{ '-onBreak': clock.is_on_break }">
          </div>
          {{employee.employee_code}}
        </div>

        <div class="ph-groupedList-groupHeaderLine"></div>

        <div class="ph-groupedList-groupHeaderTimer"
             *ngFor="let clock of clocksMap[employee.employee_key]"
             [ngClass]="{ '-onBreak': clock.is_on_break }">
          <span *ngIf="clock.is_on_break">
            {{clockTimeDataMap[clock.clock_key].breakTimerSeconds | clockTimerMainPipe}}
          </span>
          <span *ngIf="!clock.is_on_break">
            {{clockTimeDataMap[clock.clock_key].clockTimerSeconds | clockTimerMainPipe}}
          </span>
        </div>
      </div>

      <div class="ph-spinner"
           *ngIf="employeeLoadingMap[employee.employee_key]">
        <ion-spinner></ion-spinner>
      </div>

      <div class="ph-groupedList-item -timerItem"
           *ngFor="let clock of clocksMap[employee.employee_key]"
           [ngStyle]="{ 'opacity': employeeLoadingMap[employee.employee_key] ? 0 : '' }"
           (click)="viewClock(clock)">

        <div class="ph-groupedList-itemColor"
             *ngIf="clock.project"
             [ngStyle]="{'background-color': clock.project.project_colour}">
        </div>

        <div class="ph-groupedList-itemTitle">
          <span>{{clock.project ? clock.project.project_title : 'New Clock'}}</span>
        </div>

        <div class="ph-groupedList-itemDescription"
             *ngIf="clock.clock_breaks.length">
          {{clock.clock_breaks.length}} Break{{clock.clock_breaks.length !== 1 ? 's' : ''}}
        </div>

        <div class="ph-groupedList-itemDescription"
             *ngIf="clock.location_in || clock.address_in">
          <i class="ion ion-ios-pin"></i>
          <span *ngIf="clock.location_in">
            {{clock.location_in.location_name}}
          </span>
          <span *ngIf="!clock.location_in && clock.address_in">
            {{clock.address_in}}
          </span>
        </div>

        <div class="ph-groupedList-itemDetails">
          <span>Clocked In: </span>
          <div>{{clock.clock_in_time | date : 'shortTime'}}</div>
          <span *ngIf="clock.is_multiple_days">
            {{clock.clock_in_time | date : 'd MMM'}}
          </span>
        </div>

        <div class="ph-groupedList-itemTimerButtons"
             [ngStyle]="{ 'width': clock.is_on_break ? '70px' : '' }"
             (click)="$event.stopPropagation()">
          <button class="-color-success-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -play"
                  *ngIf="clock.is_on_break"
                  (click)="breakOut($event, clock)">
            <i class="ion ion-md-play"></i>
          </button>

          <button class="-color-yellow-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -pause"
                  *ngIf="!clock.is_on_break"
                  (click)="breakIn($event, clock)"
                  [disabled]="clockTimeDataMap[clock.clock_key].clockExceedsMaxSeconds">
            <i class="ion ion-md-pause"></i>
          </button>

          <button class="-color-danger-clear -type-icon -size-icon-lg ph-groupedList-itemTimerButton -stop"
                  *ngIf="!clock.is_on_break"
                  (click)="clockOut($event, clock)">
            <i class="ion ion-md-square"></i>
          </button>
        </div>

      </div>

      <div class="ph-groupedList-item"
           [ngStyle]="{ 'opacity': employeeLoadingMap[employee.employee_key] ? 0 : '' }"
           *ngIf="clocksMap[employee.employee_key].length === 0">

        <div class="ph-timeManagerClocks-clockIn">
          <button class="-color-success-clear"
                  (click)="clockIn($event, employee)">
            <i class="ion ion-md-play">
            </i>
            Start Clock
          </button>
        </div>
      </div>

    </div>

  </div>


</ion-content>
