import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from '../onboard-step.interface';
import { InvOnboardService } from '../../../services/inv-onboard/inv-onboard.service';
import { DomService } from '../../../services/dom/dom.service';

@Component({
  selector: 'onboard-nztax',
  templateUrl: './onboard-nztax.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-nztax.component.scss']
})
export class OnboardNzTaxComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  // Same as Invoxy UserService list
  taxCodes: string[] = [
    "CAE", "EDW", "M", "ME", "M SL", "ME SL", "ND", "NSW", "S",
    "SB", "SH", "ST", "ST SL", "S SL", "SB SL", "SH SL", "STC", "WT"
  ];

  // Same as Invoxy UserService list
  kiwiSaverRates: any[] = [
    { label: "Not Enrolled", rate: 0 },
    { label: "3%", rate: 3 },
    { label: "4%", rate: 4 },
    { label: "6%", rate: 6 },
    { label: "8%", rate: 8 },
    { label: "10%", rate: 10 }
  ];

  taxCode: string = null;
  kiwiSaverRate: any = null;

  constructor(public invOnboardService: InvOnboardService, public domService: DomService){

  }

  ngOnInit(){
    if (this.stepAnswer){
      this.initFormFromStepAnswer();
    }
    else{
      this.taxCode = 'M';
      this.kiwiSaverRate = this.kiwiSaverRates[0];
    }
  }

  // Init functions ////////////////////////////

  initFormFromStepAnswer(){
    this.taxCode = this.stepAnswer.form_answer.tax_code;
    this.kiwiSaverRate = this.getKiwiSaverObject(this.stepAnswer.form_answer.kiwisaver_percentage);
  }

  getKiwiSaverObject(rate: number){
    for (const obj of this.kiwiSaverRates){
      if (obj.rate === rate){
        return obj;
      }
    }
    return this.kiwiSaverRates[0];
  }

  openTaxCodeList(){
    this.domService.openSlideUpList(
      this.taxCodes,
      this.taxCode,
      null,
      null,
      (tax_code: string) => {
        this.taxCode = tax_code;
      }
    );
  }

  openKiwiSaverRateList(){
    this.domService.openSlideUpList(
      this.kiwiSaverRates,
      this.kiwiSaverRate,
      'label',
      null,
      (rateObj: any) => {
        this.kiwiSaverRate = rateObj;
      }
    );
  }

  stepAnswerChanged(){
    if (!this.stepAnswer){ // If they haven't answered anything but change step then we want to post
      return true;
    }

    const form_answer = this.stepAnswer.form_answer;

    if (form_answer.tax_code !== this.taxCode ||
        form_answer.kiwisaver_percentage !== this.kiwiSaverRate.rate){
      return true;
    }
    return false;
  }

  stepAnswerNeedsUpdating(): boolean {
    return this.stepAnswerChanged();
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    stepData.form_answer = JSON.stringify({
      tax_code: this.taxCode,
      kiwisaver_percentage: this.kiwiSaverRate.rate
    });

    return stepData;
  }

  updateStepAnswer(){
    if (this.stepAnswerNeedsUpdating()){

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
