
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { InvUserUtilService } from './../inv-user-util/inv-user-util.service';
import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvProjectUtilService {

  static projectIsActive(
    project: any, periodStart: Date, periodEnd: Date, expensesEnabled: boolean,
    onlyTimesheetProjects: boolean = false, onlyExpenseProjects: boolean = false
  ): boolean {

    let activeProjectTasks: any[];

    if (onlyTimesheetProjects) {
      activeProjectTasks = InvProjectUtilService.getActiveTimesheetTasks(project.tasks, expensesEnabled);
    }
    else if (onlyExpenseProjects) {
      activeProjectTasks = InvProjectUtilService.getActiveExpenseTasks(project.tasks);
    }
    else {
      activeProjectTasks = InvProjectUtilService.getActiveTasks(project.tasks);
    }

    if (activeProjectTasks.length === 0) {
      return false;
    }
    if (!periodStart && !periodEnd) {
      return project.tasks.length > 0;
    }

    return project.tasks.length &&
      moment(project.start_date).isSameOrBefore(periodStart, 'day') &&
      (!project.end_date || moment(project.end_date).isSameOrAfter(periodEnd, 'day'));
  }

  static getProjectFromList(project_key: number, projects: any[]): any {
    for (const project of projects) {
      if (project.project_key === project_key) {
        return project;
      }
    }
    return null;
  }

  static getActiveTimesheetTasks(tasks: any[], companyExpensesEnabled: boolean): any[] {
    const activeTasks = InvProjectUtilService.getActiveTasks(tasks);

    if (companyExpensesEnabled) {
      const timesheetTasks = [];

      for (const task of activeTasks) {
        if (!task.unit_type || task.unit_type !== 'expense') {
          timesheetTasks.push(task);
        }
      }
      return timesheetTasks;
    }
    else {
      return activeTasks;
    }
  }

  static getActiveExpenseTasks(tasks: any[]): any[] {
    const activeTasks = InvProjectUtilService.getActiveTasks(tasks);
    const expenseTasks = [];

    for (const task of activeTasks) {
      if (task.unit_type === 'expense') {
        expenseTasks.push(task);
      }
    }
    return expenseTasks;
  }

  static getActiveNonUnitTasks(tasks: any[]): any[] {
    const activeTasks = InvProjectUtilService.getActiveTasks(tasks);
    const clockTasks = [];

    for (const task of activeTasks){
      if (!task.unit_flag){
        clockTasks.push(task);
      }
    }
    return clockTasks;
  }

  static getActiveTasks(tasks: any[]): any[] {
    const activeTasks = [];

    for (const task of tasks) {
      if (!task.archived_flag && !task.admin_only_flag) {
        activeTasks.push(task);
      }
    }

    return activeTasks;
  }

  static projectHasResource(project: any): boolean {
    for (const user of project.users) {
      if (user.resource_flag) {
        return true;
      }
    }
    return false;
  }

  static projectHasSelectedTask(project: any, task_key: number){
    for (const task of project.tasks){
      if (task.task_key === task_key){
        return true;
      }
    }
    return false;
  }

  static isActiveHoursProject(project: any): boolean {
    return project.rate_type === 'hours' && this.getActiveNonUnitTasks(project.tasks).length > 0;
  }

  static userInProject(user_key: number, project: any): boolean {
    for (const user of project.users) {
      if (user.user_key === user_key) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sorts an array of projects by project_name
   */
  static sortProjects(projects: any[]): any[] {
    return projects.sort((a: any, b: any) => {
      const projectTitleA = a.project_name.toUpperCase();
      const projectTitleB = b.project_name.toUpperCase();

      if (projectTitleA > projectTitleB) {
        return 1;
      }
      else if (projectTitleA < projectTitleB) {
        return -1;
      }
      return 0;
    });
  }

  static setupProject(project: any): any {
    project.project_key = parseInt(project.project_key, null);
    project.client_key = parseInt(project.client_key, null);

    project.start_date = TimeUtilService.dateStringToDate(project.start_date, null, false);
    project.end_date = TimeUtilService.dateStringToDate(project.end_date, null, false);
    project.created_date = TimeUtilService.dateStringToDate(project.created_date, null, false);

    project.labels = UtilService.parseJSON(project.labels);

    project.projectTasksMap = {};
    project.projectUsersMap = {};

    for (let i = 0; i < project.tasks.length; i++) {
      const task = InvTaskUtilService.setupTask(project.tasks[i]);

      project.tasks[i] = task;
      project.projectTasksMap[task.task_key] = task;
    }
    for (let i = 0; i < project.users.length; i++) {
      const user = InvUserUtilService.setupUser(project.users[i]);
      // const user = project.users[i];

      project.users[i] = user;
      project.projectUsersMap[user.user_key] = user;
    }

    InvTaskUtilService.sortTasks(project.tasks);
    InvUserUtilService.sortUsersByName(project.users);

    return project;
  }

}
