import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';

@Component({
  selector: 'onboard-ato',
  templateUrl: './onboard-ato.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-ato.component.scss']
})
export class OnboardAtoComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  formAnswer = {
    approved_withholding_variation: false,
    approved_withholding_variation_percentage: null,
    approved_working_holiday_visa: false,
    australian_tax_resident: false,
    residency_status: null,
    employment_basis: null,
    financial_supplement_debt: false,
    help_debt: false,
    previous_surname: null,
    seniors_tax_offset: false,
    tax_file_number: null,
    tax_free_threshold: false,
    tfn_exemption_type: 0
  };

  tfnNumber: string = '';
  tfnExemptionOptions: boolean[] = [ false, false, false ];

  previousSurname: string = '';

  payBasisOptions: boolean[] = [ false, false, false, false, false ];
  payBasisLabels: string[] = [ 'Full Time', 'Part Time', 'Labour Hire', 'Superannuation Income Stream', 'Casual Employment' ];

  residenceStatusOptions: boolean[] = [ false, false, false ];
  residenceStatusLabels: string[] = [ 'Australian Resident', 'Foreign Resident', 'Working Holiday Maker' ];

  taxFreeThreshold: boolean = false;
  helpDebt: boolean = false;
  financialSupplementDebt: boolean = false;
  seniorsTaxOffset: boolean = false;

  approvedWithholdingVariation: boolean = false;
  approvedWithholdingVariationPercentage: number = null;

  validationFlags: any = {
    invalid_tfn: false,
    tfn_missing: false,
    pay_basis_missing: false,
    residency_status_missing: false,
    variation_percentage_missing: false
  };

  constructor(public invOnboardService: InvOnboardService){

  }

  ngOnInit(){
    if (this.stepAnswer){
      this.initFormFromStepAnswer();
    }
  }

  // Init functions ////////////////////////////

  initFormFromStepAnswer(){
    this.tfnNumber = this.stepAnswer.form_answer.tax_file_number;
    this.initTfnExemptionOptions();
    this.previousSurname = this.stepAnswer.form_answer.previous_surname;
    this.initPayBasisOptions();
    this.initResidenceStatusOptions();
    this.taxFreeThreshold = this.stepAnswer.form_answer.tax_free_threshold;
    this.helpDebt = this.stepAnswer.form_answer.help_debt;
    this.financialSupplementDebt = this.stepAnswer.form_answer.financial_supplement_debt;
    this.seniorsTaxOffset = this.stepAnswer.form_answer.seniors_tax_offset;
    this.approvedWithholdingVariation = this.stepAnswer.form_answer.approved_withholding_variation;
    this.approvedWithholdingVariationPercentage = this.stepAnswer.form_answer.approved_withholding_variation_percentage;
  }

  initTfnExemptionOptions(){
    switch (this.stepAnswer.form_answer.tfn_exemption_type){
      case (1):
        this.tfnExemptionOptions[0] = true;
        break;
      case (3):
        this.tfnExemptionOptions[1] = true;
        break;
      case (4):
        this.tfnExemptionOptions[2] = true;
        break;
    }
  }

  initPayBasisOptions(){
    const index = this.payBasisLabels.indexOf(this.stepAnswer.form_answer.employment_basis);

    if (index !== -1){
      this.payBasisOptions[index] = true;
    }
  }

  initResidenceStatusOptions(){
    const index = this.residenceStatusLabels.indexOf(this.stepAnswer.form_answer.residency_status);

    if (index !== -1){
      this.residenceStatusOptions[index] = true;
    }
  }

  // Checkbox toggle functions ////////////////////////////

  tfnExemptionOptionToggled(index: number){
    for (let i = 0; i < this.tfnExemptionOptions.length; i++){
      if (index !== i){
        this.tfnExemptionOptions[i] = false;
      }
    }
    this.validateTfnNumber();
  }

  payBasisOptionToggled(index: number){
    for (let i = 0; i < this.payBasisOptions.length; i++){
      if (index !== i){
        this.payBasisOptions[i] = false;
      }
      else {
        this.payBasisOptions[i] = true;
      }
    }
  }

  residenceStatusOptionToggled(index: number){
    for (let i = 0; i < this.residenceStatusOptions.length; i++){
      if (index !== i){
        this.residenceStatusOptions[i] = false;
      }
      else {
        this.residenceStatusOptions[i] = true;
      }
    }

    if (!this.residenceStatusOptions[0]){
      this.taxFreeThreshold = false;
    }
  }

  approvedWithholdingVariationToggled(){
    if (this.approvedWithholdingVariation === false){
      this.approvedWithholdingVariationPercentage = null;
    }
    this.validateVariationPercentage();
  }

  // Checkbox value getter functions

  getSelectedTfnExemptionCode(): number {
    if (this.tfnExemptionOptions[0]){
      return 1;
    }
    else if (this.tfnExemptionOptions[1]){
      return 3;
    }
    else if (this.tfnExemptionOptions[2]){
      return 4;
    }
    return 0;
  }

  getSelectedPayBasisLabel(): string {
    for (let i = 0; i < this.payBasisOptions.length; i++){
      if (this.payBasisOptions[i] === true){
        return this.payBasisLabels[i];
      }
    }
    return null;
  }

  getSelectedResidenceStatusLabel(): string {
    for (let i = 0; i < this.residenceStatusOptions.length; i++){
      if (this.residenceStatusOptions[i] === true){
        return this.residenceStatusLabels[i];
      }
    }
    return null;
  }

  // Validation functions ////////////////////////////

  validateTfnNumber(){
    this.validationFlags.tfn_missing = false;
    this.validationFlags.invalid_tfn = false;

    if (OnboardAtoComponent.allvaluesFalse(this.tfnExemptionOptions)){

      if (!this.tfnNumber){
        this.validationFlags.tfn_missing = true;
      }
      else if (this.tfnNumber.length !== 9){
        this.validationFlags.invalid_tfn = true;
      }
    }
  }

  validateVariationPercentage(){
    this.validationFlags.variation_percentage_missing = false;

    if (this.approvedWithholdingVariation === true &&
        !this.approvedWithholdingVariationPercentage){

      this.validationFlags.variation_percentage_missing = true;
    }
  }

  stepAnswerValid(){
    this.validationFlags = {
      invalid_tfn: false,
      tfn_missing: false,
      pay_basis_missing: false,
      residency_status_missing: false,
      variation_percentage_missing: false
    };

    this.validateTfnNumber();
    if (OnboardAtoComponent.allvaluesFalse(this.payBasisOptions)){
      this.validationFlags.pay_basis_missing = true;
    }
    if (OnboardAtoComponent.allvaluesFalse(this.residenceStatusOptions)){
      this.validationFlags.residency_status_missing = true;
    }
    this.validateVariationPercentage();

    for (const flag of Object.keys(this.validationFlags)){
      if (this.validationFlags[flag] === true){
        return false;
      }
    }
    return true;
  }

  stepAnswerChanged(){
    const form_answer = this.stepAnswer.form_answer;

    if (form_answer.tax_file_number !== this.tfnNumber ||
        form_answer.tfn_exemption_type !== this.getSelectedTfnExemptionCode() ||
        form_answer.previous_surname !== this.previousSurname ||
        form_answer.employment_basis !== this.getSelectedPayBasisLabel() ||
        form_answer.residency_status !== this.getSelectedResidenceStatusLabel() ||
        form_answer.tax_free_threshold !== this.taxFreeThreshold ||
        form_answer.help_debt !== this.helpDebt ||
        form_answer.financial_supplement_debt !== this.financialSupplementDebt ||
        form_answer.seniors_tax_offset !== this.seniorsTaxOffset ||
        form_answer.approved_withholding_variation !== this.approvedWithholdingVariation ||
        form_answer.approved_withholding_variation_percentage !== this.approvedWithholdingVariationPercentage){

      return true;
    }
    return false;
  }

  stepAnswerNeedsUpdating(): boolean {
    return this.stepAnswerValid() && this.stepAnswerChanged();
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    const tfnCode = this.getSelectedTfnExemptionCode();

    stepData.form_answer = JSON.stringify({
      approved_withholding_variation: this.approvedWithholdingVariation,
      approved_withholding_variation_percentage: this.approvedWithholdingVariationPercentage,
      approved_working_holiday_visa: this.residenceStatusOptions[2] === true,
      australian_tax_resident: this.residenceStatusOptions[0] === true,
      residency_status: this.getSelectedResidenceStatusLabel(),
      employment_basis: this.getSelectedPayBasisLabel(),
      financial_supplement_debt: this.financialSupplementDebt,
      help_debt: this.helpDebt,
      previous_surname: this.previousSurname,
      seniors_tax_offset: this.seniorsTaxOffset,
      tax_file_number: tfnCode === 0 ? this.tfnNumber : null,
      tax_free_threshold: this.taxFreeThreshold,
      tfn_exemption_type: tfnCode
    });

    return stepData;
  }

  updateStepAnswer(){
    if (this.stepAnswerNeedsUpdating()){

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  static allvaluesFalse(data: boolean[]): boolean {
    for (const value of data){
      if (value === true){
        return false;
      }
    }
    return true;
  }

}
