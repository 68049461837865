import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../../components/components.module';

import { ExpenseComponent } from './expense.component';
import { ExpenseManagerComponent } from './expense-manager/expense-manager.component';
import { ExpenseEmployeeComponent } from './expense-employee/expense-employee.component';
import { ExpenseEditComponent } from './expense-edit/expense-edit.component';
import { ExpenseCameraComponent } from './expense-camera/expense-camera.component';

export const EXPENSE_CONFIG: any[] = [
  {
    name: 'app.expense',
    url: '/expense',
    abstract: true,
    component: ExpenseComponent
  },
  {
    name: 'app.expense.camera',
    url: '/camera?{employee_key:int}&{user_key:int}',
    component: ExpenseCameraComponent,
    params: {
      expense_date: null,
      employee_key: null,
      user_key: null,
      expense: null
    }
  },
  {
    name: 'app.expense.edit',
    url: '/edit?{expense_key:int}&{employee_key:int}&{project_key:int}',
    component: ExpenseEditComponent,
    params: {
      expense_key: null,
      employee_key: null,
      project_key: null,
      expense: null,
      expense_date: null,
      imageUrl: null
    }
  },
  {
    name: 'app.expense.employee',
    url: '/employee?{employee_key:int}',
    component: ExpenseEmployeeComponent,
    parms: {
      employee_key: null,
      selected_month: null
    }
  },
  {
    name: 'app.expense.manager',
    url: '/manager',
    component: ExpenseManagerComponent,
    parms: {
      selected_month: null
    }
  }
];

@NgModule({
  declarations: [
    ExpenseComponent,
    ExpenseCameraComponent,
    ExpenseEditComponent,
    ExpenseEmployeeComponent,
    ExpenseManagerComponent
  ],
  imports: [
    ComponentsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    UIRouterModule.forChild({ states: EXPENSE_CONFIG })
  ]
})
export class ExpenseModule { }
