import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from './../../components/components.module';

import { LeaveComponent } from './leave.component';
import { LeaveManagerComponent } from './leave-manager/leave-manager.component';
import { LeaveEmployeeComponent } from './leave-employee/leave-employee.component';
import { LeaveEditComponent } from './leave-edit/leave-edit.component';
import { LeaveBalancesComponent } from './leave-balances/leave-balances.component';

export const LEAVE_CONFIG: any[] = [
  {
    name: 'app.leave',
    url: '/leave',
    abstract: true,
    component: LeaveComponent
  },
  {
    name: 'app.leave.balances',
    url: '/balances',
    component: LeaveBalancesComponent
  },
  {
    name: 'app.leave.edit',
    url: '/edit?{leave_request_key:int}&{employee_key:int}',
    component: LeaveEditComponent,
    params: {
      leave_request_key: null,
      employee_key: null,
      leave_start: null,
      leave_end: null,
      leaveRequest: null
    }
  },
  {
    name: 'app.leave.employee',
    url: '/employee?{employee_key:int}',
    component: LeaveEmployeeComponent,
    params: {
      employee_key: null,
      selected_month: null
    }
  },
  {
    name: 'app.leave.manager',
    url: '/manager',
    component: LeaveManagerComponent,
    params: {
      selected_month: null
    }
  },
];

@NgModule({
  declarations: [
    LeaveComponent,
    LeaveBalancesComponent,
    LeaveEditComponent,
    LeaveEmployeeComponent,
    LeaveManagerComponent
  ],
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    UIRouterModule.forChild({ states: LEAVE_CONFIG }),
    ComponentsModule
  ]
})
export class LeaveModule { }
