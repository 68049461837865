import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './../../components/components.module';
import { PipesModule } from './../../pipes/pipes.module';

import { InvAccountComponent } from './inv-account.component';
import { InvAccountDetailsComponent } from './inv-account-details/inv-account-details.component';
import { InvAccountPasswordComponent } from './inv-account-password/inv-account-password.component';

export const INV_ACCOUNT_CONFIG: any[] = [
  {
    name: 'app.inv.account',
    url: '/account',
    abstract: true,
    component: InvAccountComponent
  },
  {
    name: 'app.inv.account.details',
    url: '/details',
    component: InvAccountDetailsComponent
  },
  {
    name: 'app.inv.account.password',
    url: '/password',
    component: InvAccountPasswordComponent
  }
];

@NgModule({
  declarations: [
    InvAccountComponent,
    InvAccountDetailsComponent,
    InvAccountPasswordComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    ComponentsModule,
    FormsModule,
    UIRouterModule.forChild({ states: INV_ACCOUNT_CONFIG })
  ]
})
export class InvAccountModule { }
