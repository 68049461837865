import { AuthService } from './../../../services/auth/auth.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isInvoxyUser: boolean;

  constructor(
    public stateAccessService: StateAccessService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;
    this.authService.tryTokenLogin();
  }

}
