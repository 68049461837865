import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { InvUserService } from './../../../services/inv-user/inv-user.service';
import { StateChangeService } from 'src/app/services/state-change/state-change.service';
import { UtilService } from './../../../services/util/util.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-inv-account-details',
  templateUrl: './inv-account-details.component.html',
  styleUrls: ['./inv-account-details.component.scss']
})
export class InvAccountDetailsComponent implements OnInit {

  userDetails: any;
  backupUserDetails: any;

  changeGuard: any;

  loading: boolean = true;

  constructor(
    public stateService: StateService,
    public invUserService: InvUserService,
    public stateChangeService: StateChangeService,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.initUserDetails();
  }

  initUserDetails() {
    this.loading = true;

    this.invUserService.loadUserAccount()
      .then((userDetails) => {
        this.userDetails = userDetails;
        this.backupUserDetails = _.cloneDeep(this.userDetails);

        this.changeGuard = this.stateChangeService.guardUnsavedChanges(
          this.userDetails, this.backupUserDetails, () => this.saveDetails()
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  saveDetails() {
    return new Promise((resolve, reject) => {
      if (!this.userDetails.user_name || !this.userDetails.registered_email) {
        reject();
      }
      else {
        const data = {
          user_key: this.userDetails.user_key,
          user_name: this.userDetails.user_name.toLowerCase(),
          registered_email: this.userDetails.registered_email,
        };

        this.invUserService.postUserAccount(data)
          .then(() => {
            this.changeGuard();
            this.initUserDetails();
            resolve();
          })
          .catch(() => {
            this.loading = false;
            reject();
          });
      }
    });
  }

  saveClicked() {
    this.saveDetails()
      .then(() => {
        this.stateService.go('app.inv.account.details', null, { reload: true });
      })
      .catch(() => { });
  }

  changePassword() {
    this.stateService.go('app.inv.account.password');
  }

}
