<div class="onboard-progress"
     [ngClass]="{ '-visible': slideIndex > 0 }">

  <button class="-color-default-clear -size-btn-lg -type-icon -size-icon-lg -visible">
    <i class="ion ion-ios-arrow-back"
       (click)="backStep()">
    </i>
  </button>

  <div class="onboard-progress-stepNumber">
    {{stepNumber}} <span>/ {{onboardSteps.length}}</span>
  </div>
  <div class="onboard-progress-bar">
    <div class="onboard-progress-barInner"
         [ngStyle]="{ 'width': 'calc(' + stepFraction + ' * ' + stepNumber + '%)'  }">
    </div>
  </div>

  <button class="-color-default-clear -size-btn-lg -type-icon -size-icon-lg"
          [ngClass]="{ '-visible': slideIndex < onboardSteps.length + 1 }">
    <i class="ion ion-ios-arrow-forward"
       (click)="forwardStep()">
    </i>
  </button>

</div>

<ion-slides class="onboard-steps"
            #onboardSlides
            [options]="slideOptions"
            (ionSlideDidChange)="stepChanged()">

  <ion-slide class="onboard-step -welcomeStep">
    <div class="-welcomeStep-name">
      Hello <span>{{display_name}}</span>
    </div>
    <div class="-welcomeStep-title">
      Welcome to <span>{{company_name}}</span>
    </div>
    <div class="-welcomeStep-message">
      {{onboard.welcome_message}}
    </div>
    <button class="-color-success -size-btn-lg"
            (click)="forwardStep()">
      Begin
    </button>
  </ion-slide>

  <ion-slide class="onboard-step"
             *ngFor="let step of onboardSteps">

    <onboard-ato #onboardStep
                 *ngIf="step.step_template.step_type === 'ato'"
                 [onboard_key]="onboard.onboard_key"
                 [stepAnswer]="step.step_answer"
                 [stepTemplate]="step.step_template"
                 (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-ato>
    <onboard-checkbox #onboardStep
                      *ngIf="step.step_template.step_type === 'checkbox'"
                      [onboard_key]="onboard.onboard_key"
                      [stepAnswer]="step.step_answer"
                      [stepTemplate]="step.step_template"
                      (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-checkbox>
    <onboard-download #onboardStep
                      *ngIf="step.step_template.step_type === 'download'"
                      [onboard_key]="onboard.onboard_key"
                      [stepAnswer]="step.step_answer"
                      [stepTemplate]="step.step_template"
                      [display_name]="display_name"
                      (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-download>
    <onboard-form #onboardStep
                  *ngIf="step.step_template.step_type === 'form'"
                  [onboard_key]="onboard.onboard_key"
                  [stepAnswer]="step.step_answer"
                  [stepTemplate]="step.step_template"
                  (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-form>
    <onboard-link #onboardStep
                  *ngIf="step.step_template.step_type === 'link'"
                  [onboard_key]="onboard.onboard_key"
                  [stepAnswer]="step.step_answer"
                  [stepTemplate]="step.step_template"
                  (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-link>
    <onboard-nztax #onboardStep
                    *ngIf="step.step_template.step_type === 'nztax'"
                    [onboard_key]="onboard.onboard_key"
                    [stepAnswer]="step.step_answer"
                    [stepTemplate]="step.step_template"
                    (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-nztax>
    <onboard-upload #onboardStep
                    *ngIf="step.step_template.step_type === 'upload'"
                    [onboard_key]="onboard.onboard_key"
                    [stepAnswer]="step.step_answer"
                    [stepTemplate]="step.step_template"
                    (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-upload>
    <onboard-video #onboardStep
                   *ngIf="step.step_template.step_type === 'video'"
                   [onboard_key]="onboard.onboard_key"
                   [stepAnswer]="step.step_answer"
                   [stepTemplate]="step.step_template"
                   (stepAnswerUpdated)="stepAnswerUpdated($event)">
    </onboard-video>

  </ion-slide>

  <ion-slide class="onboard-step -completeStep">

    <div *ngIf="hasUnfinishedRequiredSteps">
      <div class="-completeStep-title">
        You're nearly there
      </div>
      <div class="-completeStep-message">
        You've got a few steps left to complete
      </div>

      <div class="-completeStep-unfinishedStep"
           *ngFor="let step of onboardSteps"
           [hidden]="step.step_answer !== null || !step.step_template.required_flag">
        <span>Step {{step.step_template.step_number}}:</span>
        <span>{{step.step_template.step_type}}</span>
        <span>- {{step.step_template.step_description}}</span>
      </div>
    </div>

    <div *ngIf="!hasUnfinishedRequiredSteps">
      <img src="assets/imgs/Tick_Animated.svg"
           class="-completeStep-tick">

      <div class="-completeStep-title">
        Nice Work!
      </div>
      <div class="-completeStep-message">
        {{onboard.completed_message}}
      </div>
      <button class="-color-success -size-btn-lg"
              (click)="finishOnboard()">
        Submit
      </button>

    </div>

  </ion-slide>

</ion-slides>
