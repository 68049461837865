import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { UtilService } from '../util/util.service';
import { LeaveUtilService } from './../leave-util/leave-util.service';
import { EmployeeService } from './../employee/employee.service';
import { TeamService } from './../team/team.service';
import { CompanyService } from './../company/company.service';
import { TimeUtilService } from './../time-util/time-util.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  leaveTypes: any[];
  inactiveLeaveTypes: any[];
  leaveTypesMap: any;
  leaveDetails: any[];

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService,
    public employeeService: EmployeeService,
    public teamService: TeamService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadLeaveData()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.leaveTypes = [];
    this.inactiveLeaveTypes = [];
    this.leaveTypesMap = {};
    this.serviceSetup = false;
  }

  getLeaveTypes(includeArchived: boolean) {
    if (!includeArchived) {
      return LeaveUtilService.sortLeaveTypes(_.cloneDeep(this.leaveTypes));
    }
    return LeaveUtilService.sortLeaveTypes(_.cloneDeep(this.leaveTypes.concat(this.inactiveLeaveTypes)));
  }

  getSingleLeaveType(leave_type_key: number) {
    return _.cloneDeep(this.leaveTypesMap[leave_type_key]) || null;
  }

  getLeaveDetails() {
    return this.leaveDetails;
  }

  saveLeaveRequest(request: any) {
    return new Promise((resolve, reject) => {
      const requestData = LeaveUtilService.formatLeaveRequestForPosting(request);

      this.apiService.phPost('leaverequest', requestData)
        .then((data) => {
          resolve(this.setupLeaveRequests(data));
        })
        .catch((error) => {
          reject(error);
        });

    });
  }

  loadLeaveRequest(leave_request_key: number) {
    return new Promise<any[]>((resolve, reject) => {
      this.apiService.phGet('leaverequest', { leave_request_key })
        .then((data) => {
          if (data.length) {
            resolve(this.setupLeaveRequests(data)[0]);
          }
          else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadMonthOfLeaveRequests(month: Date, employee_key: number = null) {
    const start = _.cloneDeep(month);
    const end = _.cloneDeep(month);

    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    return this.loadLeaveRequests(start, end, employee_key);
  }

  loadYearOfLeaveRequests(year: any, employee_key: number = null) {
    year = parseInt(year, null);

    const start = new Date();
    start.setFullYear(year, 0, 1);

    const end = _.cloneDeep(start);
    end.setFullYear(year + 1);
    end.setDate(0);

    return this.loadLeaveRequests(start, end, employee_key);
  }

  loadLeaveRequests(start_date: Date, end_date: Date, employee_key: number = null) {
    return new Promise<any[]>((resolve, reject) => {
      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key
      };

      if (!employee_key) {
        delete params.employee_key;
      }

      this.apiService.phGet('leaverequest', params)
        .then((data) => {
          const leaveRequests = this.setupLeaveRequests(data);

          if (!employee_key) {
            resolve(this.filterLeaveRequestsForSelectedTeam(leaveRequests));
          }
          else {
            resolve(leaveRequests);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadLeaveData() {
    return new Promise<void>((resolve, reject) => {
      const promises = [
        this.loadLeaveRequestTypes()
      ];

      if (this.companyService.getEmployeeKey() !== null) {
        promises.push(this.loadEmployeeLeaveDetails());
      }

      forkJoin(promises).subscribe(
        () => {
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  loadLeaveRequestTypes() {
    return new Promise((resolve, reject) => {

      const employee_key = this.companyService.getEmployeeKey();
      const params = employee_key ? { employee_key } : {};

      this.apiService.phGet('leavetype', params)
        .then((data) => {
          data = LeaveUtilService.setupLeaveRequestTypes(data);

          const isManager = this.companyService.getTeamManagerFlag();

          this.leaveTypes = [];
          this.inactiveLeaveTypes = [];
          this.leaveTypesMap = {};

          for (const type of data) {
            this.leaveTypesMap[type.leave_type_key] = type;

            if (type.active_flag && (isManager || !type.admin_only_flag)) {
              this.leaveTypes.push(type);
            }
            else {
              this.inactiveLeaveTypes.push(type);
            }
          }

          LeaveUtilService.sortLeaveTypes(this.leaveTypes);
          LeaveUtilService.sortLeaveTypes(this.inactiveLeaveTypes);

          resolve(this.leaveTypes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadEmployeeLeaveDetails() {
    return new Promise((resolve, reject) => {

      const params = {
        employee_key: this.companyService.getEmployeeKey()
      };

      this.apiService.phGet('employee/leave', params)
        .then((data) => {
          this.leaveDetails = LeaveUtilService.setupEmployeeLeaveDetails(data[0]);
          resolve(this.leaveDetails);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadLeaveRequestInfo(
    start_date: Date, end_date: Date, partDay: number, requestHours: number,
    leave_type_key: number, leave_request_key: number, employee_key: number
  ) {
    return new Promise((resolve, reject) => {

      const params: any = {
        employee_key,
        leave_type_key,
        leave_request_key,
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        part_day: partDay || 1,
        request_hours: requestHours
      };

      if (!params.leave_request_key) {
        delete params.leave_request_key;
      }

      this.apiService.phGet('leaverequest/info', params)
        .then((data) => {
          resolve(LeaveUtilService.setupLeaveRequestInfo(data[0]));
        })
        .catch((error) => {
          reject(error);
        });

    });
  }

  setupLeaveRequests(requests: any[]) {
    for (let i = requests.length - 1; i >= 0; i--) {
      const request = this.setupLeaveRequest(requests[i]);

      // Valid request
      if (request !== null) {
        requests[i] = request;
      }
      // Remove invalid requests
      else {
        requests.splice(i, 1);
      }
    }

    return requests;
  }

  setupLeaveRequest(request: any) {
    const leaveType = this.getSingleLeaveType(request.leave_type_key);
    const employee = this.employeeService.getSingleEmployee(request.employee_key);

    if (leaveType && employee) {
      request.leaveType = leaveType;
      request.employee = employee;

      request.approved_declined_date = TimeUtilService.dateStringToDate(request.approved_declined_date, null, false);
      request.start_date = TimeUtilService.dateStringToDate(request.start_date, null, false);
      request.end_date = TimeUtilService.dateStringToDate(request.end_date, null, false);
      request.leave_type_colour = UtilService.intToHexColor(request.leave_type_colour);
      request.leave_type_colour_light = UtilService.hexToRgba(request.leave_type_colour, 0.4);

      request.approved_flag = !!request.is_approved;
      request.declined_flag = !!request.approved_declined_date && !request.approved_flag;
      request.pending_flag = !request.approved_declined_date;

      request.singleDay = moment(request.start_date).isSame(moment(request.end_date), 'days');

      return request;
    }
    else {
      return null;
    }
  }

  filterLeaveRequestsForSelectedTeam(requests: any[]): any[] {
    const teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    if (teamEmployeeMap === null) {
      return requests;
    }
    else {
      const teamRequests = [];

      for (const request of requests) {
        if (!!teamEmployeeMap[request.employee.employee_key]) {
          teamRequests.push(request);
        }
      }

      return teamRequests;
    }
  }

}
