import { StateService } from '@uirouter/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { ClockService } from './../../../services/clock/clock.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-time-manager-clocks',
  templateUrl: './time-manager-clocks.component.html',
  styleUrls: ['./time-manager-clocks.component.scss']
})
export class TimeManagerClocksComponent implements OnInit, OnDestroy {

  allEmployees: any[] = [];
  filteredEmployees: any[] = [];
  clocksMap: any;
  clockTimeDataMap: any;

  timer: any;
  timerFlasher: boolean;

  filtersOpen: boolean = false;
  filterFlags: any;

  search: string = '';

  loading: boolean = true;
  employeeLoadingMap: any;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateDataService: StateDataService,
    public employeeService: EmployeeService,
    public clockService: ClockService
  ) {

    this.initFilterFlags();
  }

  ngOnInit() {
    this.loadEmployeeClocks();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  initPage() {
    this.loadEmployeeClocks();
  }

  initFilterFlags() {
    const cachedFilterFlags = this.stateDataService.getCachedComponentSessionData('TimeManagerClocksComponent', 'filterFlags');

    this.filterFlags = cachedFilterFlags || {
      active: true,
      onBreak: true,
      inactive: true
    };
  }

  openFiltersPopup() {
    this.filtersOpen = true;
  }

  closeFilterPopup() {
    this.filtersOpen = false;
  }

  startTimer() {
    if (!this.timer) {
      this.timerFlasher = true;

      this.timer = setInterval(() => {
        this.updateClockTimeData();
      }, 1000);
    }
  }

  stopTimer() {
    if (!!this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  setupClockTimeData() {
    this.clockTimeDataMap = {};

    for (const employee_key of Object.keys(this.clocksMap)) {
      for (const clock of this.clocksMap[employee_key]) {

        this.clockTimeDataMap[clock.clock_key] = ClockService.setupClockTimeData(clock);
      }
    }
  }

  updateClockTimeData() {
    const now = new Date();
    const nowMilliseconds = now.valueOf();
    const nowSeconds = Math.floor(nowMilliseconds / 1000);
    this.timerFlasher = nowSeconds % 2 === 0;

    for (const employee_key of Object.keys(this.clocksMap)) {
      for (const clock of this.clocksMap[employee_key]) {

        ClockService.updateClockTimeData(this.clockTimeDataMap[clock.clock_key], clock);
      }
    }
  }

  clockIn(event: any, employee: any) {
    event.stopPropagation();
    this.employeeLoadingMap[employee.employee_key] = true;

    this.clockService.clockIn(employee)
      .finally(() => {
        this.loadEmployeeClocks(true);
      });
  }

  clockOut(event: any, clock: any) {
    event.stopPropagation();
    this.employeeLoadingMap[clock.employee.employee_key] = true;

    this.clockService.clockOut(clock)
      .finally(() => {
        this.loadEmployeeClocks(true);
      });
  }

  breakIn(event: any, clock: any) {
    event.stopPropagation();
    this.employeeLoadingMap[clock.employee.employee_key] = true;

    this.clockService.breakIn(clock)
      .finally(() => {
        this.loadEmployeeClocks(true);
      });
  }

  breakOut(event: any, clock: any) {
    event.stopPropagation();
    this.employeeLoadingMap[clock.employee.employee_key] = true;

    this.clockService.breakOut(clock)
      .finally(() => {
        this.loadEmployeeClocks(true);
      });
  }

  viewClock(clock: any) {
    this.stateService.go('app.time.clock', {
      clock,
      clock_key: clock.clock_key
    });
  }

  refreshEmployeeClocks(event: any) {
    this.loadEmployeeClocks()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  loadEmployeeClocks(disableLoading: boolean = false) {
    return new Promise((resolve, reject) => {

      if (!disableLoading) {
        this.loading = true;
      }
      this.allEmployees = this.employeeService.getAllEmployees();

      this.clockService.loadClocks()
        .then((clocks) => {
          this.loadClocksInClocksMap(clocks);

          this.setupClockTimeData();
          this.startTimer();

          this.filteredEmployees = this.getFilteredEmployees();

          this.loading = false;
          this.initEmployeeLoadingMap();
          resolve();
        })
        .catch(() => {
          this.loading = false;
          this.initEmployeeLoadingMap();
          reject();
        });
    });
  }

  loadClocksInClocksMap(clocks: any[]) {
    this.clocksMap = {};

    for (const employee of this.allEmployees) {
      this.clocksMap[employee.employee_key] = [];
    }

    for (const clock of clocks) {
      this.clocksMap[clock.employee.employee_key].push(clock);
    }
  }

  toggleFilterFlag(flag: string) {
    this.filterFlags[flag] = !this.filterFlags[flag];
    this.stateDataService.cacheComponentSessionData('TimeManagerClocksComponent', 'filterFlags', this.filterFlags);
    this.searchUpdated();
  }

  searchUpdated() {
    this.filteredEmployees = this.getFilteredEmployees();
  }

  clearSearch() {
    this.search = '';
    this.searchUpdated();
  }

  initEmployeeLoadingMap() {
    this.employeeLoadingMap = {};

    for (const employee of this.allEmployees) {
      this.employeeLoadingMap[employee.employee_key] = false;
    }
  }

  getFilteredEmployees() {
    const filtered = [];
    const search = this.search.toUpperCase();

    for (const employee of this.allEmployees) {
      if (this.employeeMatchesFilterFlags(employee)) {

        if (employee.employee_code.toUpperCase().indexOf(search) !== -1) {
          filtered.push(employee);
        }
        else {
          const employeeClocks = this.clocksMap[employee.employee_key];

          for (const clock of employeeClocks) {
            if (ClockService.clockLocationMatchesSearch(search, clock)) {
              filtered.push(employee);
            }
          }
        }
      }
    }

    return filtered;
  }

  employeeMatchesFilterFlags(employee: any): boolean {
    const employeeClocks = this.clocksMap[employee.employee_key] || [];

    let isActive = false;
    let isOnBreak = false;
    let isInactive = false;

    if (employeeClocks.length === 0) {
      isInactive = true;
    }
    else {
      for (const clock of employeeClocks) {
        if (clock.is_on_break) {
          isOnBreak = true;
        }
        else {
          isActive = true;
        }
      }
    }

    return (this.filterFlags.active && isActive) ||
      (this.filterFlags.onBreak && isOnBreak) ||
      (this.filterFlags.inactive && isInactive);
  }

}
