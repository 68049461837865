import { Directive, ElementRef, OnInit, Input, Output, EventEmitter, NgZone  } from '@angular/core';
import { NgModel } from '@angular/forms';
/// <reference types=”@types/googlemaps” />

@Directive({
  selector: '[GooglePlacesAutocomplete]',
  providers: [NgModel]
})
export class GooglePlacesAutocompleteDirective implements OnInit {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @Input() country_key: string;

  autocompleteOptions: any;
  autocompleteFields: any = [
    'address_component'
  ];

  autoComplete: any;

  constructor(private element: ElementRef,
              private ngZone: NgZone) {

  }

  ngOnInit(){
    this.initAutoCompleteOptions();

    this.autoComplete = new google.maps.places.Autocomplete(
      this.element.nativeElement,
      this.autocompleteOptions
    );
    this.autoComplete.setFields(this.autocompleteFields);

    google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
      this.ngZone.run(() => {
        this.ngModelChange.emit(this.element.nativeElement.value);
      });
    });
  }

  initAutoCompleteOptions(){
    this.autocompleteOptions = {
      types: ['address'],
      componentRestrictions: {
        country: this.country_key
      }
    };
  }

}
