<button class="-color-default-clear -type-icon"
        [disabled]="monthChanging || yearChanging"
        (click)="back()">
  <i class="ion ion-ios-arrow-back"></i>
</button>

<div *ngIf="!showYearSelector"
     (click)="toggleSelector()">
  <span>
    {{currentMonth | date: 'LLLL,'}}
  </span>
  <span>
    {{currentMonth | date: 'yyyy'}}
  </span>
</div>

<div *ngIf="showYearSelector"
     (click)="toggleSelector()">
  {{currentMonth | date: 'yyyy'}}
</div>

<button class="-color-default-clear -type-icon"
        [disabled]="monthChanging || yearChanging"
        (click)="forward()">
  <i class="ion ion-ios-arrow-forward"></i>
</button>
