<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              *ngIf="showDeleteBtn"
              [disabled]="errorMessage || loading"
              (click)="deleteLeaveRequest()">
        <i class="ion ion-md-trash"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<slider-button *ngIf="!loading && showSaveBtn"
               [btnDisabled]="errorMessage"
               [btnShort]="false"
               [btnText]="'Save Request'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveLeaveRequest(null, false)">
</slider-button>

<slider-button *ngIf="!loading && showApproveBtn"
               [btnDisabled]="errorMessage"
               [btnShort]="leave_request_key !== null"
               [btnText]="isApproved ? 'Save Request' : 'Approve Request'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveLeaveRequest('APPROVE', false)">
</slider-button>

<slider-button *ngIf="!loading && showDeclineBtn"
               [btnDisabled]="errorMessage"
               [btnShort]="true"
               [btnText]="'Decline Request'"
               [btnIconName]="'ios-close'"
               [btnType]="'danger'"
               (btnClicked)="saveLeaveRequest('DECLINE', false)">
</slider-button>

<ion-content>

  <div class="ph-pageHeaderRow">
    <div class="ph-pageHeaderText">
      {{pageTitle}}
    </div>
  </div>

  <coloured-item-selector [allItems]="allLeaveTypes"
                          [itemType]="'Leave Type'"
                          [itemKeyProperty]="'leave_type_key'"
                          [itemNameProperty]="'leave_type_name'"
                          [itemColourProperty]="'leave_type_colour'"
                          [disabled]="editingDisabled"
                          [selectedItem]="selectedLeaveType"
                          (itemSelected)="leaveTypeSelected($event)">
  </coloured-item-selector>

  <div class="ph-leaveEdit-requestDetails">

    <div class="ph-errorMessage"
         [ngClass]="{ 'ph-errorMessageHidden': !errorMessage }">
      {{errorMessage}}
    </div>

    <div class="ph-formItem"
         *ngIf="(isManager || isAdmin) && selectedEmployee">
      <div class="ph-formItem-label">
        Employee
      </div>
      <button class="ph-formItem-inputButton"
              [ngClass]="{ 'ph-inputLocked': editingDisabled || leave_request_key !== null }"
              (click)="openEmployeeList()">
        {{selectedEmployee.employee_code}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

    <div class="ph-formItem -halfWidth">
      <div class="ph-formItem-label">
        Start
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDD, D MMM YY"
                    max="2030"
                    [ngClass]="{ 'ph-inputLocked': editingDisabled }"
                    [(ngModel)]="startString"
                    (ngModelChange)="dateChanged()">
      </ion-datetime>
    </div>

    <div class="ph-formItem -halfWidth">
      <div class="ph-formItem-label">
        End
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDD, D MMM YY"
                    max="2030"
                    [ngClass]="{ 'ph-inputLocked': endDateEditingDisabled }"
                    [(ngModel)]="endString"
                    (ngModelChange)="dateChanged()">
      </ion-datetime>
    </div>

    <div class="ph-formItem -noLabel"
         *ngIf="showPartDay">
      <button class="ph-formItem-inputButton"
              [ngClass]="{ 'ph-inputLocked': editingDisabled }"
              (click)="openPartDayOptionList()">
        {{partDayOption.label}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

    <div class="ph-formItem"
         *ngIf="showLeaveHours">
      <div class="ph-formItem-label">
        Hours
      </div>
      <input class="ph-formItem-input"
             [ngClass]="{ 'ph-inputLocked': editingDisabled }"
             [disabled]="editingDisabled"
             type="number"
             [(ngModel)]="request_hours"
             [ngModelOptions]="{ updateOn: 'blur' }"
             placeholder="Request Hours"
             (ngModelChange)="calcRequestInfo()">
    </div>

    <div class="ph-leaveEdit-info"
         [ngStyle]="{ 'opacity': leaveInfoLoaded ? 1 : 0.7 }"
         *ngIf="leaveInfoLines.length">
      <div class="ph-leaveEdit-infoHeader">
        Request Information
      </div>
      <div class="ph-leaveEdit-infoLine"
           *ngFor="let line of leaveInfoLines">
        {{line}}
      </div>
    </div>

    <textarea class="text-input ph-textArea ph-leaveEdit-notes"
              [(ngModel)]="notes"
              [ngClass]="{ 'ph-inputLocked': editingDisabled }"
              placeholder="{{editingDisabled ? 'No Notes' : 'Tap to Add Note...'}}">
    </textarea>

  </div>

</ion-content>
