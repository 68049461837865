import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';

import { DomService } from './../../services/dom/dom.service';

@Component({
  selector: 'coloured-item-selector',
  templateUrl: './coloured-item-selector.component.html',
  styleUrls: ['./coloured-item-selector.component.scss']
})
export class ColouredItemSelectorComponent implements OnInit {

  @HostBinding('style.background-color') selectedItemColour: string;

  private _selectedItem: any;
  private _selectorColour: string;

  @Input() allItems: any[];

  @Input() itemType: string;
  @Input() itemKeyProperty: string;
  @Input() itemNameProperty: string;
  @Input() itemColourProperty: string;

  @Input() disabled: boolean = false;
  @Input() showConfirmButton: boolean = false;

  @Input()
  get selectedItem(): any {
    return this._selectedItem;
  }
  set selectedItem(item: any){
    this._selectedItem = item;

    if (!this.selectorColour){
      this.selectedItemColour = this._selectedItem ? this._selectedItem[this.itemColourProperty] : '#777';
    }
  }

  // Use this option if the selector colour is determined by something other than the selected item
  @Input()
  get selectorColour(): string {
    return this._selectorColour;
  }
  set selectorColour(selectorColour: string){
    this._selectorColour = selectorColour;
    this.selectedItemColour = this.selectorColour ? this.selectorColour : '#777';
  }

  @Output() itemSelected = new EventEmitter();
  @Output() itemConfirmed = new EventEmitter();

  constructor(public domService: DomService){

  }

  ngOnInit() {
    this.initSelectedItem();
  }

  initSelectedItem(){
    if (!!this._selectedItem){
      for (const item of this.allItems){
        if (this._selectedItem[this.itemKeyProperty] === item[this.itemKeyProperty]){
          this.selectedItem = item;
        }
      }
    }
  }

  selectItem(item: any){
    this._selectedItem = item;
    this.itemSelected.emit({ item });
  }

  confirmItem(){
    if (this.showConfirmButton && !this.disabled){
      this.itemConfirmed.emit();
      this.closeList();
    }
  }

  openList(){
    if (!this.disabled){

      this.domService.openSlideUpList(
        this.allItems,
        this.selectedItem,
        this.itemNameProperty,
        this.itemColourProperty,
        (item: any) => {
          this.selectItem(item);
        }
      );
    }
  }

  closeList(){
    this.domService.closeSlideUpList();
  }
}
