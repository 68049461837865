import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvProjectUtilService } from './../../../services/inv-project-util/inv-project-util.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { UtilService } from './../../../services/util/util.service';
import { InvTaskUtilService } from './../../../services/inv-task-util/inv-task-util.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-inv-time-work',
  templateUrl: './inv-time-work.component.html',
  styleUrls: ['./inv-time-work.component.scss']
})
export class InvTimeWorkComponent implements OnInit {

  expensesEnabled: boolean = false;

  selectedProject: any = null;
  selectedProjectTask: any = null;

  user_key: number;
  segments: any[];

  allProjects: any = [];
  allProjectTasks: any = [];

  recentProjectTasks: any = [];

  loading = true;

  constructor(
    public stateService: StateService,
    public invCompanyService: InvCompanyService,
    public stateChangeService: StateChangeService,
    public invProjectService: InvProjectService,
    public utilService: UtilService
  ) {

  }

  ngOnInit() {
    this.expensesEnabled = this.invCompanyService.expensesEnabled();

    this.allProjects = this.invProjectService.getAllActiveProjects(
      null, null, true, false, false, this.user_key
    );

    if (this.allProjects.length === 0) {
      this.utilService.toastMessage('An active placement is required for recording time');
      this.back();
    }
    else {
      this.user_key = this.stateService.params.user_key || this.invCompanyService.getUserKey();
      this.recentProjectTasks = this.invProjectService.getRecentProjectTasks(this.user_key);
      this.segments = this.stateService.params.segments || [];

      this.setDefaultSelectedProject();
      this.loading = false;
    }
  }

  back() {
    this.stateChangeService.back();
  }

  setDefaultSelectedProject() {
    if (this.recentProjectTasks.length) {
      const pt = this.recentProjectTasks[0];
      const project = InvProjectUtilService.getProjectFromList(pt.project_key, this.allProjects);

      if (!!project) {
        this.selectedProject = project;
        this.allProjectTasks =
          InvTaskUtilService.filterOutNonTimesheetTasks(this.selectedProject.tasks, this.expensesEnabled);
        this.allProjectTasks = InvTaskUtilService.filterOutArchivedAndAdminOnlyTasks(this.allProjectTasks);

        const task = InvTaskUtilService.getTaskFromList(pt.task_key, this.allProjectTasks);

        if (!!task) {
          this.selectedProjectTask = task;
          this.recentProjectTasks.splice(0, 1);
        }
      }
    }

    if (!this.selectedProject || !this.selectedProjectTask) {

      this.selectedProject = this.allProjects[0];
      this.selectedProjectChanged();
    }
  }

  selectedProjectChanged() {
    if (this.selectedProject) {
      this.allProjectTasks =
        InvTaskUtilService.filterOutNonTimesheetTasks(this.selectedProject.tasks, this.expensesEnabled);
      this.allProjectTasks = InvTaskUtilService.filterOutArchivedAndAdminOnlyTasks(this.allProjectTasks);

      if (this.selectedProjectTask) {
        const projectTask = InvTaskUtilService.getTaskFromList(this.selectedProjectTask.task_key, this.selectedProject.tasks);
        this.selectedProjectTask = projectTask && !projectTask.admin_only_flag ? projectTask : this.allProjectTasks[0];
      }
      else {
        this.selectedProjectTask = this.allProjectTasks[0];
      }
    }
  }

  projectSelected(event: any) {
    this.selectedProject = event.item;
    this.selectedProjectChanged();
  }

  taskSelected(event: any) {
    this.selectedProjectTask = event.item;
  }

  newSegment(
    project_key: number = this.selectedProject.project_key,
    task_key: number = this.selectedProjectTask.task_key
  ) {

    this.stateService.go('app.inv.time.edit', {
      project_key,
      task_key,
      user_key: this.user_key,
      segments: this.segments
    });
  }

}
