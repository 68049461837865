import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { EmployeeService } from './../../../services/employee/employee.service';
import { LeaveService } from './../../../services/leave/leave.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { CompanyService } from './../../../services/company/company.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-leave-employee',
  templateUrl: './leave-employee.component.html',
  styleUrls: ['./leave-employee.component.scss']
})
export class LeaveEmployeeComponent implements OnInit {

  @ViewChild('calendarSlides') calendarSlides: any;

  employee: any;
  loggedInEmployeeKey: number;
  currentMonth: Date;

  allLeaveTypes: any[] = [];
  leaveRequests: any[] = [];

  yearCalendarVisible: boolean = false;

  constructor(public employeeService: EmployeeService,
              public stateService: StateService,
              public leaveService: LeaveService,
              public companyService: CompanyService,
              public stateDataService: StateDataService){

  }

  ngOnInit() {
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    const employee_key = this.stateService.params.employee_key || this.loggedInEmployeeKey;
    this.employee = this.employeeService.getSingleEmployee(employee_key);

    this.allLeaveTypes = this.leaveService.getLeaveTypes(false);

  }

  newLeave(){
    const startDate = _.cloneDeep(this.currentMonth);

    if (moment(startDate).isSame(moment(), 'month')){
      const today = new Date();
      startDate.setDate(today.getDate());
    }

    this.stateService.go('app.leave.edit', {
      requests: this.leaveRequests,
      start: startDate,
      employee_key: this.employee.employee_key
    });
  }

  editLeaveRequest(event: any){
    if (event.leaveRequest){
      this.stateService.go('app.leave.edit', {
        leaveRequest: event.leaveRequest,
        leave_request_key: event.leaveRequest.leave_request_key
      });
    }
  }

  refreshLeave(event: any){
    this.calendarSlides.refreshCalendar()
      .finally(() => {
        event.target.complete();
      });
  }

  viewLeaveBalances(){
    this.stateService.go('app.leave.balances');
  }

  yearCalendarVisibleUpdated(event: any){
    this.yearCalendarVisible = event.yearCalendarVisible;
  }

  currentMonthUpdated(event: any){
    this.currentMonth = event.currentMonth;
    this.stateDataService.selectedMonth = this.currentMonth;
  }

  leaveRequestsUpdated(event: any){
    this.leaveRequests = event.leaveRequests;
  }

}
