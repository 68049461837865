<button class="sliderButton-btn"
        [ngClass]="{
          'btnRevealed': btnRevealed,
          '-color-success': btnType === 'success',
          '-color-danger': btnType === 'danger',
          '-color-primary': btnType === 'primary',
          '-size-btn-sm': btnSmall }"
        [disabled]="btnDisabled"
        (click)="buttonClicked()">
  <i class="ion sliderButton-btnIcon"
     [ngClass]="'ion-' + btnIconName">
  </i>
  <span class="sliderButton-btnText"
        [hidden]="!btnRevealed">
    {{btnText}}
  </span>
</button>
