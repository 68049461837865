import { EventEmitter, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { StateRegistry, StateService } from '@uirouter/angular';

import { StateDataService } from './../state-data/state-data.service';
import { TeamService } from '../team/team.service';
import { ProjectService } from '../project/project.service';
import { LeaveService } from '../leave/leave.service';
import { EmployeeService } from '../employee/employee.service';
import { AuthService } from '../auth/auth.service';
import { CompanyService } from '../company/company.service';
import { LocationService } from '../location/location.service';
import { UtilService } from './../util/util.service';
import { ActionService } from './../action/action.service';

import { InvCompanyService } from './../inv-company/inv-company.service';
import { InvProjectService } from './../inv-project/inv-project.service';
import { InvTaskService } from './../inv-task/inv-task.service';
import { InvIntegrationService } from './../inv-integration/inv-integration.service';
import { InvOnboardService } from './../inv-onboard/inv-onboard.service';
import { InvUserService } from './../inv-user/inv-user.service';

import { PhOnboardService } from './../ph-onboard/ph-onboard.service';

import { env } from '../../../environments/environment';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StateAccessService {

  private _isInvoxyUser: boolean;

  // PayHero states
  private readonly _UNLOCKED_STATE_DEFAULTS: Record<string, boolean> = {
    'app.time.*': false,
    'app.time.clock': false,
    'app.time.manager': false,
    'app.time.managerClocks': false,
    /////////////////////////////////////
    'app.expense.*': false,
    'app.expense.manager': false,
    'app.expense.camera': false,
    /////////////////////////////////////
    'app.leave.*': false,
    'app.leave.employee': false,
    'app.leave.balances': false,
    'app.leave.manager': false,
    /////////////////////////////////////
    'app.pay.*': false
  };
  // Invoxy states
  private readonly _UNLOCKED_INV_STATE_DEFAULTS: Record<string, boolean> = {
    'app.inv.onboard.*': false,
    /////////////////////////////////////
    'app.inv.time.*': false,
    'app.inv.time.manager': false,
    /////////////////////////////////////
    'app.inv.expense.*': false,
    'app.inv.expense.manager': false,
    /////////////////////////////////////
    'app.inv.approval.*': false,
    'app.inv.approval.manager': false,
    'app.inv.approval.view': false
  };

  private _unlockedStates: Record<string, boolean>;
  private _unlockedInvStates: Record<string, boolean>;

  private _STATE_CONFIG: any;
  private _INV_STATE_CONFIG: any;

  private _phAccessFlags: {
    is_employee: boolean,
    team_manager_flag: boolean,
    contractor_flag: boolean,
    employee_finished_flag: boolean,
    time_disabled_on_all_teams: boolean
  };
  private _invAccessFlags: {
    auth_value: number,
    is_invoxy_projects: boolean,
    incomplete_onboards: boolean,
    mandatory_onboards: boolean,
    resource_flag: boolean,
    consultant_flag: boolean,
    show_timesheets: boolean,
    manage_time_flag: boolean,
    approver_flag: boolean,
    business_flag: boolean,
    is_payhero_integrated: boolean,
    has_payhero_login: boolean,
    expenses_enabled: boolean
  };

  private _servicesInitialised: boolean = false;

  private _nav: any[];

  public stateAccessReloaded$: EventEmitter<string>;

  constructor(
    public stateRegistry: StateRegistry,
    public stateService: StateService,
    public projectService: ProjectService,
    public leaveService: LeaveService,
    public employeeService: EmployeeService,
    public teamService: TeamService,
    public authService: AuthService,
    public companyService: CompanyService,
    public locationService: LocationService,
    public stateDataService: StateDataService,
    public invCompanyService: InvCompanyService,
    public invProjectService: InvProjectService,
    public invTaskService: InvTaskService,
    public invIntegrationService: InvIntegrationService,
    public invOnboardService: InvOnboardService,
    public invUserService: InvUserService,
    public utilService: UtilService,
    public actionService: ActionService,
    public phOnboardService: PhOnboardService
  ) {

    this._unlockedStates = _.cloneDeep(this._UNLOCKED_STATE_DEFAULTS);
    this.stateAccessReloaded$ = new EventEmitter();
    this._isInvoxyUser = env.product_code === 'INV';

    this.loadStateConfig();
  }

  /////////////////////////

  get isInvoxyUser(): boolean {
    return this._isInvoxyUser;
  }

  get nav(): any[] {
    return this._nav;
  }

  get servicesInitialised(): boolean {
    return this._servicesInitialised;
  }

  loadStateConfig() {
    const states = this.stateService.get();

    this._STATE_CONFIG = {
      time: [],
      expense: [],
      leave: [],
      pay: []
    };
    this._INV_STATE_CONFIG = {
      time: [],
      expense: [],
      onboard: [],
      approval: []
    };

    for (const state of states) {
      if (state.name.indexOf('app.') === 0) {
        if (state.name.indexOf('app.inv.') === 0) {
          if (state.name.indexOf('app.inv.time') === 0) {
            this._INV_STATE_CONFIG.time.push(state);
          }
          else if (state.name.indexOf('app.inv.expense') === 0) {
            this._INV_STATE_CONFIG.expense.push(state);
          }
          else if (state.name.indexOf('app.inv.onboard') === 0) {
            this._INV_STATE_CONFIG.onboard.push(state);
          }
          else if (state.name.indexOf('app.inv.approval') === 0) {
            this._INV_STATE_CONFIG.approval.push(state);
          }
        }
        else {
          if (state.name.indexOf('app.time') === 0) {
            this._STATE_CONFIG.time.push(state);
          }
          else if (state.name.indexOf('app.expense') === 0) {
            this._STATE_CONFIG.expense.push(state);
          }
          else if (state.name.indexOf('app.leave') === 0) {
            this._STATE_CONFIG.leave.push(state);
          }
          else if (state.name.indexOf('app.pay') === 0) {
            this._STATE_CONFIG.pay.push(state);
          }
        }
      }
    }
  }

  reloadStateAccess(refreshStateName: string = null, refreshStateParams: any = null, checkOnboard: boolean = false) {
    this.lockApp();

    if (this.isInvoxyUser) {
      this.reloadInvoxyStateAccess(refreshStateName, refreshStateParams);
    }
    else {
      this.reloadPayHeroStateAccess(refreshStateName, refreshStateParams, checkOnboard);
    }
  }

  private reloadPayHeroStateAccess(refreshStateName: string, refreshStateParams: any, checkOnboard: boolean) {
    const userCompany = this.companyService.getUserCompany();
    const employee_key = this.companyService.getEmployeeKey();
    const employeeFinished = this.companyService.employeeIsFinished();

    const onboardingToComplete = this.phOnboardService.onboardsToComplete();

    if (!userCompany) {
      this.clearServiceData();
      this.authService.tryTokenLogin();
    }
    else if (onboardingToComplete && checkOnboard) {
      const url = this.phOnboardService.getOnboardingUrl();
      window.open(url, '_self');
    }
    else {
      this._unlockedStates = _.cloneDeep(this._UNLOCKED_STATE_DEFAULTS);

      this._phAccessFlags = {
        is_employee: employee_key !== null,
        team_manager_flag: userCompany.team_manager_flag,
        contractor_flag: userCompany.contractor_flag,
        employee_finished_flag: employeeFinished,
        time_disabled_on_all_teams: this.teamService.timeDisabledOnAllEmployeesTeams(userCompany.employee_key)
      };

      // Finished Employee
      if (this._phAccessFlags.employee_finished_flag) {
        this._unlockedStates['app.pay.*'] = true;

        this.lockStatesFromUserActions();
        this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');

        this.goToStateAfterReload('app.pay.employee', { employee_key }, refreshStateName, refreshStateParams);
      }
      // Manager Employee
      else if (this._phAccessFlags.team_manager_flag) {
        this.toggleAllStates(this._unlockedStates, true);
        this._unlockedStates['app.leave.employee'] = !this._phAccessFlags.contractor_flag;
        this._unlockedStates['app.leave.balances'] = !this._phAccessFlags.contractor_flag;

        this.lockStatesFromUserActions();
        this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');

        const defaultState = this._getDefaultPhState();
        this.goToStateAfterReload(defaultState, { employee_key }, refreshStateName, refreshStateParams);
      }
      // Standard Employee
      else {
        this._unlockedStates['app.time.*'] = !this._phAccessFlags.time_disabled_on_all_teams;
        this._unlockedStates['app.expense.*'] = true;
        this._unlockedStates['app.expense.camera'] = true;
        this._unlockedStates['app.leave.*'] = !this._phAccessFlags.contractor_flag;
        this._unlockedStates['app.leave.employee'] = !this._phAccessFlags.contractor_flag;
        this._unlockedStates['app.leave.balances'] = !this._phAccessFlags.contractor_flag;
        this._unlockedStates['app.pay.*'] = true;

        this.lockStatesFromUserActions();
        this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');

        const defaultState = this._getDefaultPhState();
        this.goToStateAfterReload(defaultState, { employee_key }, refreshStateName, refreshStateParams);
      }
    }
  }

  private _getDefaultPhState(): string {
    if (this.stateRegistry.get('app.time.employee')) {
      return 'app.time.employee';
    }
    else if (this.stateRegistry.get('app.expense.employee')) {
      return 'app.expense.employee';
    }
    else if (this.stateRegistry.get('app.leave.employee')) {
      return 'app.leave.employee';
    }
    else {
      return 'app.pay.employee';
    }
  }

  private reloadInvoxyStateAccess(refreshStateName: string, refreshStateParams: any) {
    const currentCompany = this.invCompanyService.getCurrentCompany();
    const currentCompanyUser = this.invCompanyService.getCurrentCompanyUser();
    const user_key = this.invCompanyService.getUserKey();
    const employee_key = this.companyService.getEmployeeKey();
    const person = this.invUserService.getSingleUser(user_key);

    if (!currentCompany || !currentCompanyUser) {
      this.clearServiceData();
      this.authService.tryTokenLogin();
    }
    else {
      this._unlockedStates = _.cloneDeep(this._UNLOCKED_STATE_DEFAULTS);
      this._unlockedInvStates = _.cloneDeep(this._UNLOCKED_INV_STATE_DEFAULTS);

      const isInvoxyProjects = currentCompany.subscription_product === 'projects';

      this._invAccessFlags = {
        auth_value: currentCompanyUser.auth_value,
        is_invoxy_projects: isInvoxyProjects,
        incomplete_onboards: this.invOnboardService.hasIncompleteOnboards,
        mandatory_onboards: this.invOnboardService.hasMandatoryOnboards,
        resource_flag: person.resource_flag,
        consultant_flag: person.consultant_flag,
        show_timesheets: currentCompanyUser.show_timesheets,
        manage_time_flag: currentCompanyUser.manage_time_flag,
        approver_flag: currentCompanyUser.approver_flag,
        business_flag: currentCompanyUser.business_flag,
        is_payhero_integrated: this.invIntegrationService.isPayHeroIntegrated(),
        has_payhero_login: this.invIntegrationService.hasPayHeroLogin(),
        expenses_enabled: this.invCompanyService.expensesEnabled()
      };

      // Consultant
      if (this._invAccessFlags.auth_value === 2) {

        if (this._invAccessFlags.incomplete_onboards) {
          this._unlockedInvStates['app.inv.onboard.*'] = true;
        }

        if (this._invAccessFlags.manage_time_flag) {
          this._unlockedInvStates['app.inv.time.*'] = true;
          this._unlockedInvStates['app.inv.time.manager'] = true;
          this._unlockedInvStates['app.inv.approval.request'] = true;

          if (this._invAccessFlags.expenses_enabled) {
            this._unlockedInvStates['app.inv.expense.*'] = true;
            this._unlockedInvStates['app.inv.expense.manager'] = true;
            this._unlockedStates['app.expense.camera'] = true;
          }
        }

        if (this._invAccessFlags.approver_flag) {
          this._unlockedInvStates['app.inv.approval.*'] = true;
          this._unlockedInvStates['app.inv.approval.manager'] = true;
          this._unlockedInvStates['app.inv.approval.view'] = true;
        }

        if (this._invAccessFlags.is_payhero_integrated && this._invAccessFlags.has_payhero_login) {
          this._unlockedStates['app.pay.*'] = true;

          if (!this._invAccessFlags.business_flag) {
            this._unlockedStates['app.leave.*'] = true;
            this._unlockedStates['app.leave.employee'] = true;
            this._unlockedStates['app.leave.balances'] = true;
            this._unlockedStates['app.leave.manager'] = true;
          }
        }

        this.lockStatesFromUserActions();
        this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');
        this.unlockStatesInMap(this._INV_STATE_CONFIG, this._unlockedInvStates, 'app.inv');

        if (this._unlockedInvStates['app.inv.onboard.*']) {
          this.goToStateAfterReload('app.inv.onboard.resource', { user_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedInvStates['app.inv.time.manager']) {
          this.goToStateAfterReload('app.inv.time.manager', { user_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedInvStates['app.inv.approval.manager']) {
          this.goToStateAfterReload('app.inv.approval.manager', { user_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedStates['app.leave.employee']) {
          this.goToStateAfterReload('app.leave.employee', { employee_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedStates['app.pay.*']) {
          this.goToStateAfterReload('app.pay.employee', { employee_key }, refreshStateName, refreshStateParams);
        }
        else {
          this.authService.goApplet('account.edit');
        }
      }
      // Resource
      else if (this._invAccessFlags.auth_value === 3) {

        if (this._invAccessFlags.incomplete_onboards) {
          this._unlockedInvStates['app.inv.onboard.*'] = true;
        }

        if (this._invAccessFlags.show_timesheets && !this._invAccessFlags.mandatory_onboards) {
          this._unlockedInvStates['app.inv.time.*'] = true;
          this._unlockedInvStates['app.inv.approval.*'] = true;
        }

        if (this._invAccessFlags.manage_time_flag && !this._invAccessFlags.mandatory_onboards) {
          this._unlockedInvStates['app.inv.time.*'] = true;
          this._unlockedInvStates['app.inv.time.manager'] = true;
        }

        if (this._invAccessFlags.expenses_enabled &&
          (this._invAccessFlags.show_timesheets || this._invAccessFlags.manage_time_flag) &&
          !this._invAccessFlags.mandatory_onboards) {

          this._unlockedInvStates['app.inv.expense.*'] = true;
          this._unlockedStates['app.expense.camera'] = true;

          if (this._invAccessFlags.manage_time_flag) {
            this._unlockedInvStates['app.inv.expense.manager'] = true;
          }
        }

        if (this._invAccessFlags.is_payhero_integrated && this._invAccessFlags.has_payhero_login) {
          this._unlockedStates['app.pay.*'] = true;

          if (!this._invAccessFlags.business_flag && !this._invAccessFlags.mandatory_onboards) {
            this._unlockedStates['app.leave.*'] = true;
            this._unlockedStates['app.leave.employee'] = true;
            this._unlockedStates['app.leave.balances'] = true;
          }
        }

        this.lockStatesFromUserActions();
        this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');
        this.unlockStatesInMap(this._INV_STATE_CONFIG, this._unlockedInvStates, 'app.inv');

        if (this._unlockedInvStates['app.inv.onboard.*']) {
          this.goToStateAfterReload('app.inv.onboard.resource', { user_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedInvStates['app.inv.time.*']) {
          this.goToStateAfterReload('app.inv.time.resource', { user_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedStates['app.leave.employee']) {
          this.goToStateAfterReload('app.leave.employee', { employee_key }, refreshStateName, refreshStateParams);
        }
        else if (this._unlockedStates['app.pay.*']) {
          this.goToStateAfterReload('app.pay.employee', { employee_key }, refreshStateName, refreshStateParams);
        }
        else {
          this.authService.goApplet('account.edit');
        }
      }
      else {
        this.utilService.toastMessage('Sorry, this app is only for resources and consultants');
        this.clearServiceData();
        this.authService.tryTokenLogin();
      }
    }
  }

  lockStatesFromUserActions() {
    if (!this.actionService.isActive('EXPENSES')) {
      this.lockAllStatesInModule('app.expense');
    }
    if (!this.actionService.isActive('GPS_CLOCK_IN')) {
      this._unlockedStates['app.time.clock'] = false;
      this._unlockedStates['app.time.managerClocks'] = false;
    }
  }

  lockAllStatesInModule(module: string) {
    if (this.isInvoxyUser) {
      for (const state_key of Object.keys(this._unlockedInvStates)) {
        if (state_key.indexOf(module) === 0) {
          this._unlockedInvStates[state_key] = false;
        }
      }
    }
    else {
      for (const state_key of Object.keys(this._unlockedStates)) {
        if (state_key.indexOf(module) === 0) {
          this._unlockedStates[state_key] = false;
        }
      }
    }
  }

  getNav(): any[] {
    if (this.isInvoxyUser) {
      return this._getInvNav();
    }
    else {
      return this._getPhNav();
    }
  }

  private _getPhNav(): any[] {
    const employee_code = this.companyService.getEmployeeCode();
    const employee_key = this.companyService.getEmployeeKey();

    this._nav = [{
      name: employee_code, states: [], state: 'SUBSCRIPTION.profile.edit', params: null, icon: 'ion-md-contact'
    }];

    // Finished Employee
    if (this._phAccessFlags.employee_finished_flag) {
      this._nav[0].states = [
        { state: 'app.pay.employee', params: { employee_key }, name: 'Payslips', icon: 'ion-md-list-box' }
      ];
    }
    // Active Employee
    else {
      // Employee states
      if (!this._phAccessFlags.time_disabled_on_all_teams) {
        this._nav[0].states.push(
          { state: 'app.time.employee', params: { employee_key }, name: 'Time', icon: 'ion-md-time' }
        );
      }
      if (this.actionService.isActive('EXPENSES')) {
        this._nav[0].states.push(
          { state: 'app.expense.employee', params: { employee_key }, name: 'Expenses', icon: 'ion-ios-pricetags' }
        );
      }
      if (!this._phAccessFlags.contractor_flag) {
        this._nav[0].states.push(
          { state: 'app.leave.employee', params: { employee_key }, name: 'Leave', icon: 'ion-md-calendar' }
        );
      }
      this._nav[0].states.push(
        { state: 'app.pay.employee', params: { employee_key }, name: 'Payslips', icon: 'ion-md-list-box' }
      );

      // Manager states
      if (this._phAccessFlags.team_manager_flag) {
        const team = this.teamService.getSelectedTeam();
        const teamName = team ? team.team_name : 'Manage Teams';

        this._nav.push({
          name: teamName, states: [], state: 'app.ph.teamSelect', params: null, icon: 'ion-md-swap'
        });

        this._nav[1].states = [
          { state: 'app.time.manager', params: {}, name: 'Time', icon: 'ion-md-time' }
        ];

        if (this.actionService.isActive('GPS_CLOCK_IN')) {
          this._nav[1].states.push(
            { state: 'app.time.managerClocks', params: {}, name: 'Time Clocks', icon: 'ion-ios-play' }
          );
        }
        if (this.actionService.isActive('EXPENSES')) {
          this._nav[1].states.push(
            { state: 'app.expense.manager', params: {}, name: 'Expenses', icon: 'ion-ios-pricetags' }
          );
        }
        this._nav[1].states.push(
          { state: 'app.leave.manager', params: {}, name: 'Leave', icon: 'ion-md-calendar' }
        );

      }
    }

    return this._nav;
  }

  private _getInvNav(): any[] {
    const user_key = this.invCompanyService.getUserKey();
    const display_name = this.invCompanyService.getDisplayName();
    const employee_key = this.companyService.getEmployeeKey();

    this._nav = [{
      name: display_name, states: [], state: 'SUBSCRIPTION.profile.edit', params: null, icon: 'ion-md-contact'
    }];

    // Incomplete onboards
    if (this._invAccessFlags.incomplete_onboards) {
      this._nav[0].states.push(
        { state: 'app.inv.onboard.resource', params: { user_key }, name: 'Get Started', icon: 'ion-md-rocket' }
      );
    }

    // Resource
    if (this._invAccessFlags.resource_flag && !this._invAccessFlags.mandatory_onboards) {
      this._nav[0].states.push(
        { state: 'app.inv.time.resource', params: { user_key }, name: 'Time', icon: 'ion-md-time' }
      );

      // Expenses enabled
      if (this._invAccessFlags.expenses_enabled) {
        this._nav[0].states.push(
          { state: 'app.inv.expense.resource', params: { user_key }, name: 'Expenses', icon: 'ion-ios-pricetags' }
        );
      }
    }

    // PayHero connected user with synced PayHero employee
    if (this._invAccessFlags.is_payhero_integrated && this._invAccessFlags.has_payhero_login) {

      // Doesn't have incomplete mandatory onboards
      if (!this._invAccessFlags.business_flag && !this._invAccessFlags.mandatory_onboards) {
        this._nav[0].states.push(
          { state: 'app.leave.employee', params: { employee_key }, name: 'Leave', icon: 'ion-md-calendar' }
        );
      }

      this._nav[0].states.push(
        { state: 'app.pay.employee', params: { employee_key }, name: 'Payslips', icon: 'ion-md-card' }
      );
    }

    // Consultant/Resource with Manager Access or Consultant with Approver Access
    if ((this._invAccessFlags.manage_time_flag || (this._invAccessFlags.auth_value === 2 && this._invAccessFlags.approver_flag)) &&
      !this._invAccessFlags.mandatory_onboards) {

      this._nav.push({
        name: 'Manage Placements', states: [], state: '', params: null, icon: ''
      });

      // Time Manager
      if (this._invAccessFlags.manage_time_flag) {
        this._nav[1].states.push(
          { state: 'app.inv.time.manager', params: {}, name: 'Timesheets', icon: 'ion-md-time' }
        );

        // Expenses enabled
        if (this._invAccessFlags.expenses_enabled) {
          this._nav[1].states.push(
            { state: 'app.inv.expense.manager', params: {}, name: 'Expenses', icon: 'ion-ios-pricetags' }
          );
        }
      }

      // Consultant with Approver Access
      if (this._invAccessFlags.auth_value === 2 && this._invAccessFlags.approver_flag) {
        this._nav[1].states.push(
          { state: 'app.inv.approval.manager', params: {}, name: 'Approval Requests', icon: 'ion-ios-paper-plane' }
        );
      }

      // PayHero connected
      if (this._invAccessFlags.is_payhero_integrated && this._invAccessFlags.has_payhero_login) {
        this._nav[1].states.push(
          { state: 'app.leave.manager', params: {}, name: 'Leave', icon: 'ion-md-calendar' }
        );
      }
    }

    return this._nav;
  }

  goToStateAfterReload(defaultState: string, defaultStateParams: any, refreshStateName: string = null, refreshStateParams: any = null) {
    this.stateAccessReloaded$.emit();

    if (refreshStateName !== null && this.stateRegistry.get(refreshStateName)) {
      this.stateService.go(refreshStateName, refreshStateParams);
    }
    else {
      this.stateService.go(defaultState, defaultStateParams);
    }
  }

  unlockStatesInMap(STATE_CONFIG: any, unlockedStates: any, baseModule: string) {
    for (const module of Object.keys(STATE_CONFIG)) {
      const MODULE_CONFIG = STATE_CONFIG[module];

      for (const state of MODULE_CONFIG) {
        const stateName = state.name;

        if (!this.stateRegistry.get(stateName)) {
          // eg. app.time.* = global module definition
          // eg. app.time.employee = single state definition. Takes precedence over a global module definition

          // Check if a global module definition exists for all states in this module
          if (unlockedStates[baseModule + '.' + module + '.*'] !== undefined) {
            // If a global module defintion does exist, we need to also check if a single state defition exists for this state.
            // If a single state defition does exist, its value will override that of the global module defintion
            if ((unlockedStates[stateName] === undefined && unlockedStates[baseModule + '.' + module + '.*'] === true) ||
              unlockedStates[stateName] === true) {

              this.ensureModuleRegistered(baseModule, module, MODULE_CONFIG);
              if (!this.stateRegistry.get(stateName)) {
                this.stateRegistry.register(state);
              }
            }
          }
          // No global module defition exists so need to rely on the value of the
          // single state defintion for this state
          else {
            if (unlockedStates[stateName] === true) {

              this.ensureModuleRegistered(baseModule, module, MODULE_CONFIG);
              if (!this.stateRegistry.get(stateName)) {
                this.stateRegistry.register(state);
              }
            }
          }
        }
      }
    }
  }

  ensureModuleRegistered(baseModule: string, module: string, MODULE_CONFIG: any) {
    if (!this.stateRegistry.get(baseModule + '.' + module)) {
      for (const state of MODULE_CONFIG) {

        if (state.name === baseModule + '.' + module) {
          this.stateRegistry.register(state);
          return;
        }
      }
    }
  }

  toggleAllStates(unlockedStates: any, unlocked: boolean) {
    for (const state of Object.keys(unlockedStates)) {
      unlockedStates[state] = unlocked;
    }
  }

  lockApp() {
    if (this.stateRegistry.get('app.time')) {
      this.stateRegistry.deregister('app.time');
    }
    if (this.stateRegistry.get('app.expense')) {
      this.stateRegistry.deregister('app.expense');
    }
    if (this.stateRegistry.get('app.leave')) {
      this.stateRegistry.deregister('app.leave');
    }
    if (this.stateRegistry.get('app.pay')) {
      this.stateRegistry.deregister('app.pay');
    }
    if (this.stateRegistry.get('app.inv.time')) {
      this.stateRegistry.deregister('app.inv.time');
    }
    if (this.stateRegistry.get('app.inv.expense')) {
      this.stateRegistry.deregister('app.inv.expense');
    }
    if (this.stateRegistry.get('app.inv.onboard')) {
      this.stateRegistry.deregister('app.inv.onboard');
    }
    if (this.stateRegistry.get('app.inv.approval')) {
      this.stateRegistry.deregister('app.inv.approval');
    }
  }

  ensureServicesInitialised() {
    if (this.isInvoxyUser) {
      return this._initialiseInvoxyServices();
    }
    else {
      return this._initialisePayHeroServices();
    }
  }

  private _initialisePayHeroServices() {
    return new Promise<void>((resolve, reject) => {
      this._servicesInitialised = false;

      if (this.authService.hasSession('PH')) {

        this.companyService.initialiseService()
          .then(() => {

            this.teamService.initialiseService()
              .then(() => {

                this.employeeService.initialiseService()

                  .then(() => {

                    forkJoin([
                      this.projectService.initialiseService(),
                      this.leaveService.initialiseService(),
                      this.locationService.initialiseService(),
                      this.phOnboardService.initialiseService()
                    ])
                      .toPromise()
                      .then(() => {
                        this._servicesInitialised = true;
                        resolve();
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        reject();
      }
    });
  }

  private _initialiseInvoxyServices() {
    return new Promise<void>((resolve, reject) => {

      const initialiseInnerServices = () => {
        this.invTaskService.initialiseService()
          .then(() => {
            forkJoin([
              this.invProjectService.initialiseService(),
              this.invTaskService.initialiseService(),
              this.invOnboardService.initialiseService(),
              this.invUserService.initialiseService()
            ])
              .toPromise()
              .then(() => {
                this._servicesInitialised = true;
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      };

      this._servicesInitialised = false;

      if (this.authService.hasSession('INV')) {

        this.invCompanyService.initialiseService()
          .then(() => {

            this.invIntegrationService.initialiseService()
              .then(() => {

                if (this.invIntegrationService.isPayHeroIntegrated() && this.invIntegrationService.hasPayHeroLogin()) {
                  this._initialisePayHeroServices()
                    .then(() => {
                      initialiseInnerServices();
                    })
                    .catch((err) => {
                      reject(err);
                    });
                }
                else {
                  initialiseInnerServices();
                }
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        reject();
      }
    });
  }

  clearServiceData() {
    this._clearPayHeroServiceData();
    this._clearInvoxyServiceData();
  }

  private _clearPayHeroServiceData() {
    this.companyService.clearServiceData();
    this.projectService.clearServiceData();
    this.leaveService.clearServiceData();
    this.teamService.clearServiceData();
    this.employeeService.clearServiceData();
    this.locationService.clearServiceData();
    this._servicesInitialised = false;
  }

  private _clearInvoxyServiceData() {
    this.invCompanyService.clearServiceData();
    this.invIntegrationService.clearServiceData();
    this.invProjectService.clearServiceData();
    this.invTaskService.clearServiceData();
    this.invOnboardService.clearServiceData();
    this.invUserService.clearServiceData();
    window.Intercom('shutdown');
    this._servicesInitialised = false;
  }

}
