import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inv-time',
  templateUrl: './inv-time.component.html',
  styleUrls: ['./inv-time.component.scss']
})
export class InvTimeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
