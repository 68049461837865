import { TimeUtilService } from './../../services/time-util/time-util.service';

import { Expense } from './expense';
import { ExpenseInterface } from './../../interfaces/expense.interface';

import * as moment from 'moment';

export class InvExpense extends Expense implements ExpenseInterface {

  segment_key: number;
  ph_expense_key: number;
  ph_employee_key: number;
  ph_project_key: number;

  timesheet_attachment_key: number;
  timesheet_attachment_name: string;
  timesheet_attachment_url: string;

  invoiced_flag: boolean;
  row_version: string;

  inv_new_flag: boolean;
  inv_pending_flag: boolean;
  inv_approved_flag: boolean;
  inv_invoiced_flag: boolean;
  inv_paid_flag: boolean;

  private _inv_project: any;
  user: any;
  task: any;

  project_tasks: any[];
  project_users: any[];

  private _is_active_project: boolean;
  private _ph_employee_manageable_flag: boolean;
  private _inv_response_step: number;

  constructor(
    segment_key: number = null, ph_expense_key: number = null, inv_project: any, user_key: number, task_key: number,
    timesheet_attachment_key: number = null, timesheet_attachment_name: string = null, timesheet_attachment_url: string = null,
    row_version: string = null, ph_employee_manageable_flag: boolean = false,
    inv_pending_flag: boolean = false, inv_approved_flag: boolean = false, inv_invoiced_flag: boolean = false, inv_paid_flag = false,
    expense_amount: number, expense_date: Date, description: string = '',
    ph_authorised_flag: boolean = false, ph_paid_flag: boolean = false, ph_approval_date: Date = null, ph_declined_date: Date = null,
    ph_employee_key: number = null, ph_project_key: number = null
  ) {

    const task = inv_project.projectTasksMap[task_key];
    const user = inv_project.projectUsersMap[user_key];

    super(
      expense_amount, expense_date, description,
      ph_approval_date, ph_declined_date, ph_paid_flag, ph_authorised_flag
    );

    this.segment_key = segment_key;
    this.ph_expense_key = ph_expense_key;
    this.ph_employee_key = ph_employee_key;
    this.ph_project_key = ph_project_key;

    this._inv_project = inv_project;
    this.task = task;
    this.user = user;
    this._updateTasksAndUsersOnProjectChange();
    this._updateIsActiveProject();

    this.timesheet_attachment_key = timesheet_attachment_key;
    this.timesheet_attachment_name = timesheet_attachment_name;
    this.timesheet_attachment_url = timesheet_attachment_url;

    this.row_version = row_version;

    this.inv_new_flag = !inv_pending_flag && !inv_approved_flag && !inv_invoiced_flag && !inv_paid_flag;
    this.inv_pending_flag = inv_pending_flag;
    this.inv_approved_flag = inv_approved_flag;
    this.inv_invoiced_flag = inv_invoiced_flag;
    this.inv_paid_flag = inv_paid_flag;

    this._ph_employee_manageable_flag = ph_employee_manageable_flag;

    this._inv_response_step = null;
    this._updateInvResponseStep();
  }

  // Super class function extensions /////////////////////////////////
  get expense_date(): Date {
    return super.expense_date;
  }
  set expense_date(expense_date: Date) {
    super.expense_date = expense_date;
    this._updateIsActiveProject();
  }

  // Interface getters/setters //////////////////////////////////////
  get is_active_project(): boolean {
    return this._is_active_project;
  }

  get inv_locked(): boolean {
    return this.task.archived_flag || this.inv_approved_flag || this.inv_invoiced_flag || this.inv_paid_flag;
  }
  get ph_locked(): boolean {
    return (!this.ph_authorised_flag && !!this.ph_expense_key) || this.ph_paid_flag || this.ph_declined_flag ||
      (this.ph_approved_flag && !this._ph_employee_manageable_flag);
  }

  get is_ph_expense(): boolean {
    return false;
  }
  get is_inv_expense(): boolean {
    return true;
  }

  // Class getters/setters //////////////////////////////////////
  get inv_project(): any {
    return this._inv_project;
  }
  set inv_project(inv_project: any) {
    this._inv_project = inv_project;

    this._updateTasksAndUsersOnProjectChange();
    this._updateIsActiveProject();
  }

  get is_active_task(): boolean {
    return !this.task.archived_flag;
  }
  get ph_employee_manageable_flag() {
    return this._ph_employee_manageable_flag;
  }
  get inv_response_step() {
    return this._inv_response_step;
  }

  // Interface functions //////////////////////////////////////
  formatExpenseForPosting(): any {
    const data = new FormData();

    data.append('project_key', this._inv_project.project_key);
    data.append('user_key', this.user.user_key);
    data.append('task_key', this.task.task_key);
    data.append('expense_amount', this.expense_amount + '');
    data.append('expense_date', TimeUtilService.dateToDateString(this.expense_date, null));
    data.append('description', this.description || '');

    if (this.segment_key) {
      data.append('segment_key', this.segment_key + '');
    }
    if (this.row_version) {
      data.append('row_version', this.row_version);
    }
    if (this.ph_approval_date) {
      data.append('ph_approval_date', TimeUtilService.dateToDateTimeString(this.ph_approval_date));
    }
    if (this.ph_declined_date) {
      data.append('ph_declined_date', TimeUtilService.dateToDateTimeString(this.ph_declined_date));
    }
    if (this.deleted_flag) {
      data.append('deleted_flag', this.deleted_flag + '');
    }

    return data;
  }

  // Class functions //////////////////////////////////////
  private _updateIsActiveProject() {
    const expenseDate = moment(this.expense_date);
    const projectStartDate = moment(this.inv_project.start_date);
    const projectEndDate = this.inv_project.end_date ? moment(this.inv_project.end_date) : null;

    this._is_active_project = expenseDate.isSameOrAfter(projectStartDate, 'day') &&
      (projectEndDate === null || expenseDate.isSameOrBefore(projectEndDate, 'day'));
  }

  private _updateTasksAndUsersOnProjectChange() {
    this.project_users = [];
    this.project_tasks = [];

    for (const pu of this._inv_project.users) {
      if (pu.resource_flag) {
        this.project_users.push(pu);
      }
    }

    for (const pt of this._inv_project.tasks) {
      if (!pt.archived_flag) {
        if (pt.unit_type === 'expense' && !pt.admin_only_flag) {
          this.project_tasks.push(pt);
        }
      }
    }

    this._updateUserAndTask();
  }

  private _updateUserAndTask() {
    let projectUser = null;
    let projectTask = null;

    for (const pu of this._inv_project.users) {
      if (pu.resource_flag) {
        if (pu.user_key === this.user.user_key) {
          projectUser = pu;
        }
      }
    }
    for (const pt of this._inv_project.tasks) {
      if (pt.unit_type === 'expense') {
        // Don't allow archived tasks to be set on new expenses
        if (this.segment_key || !pt.archived_flag) {
          if (pt.task_key === this.task.task_key) {
            projectTask = pt;
          }
        }

      }
    }

    if (projectUser !== null) {
      this.user = projectUser;
    }
    else if (this.project_users.length) {
      this.user = this.project_users[0];
    }
    else {
      this.user = null;
    }

    if (projectTask !== null) {
      this.task = projectTask;
    }
    else if (this.project_tasks.length) {
      this.task = this.project_tasks[0];
    }
    else {
      this.task = null;
    }
  }

  private _updateInvResponseStep() {
    if (this.inv_new_flag) {
      this._inv_response_step = 1;
    }
    if (this.inv_pending_flag) {
      this._inv_response_step = 2;
    }
    if (this.inv_approved_flag) {
      this._inv_response_step = 3;
    }
    if (this.inv_invoiced_flag) {
      this._inv_response_step = 4;
    }
    if (this.inv_paid_flag) {
      this._inv_response_step = 5;
    }
  }

}
