import { ToastController } from '@ionic/angular';
import { AppProduct, StateDataService } from './../state-data/state-data.service';
import { AuthService } from './../auth/auth.service';

import { env } from '../../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type ApiConfig = {
  hide_error?: boolean,
  include_sub_session?: boolean,
  session_only?: boolean,
  response_type?: string
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly ph_api_url: string = env.payhero.api_url;
  readonly inv_api_url: string = env.invoxy.api_url;
  readonly sub_api_url: string = env.subscription.api_url;

  constructor(
    public authService: AuthService,
    public stateDataService: StateDataService,
    public toastCtrl: ToastController,
    public http: HttpClient
  ) { }

  phPost(
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return this._post('PH', url, data, config);
  }

  invPost(
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._post('INV', url, data, config)
        .then((res) => res?.success ? resolve(res.result) : reject(res))
        .catch((err) => reject(err));
    });
  }

  phGet(
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return this._get('PH', url, data, config);
  }

  invGet(
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._get('INV', url, data, config)
        .then((res) => res?.success ? resolve(res.result) : reject(res))
        .catch((err) => reject(err));
    });
  }

  subPost(
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._post('SUB', url, data, config)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
    })
  }



  getBlob(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this._verifyUserAccessCompanyKey()) {
        this.http
          .get(url, { responseType: 'blob' })
          .subscribe(
            (blob) => {
              resolve(blob);
            },
            (error) => {
              console.log(error);
              reject();
            }
          );
      }
      else {
        reject({ message: 'verifyGlobalUserAccessCompanyKey failed' });
      }
    });
  }

  private _post(
    product: AppProduct,
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this._verifyUserAccessCompanyKey()) {
        const options: any = {
          headers: this._getHeader(product, config)
        };

        // Only need to set as arraybuffer when downloading PDF
        if (!!config?.response_type) {
          options.responseType = config.response_type;
        }

        url = this._getUrl(product, url);

        this.http
          .post(url, data, options)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((error) => {
            reject(this._processAPIError(error, config?.hide_error || false));
          });
      }
      else {
        reject({ message: 'verifyGlobalUserAccessCompanyKey failed' });
      }
    });
  }

  private _get(
    product: AppProduct,
    url: string,
    params: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this._verifyUserAccessCompanyKey()) {
        const options: any = {
          headers: this._getHeader(product, config),
          params
        };

        // Only need to set as arraybuffer when downloading PDF
        if (!!config?.response_type) {
          options.responseType = config.response_type;
        }

        url = this._getUrl(product, url);
        this.http
          .get(url, options)
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((error) => {
            reject(this._processAPIError(error, config?.hide_error || false));
          });
      }
      else {
        reject({ message: 'verifyGlobalUserAccessCompanyKey failed' });
      }
    });
  }

  private _getUrl(product: AppProduct, url: string) {
    switch (product) {
      case 'PH':
        return this.ph_api_url + url;
      case 'INV':
        return this.inv_api_url + url;
      case 'SUB':
        return this.sub_api_url + url;
    }
  }

  private _getHeader(product: AppProduct, config: ApiConfig) {
    return this.authService.getHTTPHeader(
      product,
      config?.session_only || false,
      config?.include_sub_session || false
    );
  }

  private _verifyUserAccessCompanyKey(): boolean {
    return this.stateDataService.verifyGlobalUserAccessCompanyKey();
  }

  private _processAPIError(err: any, hideError: boolean = false) {
    console.log(err);

    if (err && err.status === 503) {
      let product: string = '';

      switch (env.product_code) {
        case 'PH':
          product = 'PayHero';
          break;
        case 'INV':
          product = 'Invoxy';
          break;
      }
      this.authService.logout(false, 'Sorry, ' + product + ' is currently down for maintenance. We should be back shortly.');
    }
    else {
      let res: any;

      if (err && err.error && typeof err.error === 'string') {
        res = {
          message: err.error,
          data: err
        };
      }
      else if (err && err.error && err.error.message && typeof err.error.message === 'string') {
        res = {
          message: err.error.message,
          data: err
        };
      }
      else if (err && err.message && typeof err.message === 'string') {
        res = {
          message: err.message,
          data: err
        };
      }
      else {
        res = {
          message: AuthService.defaultErrorMessage,
          data: err
        };
      }

      if (!hideError && res.message) {
        this.toastMessage(res.message);
      }

      return res;
    }
  }

  async toastMessage(message: string) {
    this.toastCtrl.create({
      message: message || AuthService.defaultErrorMessage,
      duration: 5000,
      position: 'top',
      showCloseButton: true
    } as any)
      .then((toast) => {
        toast.present();
      });
  }

}
