<div class="slideupList-container"
     [ngStyle]="{ 'height': containerHeight + 'px' }">

  <div class="slideupList-items">
    <div class="slideupList-item"
         *ngFor="let item of allItems"
         [ngClass]="{ '-selectedItem': selectedItem && (itemNameProperty ? item[itemNameProperty] === selectedItem[itemNameProperty] : item === selectedItem) }"
         (click)="selectItem(item)">
      <div class="slideupList-itemColour"
           *ngIf="itemColourProperty"
           [ngStyle]="{ 'background-color': item[itemColourProperty] }">
      </div>
      {{itemNameProperty ? item[itemNameProperty] : item}}
    </div>
  </div>
  <div class="slideupList-close">
    Cancel
  </div>
</div>
