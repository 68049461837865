import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { UtilService } from './../../../services/util/util.service';

import * as _ from 'lodash';

@Component({
  selector: 'onboard-form',
  templateUrl: './onboard-form.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-form.component.scss']
})
export class OnboardFormComponent implements OnInit, OnboardStepInterface {

  formAnswerCacheName: string;

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  country_key: string;

  formAnswers: {
    form_name: string,
    field_name: string,
    field_type: string,
    form_answer: any,
    invalid_answer: boolean
  }[];

  constructor(
    public invOnboardService: InvOnboardService,
    public invCompanyService: InvCompanyService,
    public stateDataService: StateDataService
  ) {

  }

  ngOnInit() {
    this.formAnswerCacheName = 'formAnswer-' + this.onboard_key + '-' + this.stepTemplate.onboard_template_step_key;
    this.country_key = this.invCompanyService.getCurrentCompany().country_key;

    this.initFormAnswers();
  }

  initFormAnswers() {
    const cachedFormAnswers = this.stateDataService.getCachedComponentSessionData(
      'OnboardFormComponent', this.formAnswerCacheName
    );

    if (cachedFormAnswers !== null) {
      this.formAnswers = cachedFormAnswers;
    }
    else {
      this.formAnswers = [];

      for (const formDefinition of this.stepTemplate.form_definition) {
        const formAnswer = this.getFormAnswerFromStepAnswer(formDefinition.form_name);

        this.formAnswers.push({
          form_name: formDefinition.form_name,
          field_name: formDefinition.field_name || null,
          field_type: formDefinition.field_type || null,
          form_answer: OnboardFormComponent.setupFormAnswer(formAnswer),
          invalid_answer: false
        });
      }
    }
  }

  getFormAnswerFromStepAnswer(form_name: string) {
    if (this.stepAnswer) {
      for (const formAnswer of this.stepAnswer.form_answer) {
        if (formAnswer.form_name === form_name) {
          return formAnswer;
        }
      }
    }
    return null;
  }

  formAnswerUpdated(formAnswer: any) {
    switch (formAnswer.field_type) {
      case 'email':
        formAnswer.invalid_answer = !!formAnswer.form_answer &&
          !UtilService.validateEmailAddress(formAnswer.form_answer);
        break;
      case 'nzird':
        formAnswer.invalid_answer = !!formAnswer.form_answer &&
          !UtilService.validateIrdNumber(formAnswer.form_answer);
        break;
      case 'nzbank':
        formAnswer.invalid_answer = !!formAnswer.form_answer &&
          !UtilService.validateNzBankAccount(formAnswer.form_answer);
        break;
    }

    this.stateDataService.cacheComponentSessionData(
      'OnboardFormComponent', this.formAnswerCacheName, this.formAnswers
    );
  }

  formAnswerFocused(formAnswer: any) {
    formAnswer.invalid_answer = false;
  }

  formAnswerHasChanged() {
    for (const newFormAnswer of this.formAnswers) {
      const currentFormAnswer = this.getFormAnswerFromStepAnswer(newFormAnswer.form_name);

      if (currentFormAnswer && currentFormAnswer.form_answer !== newFormAnswer) {
        return true;
      }
    }
    return false;
  }

  someFormAnswerProvided() {
    for (const formAnswer of this.formAnswers) {
      if (!!formAnswer.form_answer) {
        return true;
      }
    }
    return false;
  }

  getFormAnswerStatus() {
    const status = {
      all_provided: true,
      some_provided: false,
      none_provided: true,
      some_changed: false,
      all_valid: true
    };

    for (const newFormAnswer of this.formAnswers) {
      const existingFormAnswer = this.getFormAnswerFromStepAnswer(newFormAnswer.form_name);

      if (!newFormAnswer.form_answer && newFormAnswer.form_answer !== 0) {
        status.all_provided = false;
      }
      else {
        status.some_provided = true;
        status.none_provided = false;
      }

      if (newFormAnswer.invalid_answer) {
        status.all_valid = false;
      }

      if (existingFormAnswer &&
        OnboardFormComponent.setupFormAnswer(existingFormAnswer) !== newFormAnswer.form_answer) {
        status.some_changed = true;
      }
    }

    return status;
  }

  stepAnswerNeedsUpdating(): boolean {
    const status = this.getFormAnswerStatus();

    if (status.all_valid) {
      if (this.stepAnswer) {
        if (status.some_changed) {

          if (this.stepTemplate.required_flag) {
            return status.all_provided;
          }
          else {
            return status.some_provided;
          }
        }
      }
      else {
        if (this.stepTemplate.required_flag) {
          return status.all_provided;
        }
        else {
          return status.some_provided;
        }
      }
    }

    return false;
  }

  getStepAnswerData(): any {
    const status = this.getFormAnswerStatus();

    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer) {
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (this.stepTemplate.required_flag) {
      if (status.all_provided) {
        stepData.form_answer = OnboardFormComponent.formatFormAnswers(this.formAnswers);
      }
      else if (this.stepAnswer && status.none_provided) {
        stepData.deleted_flag = true;
      }
    }
    else {
      stepData.form_answer = OnboardFormComponent.formatFormAnswers(this.formAnswers);
    }

    return stepData;
  }

  updateStepAnswer() {
    if (this.stepAnswerNeedsUpdating()) {

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  static setupFormAnswer(formAnswer: any): any {
    if (!formAnswer) {
      return '';
    }
    else {
      if (formAnswer.field_type === 'date') {
        return TimeUtilService.dateToLocalTimeISOString(formAnswer.form_answer) || '';
      }
      return formAnswer.form_answer || '';
    }
  }

  static formatFormAnswer(formAnswer: any): any {
    if (formAnswer.field_type === 'date') {
      return TimeUtilService.formatISODateStringForPosting(formAnswer.form_answer, false);
    }
    return formAnswer.form_answer;
  }

  static formatFormAnswers(formAnswers: any[]): string {
    formAnswers = _.cloneDeep(formAnswers);

    for (const formAnswer of formAnswers) {
      formAnswer.form_answer = this.formatFormAnswer(formAnswer);
    }

    return JSON.stringify(formAnswers);
  }

}
