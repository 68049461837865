import { Injectable } from '@angular/core';

export type UserActionName = (
  'AUTO_PILOT' |
  'EMPLOYEE_DOCUMENTS' |
  'EXPENSES' |
  'GPS_CLOCK_IN' |
  'INSIGHTS' |
  'PAY_CYCLE_ADMINS' |
  'SHIFT' |
  'TIMESHEET_IMPORT' |
  'BREAK_CALCULATIONS' |
  'LIMIT_EMPLOYEE' |
  'LIMIT_TEAM' |
  'POWER_BI' |
  'PUBLIC_API' |
  'TIMESHEET_APPROVALS'
);

export type UserAction = {
  action_name: UserActionName,
  additional_data?: { limit?: number, additional_cost?: number }
}

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  action_map: Partial<Record<UserActionName, UserAction>> = {};

  initActionMap(action_data: UserAction[]) {
    this.action_map = {};

    for (const action of action_data) {
      this.action_map[action.action_name] = action;
    }
  }

  isActive(action_name: UserActionName) {
    return !!this.action_map[action_name];
  }

  getActionLimit(action_name: UserActionName) {
    if (
      this.isActive(action_name) &&
      action_name.indexOf('LIMIT_') === 0
    ) {
      return this.action_map[action_name].additional_data.limit;
    }
    return null;
  }

  getAction(action_name: UserActionName) {
    return this.action_map[action_name] || null;
  }

}
