import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { LoginComponent } from './login/login.component';

import { LoginExternalComponent } from './login-external/login-external.component';

export const LOGIN_CONFIG: any[] = [
  {
    name: 'login',
    url: '/login',
    component: LoginComponent
  },
  {
    name: 'loginExternal',
    url: '/loginExternal?{urlParams:string}',
    component: LoginExternalComponent,
    params: {
      urlParams: null
    }
  }
];

@NgModule({
  declarations: [
    LoginComponent,
    LoginExternalComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
    UIRouterModule.forChild({ states: LOGIN_CONFIG })
  ]
})
export class LoginModule { }
