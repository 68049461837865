import { SubAuth, PhAuth, InvAuth } from './../../../services/state-data/state-data.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { UtilService } from './../../../services/util/util.service';
import { AuthService } from './../../../services/auth/auth.service';
import { TokenService } from './../../../services/token/token.service';

import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

declare var Base64: any;

@Component({
  selector: 'app-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {

  isInvoxyUser: boolean;
  params: any;

  constructor(
    public stateAccessService: StateAccessService,
    public stateService: StateService,
    public authService: AuthService,
    public tokenService: TokenService,
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;

    const encoded_params = this.uiRouterGlobals.params.urlParams;

    if (!!encoded_params) {
      this.params = UtilService.parseJSON(Base64.decode(encoded_params));

      if (
        this.params &&
        this.params.session_key &&
        (this.params.company_code || this.params.company_key) &&
        this.params.user_access_company_key &&
        this.params.return_session_key
      ) {
        const sub_auth: SubAuth = {
          return_session_key: this.params.return_session_key,
          user_access_company_key: this.params.user_access_company_key,
          return_login_token: this.params.return_login_token || null,
        };
        let ph_auth: PhAuth = null;
        let inv_auth: InvAuth = null;

        if (!!this.isInvoxyUser) {
          inv_auth = {
            session_key: this.params.session_key,
            company_key: this.params.company_key
          };
        }
        else {
          ph_auth = {
            session_key: this.params.session_key,
            company_code: this.params.company_code
          };
        }

        this.authService.login(
          sub_auth,
          ph_auth,
          inv_auth
        );

        if (this.params?.ignore_onboard_check) {
          this.stateService.go('splash', {
            refreshStateName: this.params.state_name || null,
            refreshStateParams: UtilService.parseJSON(this.params.state_params)
          });
        } else {
          this.stateService.go('splash', {
            refreshStateName: this.params.state_name || null,
            refreshStateParams: UtilService.parseJSON(this.params.state_params),
            checkOnboard: true
          });
        }
      }
      else {
        this.authService.tryTokenLogin();
      }
    }
    else {
      this.authService.tryTokenLogin();
    }
  }

}
