import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete/google-places-autocomplete.directive';

@NgModule({
  declarations: [
    GooglePlacesAutocompleteDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    GooglePlacesAutocompleteDirective
  ]
})
export class DirectivesModule { }
