import { Injectable } from '@angular/core';

import { TimeUtilService } from './../time-util/time-util.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeUtilService {

  static getEmployeeFromList(employee_key: number, employees: any[]): any {
    for (const employee of employees){
      if (employee.employee_key === employee_key){
        return employee;
      }
    }
    return null;
  }

  static excludeContractorsFromEmployees(employees: any[]): any[] {
    const filteredEmployees = [];

    for (const employee of employees){
      if (!employee.contractor_flag){
        filteredEmployees.push(employee);
      }
    }
    return filteredEmployees;
  }

  static sortEmployeesByName(employees: any[]): any[] {
    return employees.sort((a: any, b: any) => {
      const empCodeA = a.employee_code.toUpperCase();
      const empCodeB = b.employee_code.toUpperCase();

      if (empCodeA > empCodeB){
        return 1;
      }
      else if (empCodeA < empCodeB){
        return -1;
      }
      return 0;
    });
  }

  static activeWithinRange(period_start: Date, period_end: Date, employee_start: Date, employee_finish: Date): boolean {
    if (period_end && employee_start){
        if (period_end.valueOf() - employee_start.valueOf() < 0){
            return false;
        }
    }
    if (period_start && employee_finish){
        if (employee_finish.valueOf() - period_start.valueOf() < 0){
            return false;
        }
    }
    return true;
  }

  static setupEmployee(employee: any): any {
    if (employee.birth_date) {
      employee.birth_date = TimeUtilService.dateStringToDate(employee.birth_date, null, false);
    }
    if (employee.employment_start_date) {
      employee.employment_start_date = TimeUtilService.dateStringToDate(employee.employment_start_date, null, false);
    }
    if (employee.employment_finish_date) {
      employee.employment_finish_date = TimeUtilService.dateStringToDate(employee.employment_finish_date, null, false);
    }

    if (employee.parental_leave && employee.parental_leave.length){
      const today = moment();

      employee.on_parental_leave = _.some(employee.parental_leave, (leave) => {
          return today.isSameOrAfter(leave.start_date, 'day')
              && (!leave.end_date || today.isSameOrBefore(leave.end_date, 'day'));
      });
    }

    return employee;
  }
}
