import { StateService } from '@uirouter/angular';
import { Component, OnInit, ViewChild } from '@angular/core';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from 'src/app/services/state-change/state-change.service';
import { ProjectService } from '../../../services/project/project.service';
import { ClockService } from '../../../services/clock/clock.service';
import { AlertService } from '../../../services/alert/alert.service';
import { UtilService } from '../../../services/util/util.service';
import { CompanyService } from './../../../services/company/company.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.scss']
})
export class TimeClockComponent implements OnInit {

  @ViewChild('noteTextArea') noteTextArea: any;

  isAdmin: boolean;
  isManager: boolean;

  loggedInEmployeeKey: number;

  clock_key: number;
  clock: any;
  clockTimeData: any;

  timer: any;
  timerFlasher: boolean;

  readonly longClockMessage: string = 'This clock entry will be trimmed when you clock out due to the total duration exceeding 24 hours';

  selectedProject: any;
  allProjects: any = [];

  note: string;
  addingNote: boolean = false;

  loading: boolean = true;

  constructor(public stateService: StateService,
              public stateAccessService: StateAccessService,
              public stateChangeService: StateChangeService,
              public projectService: ProjectService,
              public clockService: ClockService,
              public alertService: AlertService,
              public utilService: UtilService,
              public companyService: CompanyService) {

  }

  ngOnInit(){
    this.isAdmin = this.companyService.isAdmin();
    this.isManager = this.companyService.isManager();
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    this.clock = _.cloneDeep(this.stateService.params.clock) || null;
    const clock_key = this.stateService.params.clock_key;

    if (!isNaN(clock_key) && !this.clock){
      this.clock_key = clock_key;

      this.clockService.loadClock(this.clock_key)
        .then((clock) => {
          this.clock = clock;
          this.clock_key = this.clock.clock_key;

          this.initialiseData();
        })
        .catch(() => {
          this.back();
        });
    }
    else if (this.clock) {
      this.clock_key = this.clock.clock_key;

      this.initialiseData();
    }
    else {
      this.back();
    }
  }

  back(){
    this.stateChangeService.back();
  }

  initialiseData(){
    this.allProjects = this.projectService.getAllTimesheetHoursProjects(false);
    this.selectedProject = ProjectService.getProjectFromList(this.clock.project_key, this.allProjects);

    this.setupClockTimeData();
    this.startTimer();

    this.loading = false;
  }

  projectSelected(event: any){
    this.selectedProject = event.item;
    this.selectedProjectChanged();
  }

  startTimer(){
    if (!this.timer){
      this.timerFlasher = true;

      this.timer = setInterval(() => {
        this.updateClockTimeData();
      }, 1000);
    }
  }

  stopTimer(){
    if (!!this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  setupClockTimeData(){
    this.clockTimeData = ClockService.setupClockTimeData(this.clock);
  }

  updateClockTimeData(){
    const nowSeconds = Math.floor(new Date().valueOf() / 1000);
    this.timerFlasher = nowSeconds % 2 === 0;

    ClockService.updateClockTimeData(this.clockTimeData, this.clock);
  }

  breakOut(){
    if (!this.loading){
      this.loading = true;

      this.clockService.breakOut(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  breakIn(){
    if (!this.loading){
      this.loading = true;

      this.clockService.breakIn(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  clockOut(){
    if (!this.loading){
      this.loading = true;

      this.clockService.clockOut(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  refreshClock(){
    this.loading = true;

    this.clockService.loadClock(this.clock_key)
      .then((clock) => {
        this.clock = clock;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  selectedProjectChanged(){
    this.clockService.postClockProject(this.clock_key, this.selectedProject.project_key);
  }

  newNote(){
    this.note = this.note || '';
    this.addingNote = true;

    setTimeout( () => {
      this.noteTextArea.nativeElement.focus();
    });
  }

  cancelNote(){
    this.addingNote = false;
  }

  saveNote(){
    this.loading = true;

    this.clockService.postClockNote(this.clock, null, this.note)
      .then(() => {
        this.note = '';
        this.addingNote = false;
        this.refreshClock();
      })
      .catch(() => {
        this.refreshClock();
      });
  }

  deleteNote(clockNote: any){
    this.loading = true;

    this.clockService.postClockNote(this.clock, clockNote.clock_note_key, clockNote.note, true)
      .then(() => {
        this.note = '';
        this.addingNote = false;
        this.refreshClock();
      })
      .catch(() => {
        this.refreshClock();
      });
  }

}
