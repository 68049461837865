import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

import * as moment from 'moment';

@Pipe({
  name: 'clockTimerSecondsPipe',
})
export class ClockTimerSecondsPipe implements PipeTransform {

  constructor(public utilService: UtilService) {

  }

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value, ...args) {
    const seconds = moment.duration(value, 'seconds').seconds();

    return UtilService.padNumber(seconds, 2, false);
  }
}
