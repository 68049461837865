import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LeaveUtilService {

  constructor() {

  }

  static getLeaveTypeFromList(leave_type_key: number, leaveTypes: any[]): any {
    for (const leaveType of leaveTypes){
      if (leaveType.leave_type_key === leave_type_key){
        return leaveType;
      }
    }
    return null;
  }

  static getFirstDateInListOfYears(years: number[]): Date {
    const year = Math.min(...years);
    return new Date(year, 0, 1, 0, 0, 0);
  }

  static getLastDateInListOfYears(years: number[]): Date {
    const year = Math.max(...years);
    return new Date(year + 1, 0, 0, 0, 0, 0);
  }

  static formatLeaveRequestForPosting(request: any): any {
    request = _.cloneDeep(request);

    request.start_date = TimeUtilService.formatDateForPosting(request.start_date, false);
    request.end_date = TimeUtilService.formatDateForPosting(request.end_date, false);

    if (request.approved_declined_date){
      request.approved_declined_date = TimeUtilService.formatDateForPosting(request.approved_declined_date, true);
    }

    request.leave_type_key = _.cloneDeep(request.leaveType.leave_type_key);
    request.employee_key = _.cloneDeep(request.employee.employee_key);

    delete request.leaveType;
    delete request.employee;

    return request;
  }

  static sortLeaveTypes(leaveTypes: any[]): any[] {
    return leaveTypes.sort((a, b) => {
      if (a.leave_type_name < b.leave_type_name){
        return -1;
      }
      else if (a.leave_type_name > b.leave_type_name){
        return 1;
      }
      return 0;
    });
  }

  static sortLeaveRequests(leaveRequests: any[]): any[] {
    return leaveRequests.sort((a, b) => {
      if (a.start_date.valueOf() > b.start_date.valueOf()){
        return -1;
      }
      else if (a.start_date.valueOf() < b.start_date.valueOf()){
        return 1;
      }
      return 0;
    });
  }

  static setupLeaveRequestInfo(info: any): any {
    for (const prop in info){
      if (info.hasOwnProperty(prop)){

        if (typeof info[prop] === 'number'){
          info[prop] = UtilService.numberToTwoDP(info[prop]);
        }
      }
    }
    return info;
  }

  static setupLeaveRequestTypes(types: any[]): any[] {
    for (const type of types){
      if (type.leave_type_colour){
        type.leave_type_colour = UtilService.intToHexColor(type.leave_type_colour);
      }
    }

    return types;
  }

  static setupEmployeeLeaveDetails(leaveDetails: any): any {
    leaveDetails.next_holiday_anniversary = TimeUtilService.dateStringToDate(leaveDetails.next_holiday_anniversary, null, false);
    leaveDetails.next_sick_anniversary = TimeUtilService.dateStringToDate(leaveDetails.next_sick_anniversary, null, false);

    return leaveDetails;
  }

  static groupLeaveRequestByStatus(leaveRequests: any[]): any[] {
    const groupedLeaveRequests = [
      { status: 'Pending', leaveRequests: [] },
      { status: 'Approved', leaveRequests: [] },
      { status: 'Declined', leaveRequests: [] }
    ];

    for (const req of leaveRequests){
      // Pending
      if (req.pending_flag){
        groupedLeaveRequests[0].leaveRequests.push(req);
      }
      // Approved
      else if (req.approved_flag){
        groupedLeaveRequests[1].leaveRequests.push(req);
      }
      // Declined
      else if (req.declined_flag){
        groupedLeaveRequests[2].leaveRequests.push(req);
      }
    }

    LeaveUtilService.sortLeaveRequests(groupedLeaveRequests[0].leaveRequests);
    LeaveUtilService.sortLeaveRequests(groupedLeaveRequests[1].leaveRequests);
    LeaveUtilService.sortLeaveRequests(groupedLeaveRequests[2].leaveRequests);

    return groupedLeaveRequests;
  }

}
