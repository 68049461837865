import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { ProjectService } from '../../../services/project/project.service';
import { UtilService } from '../../../services/util/util.service';
import { TimeService } from '../../../services/time/time.service';
import { TimeUtilService } from '../../../services/time-util/time-util.service';
import { AlertService } from '../../../services/alert/alert.service';
import { EmployeeService } from '../../../services/employee/employee.service';
import { CompanyService } from './../../../services/company/company.service';
import { DomService } from './../../../services/dom/dom.service';
import { TimesheetApprovalsService } from './../../../services/timesheet-approvals/timesheet-approvals.service';

import { PageTimeEdit } from '../../../classes/abstract/page-time-edit/page-time-edit';

import { PhSegment } from './../../../models/segment/ph-segment';

import * as moment from 'moment';
import * as _ from 'lodash';


@Component({
  selector: 'app-time-edit',
  templateUrl: './time-edit.component.html',
  styleUrls: ['./time-edit.component.scss']
})
export class TimeEditComponent extends PageTimeEdit implements OnInit {

  readonly errorMessages: any = {
    noProjects: 'At least one work with a timesheet pay item must be available to record time',
    inactiveProject: 'The work attached to this time entry is archived',
    noEmployees: 'At least one employee must be active on the selected date to record time',
    inactiveEmployee: 'Time can\'t be recorded outside an employee\'s employment period',
    timeApproved: 'This day has been approved and cannot be edited'
  };

  segment: PhSegment;

  allEmployees: any[] = [];

  loggedInEmployeeKey: number;

  approvedDaysStrings: any[] = [];

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public projectService: ProjectService,
    public utilService: UtilService,
    public timeService: TimeService,
    public alertService: AlertService,
    public employeeService: EmployeeService,
    public companyService: CompanyService,
    public domService: DomService,
    public uiRouterGlobals: UIRouterGlobals,
    public timesheetApprovalsService: TimesheetApprovalsService
  ) {
    super();
  }

  ngOnInit() {
    this.isAdmin = this.companyService.isAdmin();
    this.isManager = this.companyService.isManager();
    this.currencySymbol = this.utilService.currencySymbol;
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    this.segments = this.uiRouterGlobals.params.segments || [];
    this.segment = _.cloneDeep(this.uiRouterGlobals.params.segment) || null;
    const segment_key = this.uiRouterGlobals.params.segment_key || null;

    if (this.segment) {
      this.initialiseData();
    }
    else if (segment_key) {
      this.timeService.loadSegment(segment_key)
        .then((segment) => {
          this.segment = segment;
          this.initialiseData();
        })
        .catch(() => {
          this.back();
        });
    }
    else {
      const project = this.getDefaultProject();
      const employee = this.getDefaultEmployee();

      if (project !== null && employee !== null) {

        this.segment = this.timeService.initNewSegment(
          employee.employee_key,
          project.project_key,
          this.defaultSegmentDate
        );
        this.initialiseData();
      }
      else {
        this.back();
      }
    }
  }

  back() {
    this.stateChangeService.back(['app.time.work']);
  }

  initialiseData() {
    this.loading = true;

    this.initEditingDisabled();

    this.initSegmentUnitsTouched();
    this.initPublicHolidays();
    this.initAllEmployees();
    this.initAllProjects();

    this.updateDateStrings();

    this.checkForErrors();
    this.checkPublicHoliday();
    this._checkIsDayApproved();

    this.updatePageTitle();

    this.loading = false;
  }

  getDefaultEmployee() {
    let employee_key: number = null;

    if (this.uiRouterGlobals.params.employee_key) {
      employee_key = this.uiRouterGlobals.params.employee_key;
    }
    else {
      const allEmployees = this.employeeService.getAllEmployees();

      if (allEmployees.length) {
        employee_key = allEmployees[0].employee_key;
      }
    }

    return this.employeeService.getSingleEmployee(employee_key);
  }

  getDefaultProject() {
    let project_key: number = null;

    if (this.uiRouterGlobals.params.project_key) {
      project_key = this.uiRouterGlobals.params.project_key;
    }
    else {
      const allProjects = this.projectService.getAllTimesheetProjects();

      if (allProjects.length) {
        project_key = allProjects[0].project_key;
      }
    }

    return this.projectService.getSingleProject(project_key);
  }

  initEditingDisabled() {
    this.editingDisabled = this.segment.credit_flag;
  }

  initPublicHolidays() {
    this.publicHolidaysMap = this.companyService.getPublicHolidaysMap();
  }

  initAllEmployees() {
    this.allEmployees = this.employeeService.getAllEmployees();

    this.checkForErrors();
  }

  initAllProjects() {
    if (this.segment.segment_key !== null) {
      if (this.segment.unit_flag) {
        this.allProjects = this.projectService.getAllTimesheetUnitsProjects();
      }
      else {
        this.allProjects = this.projectService.getAllTimesheetHoursProjects();
      }
    }
    else {
      this.allProjects = this.projectService.getAllTimesheetProjects();
    }

    this.checkForErrors();
  }

  projectSelected(event: any) {
    this.segment.ph_project = event.item;
    this.segmentUnitsTouched = true;

    this.checkForErrors();
    this.updateDateStrings();
  }

  employeeSelected(employee: any) {
    this.segment.employee = employee;
    this.segmentUnitsTouched = true;

    this.checkForErrors();
    this.updateDateStrings();
    this._checkIsDayApproved();
  }

  openEmployeeList() {
    if (!this.segment.is_locked && !this.segment.segment_key) {

      this.domService.openSlideUpList(
        this.allEmployees,
        this.segment.employee,
        'employee_code',
        null,
        (employee: any) => {
          this.employeeSelected(employee);
        }
      );
    }
  }

  updatePageTitle() {
    let prefix: string;

    if (!this.segment.segment_key) {
      prefix = 'New ';
    }
    else if (this.segment.paid) {
      prefix = 'Paid ';
    }
    else if (this.segment.credit_flag) {
      prefix = 'Credited '
    }
    else if (this.segment.is_locked || this.editingDisabled) {
      prefix = '';
    }
    else {
      prefix = 'Edit ';
    }

    this.pageTitle = prefix + (this.segment.unit_flag ? 'Work' : 'Time');
  }

  segmentDateStringUpdated() {
    super.segmentDateStringUpdated();
    this.checkForErrors();
    this.checkPublicHoliday();
    this._checkIsDayApproved();
  }

  deleteSegment() {
    this.alertService.confirmDeleteAlert('Are you sure you want to delete this time record?')
      .then(() => {
        this.saveSegment(true);
      });
  }

  saveSegment(toDelete: boolean = false) {
    this.loading = true;

    this.segment.deleted_flag = toDelete;

    this.timeService.saveSegments([this.segment])
      .then(() => {
        this.loading = false;
        this.back();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  checkPublicHoliday() {
    const dateString = TimeUtilService.dateToDateString(this.segment.segment_date, null);
    this.currentPublicHoliday = this.publicHolidaysMap[dateString] || null;
  }

  checkForErrors() {
    super.checkForErrors();
    this._checkAnyEmployeesAvailable();
    this._checkIsActiveEmployee();
    this._checkIsActiveProject();
  }

  private _checkAnyEmployeesAvailable() {
    this.clearErrorIfMatches(this.errorMessages.noEmployees);
    if (this.allEmployees.length === 0) {
      this.errorMessage = this.errorMessages.noEmployees;
    }
  }

  private _checkIsActiveEmployee() {
    this.clearErrorIfMatches(this.errorMessages.inactiveEmployee);
    if (!this.segment.is_active_employee) {
      this.errorMessage = this.errorMessages.inactiveEmployee;
    }
  }

  private _checkIsActiveProject() {
    this.clearErrorIfMatches(this.errorMessages.inactiveProject);
    if (!this.segment.is_active_project) {
      this.errorMessage = this.errorMessages.inactiveProject;

      for (const project of this.allProjects) {
        if (project.project_key === this.segment.ph_project.project_key) {
          return;
        }
      }

      console.log(_.cloneDeep(this.segment), _.cloneDeep(this.segment.ph_project));
      console.log(this.projectService.getSingleProject(this.segment.ph_project.project_key));
      this.allProjects.unshift(this.projectService.getSingleProject(this.segment.ph_project.project_key));
    }
  }

  private _checkIsDayApproved() {
    this.clearErrorIfMatches(this.errorMessages.timeApproved);

    if (this.segmentDateString) {     
      let date =  TimeUtilService.dateStringToDate(this.segmentDateString);
      //gets all timesheet approvals on the selected day
      this.timesheetApprovalsService.getTimeApprovals(this.segment.employee.employee_key, date, date)
      .then((approvals) => {
        if (approvals.length > 0) { //if any exist, throw error
          this.errorMessage = this.errorMessages.timeApproved;
        }
      });
    }
  }
}
