<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
    <ion-title *ngIf="approvalProject">
      {{approvalProject.display_name}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<popup-menu [items]="menuItems"
            (itemSelected)="menuItemSelected($event)">
</popup-menu>

<ion-content *ngIf="approvalProject && approvalSegments">

  <div class="inv-approval-projectHeader">
    {{approvalProject.project_name}}
    <span>{{approvalProject.client_name}}</span>
  </div>

  <div class="inv-approvalView-dates">
    <div class="inv-approvalView-date">
      <div class="-dateLabel">
        Requested On
      </div>
      <div class="-dateValue">
        {{approvalProject.request_date | date : 'd MMM'}},
        <span>{{approvalProject.request_date | date : 'yyyy'}}</span>
      </div>
    </div>
    <div class="inv-approvalView-date">
      <div class="-dateLabel">
        Request Period
      </div>
      <div class="-dateValue">
        {{approvalProject.from_date | date : 'd MMM'}} -
        {{approvalProject.to_date | date : 'd MMM'}},
        <span>{{approvalProject.to_date | date : 'yyyy'}}</span>
      </div>
    </div>
  </div>

  <div class="ph-groupedList-container inv-approvalView-segments"
       *ngFor="let unitGroup of unitGroups">

    <div class="ph-groupedList-groupHeader">
      <div class="ph-groupedList-groupHeaderTitle">
        {{unitGroup.unit_type}}
      </div>
      <div class="ph-groupedList-groupHeaderLine"></div>
    </div>

    <div class="ph-groupedList-item"
         *ngFor="let segment of unitGroup.segments">

      <div class="ph-groupedList-itemColor"
           [ngStyle]="{'background-color': segment.colour}">
      </div>

      <div class="ph-groupedList-itemDescription">
        {{segment.segment_date | date: 'EEE, d LLL'}}
      </div>
      <div class="ph-groupedList-itemTitle">
        {{segment.work_name}}
      </div>
      <div class="ph-groupedList-itemDetails"
           *ngIf="!segment.unit_flag">
        <div>{{segment.start_time | date : 'shortTime'}} - {{segment.end_time | date : 'shortTime'}}</div>
        <span *ngIf="segment.break_duration">
          ({{(60 * segment.break_duration).toFixed(0)}} mins break)
        </span>
      </div>
      <div class="ph-groupedList-itemDetails"
           *ngIf="segment.credit_flag">
        <span>CREDIT</span>
      </div>

      <div class="ph-groupedList-itemValueRight">
        <div *ngIf="!segment.unit_flag">
          {{segment.duration | hoursToTimePipe}}
        </div>
        <div *ngIf="segment.unit_flag && segment.unit_type === 'hours'">
          {{segment.units | hoursToTimePipe}}
        </div>
        <div *ngIf="segment.unit_flag && segment.unit_type === 'expense'">
          {{segment.units | currency : currencySymbol}}
        </div>
        <div *ngIf="segment.unit_flag && segment.unit_type !== 'expense' && segment.unit_type !== 'hours'">
          {{segment.units}} <span>{{segment.unit_type}}</span>
        </div>
      </div>

    </div>

    <div class="ph-groupedList-groupFooter"
         *ngIf="unitGroup.unit_type === 'hours'">
      <div class="ph-groupedList-groupFooterRight">
        {{unitGroup.total | hoursToTimePipe}}
      </div>
    </div>
    <div class="ph-groupedList-groupFooter"
         *ngIf="unitGroup.unit_type === currencySymbol + ' Expense'">
      <div class="ph-groupedList-groupFooterRight">
        {{unitGroup.total | currency : currencySymbol}}
      </div>
    </div>
    <div class="ph-groupedList-groupFooter"
         *ngIf="unitGroup.unit_type !== 'hours' && unitGroup.unit_type !== currencySymbol + ' Expense'">
      <div class="ph-groupedList-groupFooterRight">
        {{unitGroup.total}}
        <span>{{unitGroup.unit_type}}</span>
      </div>
    </div>

  </div>

  <div class="ph-groupedList-container inv-approvalView-attachments"
       *ngIf="approvalProject.timesheet_attachments.length">
    <div class="ph-groupedList-groupHeader">
      <div class="ph-groupedList-groupHeaderTitle">
        Timesheet Attachments
      </div>
      <div class="ph-groupedList-groupHeaderLine"></div>
    </div>

    <div class="ph-groupedList-item"
         *ngFor="let attachment of approvalProject.timesheet_attachments">

      <a [href]="attachment.timesheet_attachment_url"
         target="_blank"
         class="ph-groupedList-itemTitle -color-payhero-clear -type-link">
        {{attachment.timesheet_attachment_name}}
      </a>
      <div class="ph-groupedList-itemDescription"
           *ngIf="attachment.description">
        {{attachment.description}}
      </div>

      <div class="ph-groupedList-itemValueRight">
        <span>{{attachment.created_date | date : 'EEE, d MMM'}}</span>
      </div>

    </div>
  </div>

  <div class="ph-groupedList-container inv-approvalView-approvers">
    <div class="ph-groupedList-groupHeader">
      <div class="ph-groupedList-groupHeaderTitle">
        Approvers
      </div>
      <div class="ph-groupedList-groupHeaderLine"></div>
    </div>

    <div class="ph-groupedList-item"
         *ngFor="let approvalUser of approvalProject.approval_users">

      <div class="inv-approvalManager-initialsIcon"
           [ngClass]="{
             '-approved': approvalUser.approved_flag,
             '-rejected': approvalUser.rejected_flag }">
        {{approvalUser.initials}}
      </div>


      <div class="ph-groupedList-itemTitle">
        {{approvalUser.display_name}}
      </div>

      <div class="ph-groupedList-itemValueRight -approved"
           *ngIf="approvalUser.approved_flag">
        <div>Approved</div>
        <div>{{approvalUser.response_date | date: 'h:mma, dd MMM'}}</div>
      </div>
      <div class="ph-groupedList-itemValueRight -rejected"
           *ngIf="approvalUser.rejected_flag">
        <div>Rejected</div>
        <div>{{approvalUser.response_date | date: 'h:mma, dd MMM'}}</div>
      </div>
      <div class="ph-groupedList-itemValueRight -pending"
           *ngIf="!approvalUser.approved_flag && !approvalUser.rejected_flag">
        <div>Pending</div>
      </div>

    </div>
  </div>

</ion-content>
