<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>My Details</ion-title>
  </ion-toolbar>
</ion-header>

<slider-button *ngIf="userDetails"
               [btnDisabled]="loading || !userDetails.user_name || !userDetails.registered_email"
               [btnShort]="false"
               [btnText]="'Update Details'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveClicked()">
</slider-button>

<ion-content>

  <div class="app-accountDetails"
       *ngIf="userDetails && !loading">

    <button class="-type-text -color-white-outline app-accountDetails-passwordButton"
            (click)="changePassword()">
      Change Password
    </button>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Login Username
      </div>
      <input class="ph-formItem-input"
             type="text"
             [(ngModel)]="userDetails.user_name">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Login Email
      </div>
      <input class="ph-formItem-input"
             type="email"
             [(ngModel)]="userDetails.registered_email">
    </div>

    <div class="ph-formItem"
         *ngIf="userDetails.verification_email">
      <div class="ph-formItem-label">
        A verification email has been sent to <b>{{userDetails.verification_email}}</b>
      </div>
    </div>

  </div>

</ion-content>
