import { Component, OnInit, Input, Output, ViewChild, ViewChildren, QueryList, EventEmitter } from '@angular/core';

import { InvOnboardService } from './../../services/inv-onboard/inv-onboard.service';

@Component({
  selector: 'onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {

  @ViewChild('onboardSlides') onboardSlides: any;
  @ViewChildren('onboardStep') stepComponents: QueryList<any>;

  @Input() display_name: string;
  @Input() company_name: string;
  @Input() onboard_key: number;

  @Output() onboardComplete = new EventEmitter();

  onboard: any;

  onboardSteps: {
    step_template: any,
    step_answer: any
  }[];

  slideOptions: any;

  hasUnfinishedRequiredSteps: boolean;
  slideIndex: number = 0;
  stepNumber: number = 0;

  stepFraction: number;

  constructor(public invOnboardService: InvOnboardService){}

  ngOnInit() {
    this.onboard = this.invOnboardService.getSingleOnboard(this.onboard_key);

    this.slideOptions = {
      initialSlide: this.slideIndex
    };

    this.initOnboardSteps();
    this.stepFraction = 100 / this.onboard.step_templates.length;
  }

  initOnboardSteps(){
    this.onboardSteps = [];

    for (const step_template of this.onboard.step_templates){
      this.onboardSteps.push({
        step_template,
        step_answer: this.getStepAnswer(step_template.onboard_template_step_key)
      });
    }
    this.checkUnfinishedRequiredSteps();
  }

  getStepAnswer(onboard_template_step_key: number){
    for (const step_answer of this.onboard.step_answers){
      if (step_answer.onboard_template_step_key === onboard_template_step_key){
        return step_answer;
      }
    }
    return null;
  }

  checkUnfinishedRequiredSteps(){
    this.hasUnfinishedRequiredSteps = false;

    for (const step of this.onboardSteps){
      if (step.step_answer === null && step.step_template.required_flag){
        this.hasUnfinishedRequiredSteps = true;
        return;
      }
    }
  }

  stepChanged(){
    this.onboardSlides.getActiveIndex()
      .then((slideIndex: number) => {
        this.updateCurrentStepAnswer();

        this.slideIndex = slideIndex;
        this.stepNumber = slideIndex;
        if (this.stepNumber === 0){
          this.stepNumber = 1;
        }
        else if (this.stepNumber > this.onboardSteps.length){
          this.stepNumber = this.onboardSteps.length;
        }
      });
  }

  finishOnboard(){
    if (!this.hasUnfinishedRequiredSteps){
      this.invOnboardService.finishOnboard(this.onboard.onboard_key)
        .then(() => {
          this.onboardComplete.emit();
        })
        .catch(() => {
          this.initOnboardSteps();
        });
    }
  }

  updateCurrentStepAnswer(){
    const step = this.onboardSteps[this.stepNumber - 1];

    const stepComponents = this.stepComponents.toArray();
    const stepComponent = stepComponents[this.stepNumber - 1];

    if (step && stepComponent){
      stepComponent.updateStepAnswer();
    }
  }

  stepAnswerUpdated(event: any){
    this.onboard = this.invOnboardService.getSingleOnboard(this.onboard_key);
    this.initOnboardSteps();
  }

  forwardStep(){
    this.onboardSlides.slideNext();
  }

  backStep(){
    this.onboardSlides.slidePrev();
  }

}
