import { Component, OnInit } from '@angular/core';

import { LeaveManagerComponent } from './leave-manager/leave-manager.component';
import { LeaveEmployeeComponent } from './leave-employee/leave-employee.component';
import { LeaveEditComponent } from './leave-edit/leave-edit.component';
import { LeaveBalancesComponent } from './leave-balances/leave-balances.component';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss']
})
export class LeaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export const LEAVE_STATE_CONFIG = [
  {
    name: 'app.leave',
    url: '/leave',
    abstract: true,
    component: LeaveComponent
  },
  {
    name: 'app.leave.balances',
    url: '/balances',
    component: LeaveBalancesComponent
  },
  {
    name: 'app.leave.edit',
    url: '/edit',
    component: LeaveEditComponent
  },
  {
    name: 'app.leave.employee',
    url: '/employee',
    component: LeaveEmployeeComponent
  },
  {
    name: 'app.leave.manager',
    url: '/manager',
    component: LeaveManagerComponent
  },
];

