<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>
      Get Started
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <onboard [onboard_key]="onboard_key"
           [display_name]="display_name"
           [company_name]="company_name"
           (onboardComplete)="onboardComplete()">
  </onboard>
</ion-content>
