import { TimeUtilService } from './../../services/time-util/time-util.service';

export abstract class Expense {

  private _expense_amount: number;
  private _expense_date: Date;
  description: string;

  ph_approval_date: Date;
  ph_declined_date: Date;

  ph_authorised_flag: boolean;
  ph_pending_flag: boolean;
  ph_approved_flag: boolean;
  ph_paid_flag: boolean;
  ph_declined_flag: boolean;

  deleted_flag: boolean = false;

  _ph_response_step: number;

  constructor(
    expense_amount: number = 0, expense_date: Date, description: string = '',
    ph_approval_date: Date = null, ph_declined_date: Date = null, ph_paid_flag: boolean = false, ph_authorised_flag: boolean = false
  ) {

    this._expense_amount = expense_amount;
    this._expense_date = expense_date;
    this.description = description;

    this.ph_approval_date = ph_approval_date;
    this.ph_declined_date = ph_declined_date;

    this.ph_authorised_flag = ph_authorised_flag;
    this.ph_approved_flag = this.ph_declined_date === null && this.ph_approval_date !== null;
    this.ph_paid_flag = ph_paid_flag;
    this.ph_declined_flag = this.ph_declined_date !== null;
    this.ph_pending_flag = this.ph_authorised_flag && !this.ph_approval_date && !this.ph_declined_date && !this.ph_paid_flag;

    this._ph_response_step = null;
    this._updatePhResponseStep();
  }

  get expense_date(): Date {
    return this._expense_date;
  }
  set expense_date(expense_date: Date) {
    if (TimeUtilService.dateIsValid(expense_date)) {
      expense_date = this._cleanDate(expense_date);

      this._expense_date = expense_date;
    }
  }

  get expense_amount(): number {
    return this._expense_amount;
  }
  set expense_amount(expense_amount: number) {
    if (TimeUtilService.numberIsValid(expense_amount)) {
      if (expense_amount < 0) {
        expense_amount = 0;
      }
      this._expense_amount = expense_amount;
    }
  }

  get ph_response_step() {
    return this._ph_response_step;
  }

  private _updatePhResponseStep() {
    if (this.ph_pending_flag) {
      this._ph_response_step = 1;
    }
    if (this.ph_approved_flag) {
      this._ph_response_step = 2;
    }
    if (this.ph_paid_flag) {
      this._ph_response_step = 3;
    }
    if (this.ph_declined_flag) {
      this._ph_response_step = 4;
    }
    if (!this.ph_authorised_flag) {
      this._ph_response_step = 5;
    }
  }

  private _cleanDate(date: Date): Date {
    try {
      date.setHours(0, 0, 0, 0);
      return date;
    }
    catch (err) {
      return date;
    }
  }

}
