
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from './../../components/components.module';

import { NavComponent } from './nav/nav.component';
import { InvComponent } from './inv/inv.component';
import { PhComponent } from './ph/ph.component';

import { SplashComponent } from './splash/splash.component';
import { TeamSelectComponent } from './team-select/team-select.component';

export const CORE_CONFIG: any[] = [
  {
    name: 'app',
    abstract: true,
    component: NavComponent,
  },
  {
    name: 'app.ph',
    abstract: true,
    component: PhComponent
  },
  {
    name: 'app.inv',
    url: '/inv',
    abstract: true,
    component: InvComponent
  },
  {
    name: 'app.ph.teamSelect',
    url: '/teamSelect',
    component: TeamSelectComponent
  },
  {
    name: 'splash',
    url: '/splash',
    component: SplashComponent,
    params: {
      refreshStateName: null,
      refreshStateParams: null,
      checkOnboard: null
    }
  }
];

@NgModule({
  declarations: [
    NavComponent,
    InvComponent,
    PhComponent,
    TeamSelectComponent,
    SplashComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    UIRouterModule.forChild({ states: CORE_CONFIG })
  ]
})
export class CoreModule { }
