import { Injectable } from '@angular/core';

import { StateDataService, Auth, SubAuth, PhAuth, InvAuth, AppProduct } from './../state-data/state-data.service';

import { env } from '../../../environments/environment';
import * as _ from 'lodash';

declare var Base64: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static readonly defaultErrorMessage: 'We\'re having trouble connecting to the network. If this continues to happen please, contact support.';

  private _auth: Auth = {
    SUB: {
      return_session_key: null,
      return_login_token: null,
      user_access_company_key: null,
    },
    PH: {
      session_key: null,
      company_code: null
    },
    INV: {
      session_key: null,
      company_key: null
    }
  };

  private readonly _trace: string = AuthService.generateUUID();

  constructor(
    public stateDataService: StateDataService
  ) {
    this._auth = this.stateDataService.auth;
  }

  //////////////////////////////////////////

  goApplet(state: string, params: any = null, new_tab: boolean = false) {
    let url = env.subscription.app_url;
    if (state === 'login') {
      new_tab = false;
      url += 'login' + this._getSubUrlLoginDestination();

      if (params) {
        url += '&encoded_params=' + Base64.encode(JSON.stringify(params));
      }
    }
    else {
      url += 'loginExternal?urlParams=' + Base64.encode(
        JSON.stringify({
          session_key: this._auth.SUB.return_session_key,
          state,
          params
        })
      );
    }

    if (!new_tab) {
      window.location.href = url;
    }
    else {
      window.open(url, '_blank');
    }
  }

  login(
    sub_auth: SubAuth = null,
    ph_auth: PhAuth = null,
    inv_auth: InvAuth = null
  ): void {
    if (!!sub_auth) {
      this._auth.SUB = {
        return_session_key: sub_auth.return_session_key,
        return_login_token: sub_auth.return_login_token,
        user_access_company_key: sub_auth.user_access_company_key,
      };
    }
    if (!!ph_auth) {
      this._auth.PH = {
        session_key: ph_auth.session_key,
        company_code: ph_auth.company_code
      };
    }
    if (!!inv_auth) {
      this._auth.INV = {
        session_key: inv_auth.session_key,
        company_key: inv_auth.company_key
      };
    }

    this.stateDataService.auth = this._auth;
  }

  tryTokenLogin() {
    if (this._auth.SUB.return_login_token) {
      this.goApplet('login', { token: this._auth.SUB.return_login_token });
    }
    else {
      this.logout();
      this.goApplet('login');
    }
  }

  logout(manual_logout: boolean = false, error_message: string = null) {
    this._auth = {
      SUB: {
        return_session_key: null,
        return_login_token: null,
        user_access_company_key: null,
      },
      PH: {
        session_key: null,
        company_code: null
      },
      INV: {
        session_key: null,
        company_key: null
      }
    };
    this.stateDataService.auth = null; // Clear auth

    if (manual_logout) {
      this.stateDataService.clearAllCachedData();
    }
    this.goApplet('login', { error_message });
  }

  //////////////////////////////////////////

  private _getSubUrlLoginDestination(): string {
    switch (env.product_code) {
      case 'PH':
        return '?destination=PAYHERO_MOBILE';
      case 'INV':
        return '?destination=INVOXY_MOBILE';
    }
    return '';
  }

  hasSession(product: AppProduct) {
    switch (product) {
      case 'SUB':
        return !!this._auth.SUB.return_session_key && !!this._auth.SUB.user_access_company_key;
      case 'PH':
        return !!this._auth.PH.session_key && !!this._auth.PH.company_code;
      case 'INV':
        return !!this._auth.INV.session_key && !!this._auth.INV.company_key;
    }
  }

  getHTTPHeader(product: AppProduct, session_only: boolean = false, include_sub_session: boolean = false): any {
    const httpHeader: any = {
      trace: this._trace
    };

    let header_auth: string;

    switch (product) {
      case 'PH':
        header_auth = session_only ?
          this._auth.PH.session_key :
          (this._auth.PH.company_code + ':' + this._auth.PH.session_key);
        break;
      case 'INV':
        header_auth = session_only ?
          this._auth.INV.session_key :
          (this._auth.INV.company_key + ':' + this._auth.INV.session_key);
        break;
      case 'SUB' :
        header_auth = this._auth.SUB.return_session_key
        break;
    }

    httpHeader.Authorization = 'Basic ' + Base64.encode(header_auth);

    if (include_sub_session) {
      httpHeader.sub_session_key = Base64.encode(this._auth.SUB.return_session_key);
    }

    return httpHeader;
  }

  //////////////////////////////////////////

  get return_login_token(): string {
    return this._auth.SUB.return_login_token || null;
  }

  set return_login_token(value: string) {
    this._auth.SUB.return_login_token = value;
    this.stateDataService.auth = this._auth;
  }

  /////////////////////////////////////////

  /**
   * Generates a unique ID for use with Google login APIs
   *
   * @returns {string}
   */
  static generateUUID() {
    let d = new Date().getTime();

    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

}
