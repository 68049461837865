<div class="ph-expenseCamera-backContainer">
  <button class="-color-white-clear -type-icon -size-icon-lg"
          id="goBack"
          (click)="goBack()">
    <i class="ion ion-ios-arrow-back"></i>
  </button>
</div>

<div class="ph-expenseCamera-skipContainer"
     *ngIf="!fromExpenseEdit">
  <button class="-color-white-clear -size-text-lg"
          id="skipPhoto"
          (click)="confirmExpense($event, true)">
    Skip
  </button>
</div>

<ion-content id="expenseCameraContent">

  <div class="ph-spinner"
       *ngIf="!pageInitialised || uploadingPhoto">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-expenseCamera-imgContainer">

    <div class="ph-emptyMessage"
         *ngIf="pageInitialised && !uploadingPhoto">
      <i class="ion ion-md-paper"></i>
      <div>Tap to add photo</div>
    </div>

    <form>
      <input id="expenseCameraInput"
             #cameraInput
             (click)="clearErrors()"
             (change)="imageUploaded($event)"
             [hidden]="uploadingPhoto"
             type="file"
             accept="image/*, .pdf">
    </form>
  </div>

  <div class="ph-expenseCamera-error"
       *ngIf="fileSizeError">
    <span>Image Too Large</span>
    <span>Images must be 4MB or smaller</span>
  </div>

</ion-content>
