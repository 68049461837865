import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { AuthService } from './../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public apiService: ApiService
  ) { }

  loadNewReturnLoginToken(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.phPost('useraccesscompany/token', null, { include_sub_session: true })
        .then((result) => {
          if (result?.return_login_token) {
            this.authService.return_login_token = result.return_login_token;
            resolve();
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  tryTokenLogin(refreshStateName: string = null, refreshStateParams: any = null): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!!this.authService.return_login_token) {
        this.authService.goApplet('login', {
          token: this.authService.return_login_token,
          external_state_name: refreshStateName,
          external_state_params: JSON.stringify(refreshStateParams)
        });
      }
      else {
        reject();
      }
    });
  }

}
