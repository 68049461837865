import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UIRouterModule, UIRouter } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';

import { NgbParserFormatterDDMMYYYY } from './classes/ngb-parser-formatter-ddmmyyyy/ngb-parser-formatter-ddmmyyyy';

import { StateChangeService } from './services/state-change/state-change.service';

import { AppComponent } from './app.component';

import { LoginModule } from './pages/login/login.module';
import { CoreModule } from './pages/core/core.module';
import { TimeModule } from './pages/time/time.module';
import { ExpenseModule } from './pages/expense/expense.module';
import { LeaveModule } from './pages/leave/leave.module';
import { PayModule } from './pages/pay/pay.module';

import { InvOnboardModule } from './pages/inv-onboard/inv-onboard.module';
import { InvTimeModule } from './pages/inv-time/inv-time.module';
import { InvExpenseModule } from './pages/inv-expense/inv-expense.module';
import { InvApprovalModule } from './pages/inv-approval/inv-approval.module';
import { InvAccountModule } from './pages/inv-account/inv-account.module';

import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';

export function UIRouterConfig(router: UIRouter) {
  router.urlService.rules.otherwise({ state: 'loginExternal' });
}

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    IonicModule.forRoot(),
    NgbModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    UIRouterModule.forRoot({
      config: UIRouterConfig,
      useHash: true
    }),
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    CoreModule,
    LoginModule,
    TimeModule,
    ExpenseModule,
    LeaveModule,
    PayModule,
    InvOnboardModule,
    InvTimeModule,
    InvExpenseModule,
    InvApprovalModule,
    InvAccountModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbParserFormatterDDMMYYYY }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor(private stateChangeService: StateChangeService) {
    this.stateChangeService.initTransitionListeners();
  }

}
