<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content scroll-fix>

  <div class="ph-spinner"
       *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-timeClock-container"
       *ngIf="!loading">

    <div class="ph-pageHeaderRow">
      <div class="ph-pageHeaderText">
        {{clock.employee.employee_code}}
      </div>
    </div>

    <coloured-item-selector [allItems]="allProjects"
                            [itemType]="'Work'"
                            [itemKeyProperty]="'project_key'"
                            [itemNameProperty]="'project_title'"
                            [itemColourProperty]="'project_colour'"
                            [selectedItem]="selectedProject"
                            (itemSelected)="projectSelected($event)">
    </coloured-item-selector>

    <div class="ph-timeClock-location"
         *ngIf="clock.location_in || clock.address_in">
      <i class="ion ion-ios-pin"></i>
      <span *ngIf="clock.location_in">
        {{clock.location_in.location_name}}
      </span>
      <span *ngIf="!clock.location_in && clock.address_in">
        {{clock.address_in}}
      </span>
    </div>

    <div class="ph-timeClock-date"
         *ngIf="clock"
         [ngStyle]="{ 'margin-top': !clock.location_in && !clock.address_in ? '20px' : '' }">
      {{clock.clock_in_time | date : 'd MMMM,'}}
      <span>{{clock.clock_in_time | date : 'yyyy'}}</span>
    </div>

    <div class="ph-timeClock-timer"
         *ngIf="clock">
      <div class="ph-timeClock-timerIcon"
           *ngIf="timerFlasher && !clock.is_on_break">
      </div>
      <div class="ph-timeClock-timerMain">
        {{clockTimeData.clockTimerSeconds | clockTimerMainPipe}}
      </div>
    </div>

    <div class="ph-timeClock-timer -breakTimer"
         *ngIf="clock && clock.is_on_break">
      <div class="ph-timeClock-timerIcon"
           *ngIf="timerFlasher && clock.is_on_break">
      </div>
      <div class="ph-timeClock-timerMain">
        {{clockTimeData.breakTimerSeconds | clockTimerMainPipe}}
      </div>
    </div>

    <div class="ph-timeClock-btnRow"
         *ngIf="clock">

      <button *ngIf="clock.is_on_break"
              [disabled]="loading"
              (click)="breakOut()"
              class="ph-timeClock-btn btn-play">
        <i class="ion ion-md-play"></i>
        End Break
      </button>

      <button *ngIf="!clock.is_on_break"
              [disabled]="loading || (clockTimeData && clockTimeData.clockExceedsMaxSeconds)"
              (click)="breakIn()"
              class="ph-timeClock-btn btn-pause">
        <i class="ion ion-md-pause"></i>
        <span>Start Break</span>
      </button>

      <button *ngIf="!clock.is_on_break"
              [disabled]="loading"
              (click)="clockOut()"
              class="ph-timeClock-btn btn-stop">
        <i class="ion ion-md-square"></i>
        Clock Out
      </button>

    </div>

    <div class="ph-timeClock-longClockMessage"
         *ngIf="clockTimeData && clockTimeData.clockExceedsMaxSeconds">
      {{longClockMessage}}
    </div>

    <div class="ph-groupedList-container -clockBreaks"
         *ngIf="clock.clock_breaks.length || ((selectedProject.strict_shift_flag && selectedProject.strict_shift_break) || selectedProject.default_break_duration)">

      <div class="ph-groupedList-groupHeader">
        <div class="ph-groupedList-groupHeaderTitle">
          Breaks:
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
      </div>

      <div class="ph-groupedList-item"
           *ngIf="clock.clock_breaks.length === 0 && ((selectedProject.strict_shift_flag && selectedProject.strict_shift_break) || selectedProject.default_break_duration)">
        <div class="-defaultBreakTime">
          Default Break:
        </div>
        <div class="-clockBreakDuration">
          {{(selectedProject.strict_shift_flag ? selectedProject.strict_shift_break :
          selectedProject.default_break_duration) | hoursToTimePipe}}
        </div>
      </div>

      <div class="ph-groupedList-item"
           *ngFor="let cb of clock.clock_breaks"
           [ngClass]="{ '-activeBreak': !cb.end_time}">

        <div class="-clockBreakTime">
          {{cb.start_time | date : 'shortTime'}}
          <span>-</span>
        </div>
        <div class="-clockBreakTime"
             *ngIf="cb.end_time">
          {{cb.end_time | date : 'shortTime'}}
        </div>
        <div class="-clockBreakDuration"
             *ngIf="cb.end_time">
          {{cb.break_duration | hoursToTimePipe}}
        </div>
        <div class="-clockBreakDuration"
             *ngIf="!cb.end_time">
          {{clockTimeData.breakTimerSeconds | clockTimerMainPipe}}
        </div>

      </div>

    </div>

    <div class="ph-groupedList-container -clockNotes">

      <div class="ph-groupedList-groupHeader">
        <div class="ph-groupedList-groupHeaderTitle">
          Notes:
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
      </div>

      <div class="ph-groupedList-item"
           *ngFor="let clockNote of clock.clock_notes">

        <div class="-noteContainer">
          <p>{{clockNote.note}}</p>
          <button class="-color-grey-clear -type-icon"
                  (click)="deleteNote(clockNote)">
            <i class="ion ion-md-close"></i>
          </button>
        </div>
      </div>

      <div class="ph-groupedList-item"
           *ngIf="addingNote">
        <textarea #noteTextArea
                  class="text-input ph-textArea"
                  placeholder="Add a note..."
                  [(ngModel)]="note">
        </textarea>
      </div>

      <div class="ph-groupedList-item -noteButtonsRow">
        <button class="-color-success -type-icon -size-btn-lg"
                (click)="newNote()"
                *ngIf="!addingNote">
          <i class="ion ion-md-add"></i>
        </button>
        <button class="-color-success -type-icon -size-btn-lg -noteSave"
                (click)="saveNote()"
                *ngIf="addingNote">
          <i class="ion ion-md-checkmark"></i>
        </button>
        <button class="-color-danger -type-icon -size-btn-lg -noteCancel"
                (click)="cancelNote()"
                *ngIf="addingNote">
          <i class="ion ion-md-close"></i>
        </button>
      </div>

    </div>

  </div>

</ion-content>
