<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Approval Requests</ion-title>
  </ion-toolbar>
</ion-header>

<popup-menu [items]="menuItems"
            (itemSelected)="menuItemSelected($event)">
</popup-menu>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshApprovalRequests($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="inv-approvalManager-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible &&
                !monthChanging &&
                monthsApprovalRequests !== null &&
                !monthsApprovalRequests[0].approvalRequests.length &&
                !monthsApprovalRequests[1].approvalRequests.length">
      <i class="ion ion-md-paper-plane"></i>
      <div>No approval requests this month</div>
    </div>

    <div class="inv-approvalManager-monthContainer"
         *ngIf="!yearGridVisible && !monthChanging && monthsApprovalRequests !== null">

      <div class="inv-approvalManager-approvalRequestGroup"
           *ngFor="let approvalRequestGroup of monthsApprovalRequests; let i = index">

        <div class="ph-groupedList-container"
             [ngClass]="{ '-complete': i === 1 }"
             *ngFor="let approvalRequest of approvalRequestGroup.approvalRequests">

          <div class="ph-groupedList-groupHeader">
            <div class="ph-groupedList-groupHeaderTitle">
              {{approvalRequest.display_name}}
            </div>
            <div class="ph-groupedList-groupHeaderLine"></div>
            <div class="ph-groupedList-groupHeaderTitle -titleRight"
                 *ngIf="i === 0">
              <ph-checkbox [btnSmall]="true"
                           [(ngModel)]="selectedApprovalRequests[approvalRequest.approval_request_key]"
                           (ngModelChange)="updateSelectedApprovalRequestCount()">
              </ph-checkbox>
            </div>
            <div class="ph-groupedList-groupHeaderTitle -titleRight"
                 *ngIf="i === 1">
              {{approvalRequestGroup.status}}
            </div>
          </div>

          <div class="ph-groupedList-groupHeader -subHeader">
            <div class="ph-groupedList-groupHeaderTitle">
              {{approvalRequest.from_date | date : 'd MMM'}} -
              {{approvalRequest.to_date | date : 'd MMM'}},
              <span>{{approvalRequest.to_date | date : 'yyyy'}}</span>
            </div>
          </div>

          <div class="ph-groupedList-item"
               *ngFor="let approvalProject of approvalRequest.approval_projects"
               (click)="viewApprovalRequest(approvalProject)">

            <div class="inv-approvalManager-initialsIcons">
              <div class="inv-approvalManager-initialsIcon"
                   *ngFor="let approvalUser of approvalProject.approval_users | slice:0:3"
                   [ngClass]="{
                '-approved': approvalUser.approved_flag,
                '-rejected': approvalUser.rejected_flag }">
                {{approvalUser.initials}}
              </div>
            </div>

            <div class="ph-groupedList-itemTitle">
              <span>{{approvalProject.project_name}}</span>
            </div>

            <div class="ph-groupedList-itemDetails"
                 *ngFor="let summary of approvalProject.segment_summary">
              <div *ngIf="summary.unit_type !== 'expense' && summary.unit_type !== 'hours'">
                {{summary.quantity}} <span>{{summary.unit_type}}</span>
              </div>
              <div *ngIf="summary.unit_type === 'hours'">
                {{summary.quantity | hoursToTimePipe}} <span>{{summary.unit_type}}</span>
              </div>
              <div *ngIf="summary.unit_type === 'expense'">
                {{summary.quantity | currency : currencySymbol }}
              </div>
            </div>

            <i class="ion ion-ios-arrow-forward"></i>

          </div>
        </div>
      </div>

    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
