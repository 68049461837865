<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
    <ion-title>
      Request Approval
    </ion-title>
  </ion-toolbar>
</ion-header>

<slider-button [btnShort]="false"
               [btnSmall]="true"
               [btnText]="'Send Request'"
               [btnIconName]="'ios-paper-plane'"
               [btnType]="'primary'"
               (btnClicked)="requestApproval()">
</slider-button>

<ion-content>
  <div class="inv-approvalRequest-totals">
    <div class="inv-approvalRequest-total"
         [ngClass]="{ '-hoursOnly': totalDays === 0 }">
      <div class="-totalLabel">
        Total Hours
      </div>
      <div class="-totalValue">
        {{totalHours | hoursToTimePipe}}
      </div>
    </div>
    <div class="inv-approvalRequest-total"
         *ngIf="totalDays !== 0">
      <div class="-totalLabel">
        Total Days
      </div>
      <div class="-totalValue">
        {{totalDays.toFixed(2)}}
      </div>
    </div>
  </div>

  <div class="inv-approvalRequest-projectContainer"
       *ngFor="let project of groupedSegments; let l = last;"
       [ngStyle]="{ 'margin-bottom': l ? '90px' : '' }">

    <div class="inv-approval-projectHeader">
      {{project.project_name}}
      <span>{{project.client_name}}</span>
    </div>

    <div class="ph-groupedList-container"
         *ngFor="let day of project.days">

      <div class="ph-groupedList-groupHeader">
        <div class="ph-groupedList-groupHeaderTitle">
          {{day.date | date: 'EEE, '}}
          <span>{{day.date | date: 'd LLL'}}</span>
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
      </div>

      <div class="ph-groupedList-item"
           *ngFor="let segment of day.segments">

        <div class="ph-groupedList-itemColor"
             [ngStyle]="{'background-color': segment.colour}">
        </div>

        <div class="ph-groupedList-itemTitle">
          <span>{{segment.work_name}}</span>
        </div>
        <div class="ph-groupedList-itemDetails"
             *ngIf="!segment.unit_flag">
          <div>{{segment.start_time | date : 'shortTime'}} - {{segment.end_time | date : 'shortTime'}}</div>

          <span *ngIf="segment.break_duration">
            ({{segment.break_duration | hoursToTimePipe}} Break)
          </span>
        </div>
        <div class="ph-groupedList-itemDetails"
             *ngIf="segment.credit_flag">
          <span>CREDIT</span>
        </div>

        <div class="ph-groupedList-itemValueRight">
          <div *ngIf="!segment.unit_flag">
            {{segment.duration | hoursToTimePipe}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type === 'hours'">
            {{segment.units | hoursToTimePipe}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type === 'expense'">
            {{segment.units | currency : currencySymbol}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type !== 'expense' && segment.unit_type !== 'hours'">
            {{segment.units}} <span>{{segment.unit_type}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>

</ion-content>
