import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inv-approval',
  templateUrl: './inv-approval.component.html',
  styleUrls: ['./inv-approval.component.scss']
})
export class InvApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
