import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TeamService } from '../team/team.service';
import { CompanyService } from '../company/company.service';
import { TimeUtilService } from './../time-util/time-util.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  allProjects: any[] = [];
  allInactiveProjects: any[] = [];

  allExpenseProjects: any[] = [];
  allInactiveExpenseProjects: any[] = [];

  allTimesheetProjects: any[] = [];
  allInactiveTimesheetProjects: any[] = [];

  allTimesheetHoursProjects: any[] = [];
  allInactiveTimesheetHoursProjects: any[] = [];

  allTimesheetUnitsProjects: any[] = [];
  allInactiveTimesheetUnitsProjects: any[] = [];

  allProjectsMap: any = {};

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public teamService: TeamService,
    public companyService: CompanyService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadAllProjects()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.allProjects = [];
    this.allInactiveProjects = [];

    this.allExpenseProjects = [];
    this.allInactiveExpenseProjects = [];

    this.allTimesheetProjects = [];
    this.allInactiveTimesheetProjects = [];

    this.allTimesheetHoursProjects = [];
    this.allInactiveTimesheetHoursProjects = [];

    this.allTimesheetUnitsProjects = [];
    this.allInactiveTimesheetUnitsProjects = [];

    this.allProjectsMap = {};
    this.serviceSetup = false;
  }

  getAllProjects(includeArchived: boolean = false): any[] {
    return includeArchived ?
      ProjectService.sortProjects(_.cloneDeep(this.allProjects.concat(this.allInactiveProjects))) :
      ProjectService.sortProjects(_.cloneDeep(this.allProjects));
  }

  getAllExpenseProjects(includeArchived: boolean = false): any[] {
    return includeArchived ?
      ProjectService.sortProjects(_.cloneDeep(this.allExpenseProjects.concat(this.allInactiveExpenseProjects))) :
      ProjectService.sortProjects(_.cloneDeep(this.allExpenseProjects));
  }

  getAllTimesheetProjects(includeArchived: boolean = false): any[] {
    return includeArchived ?
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetProjects.concat(this.allInactiveTimesheetProjects))) :
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetProjects));
  }

  getAllTimesheetHoursProjects(includeArchived: boolean = false): any[] {
    return includeArchived ?
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetHoursProjects.concat(this.allInactiveTimesheetHoursProjects))) :
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetHoursProjects));
  }

  getAllTimesheetUnitsProjects(includeArchived: boolean = false): any[] {
    return includeArchived ?
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetUnitsProjects.concat(this.allInactiveTimesheetUnitsProjects))) :
      ProjectService.sortProjects(_.cloneDeep(this.allTimesheetUnitsProjects));
  }

  getSingleProject(project_key: number): any {
    return _.cloneDeep(this.allProjectsMap[project_key]) || null;
  }

  loadAllProjects() {
    return new Promise((resolve, reject) => {
      const teamProjectKeys = this.getTeamProjectKeysMap();
      const employeeInAtLeastOneTeam = this.teamService.getAllTeams().length > 0;

      this.apiService.phGet('project')
        .then((data) => {
          this.allProjects = [];
          this.allInactiveProjects = [];

          this.allExpenseProjects = [];
          this.allInactiveExpenseProjects = [];

          this.allTimesheetProjects = [];
          this.allInactiveTimesheetProjects = [];

          this.allTimesheetHoursProjects = [];
          this.allInactiveTimesheetHoursProjects = [];

          this.allProjectsMap = {};

          for (let project of data) {
            project = ProjectService.setupProject(project);

            // Project that doesn't currently exist on any of the logged in employee's teams
            project.non_team_project_flag = employeeInAtLeastOneTeam && !teamProjectKeys[project.project_key];
            // Project that doesn't currently exist on any of the logged in employee's teams or
            // the project exists on a team that the employee doesn't have time entry access for
            project.non_team_time_project_flag = project.non_team_project_flag ||
              (teamProjectKeys[project.project_key] && !teamProjectKeys[project.project_key].time);

            this.allProjectsMap[project.project_key] = project;

            if (project.active_flag && !project.non_team_project_flag) {
              this.allProjects.push(project);
            }
            else {
              this.allInactiveProjects.push(project);
            }

            if (project.expense_pay_code_key) {

              if (project.active_flag && !project.non_team_project_flag) {
                this.allExpenseProjects.push(project);
              }
              else {
                this.allInactiveExpenseProjects.push(project);
              }
            }

            if (project.pay_code_sub_type === 'Regular Earnings' ||
              project.pay_code_sub_type === 'Other Earnings') {

              if (project.active_flag && !project.non_team_time_project_flag) {
                this.allTimesheetProjects.push(project);
              }
              else {
                this.allInactiveTimesheetProjects.push(project);
              }
            }

            if (project.pay_code_unit === 'Hours') {

              if (project.active_flag && !project.non_team_time_project_flag) {
                this.allTimesheetHoursProjects.push(project);
              }
              else {
                this.allInactiveTimesheetHoursProjects.push(project);
              }
            }
            else if (project.pay_code_unit !== null) {
              if (project.active_flag && !project.non_team_time_project_flag) {
                this.allTimesheetUnitsProjects.push(project);
              }
              else {
                this.allInactiveTimesheetUnitsProjects.push(project);
              }
            }
          }

          ProjectService.sortProjects(this.allProjects);
          ProjectService.sortProjects(this.allInactiveProjects);

          ProjectService.sortProjects(this.allExpenseProjects);
          ProjectService.sortProjects(this.allInactiveExpenseProjects);

          ProjectService.sortProjects(this.allTimesheetProjects);
          ProjectService.sortProjects(this.allInactiveTimesheetProjects);

          ProjectService.sortProjects(this.allTimesheetHoursProjects);
          ProjectService.sortProjects(this.allInactiveTimesheetHoursProjects);

          ProjectService.sortProjects(this.allTimesheetUnitsProjects);
          ProjectService.sortProjects(this.allInactiveTimesheetUnitsProjects);

          resolve(this.allProjects);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRecentEmployeeProjects(employee_key: number) {
    return new Promise((resolve, reject) => {
      const params = {
        employee_key
      };

      this.apiService.phGet('employee/project', params)
        .then((data) => {
          resolve(ProjectService.setupRecentEmployeeProjects(data));
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Returns an array of default project_keys for a single employee
   */
  getSingleEmployeeDefaultProjectKeys(employee_key: number) {
    return new Promise((resolve) => {

      if (!employee_key) {
        resolve([]);
      }
      else {
        const defaultTeamProjectKeys = this.teamService.getSingleEmployeeDefaultTeamProjectKeys(employee_key);

        this.getRecentEmployeeProjects(employee_key)
          .then((data) => {
            const recentTimesheetProjectKeys = data[employee_key] || [];
            const keys = UtilService.concatUnique(recentTimesheetProjectKeys, defaultTeamProjectKeys);

            // Filter out non team projects for non-admins (Portal/Mobile)
            if (!this.companyService.isAdmin()) {
              for (let i = keys.length - 1; i >= 0; i--) {
                const key = keys[i];

                if (this.allProjectsMap[key] && this.allProjectsMap[key].non_team_project_flag) {
                  keys.splice(i, 1);
                }
              }
            }

            resolve(keys);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  }

  filterProjectsForEmployee(employe_key: number, projects: any[]): any[] {
    const teams = this.teamService.getAllTeams();
    const employeTeamProjectKeys = {};
    const employeeProjects = [];

    for (const team of teams) {
      // Check if the employee is a memeber of the current team
      const teamEmployee = _.find(team.employees, (emp) => {
        return emp.employee_key === employe_key;
      });

      // If the employee is a team memeber, add all team projects to the employeTeamProjectKeys map
      if (teamEmployee) {
        for (const teamProject of team.projects) {
          employeTeamProjectKeys[teamProject.project_key] = true;
        }
      }
    }

    // Filter projects to those that exist in the employeTeamProjectKeys map
    for (const project of projects) {
      if (employeTeamProjectKeys[project.project_key]) {
        employeeProjects.push(project);
      }
    }

    return employeeProjects;
  }

  /**
   * Returns a map of project_keys for all projects that're on one or more of the teams that the logged in employee is a member of
   */
  getTeamProjectKeysMap() {
    const teams = this.teamService.getAllTeams();
    const teamProjectKeys = {};

    for (const team of teams) {
      const team_employee = _.find(team.employees, (emp) => {
        return emp.employee_key === this.companyService.getEmployeeKey();
      });

      if (team_employee) {

        for (const teamProject of team.projects) {

          if (teamProjectKeys[teamProject.project_key]) {
            teamProjectKeys[teamProject.project_key].time =
              (teamProjectKeys[teamProject.project_key].time) || (!team_employee.disabled_time_flag || team_employee.manager_flag);
          }
          else {
            teamProjectKeys[teamProject.project_key] = {
              team: true,
              time: !team_employee.disabled_time_flag
            };
          }
        }
      }
    }

    return teamProjectKeys;
  }

  static setupRecentEmployeeProjects(employees: any[]): any {
    const recentEmployeeProjects = {};

    for (const employee of employees) {
      const employeeProjectKeys = [];

      for (const project of employee.project_key_list) {
        employeeProjectKeys.push(project.project_key);
      }

      if (employeeProjectKeys.length) {
        recentEmployeeProjects[employee.employee_key] = employeeProjectKeys;
      }
    }

    return recentEmployeeProjects;
  }

  /**
   * Sorts an array of projects by project_title
   */
  static sortProjects(projects: any[]): any[] {
    return projects.sort((a: any, b: any) => {
      const projectTitleA = a.project_title.toUpperCase();
      const projectTitleB = b.project_title.toUpperCase();

      if (projectTitleA > projectTitleB) {
        return 1;
      }
      else if (projectTitleA < projectTitleB) {
        return -1;
      }
      return 0;
    });
  }

  /**
   * Sets up non primitive project properties
   */
  static setupProject(project: any): any {
    project.project_colour = UtilService.intToHexColor(project.project_colour);

    if (project.default_start_time) {
      project.default_start_time = TimeUtilService.hoursMinsStringToDate(project.default_start_time);
    }
    if (project.default_end_time) {
      project.default_end_time = TimeUtilService.hoursMinsStringToDate(project.default_end_time);
    }

    return project;
  }

  static getProjectFromList(project_key: number, projects: any[]): any {
    for (const project of projects) {
      if (project.project_key === project_key) {
        return project;
      }
    }
    return null;
  }

}
