import { ApiService } from './../api/api.service';

import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { InvProjectService } from './../inv-project/inv-project.service';
import { InvCompanyService } from './../inv-company/inv-company.service';
import { InvUserService } from './../inv-user/inv-user.service';
import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';
import { InvUserUtilService } from './../inv-user-util/inv-user-util.service';

import { InvSegment } from './../../models/segment/inv-segment';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvTimeService {

  constructor(
    public utilService: UtilService,
    public invProjectService: InvProjectService,
    public invCompanyService: InvCompanyService,
    public invUserService: InvUserService,
    public apiService: ApiService
  ) { }

  /**
   * Get single segment
   */
  loadSegment(segment_key: number) {
    return new Promise<InvSegment>((resolve, reject) => {

      const params = {
        segment_key
      };

      this.apiService.invGet('segment', params)
        .then((segments) => {
          resolve(this.setupSegment(segments[0]));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get duration and unit segments
   */
  loadSegments(start_date: Date, end_date: Date, user_key: number = null) {
    return new Promise<InvSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        user_key
      };

      if (!user_key) {
        delete params.user_key;
      }

      this.apiService.invGet('segment', params)
        .then((segments) => {
          segments = this.setupSegments(segments);
          this.invProjectService.updateRecentProjectTasks(segments);
          resolve(segments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get duration segments
   */
  loadDurationSegments(start_date: Date, end_date: Date) {
    return new Promise<InvSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        user_key: this.invCompanyService.getUserKey()
      };

      this.apiService.invGet('segment/duration', params)
        .then((segments) => {
          segments = this.setupSegments(segments);
          this.invProjectService.updateRecentProjectTasks(segments);
          resolve(segments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get non-duration unit segments
   */
  loadUnitSegments(start_date: Date, end_date: Date) {
    return new Promise<InvSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        user_key: this.invCompanyService.getUserKey()
      };

      this.apiService.invGet('segment/unit', params)
        .then((segments) => {
          segments = this.setupSegments(segments);
          this.invProjectService.updateRecentProjectTasks(segments);
          resolve(segments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // TODO: check that error handling is working as expected
  /**
   * Create/Update/Delete segments
   */
  saveSegments(segs: InvSegment[]) {
    return new Promise((resolve, reject) => {
      const data = {
        segments: InvTimeService.formatSegmentsForPosting(segs)
      };

      this.apiService.invPost('segment', data)
        .then((segments) => {

          if (segments.length === 1 &&
            segments[0] && segments[0].error) {

            this.utilService.toastMessage(segments[0].error);
            resolve(this.setupSegments(segments));
          }
          else {
            // If we posted multiple segments, check if any of them had errors
            for (const seg of segments) {
              if (seg && seg.error) {

                if (seg.error === 'This entry overlaps with another and cannot be saved.') {
                  this.utilService.toastMessage('One or more entries overlap with existing time and could not be saved');
                  resolve(this.setupSegments(segments));
                  break;
                }
                else {
                  this.utilService.toastMessage(null);
                  reject(this.setupSegments(segments));
                  break;
                }
              }
            }
          }

          resolve(this.setupSegments(segments));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * Copy time and unit segments from the previous week for the given list of user
   */
  copyPreviousWeeksSegments(user_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const lastMonday = moment().day('Monday').toDate();

      const params = {
        user_key_list: [user_key],
        start_date: TimeUtilService.dateToDateString(lastMonday, null),
        copy_units: true
      };

      this.apiService.invPost('segment/copylastweek', params)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setupSegments(segments: any[], ignoreExpensesEnabled: boolean = false): any[] {
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i] ? this.setupSegment(segments[i], ignoreExpensesEnabled) : null;

      // Valid segment
      if (segment !== null) {
        segments[i] = segment;
      }
      // Remove invalid segment
      else {
        segments.splice(i, 1);
      }
    }

    return segments;
  }

  setupSegment(segment: any, ignoreExpensesEnabled: boolean = false): any {
    const project = this.invProjectService.getSingleProject(segment.project_key);

    if (project) {
      const user = InvUserUtilService.getUserFromList(segment.user_key, project.users);
      const task = InvTaskUtilService.getTaskFromList(segment.task_key, project.tasks);

      if (task && user) {
        return new InvSegment(
          parseInt(segment.segment_key, null),
          segment.deleted_flag,
          segment.row_version,
          segment.unit_flag,
          segment.unit_flag && segment.units < 0,
          TimeUtilService.dateTimeStringToDate(segment.segment_date, null, false),
          TimeUtilService.dateTimeStringToDate(segment.start_time, null, false),
          TimeUtilService.dateTimeStringToDate(segment.end_time, null, false),
          segment.break_duration,
          segment.units,
          parseInt(segment.company_key, null),
          segment.notes,
          project,
          user.user_key,
          task.task_key,
          ignoreExpensesEnabled ? false : this.invCompanyService.expensesEnabled(),
          segment.new_flag,
          segment.pending_flag,
          segment.approved_flag,
          segment.invoiced_flag,
          segment.paid_flag,
          task.admin_only_flag
        );
      }
    }
    return null;
  }

  initNewSegment(user_key: number, project_key: number, task_key: number, segment_date: Date, credit_flag: boolean = false) {
    const project = this.invProjectService.getSingleProject(project_key);

    if (project) {
      const user = InvUserUtilService.getUserFromList(user_key, project.users);
      const task = InvTaskUtilService.getTaskFromList(task_key, project.tasks);

      const unit_flag = credit_flag || (task.unit_type || project.rate_type) !== 'hours';

      if (task && user) {
        return new InvSegment(
          null,
          false,
          null,
          unit_flag,
          credit_flag,
          segment_date,
          null,
          null,
          null,
          0,
          this.invCompanyService.getCurrentCompany().company_key,
          '',
          project,
          user.user_key,
          task.task_key,
          this.invCompanyService.expensesEnabled(),
        );
      }
    }
    return null;
  }

  /**
   * Format non primitive properties into strings and numbers
   */
  static formatSegmentsForPosting(segments: any): any[] {
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i];

      // Valid segment
      if (segment !== null) {
        segments[i] = segment.formatSegmentForPosting();
      }
      // Remove invalid segment
      else {
        segments.splice(i, 1);
      }
    }

    return segments;
  }

}
