<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button *ngIf="employee.employee_key !== loggedInEmployeeKey"
                   [statesToSkip]="backSkipStates">
      </back-button>
      <menu-button *ngIf="employee.employee_key === loggedInEmployeeKey"></menu-button>
    </ion-buttons>

    <ion-title *ngIf="employee">
      {{employee.employee_key === loggedInEmployeeKey ? 'My Time' : employee.employee_code}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<popup-menu [items]="menuItems"
            (itemSelected)="menuItemSelected($event)">
</popup-menu>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshTime($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-spinner"
       *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <time-clock *ngIf="clock"
              [clock]="clock"
              (viewClockClicked)="viewClock($event)"
              (breakInClicked)="breakIn($event)"
              (breakOutClicked)="breakOut($event)"
              (clockOutClicked)="clockOut($event)">
  </time-clock>

  <div class="ph-emptyMessage"
       *ngIf="!groupedTimeHistory.length && !loading">
    <i class="ion ion-md-time"></i>
    <div>No recent time history</div>
  </div>

  <div class="ph-timeEmployee-monthContainer"
       *ngFor="let month of groupedTimeHistory">

    <div class="ph-pageHeaderRow">
      <div class="ph-pageHeaderText">
        {{month.month | date: 'LLLL,'}}
        <span>{{month.month | date: 'yyyy'}}</span>
      </div>
    </div>

    <div class="ph-groupedList-container"
         *ngFor="let day of month.days">

      <div class="ph-groupedList-groupHeader">
        <i class="ion-ios-lock lock"
            *ngIf="day.approved"></i>

        <div class="ph-groupedList-groupHeaderTitle">
          {{day.day | date: 'EEE, '}}
          <span>{{day.day | date: 'd LLL'}}</span>
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
        <div class="ph-groupedList-groupHeaderTitle -titleRight"
             *ngIf="day.public_holiday !== null">
          {{day.public_holiday.name}}
        </div>
      </div>

      <div class="ph-groupedList-item"
           *ngFor="let segment of day.segments"
           (click)="editSegment(segment)">

        <div class="ph-groupedList-itemColor"
             [ngStyle]="{'background-color': segment.colour}">
        </div>

        <div class="ph-groupedList-itemTitle">
          <span>{{segment.work_name}}</span>
          <i class="ion ion-md-lock"
             *ngIf="segment.is_locked">
          </i>
          <i class="ion ion-md-time"
             *ngIf="segment.clock_in_time && segment.clock_out_time">
          </i>
          <span class="ph-paidTag"
                *ngIf="segment.paid">
            Paid
          </span>
        </div>

        <div class="ph-groupedList-itemDescription"
             *ngIf="segment.description">
          {{segment.description}}
        </div>

        <div class="ph-groupedList-itemDetails">
          <div *ngIf="!segment.unit_flag">
            {{segment.duration | hoursToTimePipe}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type === 'hours'">
            {{segment.units | hoursToTimePipe}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type === '$'">
            {{segment.units | currency}}
          </div>
          <div *ngIf="segment.unit_flag && segment.unit_type !== '$' && segment.unit_type !== 'hours'">
            {{segment.units}} {{segment.unit_type}}
          </div>
          <span *ngIf="segment.credit_flag">
            CREDIT
          </span>
          <span *ngIf="!segment.unit_flag">
            ({{segment.start_time | date : 'shortTime'}} -
            {{segment.end_time | date : 'shortTime'}})
          </span>
        </div>

        <i class="ion ion-ios-arrow-forward">
        </i>

      </div>

    </div>

  </div>

  <ion-infinite-scroll (ionInfinite)="loadMoreTimeHistory($event)"
                       position="bottom"
                       threshold="30px">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>
