import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { InvProjectService } from './../inv-project/inv-project.service';
import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';
import { InvUserUtilService } from './../inv-user-util/inv-user-util.service';
import { InvCompanyService } from '../inv-company/inv-company.service';
import { ExpenseService } from './../expense/expense.service';

import { InvExpense } from './../../models/expense/inv-expense';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvExpenseService {

  constructor(
    public utilService: UtilService,
    public invProjectService: InvProjectService,
    public invCompanyService: InvCompanyService,
    public apiService: ApiService
  ) { }

  /**
   * Get single expense
   */
  loadExpense(segment_key: number) {
    return new Promise<InvExpense>((resolve, reject) => {

      const params = {
        segment_key
      };

      this.apiService.invGet('expense', params)
        .then((data) => {
          if (data.length) {
            resolve(this.setupExpenses(data)[0]);
          }
          else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadMonthOfExpenses(month: Date, user_key: number = null) {
    const start = _.cloneDeep(month);
    const end = _.cloneDeep(month);

    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    return this.loadExpenses(start, end, user_key);
  }

  loadYearOfExpenses(year: any, user_key: number = null) {
    year = parseInt(year, null);

    const start = new Date();
    start.setFullYear(year, 0, 1);

    const end = _.cloneDeep(start);
    end.setFullYear(year + 1);
    end.setDate(0);

    return this.loadExpenses(start, end, user_key);
  }

  /**
   * Get duration and unit expenses
   */
  loadExpenses(start_date: Date, end_date: Date, user_key: number = null) {
    return new Promise<InvExpense[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        user_key
      };

      if (!user_key) {
        delete params.user_key;
      }

      this.apiService.invGet('expense', params)
        .then((expenses) => {
          expenses = this.setupExpenses(expenses);
          resolve(expenses);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  saveExpense(expense: InvExpense, documentBlob: Blob){
    if (expense.inv_locked) { return this.savePayHeroExpense(expense.segment_key, expense.ph_approval_date, expense.ph_declined_date); }
    else { return this.saveInvoxyExpense(expense, documentBlob); }
  }

  saveInvoxyExpense(expense: InvExpense, documentBlob: Blob) {
    return new Promise<any>((resolve, reject) => {

      const data = expense.formatExpenseForPosting();

      if (expense.segment_key !== null){
        data.append('update_user_key', this.invCompanyService.getUserKey());
      }

      if (documentBlob) {
        data.append('file', documentBlob);
        data.append('file_name', 'Expense_' + (expense.user.display_name.replace(/\s/g, '_')) + '_' + moment().format('YYYY-MM-DD') + '.jpeg');
      }
      else if (expense.segment_key && !expense.timesheet_attachment_key){
        data.append('timesheet_attachment_deleted_flag', 'true');
      }

      this.apiService.invPost('expense', data)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  savePayHeroExpense(segment_key: number, approval_date: Date, declined_date: Date) {
    return new Promise<any>((resolve, reject) => {
      const data = {
          segment_key,
          approval_date,
          declined_date
      };

      this.apiService.invPost('payhero/syncExpense', data)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err.data);
      });
    });
  }

  setupExpenses(expenses: any[]): InvExpense[] {
    for (let i = expenses.length - 1; i >= 0; i--) {
      const expense = expenses[i] ? this.setupExpense(expenses[i]) : null;

      // Valid expense
      if (expense !== null) {
        expenses[i] = expense;
      }
      // Remove invalid expenses
      else {
        expenses.splice(i, 1);
      }
    }

    return expenses;
  }

  setupExpense(expense: any): InvExpense {
    const project = this.invProjectService.getSingleProject(expense.project_key);

    if (project) {
      const user = InvUserUtilService.getUserFromList(expense.user_key, project.users);
      const task = InvTaskUtilService.getTaskFromList(expense.task_key, project.tasks);

      if (task && user) {
        return new InvExpense(
          expense.segment_key,
          expense.ph_expense_key,
          project,
          user.user_key,
          task.task_key,
          expense.timesheet_attachment_key,
          expense.timesheet_attachment_name,
          expense.timesheet_attachment_url,
          expense.row_version,
          expense.ph_employee_manageable_flag,
          expense.pending_flag,
          expense.approved_flag,
          expense.invoiced_flag,
          expense.paid_flag,
          expense.expense_amount,
          TimeUtilService.dateTimeStringToDate(expense.expense_date, null, false),
          expense.description,
          expense.ph_authorised_flag,
          expense.ph_paid_flag,
          TimeUtilService.dateTimeStringToDate(expense.ph_approval_date, null, false),
          TimeUtilService.dateTimeStringToDate(expense.ph_declined_date, null, false),
          expense.ph_employee_key,
          expense.ph_project_key
        );
      }
    }
    return null;
  }

  initNewExpense(
    user_key: number, project_key: number, task_key: number, expense_date: Date,
    timesheet_attachment_key: number = null, timesheet_attachment_name: string = null, timesheet_attachment_url: string = null
  ): InvExpense {
    const isAdmin = this.invCompanyService.isAdminOrOwner();
    const loggedInUserKey = this.invCompanyService.getUserKey();
    const project = this.invProjectService.getSingleProject(project_key);

    if (project) {
      const user = InvUserUtilService.getUserFromList(user_key, project.users);
      const task = InvTaskUtilService.getTaskFromList(task_key, project.tasks);

      if (task && user) {
        return new InvExpense(
          null,
          null,
          project,
          user.user_key,
          task.task_key,
          timesheet_attachment_key,
          timesheet_attachment_name,
          timesheet_attachment_url,
          null,
          isAdmin,
          false,
          false,
          false,
          false,
          0,
          expense_date,
          '',
          isAdmin || loggedInUserKey === user.user_key
        );
      }
    }

    return null;
  }

  static groupExpensesByInvStatus(expenses: InvExpense[]): any[] {
    const groupedExpenses = [
      { status: 'Unapproved', expenses: [], responseClass: '-responseUnapproved', isInvoxyStatus: true },
      { status: 'Pending Approval', expenses: [], responseClass: '-responsePending', isInvoxyStatus: true },
      { status: 'Approved', expenses: [], responseClass: '-responseApproved', isInvoxyStatus: true }
    ];

    for (const expense of expenses) {
      if (expense.inv_new_flag){
        groupedExpenses[0].expenses.push(expense);
      }
      else if (expense.inv_pending_flag){
        groupedExpenses[1].expenses.push(expense);
      }
      else if (expense.inv_approved_flag){
        groupedExpenses[2].expenses.push(expense);
      }
    }

    ExpenseService.sortExpenses(groupedExpenses[0].expenses);
    ExpenseService.sortExpenses(groupedExpenses[1].expenses);
    ExpenseService.sortExpenses(groupedExpenses[2].expenses);

    return groupedExpenses;
  }

  static groupExpensesByPhStatus(expenses: InvExpense[]): any[] {
    const groupedExpenses = [
      { status: 'Pending Approval', expenses: [], responseClass: '-responseDefault', isInvoxyStatus: false },
      { status: 'Approved', expenses: [], responseClass: '-responseApproved', isInvoxyStatus: false },
      { status: 'Declined', expenses: [], responseClass: '-responseDeclined', isInvoxyStatus: false },
      { status: 'Processing', expenses: [], responseClass: '-responseDefault', isInvoxyStatus: false }
    ];

    for (const expense of expenses) {
      if (expense.ph_pending_flag){
        groupedExpenses[0].expenses.push(expense);
      }
      else if (expense.ph_approved_flag){
        groupedExpenses[1].expenses.push(expense);
      }
      else if (expense.ph_declined_flag){
        groupedExpenses[2].expenses.push(expense);
      }
      else {
        groupedExpenses[3].expenses.push(expense);
      }
    }

    ExpenseService.sortExpenses(groupedExpenses[0].expenses);
    ExpenseService.sortExpenses(groupedExpenses[1].expenses);
    ExpenseService.sortExpenses(groupedExpenses[2].expenses);
    ExpenseService.sortExpenses(groupedExpenses[3].expenses);

    return groupedExpenses;
  }

}
