import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  OnInit
} from '@angular/core';
import { TransitionService } from '@uirouter/core';

import { SlideupListComponent } from './../../components/slideup-list/slideup-list.component';

@Injectable({
  providedIn: 'root'
})
export class DomService implements OnInit {

  slideupListRef: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private transitionService: TransitionService){

  }

  ngOnInit(){
    this.destroyComponentsOnStateChange();
  }

  destroyComponentsOnStateChange(){
    this.transitionService.onSuccess({}, () => {
      this.closeSlideUpList();
    });
  }

  openSlideUpList(allItems: any[], selectedItem: any, itemNameProperty: string,
                  itemColourProperty: string, itemSelected: any){
    // Create a component reference from the component
    this.slideupListRef = this.componentFactoryResolver
      .resolveComponentFactory(SlideupListComponent)
      .create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.slideupListRef.hostView);

    // Get DOM element from component
    const slideupListDomElem = (this.slideupListRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.slideupListRef.instance.allItems = allItems;
    this.slideupListRef.instance.selectedItem = selectedItem;
    this.slideupListRef.instance.itemNameProperty = itemNameProperty;
    this.slideupListRef.instance.itemColourProperty = itemColourProperty;

    this.slideupListRef.instance.slideupListClosed.subscribe(() => {
      this.closeSlideUpList();
    });
    this.slideupListRef.instance.itemSelected.subscribe((data: any) => {
      itemSelected(data.item);
    });

    // Append DOM element to the body
    document.body.appendChild(slideupListDomElem);
  }

  closeSlideUpList(){
    if (this.slideupListRef){
      this.appRef.detachView(this.slideupListRef.hostView);
      this.slideupListRef.destroy();
    }
  }
}
