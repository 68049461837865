import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
  name: 'clockTimerMainPipe',
})
export class ClockTimerMainPipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    return moment.duration(value, 'seconds').format('hh:mm', { trim: false });
  }
}
