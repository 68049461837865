<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>
      My Leave
    </ion-title>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              (click)="viewLeaveBalances()">
        <i class="ion ion-md-information-circle"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<button class="ph-toggleAddBtn"
        (click)="newLeave()"
        *ngIf="!yearCalendarVisible && allLeaveTypes.length">
  <i class="ion ion-md-add"></i>
</button>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshLeave($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <calendar-slides #calendarSlides
                   [employee_key]="employee.employee_key"
                   [currentMonth]="currentMonth"
                   (leaveRequestSelected)="editLeaveRequest($event)"
                   (yearCalendarVisibleUpdated)="yearCalendarVisibleUpdated($event)"
                   (currentMonthUpdated)="currentMonthUpdated($event)"
                   (leaveRequestsUpdated)="leaveRequestsUpdated($event)">
  </calendar-slides>
</ion-content>
