<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Expenses</ion-title>
  </ion-toolbar>
</ion-header>

<button class="ph-toggleAddBtn"
        (click)="newExpense()"
        *ngIf="isAdmin && !yearGridVisible && allTasks.length">
  <i class="ion ion-md-add"></i>
</button>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshExpenses($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="inv-expenseManager-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible && !monthChanging &&
                !currentMonthHasExpenses">
      <i class="ion ion-md-paper"></i>
      <div>No expenses this month</div>
    </div>

    <div class="inv-expenseManager-monthContainer"
         *ngIf="!yearGridVisible">

      <div class="ph-groupedList-container -expenseManager"
           *ngFor="let group of monthsExpenses"
           [hidden]="!group.expenses.length">

        <div class="ph-groupedList-groupHeader">
          <div class="ph-groupedList-groupHeaderTitle"
               [ngClass]="group.responseClass">
            <div class="ph-segmentStatusBlock"
                 *ngIf="group.isInvoxyStatus"
                 [ngClass]="group.responseClass">
            </div>
            {{group.status}}
            <span>{{group.isInvoxyStatus ? 'for Billing' : 'for Payroll'}}</span>
          </div>
          <div class="ph-groupedList-groupHeaderLine"></div>
        </div>

        <div class="ph-groupedList-item"
             *ngFor="let expense of group.expenses"
             (click)="viewExpense(expense)">

          <div class="ph-groupedList-itemColor"
               [ngStyle]="{'background-color': expense.task.task_colour }">
          </div>
          <div class="ph-groupedList-itemTitle">
            {{expense.user.display_name}}
            <span class="ph-paidTag"
                  *ngIf="expense.inv_paid_flag">
              Paid
            </span>
            <i class="ion ion-md-lock"
               *ngIf="expense.inv_locked">
            </i>
            <i class="ion ion-ios-camera"
               *ngIf="expense.timesheet_attachment_key">
            </i>
          </div>
          <div class="ph-groupedList-itemDescription"
               *ngIf="expense.description">
            {{expense.description}}
          </div>
          <div class="ph-groupedList-itemDetails">
            <div>{{expense.expense_date | date : 'd MMM yy'}}</div>
            <span>
              ({{expense.task.task_name}}
              <i class="ion ion-md-lock"
                 *ngIf="expense.task.admin_only_flag">
              </i>)
            </span>
          </div>

          <div class="ph-groupedList-itemValueRight">
            {{expense.expense_amount | currency: currencySymbol}}
          </div>

          <i class="ion ion-ios-arrow-forward"></i>
        </div>

      </div>

    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
