import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { ProjectService } from '../project/project.service';
import { EmployeeService } from './../employee/employee.service';
import { TeamService } from './../team/team.service';
import { CompanyService } from './../company/company.service';
import { LocationService } from './../location/location.service';

import { PhSegment } from './../../models/segment/ph-segment';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(
    public utilService: UtilService,
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public teamService: TeamService,
    public companyService: CompanyService,
    public locationService: LocationService,
    public apiService: ApiService
  ) { }

  /**
   * Get single segment
   */
  loadSegment(segment_key: number) {
    return new Promise<PhSegment>((resolve, reject) => {

      const params = {
        segment_key
      };

      this.apiService.phGet('segment', params)
        .then((segments) => {
          resolve(this.setupSegments(segments)[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get duration and unit segments
   */
  loadSegments(start_date: Date, end_date: Date, employee_key: number = null) {
    return new Promise<PhSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key
      };

      if (!employee_key) {
        delete params.employee_key;
      }

      this.apiService.phGet('segment', params)
        .then((segments) => {
          resolve(this.setupSegments(segments));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get duration segments
   */
  loadDurationSegments(start_date: Date, end_date: Date, employee_key: number = null) {
    return new Promise<PhSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key
      };

      if (!employee_key) {
        delete params.employee_key;
      }

      this.apiService.phGet('segment/duration', params)
        .then((segments) => {
          resolve(this.setupSegments(segments));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get non-duration unit segments
   */
  loadUnitSegments(start_date: Date, end_date: Date, employee_key: number = null) {
    return new Promise<PhSegment[]>((resolve, reject) => {

      const params = {
        start_date: TimeUtilService.formatDateForPosting(start_date, false),
        end_date: TimeUtilService.formatDateForPosting(end_date, false),
        employee_key
      };

      if (!employee_key) {
        delete params.employee_key;
      }

      this.apiService.phGet('segment/unit', params)
        .then((segments) => {
          resolve(this.setupSegments(segments));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // TODO: check that error handling is working as expected
  /**
   * Create/Update/Delete segments
   */
  saveSegments(segs: PhSegment[]) {
    return new Promise((resolve, reject) => {
      const data = {
        segments: TimeService.formatSegmentsForPosting(segs)
      };

      this.apiService.phPost('segment', data, { hide_error: true })
        .then((segments) => {

          if (segments.length === 1 &&
            segments[0] && segments[0].error) {

            this.utilService.toastMessage(segments[0].error);
            resolve(this.setupSegments(segments));
          }
          else {
            // If we posted multiple segments, check if any of them had errors
            for (const seg of segments) {
              if (seg && seg.error) {

                if (seg.error === 'This entry overlaps with another and cannot be saved.') {
                  this.utilService.toastMessage('One or more entries overlap with existing time and could not be saved');
                  resolve(this.setupSegments(segments));
                  break;
                }
                else {
                  this.utilService.toastMessage(null);
                  reject(this.setupSegments(segments));
                  break;
                }
              }
            }
          }

          resolve(this.setupSegments(segments));
        })
        .catch((error) => {
          reject(error);
        });

    });
  }

  /**
   * Copy time and unit segments from a previous week for the given list of employees
   */
  copyPreviousWeeksSegments(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const lastMonday = moment().day('Monday').subtract(7, 'days').toDate();

      const params = {
        employee_key_list: [this.companyService.getEmployeeKey()],
        start_date: TimeUtilService.dateToDateString(lastMonday, null),
        copy_units: true
      };

      this.apiService.phPost('segment/copylastweek', params)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setupSegments(segments: any[]): any[] {
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i] ? this.setupSegment(segments[i]) : null;

      // Valid segment
      if (segment) {
        segments[i] = segment;
      }
      // Remove invalid segment
      else {
        segments.splice(i, 1);
      }
    }

    return segments;
  }

  setupSegment(segment: any): any {
    segment = _.cloneDeep(segment);

    const employee = this.employeeService.getSingleEmployee(segment.employee_key);
    const project = this.projectService.getSingleProject(segment.project_key);

    if (employee && project && !segment.error) {
      return new PhSegment(
        segment.segment_key,
        segment.deleted_flag,
        segment.row_version,
        segment.unit_flag,
        segment.unit_flag && segment.units < 0,
        TimeUtilService.dateStringToDate(segment.segment_date, null, false),
        TimeUtilService.dateTimeStringToDate(segment.start_time, null, false),
        TimeUtilService.dateTimeStringToDate(segment.end_time, null, false),
        segment.break_duration,
        segment.units,
        segment.company,
        segment.description,
        employee,
        project,
        segment.paid,
        this.locationService.getSingleLocation(segment.location_in_key),
        this.locationService.getSingleLocation(segment.location_out_key),
        segment.address_in,
        segment.address_out,
        TimeUtilService.dateTimeStringToDate(segment.clock_in_time, null, false),
        TimeUtilService.dateTimeStringToDate(segment.clock_out_time, null, false)
      );
    }
    else {
      return null;
    }
  }

  initNewSegment(employee_key: number, project_key: number, segment_date: Date, credit_flag: boolean = false) {
    const employee = this.employeeService.getSingleEmployee(employee_key);
    const project = this.projectService.getSingleProject(project_key);

    if (employee && project) {

      const unit_flag = credit_flag || project.pay_code_unit !== 'Hours';

      return new PhSegment(
        null,
        false,
        null,
        unit_flag,
        credit_flag,
        segment_date,
        null,
        null,
        null,
        null,
        this.companyService.getCurrentCompany().company_code,
        '',
        employee,
        project
      );
    }
    return null;
  }

  filterSegmentsForSelectedTeam(segments: any[]): any[] {
    const teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    if (teamEmployeeMap === null) {
      return segments;
    }
    else {
      const teamSegments = [];

      for (const segment of segments) {
        if (!!teamEmployeeMap[segment.employee_key]) {
          teamSegments.push(segment);
        }
      }

      return teamSegments;
    }
  }

  /**
   * Format non primitive properties into strings and numbers
   */
  static formatSegmentsForPosting(segments: any): any[] {
    for (let i = segments.length - 1; i >= 0; i--) {
      // Valid segment
      if (segments[i] !== null) {
        segments[i] = segments[i].formatSegmentForPosting();
      }
      // Remove invalid segment
      else {
        segments.splice(i, 1);
      }
    }

    return segments;
  }



}
