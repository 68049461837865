import { Component, OnInit } from '@angular/core';

import { TimeWorkComponent } from './time-work/time-work.component';
import { TimeManagerClocksComponent } from './time-manager-clocks/time-manager-clocks.component';
import { TimeManagerComponent } from './time-manager/time-manager.component';
import { TimeEmployeeComponent } from './time-employee/time-employee.component';
import { TimeEditComponent } from './time-edit/time-edit.component';
import { TimeClockComponent } from './time-clock/time-clock.component';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export const TIME_STATE_CONFIG = [
  {
    name: 'app.time',
    url: '/time',
    abstract: true,
    component: TimeComponent
  },
  {
    name: 'app.time.clock',
    url: '/clock',
    component: TimeClockComponent
  },
  {
    name: 'app.time.edit',
    url: '/edit',
    component: TimeEditComponent
  },
  {
    name: 'app.time.employee',
    url: '/employee',
    component: TimeEmployeeComponent
  },
  {
    name: 'app.time.manager',
    url: '/manager',
    component: TimeManagerComponent
  },
  {
    name: 'app.time.managerClocks',
    url: '/managerClocks',
    component: TimeManagerClocksComponent
  },
  {
    name: 'app.time.work',
    url: '/work',
    component: TimeWorkComponent
  },
];
