import { Injectable } from '@angular/core';

import { TimeUtilService } from './../time-util/time-util.service';

@Injectable({
  providedIn: 'root'
})
export class InvTaskUtilService {

  static setupTask(task: any): any {
    task.default_break_duration = task.default_break_duration || 0;

    task.created_date = TimeUtilService.dateStringToDate(task.created_date, null, false);
    task.updated_date = TimeUtilService.dateStringToDate(task.updated_date, null, false);

    return task;
  }

  static sortTasks(tasks: any[]): any[] {
    return tasks.sort((a: any, b: any) => {
      const taskNameA = a.task_display_name ? a.task_display_name.toUpperCase() : '';
      const taskNameB = b.task_display_name ? b.task_display_name.toUpperCase() : '';

      if (taskNameA > taskNameB) {
        return 1;
      }
      else if (taskNameA < taskNameB) {
        return -1;
      }
      return 0;
    });
  }

  static getTaskFromList(task_key: number, tasks: any[]): any {
    for (const task of tasks) {
      if (task.task_key === task_key) {
        return task;
      }
    }
    return null;
  }

  static filterOutUnitTasks(tasks: any[]): any[] {
    const hoursTasks = [];

    for (const task of tasks) {
      if (!task.unit_flag) {
        hoursTasks.push(task);
      }
    }

    return hoursTasks;
  }

  static filterOutNonTimesheetTasks(tasks: any[], companyExpensesEnabled: boolean): any[] {
    const timesheetTasks = [];

    if (companyExpensesEnabled) {
      for (const task of tasks) {
        if (!task.unit_type || task.unit_type !== 'expense') {
          timesheetTasks.push(task);
        }
      }
      return timesheetTasks;
    }
    else {
      return tasks;
    }
  }

  static filterOutArchivedAndAdminOnlyTasks(tasks: any[]): any[] {
    const availableTasks = [];

    for (const task of tasks) {
      if (!task.archived_flag && !task.admin_only_flag) {
        availableTasks.push(task);
      }
    }

    return availableTasks;
  }

  static isTimesheetTask(task: any, companyExpensesEnabled: boolean): boolean {
    if (companyExpensesEnabled) {
      return !task.unit_type || task.unit_type !== 'expense';
    }
    else {
      return true;
    }
  }
}
