import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

import { AuthService } from '../auth/auth.service';
import { StateDataService } from './../state-data/state-data.service';
import { BankValidatorService } from '../bank-validator/bank-validator.service';


import * as _ from 'lodash';
import * as EXIF from 'exif-js';
import irdNZ from 'ird-nz/irdnz';

import { env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public static readonly defaultErrorMessage: 'We\'re having trouble connecting to the network. If this continues to happen please, contact support.';

  static blobQuality: number = 0.5;

  public readonly ph_api_url: string = env.payhero.api_url;
  public readonly ph_blob_url: string = env.payhero.blob_url;

  public readonly inv_api_url: string = env.invoxy.api_url;
  public readonly inv_blob_url: string = env.invoxy.blob_url;

  public header: any = {};
  public loadingSpinner: any = null;
  public confirmPopup: any = null;

  private _cameraSupported: boolean = null;
  private _currencySymbol: string = '$';


  constructor(
    public http: HttpClient,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public alertController: AlertController,
    public authService: AuthService,
    public stateDataService: StateDataService
  ) { }

  get currencySymbol(): string {
    return this._currencySymbol;
  }
  set currencySymbol(currencySymbol: string) {
    this._currencySymbol = currencySymbol;
  }

  get cameraSupported(): boolean {
    return this._cameraSupported;
  }
  set cameraSupported(cameraSupported: boolean) {
    this._cameraSupported = cameraSupported;
  }

  async toastMessage(message: string) {
    this.toastCtrl.create({
      message: message || AuthService.defaultErrorMessage,
      duration: 5000,
      position: 'top',
      showCloseButton: true
    } as any)
      .then((toast) => {
        toast.present();
      });
  }

  async showLoadingSpinner() {
    if (this.loadingSpinner === null) {
      this.loadingSpinner = await this.loadingCtrl.create({
        message: 'Please wait...'
      });

      await this.loadingSpinner.present();
    }
  }

  hideLoadingSpinner() {
    if (this.loadingSpinner !== null) {
      this.loadingSpinner.dismiss();
      this.loadingSpinner = null;
    }
  }

  // Blob Processing ///////////////////////////////////////////////

  /**
   * Scales a blob image to ensure that the pixel values of both its
   * dimensions are no greater than maxSizePx.
   * Keeps the image ratio the same
   */
  static scaleDownImageToMaxSize(
    originalImage: Blob, maxSizePx: number, compressionQuality: number = 1
  ): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(originalImage);

      img.onload = () => {
        if (img.height > maxSizePx || img.width > maxSizePx) {

          let scaledWidth: number;
          let scaledHeight: number;
          let imageRatio: number;

          // Portrait
          if (img.height > img.width) {
            imageRatio = img.width / img.height;
            scaledHeight = maxSizePx;
            scaledWidth = scaledHeight * imageRatio;
          }
          // Landscape
          else {
            imageRatio = img.height / img.width;
            scaledWidth = maxSizePx;
            scaledHeight = scaledWidth * imageRatio;
          }

          this.scaleImage(originalImage, scaledWidth, scaledHeight, compressionQuality)
            .then((scaledImage) => resolve(scaledImage));
        }
        else {
          resolve(originalImage);
        }
      };
    });
  }

  /**
   * Scales a blob image to the resolution specificed by xPx & xPx
   */
  static scaleImage(
    originalImage: Blob, xPx: number, yPx: number = null, compressionQuality: number = 1
  ): Promise<Blob> {
    return new Promise<any>((resolve) => {
      this.checkCanvasToBlobCompatibility();

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      context.canvas.width = xPx;
      context.canvas.height = yPx || xPx;

      const img = new Image();
      img.src = URL.createObjectURL(originalImage);
      img.onload = () => {
        context.drawImage(
          img, 0, 0, context.canvas.width, context.canvas.height
        );

        canvas.toBlob(
          (scaledImage) => resolve(scaledImage),
          'image/jpeg',
          compressionQuality
        );
      };
    });
  }

  /**
   * Checks whether browser has a toBlob method for the canvas object.
   * If it doesn't then it will create its own method to read in uploaded images.
   */
  static checkCanvasToBlobCompatibility() {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value(callback, type, quality) {
          const canvas = this;

          setTimeout(() => {

            const binString = atob(canvas.toDataURL(type, quality).split(',')[1]);
            const len = binString.length;
            const array = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              array[i] = binString.charCodeAt(i);
            }

            callback(new Blob([array], { type: type || 'image/jpeg' }));
          });
        }
      });
    }
  }

  /**
   * Generates a blob image from an HTML video element
   *
   * @param {HTML Element} video
   */
  static generateBlobImageFromVideo(video) {
    return new Promise<any>((resolve, reject) => {
      this.checkCanvasToBlobCompatibility();

      // Create a canvas and use it to draw a snapshot of the video feed for creating a BLOB from
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const vidHeight = video.videoHeight;
      const vidWidth = video.videoWidth;

      context.canvas.height = vidHeight;
      context.canvas.width = vidWidth;

      context.drawImage(video, 0, 0, vidWidth, vidHeight, 0, 0, vidWidth, vidHeight);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        }, 'image/jpeg', this.blobQuality);
    });
  }

  /**
   * Compresses a blob image from a blob URL
   *
   * @param {string} blobUrl
   */
  static compressBlobImage(blobUrl) {
    return new Promise<any>((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const imgWidth = image.width;
        const imgHeight = image.height;

        context.canvas.height = imgHeight;
        context.canvas.width = imgWidth;

        context.drawImage(image, 0, 0, imgWidth, imgHeight, 0, 0, imgWidth, imgHeight);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          }, 'image/jpeg', this.blobQuality);
      };

      image.src = blobUrl;
    });
  }

  /**
   * Uses image EXIF meta data to check that image orientation is correct.
   * Compresses a blob image from a blob URL
   *
   * @param {string} blobUrl
   */
  static orientAndCompressBlobImage(blobUrl: string) {
    return new Promise<any>((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        let exifOrientation = null;

        const imgWidth = image.width;
        const imgHeight = image.height;

        const blobQuality = this.blobQuality;

        // Check orientation in EXIF metadata
        EXIF.getData(image as unknown as string, function () {
          const allMetaData = EXIF.getAllTags(this);
          exifOrientation = allMetaData.Orientation;

          // Set proper canvas dimensions before transform & export
          if ([5, 6, 7, 8].indexOf(exifOrientation) !== -1) {
            context.canvas.height = imgWidth;
            context.canvas.width = imgHeight;
          }
          else {
            context.canvas.height = imgHeight;
            context.canvas.width = imgWidth;
          }

          // transform context before drawing image
          switch (exifOrientation) {
            case 2:
              context.transform(-1, 0, 0, 1, imgWidth, 0);
              break;
            case 3:
              context.transform(-1, 0, 0, -1, imgWidth, imgHeight);
              break;
            case 4:
              context.transform(1, 0, 0, -1, 0, imgHeight);
              break;
            case 5:
              context.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              context.transform(0, 1, -1, 0, imgHeight, 0);
              break;
            case 7:
              context.transform(0, -1, -1, 0, imgHeight, imgWidth);
              break;
            case 8:
              context.transform(0, -1, 1, 0, 0, imgWidth);
              break;
            default:
              context.transform(1, 0, 0, 1, 0, 0);
          }

          context.drawImage(image, 0, 0, imgWidth, imgHeight);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            }, 'image/jpeg', blobQuality);
        });
      };

      image.src = blobUrl;
    });
  }

  // Data Validation /////////////////////////////////////////////////

  static validateIrdNumber(irdNumber: number | string): boolean {
    return irdNZ.isValid(irdNumber);
  }

  static validateNzBankAccount(bankAccount: any): boolean {
    return BankValidatorService.nzBankAccountIsValid(bankAccount);
  }

  static validateEmailAddress(email: string): boolean {
    // Regex source: https://www.w3resource.com/javascript/form/email-validation.php
    const emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailValidator.test(email);
  }

  // Number Formatting ///////////////////////////////////////////////

  static padNumber(numb, size, ignoreClipping) {
    const sign = Math.sign(numb) === -1 ? '-' : '';

    if (ignoreClipping && (Math.abs(numb) + '').length > size) {
      return sign + Math.abs(numb);
    }

    return sign + new Array(size).concat([Math.abs(numb)]).join('0').slice(-size);
  }

  static numberToTwoDP(numb) {
    return Math.round((numb + 0.00001) * 100) / 100;
  }

  // Colour Conversion ///////////////////////////////////////////////

  /**
   * Changes the shade of a HEX colour by a given percentage value
   *
   * @param {String} color
   * @param {Number} percent
   * @returns {String}
   */
  static shadeColor(color, percent) {
    const f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = f >> 8 & 0x00FF,
      B = f & 0x0000FF;

    return '#' +
      (0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      ).toString(16).slice(1);
  }

  /**
   * Returns rgba version of integer colour
   *
   * @param {int} num
   * @returns {string} rgba color string
   */
  static intToRgbaColor(num) {
    num >>>= 0;
    const b = num & 0xFF,
      g = (num & 0xFF00) >>> 8,
      r = (num & 0xFF0000) >>> 16;

    return 'rgba(' + [r, g, b, 1].join(',') + ')';
  }

  /**
   * Returns hex version of integer colour
   *
   * @param {int} num
   * @returns {string} hex color string
   */
  static intToHexColor(num) {
    return UtilService.rgbaToHex(UtilService.intToRgbaColor(num));
  }

  /**
   * Returns integer version of rgba colour
   * Required format: 'rgba(xxx,xxx,xxx,1)' or '#xxxxxx'
   *
   * @param {string} col
   * @returns {int} num color string
   */
  static rgbaOrHexColorToInt(col) {
    let vals;

    if (col[0] === '#') {
      vals = [
        parseInt(col.slice(1, 3), 16),
        parseInt(col.slice(3, 5), 16),
        parseInt(col.slice(5, 7), 16)
      ];
    }
    else if (col[0] === 'r') {
      col = col.slice(col.indexOf('(') + 1, col.indexOf(')') - 2);
      vals = col.split(',');
    }
    else {
      throw new Error('"' + col + '" is not a valid colour format. Must be rgba or hex');
    }

    return parseInt(vals[0], null) * 65536 + parseInt(vals[1], null) * 256 + parseInt(vals[2], null);
  }

  /**
   * Converts a rgba string to a hex string
   *
   * @param {string} rgb eg. 'rgba(xxx,xxx,xxx,1)'
   * @returns {string} hex eg. '#xxxxxx'
   */
  static rgbaToHex(rgb) {
    rgb = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')') - 2);
    const vals = rgb.split(',');

    return '#' +
      (
        (1 << 24) +
        (parseInt(vals[0], null) << 16) +
        (parseInt(vals[1], null) << 8) +
        parseInt(vals[2], null)
      ).toString(16).slice(1);
  }

  /**
   * Converts a hex string and an alpha value to an rgba string
   *
   * @param {String} hex
   * @param {Number} alpha
   * @returns {String}
   */
  static hexToRgba(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + (alpha || 1) + ')';
  }

  // Miscies /////////////////////////////////////////////////////////

  /**
   * Takes two arrays of values and returns a concatenated array of the two, filtering out any duplicates
   *
   * @param {Array} arrA
   * @param {Array} arrB
   * @returns {Array}
   */
  static concatUnique(arrA, arrB) {
    const uniqueVals = [];
    let i, val;

    for (i = 0; i < arrA.length; i++) {
      val = arrA[i];

      if (uniqueVals.indexOf(val) === -1) {
        uniqueVals.push(val);
      }
    }
    for (i = 0; i < arrB.length; i++) {
      val = arrB[i];

      if (uniqueVals.indexOf(val) === -1) {
        uniqueVals.push(val);
      }
    }

    return uniqueVals;
  }

  /**
   * Parses a json object returns the unparsed data if an error is thrown
   *
   * @param data
   * @returns {any}
   */
  static parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

  static getUrlNavParam(navParams: any, paramName: string): any {
    const navParam = navParams.get(paramName);

    if (navParam && navParam !== 'null' && navParam !== ':' + paramName) {
      return UtilService.parseJSON(navParam);
    }
    return null;
  }

  /**
   * Returns the index of the nth occurance of a pattern in a string
   */
  static indexOfNthOccurance(str: string, pattern: string, n: number): number {
    let i = -1;

    while (n-- && i++ < str.length) {
      i = str.indexOf(pattern, i);
      if (i < 0) {
        break;
      }
    }

    return i;
  }

  /**
   * Checks if two objects or primitives are 'loosely' equal.
   * For objects, object properties need to match but don't need to reference the exact same value
   * ie. Difference between == and ===
   *
   * @param {*} x
   * @param {*} y
   * @param {Array<String>} propsToIgnore
   * @returns {boolean}
   */
  static isLooselyEqual(x, y, propsToIgnore) {

    if (x === y || (!x && !y)) {
      return true;
    }
    // if both x and y are exactly the same or are both null/undefined/''

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) {
      return false;
    }
    // they must have the exact same prototype chain, the closest we can do is
    // test their constructor.

    // Compare date objects
    if (x instanceof Date && y instanceof Date) {
      return x.valueOf() === y.valueOf();
    }

    // Should ignore 'visible' property on items by default
    if (!propsToIgnore) {
      propsToIgnore = ['visible'];
    }
    else {
      propsToIgnore.push('visible');
    }

    let i, xKeys = Object.keys(x), yKeys = Object.keys(y);
    let validXKeysLength = 0, validYKeysLength = 0;

    for (i = 0; i < xKeys.length; i++) {
      if (propsToIgnore.indexOf(xKeys[i]) === -1 && xKeys[i] !== '$$hashKey') {
        validXKeysLength++;
      }
    }
    for (i = 0; i < yKeys.length; i++) {
      if (propsToIgnore.indexOf(yKeys[i]) === -1 && yKeys[i] !== '$$hashKey') {
        validYKeysLength++;
      }
    }

    if (validXKeysLength !== validYKeysLength) {
      return false;
    }
    // taking into account properties that should be ignored,
    // both objects should have the same number of properties

    for (let p in x) {
      // Ignore properties in propsToIgnore
      if (propsToIgnore.indexOf(p) !== -1) {
        continue;
      }
      if (!x.hasOwnProperty(p) || !y.hasOwnProperty(p) || p[0] === '$') {
        continue;
      }
      // Want to ignore properties added automatically by AngularJS for things like tracking ng-repeats etc

      if (x[p] == y[p]) {
        continue;
      }
      // if they have the same value or identity then they are equal
      if (!UtilService.isLooselyEqual(x[p], y[p], propsToIgnore)) {
        return false;
      }
      // Objects and Arrays must be tested recursively
    }

    return true;
  }

  static applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
        Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name));
      });
    });
  }


  // Generates a unique ID for use with Google login APIs
  static generateUUID() {
    let d = new Date().getTime();

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

}
