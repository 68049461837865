import { TimeUtilService } from './../../../services/time-util/time-util.service';

export abstract class PageTimeEdit {

  readonly errorMessages: any;
  readonly durationRequiredMessage: string = 'Time entries must have a duration';
  readonly unitsRequiredMessage: string = ' must be greater than 0';
  readonly noOverlapMessage: string = 'This time record can\'t overlap another record';

  readonly defaultSegmentDate: Date = new Date();

  loading: boolean = true;
  // Used to hide unitsRequiredMessage on page init/before user has
  // had a chance to set segment.units for unit based segments
  segmentUnitsTouched: boolean = false;

  isAdmin: boolean = false;
  isManager: boolean = false;

  allProjects: any[] = [];

  editingDisabled: boolean = false;

  publicHolidaysMap: any = {};
  currentPublicHoliday: any = null;

  // Dates/Durations in ISO string format
  segmentDateString: string;
  startTimeString: string;
  endTimeString: string;
  durationString: string;
  breakDurationString: string;
  unitsString: string;

  segments: any[] = [];
  segment: any = null;

  currencySymbol: string;

  errorMessage: string = null;
  pageTitle: string;

  constructor() { }

  initSegmentUnitsTouched() {
    this.segmentUnitsTouched = this.segment.segment_key !== null || !this.segment.unit_flag;
  }

  updateDateStrings() {
    this.segmentDateString = TimeUtilService.dateToLocalTimeISOString(this.segment.segment_date);

    if (this.segment?.unit_flag) {
      this.unitsString = TimeUtilService.hoursDecimalToLocalTimeISOString(Math.abs(this.segment.units));
    }
    else {
      this.startTimeString = TimeUtilService.dateToLocalTimeISOString(this.segment.start_time);
      this.endTimeString = TimeUtilService.dateToLocalTimeISOString(this.segment.end_time);
      this.breakDurationString = TimeUtilService.hoursDecimalToLocalTimeISOString(this.segment.break_duration);
      this.durationString = TimeUtilService.hoursDecimalToLocalTimeISOString(this.segment.duration);
    }
  }

  incrementDurationField(isBreakDuration: boolean, isPositive: boolean) {
    if (isBreakDuration) {
      this.segment.break_duration = TimeUtilService.incrementDecimalDurationQuarterHour(this.segment.break_duration, isPositive);
      this.breakDurationString = TimeUtilService.hoursDecimalToLocalTimeISOString(this.segment.break_duration);
      this.breakDurationUpdated();
    }
    else {
      this.segment.duration = TimeUtilService.incrementDecimalDurationQuarterHour(this.segment.duration, isPositive);
      this.durationString = TimeUtilService.hoursDecimalToLocalTimeISOString(this.segment.duration);
      this.durationUpdated();
    }
  }

  incrementUnitsField(isPositive: boolean) {
    this.segment.units = this.segment.units + (isPositive ? 1 : -1);
    this.unitsUpdated();
  }

  segmentDateStringUpdated() {
    this.segment.segment_date = TimeUtilService.dateStringToDate(this.segmentDateString, null, false);
    this.updateDateStrings();

    this.checkForErrors();
  }

  startTimeStringUpdated() {
    this.segment.start_time = TimeUtilService.dateTimeStringToDate(this.startTimeString, null, false);
    this.updateDateStrings();

    this.checkForErrors();
  }

  endTimeStringUpdated() {
    this.segment.end_time = TimeUtilService.dateTimeStringToDate(this.endTimeString, null, false);
    this.updateDateStrings();

    this.checkForErrors();
  }

  durationUpdated() {
    this.segment.duration = TimeUtilService.localTimeISOStringToHoursMinsDecimal(this.durationString);
    this.updateDateStrings();

    this.checkForErrors();
  }

  breakDurationUpdated() {
    this.segment.break_duration = TimeUtilService.localTimeISOStringToHoursMinsDecimal(this.breakDurationString);
    this.updateDateStrings();

    this.checkForErrors();
  }

  unitsStringUpdated() {
    this.segment.units = TimeUtilService.localTimeISOStringToHoursMinsDecimal(this.unitsString);
    this.segmentUnitsTouched = true;
    this.checkForErrors();
  }

  unitsUpdated() {
    this.unitsString = TimeUtilService.hoursDecimalToLocalTimeISOString(Math.abs(this.segment.units));
    this.segmentUnitsTouched = true;
    this.checkForErrors();
  }

  updateUnits(units: number) {
    this.segment.units = this.segment.credit_flag ? (0 - units) : units;
    this.unitsUpdated();
  }

  clearErrorIfMatches(errorToMatch: string, partMatch: boolean = false) {
    if (this.errorMessage === errorToMatch) {
      this.errorMessage = '';
    }
    else if (partMatch && this.errorMessage && this.errorMessage.indexOf(errorToMatch) !== -1) {
      this.errorMessage = '';
    }
  }

  checkForErrors() {
    this._checkNonZeroDurationOrUnits();
    this._checkForOverlappingSegments();
    this._checkAnyProjectsAvailable();
  }

  private _checkAnyProjectsAvailable() {
    this.clearErrorIfMatches(this.errorMessages.noProjects);
    if (this.allProjects.length === 0 && !this.segment.segment_key) {
      this.errorMessage = this.errorMessages.noProjects;
    }
  }

  private _checkNonZeroDurationOrUnits() {

    this.clearErrorIfMatches(this.durationRequiredMessage);
    this.clearErrorIfMatches(this.unitsRequiredMessage, true);

    if (this.segment.unit_flag){
      if (this.segment.units === 0 && this.segmentUnitsTouched) {
        const unitType = this.segment.unit_type === 'expense' ? this.currencySymbol : this.segment.unit_type;
        this.errorMessage = unitType + this.unitsRequiredMessage;
      }
    }
    else {
      if (this.segment.duration === 0) {
        this.errorMessage = this.durationRequiredMessage;
      }
    }
  }

  private _checkForOverlappingSegments() {
    this.clearErrorIfMatches(this.noOverlapMessage);

    if (!this.segment.unit_flag) {
      if (TimeUtilService.segmentOverlapsAnotherInList(this.segment, this.segments)) {
        this.errorMessage = this.noOverlapMessage;
      }
    }
  }

}
