import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { TimeService } from '../time/time.service';
import { UtilService } from '../util/util.service';
import { ProjectService } from '../project/project.service';
import { EmployeeService } from '../employee/employee.service';
import { TeamService } from '../team/team.service';
import { TimeUtilService } from '../time-util/time-util.service';
import { LocationService } from './../location/location.service';
import { ActionService } from './../action/action.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  public static readonly maxClockSeconds: number = 86399;

  public records: any = [];
  public activeRecord: any;
  public activeRecordPauseStart: any = null;
  public activeRecordTimer: any;

  localStorage: any = window.localStorage;

  serviceSetup: boolean = false;

  constructor(
    public timeService: TimeService,
    public utilService: UtilService,
    public projectService: ProjectService,
    public teamService: TeamService,
    public employeeService: EmployeeService,
    public locationService: LocationService,
    public actionService: ActionService,
    public apiService: ApiService
  ) {

  }

  loadClock(clock_key: number) {
    return new Promise<any>((resolve, reject) => {

      const params = clock_key ? { clock_key } : null;

      this.apiService.phGet('clock', params)
        .then((data) => {
          if (data.length) {
            resolve(this.setupClocks(data)[0]);
          }
          else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadEmployeeClock(employee_key: number) {
    return new Promise<any>((resolve, reject) => {

      const params = employee_key ? { employee_key } : null;

      this.apiService.phGet('clock', params)
        .then((data) => {
          if (data.length) {
            resolve(this.setupClocks(data)[0]);
          }
          else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  loadClocks(employee_key: number = null) {
    return new Promise<any>((resolve, reject) => {

      const params = employee_key ? { employee_key } : null;

      this.apiService.phGet('clock', params)
        .then((data) => {
          const clocks = this.setupClocks(data);

          if (!employee_key) {
            resolve(this.filterClocksForSelectedTeam(clocks));
          }
          else {
            resolve(clocks);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  clockIn(employee: any, project_key: number = null) {
    const data = {
      employee_key: employee.employee_key,
      clock_in_time: TimeUtilService.dateToDateTimeString(new Date(), null),
      team_key: this.teamService.getSelectedTeamKey(),
      project_key,
      latitude: null,
      longitude: null
    };

    return this.postClockData('clock', data);
  }

  clockOut(clock: any) {
    const data = {
      clock_key: clock.clock_key,
      employee_key: clock.employee.employee_key,
      clock_out_time: TimeUtilService.dateToDateTimeString(new Date(), null),
      latitude: null,
      longitude: null
    };

    return this.postClockData('clock', data);
  }

  breakIn(clock: any) {
    const data = {
      clock_key: clock.clock_key,
      employee_key: clock.employee.employee_key,
      start_time: TimeUtilService.dateToDateTimeString(new Date(), null),
      latitude: null,
      longitude: null
    };

    return this.postClockData('clock/break', data);
  }

  breakOut(clock: any) {
    let clock_break_key = null;

    for (const cb of clock.clock_breaks) {
      if (!cb.end_time) {
        clock_break_key = cb.clock_break_key;
        break;
      }
    }

    const data = {
      clock_key: clock.clock_key,
      employee_key: clock.employee.employee_key,
      clock_break_key,
      end_time: TimeUtilService.dateToDateTimeString(new Date(), null),
      latitude: null,
      longitude: null
    };

    return this.postClockData('clock/break', data);
  }

  postClockProject(clock_key: number, project_key: number) {
    const data = {
      clock_key,
      project_key
    };

    return this.postClockData('clock/project', data);
  }

  postClockNote(clock: any, clock_note_key: number, note: string, deleted_flag: boolean = false) {
    const data = {
      clock_key: clock.clock_key,
      employee_key: clock.employee.employee_key,
      note,
      deleted_flag,
      clock_note_key
    };

    return this.postClockData('clock/note', data);
  }

  private postClockData(url: string, data: any) {
    return new Promise<void>((resolve, reject) => {
      if (this.actionService.isActive('GPS_CLOCK_IN')) {

        const doPost = () => {
          this.apiService.phPost(url, data)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        };

        if (url === 'clock' || url === 'clock/break') {
          this.locationService.getCoordinates()
            .then((location) => {
              data.latitude = location.coords.latitude;
              data.longitude = location.coords.longitude;

              doPost();
            })
            .catch((err) => {
              // TODO: what feedback can we provide to the user if there's an error here?
              console.log(err);
              doPost();
            });
        }
        else {
          doPost();
        }
      }
      else {
        reject();
      }
    });
  }

  setupClocks(clocks: any[]): any[] {
    for (let i = clocks.length - 1; i >= 0; i--) {
      const clock = this.setupClock(clocks[i]);

      // Valid request
      if (clock !== null) {
        clocks[i] = clock;
      }
      // Remove invalid requests
      else {
        clocks.splice(i, 1);
      }
    }

    return clocks;
  }

  setupClock(clock: any): any {
    const employee = this.employeeService.getSingleEmployee(clock.employee_key);
    const project = this.projectService.getSingleProject(clock.project_key);

    if (employee && project) {
      clock.employee = employee;
      clock.project = project;

      clock.location_in = this.locationService.getSingleLocation(clock.location_in_key);
      clock.location_out = this.locationService.getSingleLocation(clock.location_out_key);

      clock.clock_in_time = TimeUtilService.dateTimeStringToDate(clock.clock_in_time, null, false);
      clock.clock_out_time = TimeUtilService.dateTimeStringToDate(clock.clock_out_time, null, false);

      clock.is_multiple_days = moment(clock.clock_in_time).isBefore(moment(), 'days');
      clock.is_on_break = false;

      for (const cb of clock.clock_breaks) {
        cb.start_time = TimeUtilService.dateTimeStringToDate(cb.start_time, null, false);
        cb.end_time = TimeUtilService.dateTimeStringToDate(cb.end_time, null, false);

        if (cb.end_time === null) {
          clock.is_on_break = true;
        }
      }

      ClockService.sortClockBreaks(clock.clock_breaks);

      return clock;
    }
    else {
      return null;
    }
  }

  filterClocksForSelectedTeam(clocks: any[]): any[] {
    const teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    if (teamEmployeeMap === null) {
      return clocks;
    }
    else {
      const teamClocks = [];

      for (const clock of clocks) {
        if (!!teamEmployeeMap[clock.employee.employee_key]) {
          teamClocks.push(clock);
        }
      }

      return teamClocks;
    }
  }

  static getActiveClockBreak(clock: any): any {
    for (const cb of clock.clock_breaks) {
      if (!cb.end_time) {
        return cb;
      }
    }
    return null;
  }

  static getTotalFinishedBreakSeconds(clock: any): number {
    let seconds = 0;

    for (const cb of clock.clock_breaks) {
      if (!!cb.end_time) {
        seconds += moment(cb.end_time).diff(moment(cb.start_time), 'minutes') * 60;
      }
    }

    return seconds;
  }

  static setupClockTimeData(clock: any): any {
    if (!clock) {
      return null;
    }

    const nowSeconds = Math.floor(new Date().valueOf() / 1000);
    const activeBreak = ClockService.getActiveClockBreak(clock);

    const clockStartSeconds = Math.floor(clock.clock_in_time.valueOf() / 1000);
    const breakStartSeconds = activeBreak ? Math.floor(activeBreak.start_time.valueOf() / 1000) : clockStartSeconds;
    const finishedBreakSeconds = ClockService.getTotalFinishedBreakSeconds(clock);

    const breakTimerSeconds = !!activeBreak ? nowSeconds - breakStartSeconds : 0;
    const clockTimerSeconds = (!!activeBreak ? breakStartSeconds : nowSeconds) - clockStartSeconds - finishedBreakSeconds;

    const clockExceedsMaxSeconds = clockTimerSeconds + breakTimerSeconds + finishedBreakSeconds > ClockService.maxClockSeconds;

    return {
      clockStartSeconds,
      breakStartSeconds,
      finishedBreakSeconds,
      clockTimerSeconds,
      breakTimerSeconds,
      clockExceedsMaxSeconds
    };
  }

  static updateClockTimeData(clockTimeData: any, clock: any) {
    const now = new Date();
    const nowMilliseconds = now.valueOf();
    const nowSeconds = Math.floor(nowMilliseconds / 1000);
    const activeBreak = ClockService.getActiveClockBreak(clock);

    clockTimeData.breakStartSeconds = activeBreak ? Math.floor(activeBreak.start_time.valueOf() / 1000) : clockTimeData.clockStartSeconds;
    clockTimeData.finishedBreakSeconds = ClockService.getTotalFinishedBreakSeconds(clock);

    const breakTimerSeconds = !!activeBreak ? nowSeconds - clockTimeData.breakStartSeconds : 0;
    const clockTimerSeconds = (!!activeBreak ? clockTimeData.breakStartSeconds : nowSeconds) -
      clockTimeData.clockStartSeconds - clockTimeData.finishedBreakSeconds;

    const clockExceedsMaxSeconds = clockTimerSeconds + breakTimerSeconds +
      clockTimeData.finishedBreakSeconds > ClockService.maxClockSeconds;

    clockTimeData.breakTimerSeconds = breakTimerSeconds;
    clockTimeData.clockTimerSeconds = clockTimerSeconds;
    clockTimeData.clockExceedsMaxSeconds = clockExceedsMaxSeconds;
  }

  static sortClockBreaks(clockBreaks: any[]): any[] {
    return clockBreaks.sort((a, b) => {
      if (a.start_time.valueOf() < b.start_time.valueOf()) {
        return 1;
      }
      else if (a.start_time.valueOf() > b.start_time.valueOf()) {
        return -1;
      }
      return 0;
    });
  }

  static clockLocationMatchesSearch(search: string, clock: any): boolean {
    if (clock.location_in && clock.location_in.location_name.toUpperCase().indexOf(search) !== -1 ||
      clock.location_out && clock.location_out.location_name.toUpperCase().indexOf(search) !== -1 ||
      clock.address_in && clock.address_in.toUpperCase().indexOf(search) !== -1 ||
      clock.address_out && clock.address_out.toUpperCase().indexOf(search) !== -1) {

      return true;
    }
    return false;
  }

}
