import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';
import { UtilService } from './../../../services/util/util.service';

import * as _ from 'lodash';

@Component({
  selector: 'onboard-upload',
  templateUrl: './onboard-upload.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-upload.component.scss']
})
export class OnboardUploadComponent implements OnInit, OnboardStepInterface {

  @ViewChild('onboardStepUploadInput') onboardStepUploadInput: any;

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  stepId: number = Math.floor(Math.random() * 10000000);
  inv_blob_url: string;

  // Document that has not yet been uploaded to BLOB storage
  pendingDocument: any = null;
  // Document from BLOB storage
  currentDocument: {
    document_key: number,
    document_url: string,
    file_name: string
  } = null;

  constructor(public invOnboardService: InvOnboardService,
              public utilService: UtilService){

  }

  ngOnInit(){
    this.inv_blob_url = this.utilService.inv_blob_url;

    if (this.stepAnswer){
      this.currentDocument = {
        document_key: this.stepAnswer.document_key,
        document_url: this.stepAnswer.document_url,
        file_name: this.stepAnswer.file_name
      };
    }
  }

  uploadButtonClicked(){
    this.onboardStepUploadInput.nativeElement.click();
  }

  documentChanged(files: FileList){
    this.pendingDocument = files.item(0);

    if (this.pendingDocument !== null){
      this.loading = true;

      this.invOnboardService.postUserOnboardDocument({ data: this.pendingDocument })
        .then((doc) => {
          this.currentDocument = doc;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  deleteClicked(){
    if (this.stepAnswer !== null){
      this.updateStepAnswer(true);
    }
    else {
      this.deleteDocument();
    }
  }

  deleteDocument(){
    if (this.currentDocument !== null){
      this.loading = true;

      this.invOnboardService.deleteOnboardDocument(this.currentDocument.document_key)
        .finally(() => {
          this.currentDocument = null;
          this.loading = false;
        });
    }
  }

  stepAnswerNeedsUpdating(): boolean {
    if (this.stepAnswer){
      return this.currentDocument && this.currentDocument.document_key !== this.stepAnswer.document_key;
    }
    else {
      return this.currentDocument !== null;
    }
  }

  getStepAnswerData(deleteClicked: boolean = false): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (this.currentDocument && !deleteClicked){
      stepData.document_key = this.currentDocument.document_key;
    }
    else {
      stepData.deleted_flag = true;
    }

    return stepData;
  }

  updateStepAnswer(deleteClicked: boolean = false){
    if (this.stepAnswerNeedsUpdating() || deleteClicked){

      this.loading = true;
      const stepData = this.getStepAnswerData(deleteClicked);

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          if (stepData.deleted_flag){
            this.deleteDocument();
          }
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
