<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
    <ion-title>Change Password</ion-title>
  </ion-toolbar>
</ion-header>

<slider-button [btnDisabled]="loading || (!oldPassword && !noPassword) || !newPassword || !newPasswordConfirm"
               [btnShort]="false"
               [btnText]="'Update Password'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="savePassword()">
</slider-button>

<ion-content>

  <div class="app-accountPassword">

    <div class="ph-errorMessage"
         [ngClass]="{ 'ph-errorMessageHidden': !errorMessage }">
      {{errorMessage}}
    </div>

    <div class="ph-formItem" *ngIf="!noPassword">
      <div class="ph-formItem-label">
        Old Password
      </div>
      <input class="ph-formItem-input"
             type="password"
             [disabled]="loading"
             [(ngModel)]="oldPassword">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        New Password
      </div>
      <input class="ph-formItem-input"
             type="password"
             [disabled]="loading || (!oldPassword && !noPassword)"
             [(ngModel)]="newPassword">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Confirm New Password
      </div>
      <input class="ph-formItem-input"
             type="password"
             [disabled]="loading || (!oldPassword && !noPassword)"
             [(ngModel)]="newPasswordConfirm">
    </div>

  </div>

</ion-content>
