<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content *ngIf="!loading">

  <div class="inv-timeWork-selectedHeader">
    Record Time
  </div>

  <div class="ph-recentWork-selectorContainer">
    <coloured-item-selector *ngIf="allProjects.length"
                            [allItems]="allProjects"
                            [itemType]="'Placement'"
                            [itemKeyProperty]="'project_key'"
                            [itemNameProperty]="'project_name'"
                            [selectorColour]="selectedProjectTask.task_colour"
                            [selectedItem]="selectedProject"
                            (itemSelected)="projectSelected($event)">
    </coloured-item-selector>

    <div class="inv-timeWork-newSegment"
         *ngIf="allProjects.length && allProjectTasks.length"
         [ngStyle]="{ 'background-color': selectedProjectTask ? selectedProjectTask.task_colour : '#777' }"
         (click)="newSegment()">
      <i class="ion ion-ios-arrow-forward"></i>
    </div>

    <coloured-item-selector class="inv-timeWork-taskSelector"
                            *ngIf="allProjectTasks.length"
                            [allItems]="allProjectTasks"
                            [itemType]="'Work'"
                            [itemKeyProperty]="'task_key'"
                            [itemNameProperty]="'task_display_name'"
                            [itemColourProperty]="'task_colour'"
                            [selectedItem]="selectedProjectTask"
                            (itemSelected)="taskSelected($event)">
    </coloured-item-selector>
  </div>

  <div class="ph-recentWork-header"
       *ngIf="recentProjectTasks.length">
    <div>Recent Work</div>
    <div></div>
  </div>

  <div class="ph-recentWork-container"
       *ngFor="let pt of recentProjectTasks"
       (click)="newSegment(pt.project_key, pt.task_key)"
       [ngStyle]="{'background-color': pt.task_colour}">
    <div class="ph-recentWork-subTitle">
      {{pt.project_name}}
    </div>
    <div class="ph-recentWork-title">
      {{pt.task_display_name}}
    </div>
    <i class="ion ion-ios-arrow-forward"></i>
  </div>

</ion-content>
