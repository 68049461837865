import { ApiService } from './../api/api.service';

import { Injectable } from '@angular/core';

import { InvProjectUtilService } from './../inv-project-util/inv-project-util.service';
import { UtilService } from './../util/util.service';
import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';
import { InvCompanyService } from './../inv-company/inv-company.service';
import { InvTaskService } from './../inv-task/inv-task.service';
import { InvUserService } from './../inv-user/inv-user.service';
import { StateDataService } from './../state-data/state-data.service';

import { InvSegment } from './../../models/segment/inv-segment';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvProjectService {

  allProjects: any[] = [];
  allTimesheetProjects: any[] = [];
  allExpenseProjects: any[] = [];
  allProjectsMap: any = {};

  recentProjectTasks: any = {};

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public invCompanyService: InvCompanyService,
    public stateDataService: StateDataService,
    public invTaskService: InvTaskService,
    public apiService: ApiService
  ) {}

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadAllProjects()
          .then(() => {
            this.loadRecentProjectTasksFromCache();
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.allProjects = [];
    this.allExpenseProjects = [];
    this.allProjectsMap = {};
    this.recentProjectTasks = {};

    this.serviceSetup = false;
  }

  getAllProjects(): any[] {
    return _.cloneDeep(this.allProjects);
  }

  getSingleProject(project_key: number): any {
    return _.cloneDeep(this.allProjectsMap[project_key]) || null;
  }

  getSingleProjectTask(project_key: number, task_key: number): any {
    const project = this.allProjectsMap[project_key] || null;

    if (project !== null) {
      return project.projectTasksMap[task_key] || null;
    }
  }

  getAllActiveProjects(
    periodStart: Date = null,
    periodEnd: Date = null,
    onlyTimesheetProjects: boolean = false,
    onlyHoursProjects: boolean = false,
    onlyExpenseProjects: boolean = false,
    user_key: number = null
  ): any[] {
    const activeProjects = [];

    let allProjects = this.allProjects;
    if (onlyTimesheetProjects) {
      allProjects = this.allTimesheetProjects;
    }
    else if (onlyExpenseProjects) {
      allProjects = this.allExpenseProjects;
    }

    const expensesEnabled = this.invCompanyService.expensesEnabled();

    for (const project of allProjects) {
      if (InvProjectUtilService.projectIsActive(
        project, periodStart, periodEnd, expensesEnabled, onlyTimesheetProjects, onlyExpenseProjects
      )) {

        if (this.invCompanyService.isConsultant() || project.project_type !== 'Labour Hire') {

          if (!onlyHoursProjects || InvProjectUtilService.isActiveHoursProject(project)) {

            if (InvProjectUtilService.projectHasResource(project)) {

              if (!user_key || InvProjectUtilService.userInProject(user_key, project)) {
                activeProjects.push(project);
              }
            }
          }
        }
      }
    }

    return activeProjects;
  }

  getRecentProjectTasks(user_key: number): any {
    return this.recentProjectTasks[user_key] || [];
  }

  loadAllProjects() {
    return new Promise((resolve, reject) => {
      const params = {
        user_key: this.invCompanyService.getUserKey()
      };

      this.apiService.invGet('project/all', params)
        .then((data) => {
          this.allProjects = [];
          this.allTimesheetProjects = [];
          this.allExpenseProjects = [];
          this.allProjectsMap = {};

          const expensesEnabled = this.invCompanyService.expensesEnabled();

          for (let project of data) {
            project = InvProjectUtilService.setupProject(project);

            this.allProjectsMap[project.project_key] = project;

            if (project.project_type !== 'Permanent') {
              this.allProjects.push(project);
            }
            if (InvProjectUtilService.getActiveTimesheetTasks(project.tasks, expensesEnabled).length > 0) {
              this.allTimesheetProjects.push(project);
            }
            if (InvProjectUtilService.getActiveExpenseTasks(project.tasks).length > 0) {
              this.allExpenseProjects.push(project);
            }
          }

          InvProjectUtilService.sortProjects(this.allProjects);
          InvProjectUtilService.sortProjects(this.allTimesheetProjects);
          InvProjectUtilService.sortProjects(this.allExpenseProjects);

          resolve(this.allProjects);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadRecentProjectTasksFromCache() {
    const recentProjectTasks = this.stateDataService.getCachedComponentSessionData('InvProjectService', 'recentProjectTasks') || {};

    for (const user_key of Object.keys(recentProjectTasks)) {
      for (let i = recentProjectTasks[user_key].length - 1; i >= 0; i--) {
        const pt = recentProjectTasks[user_key][i];
        const task = this.invTaskService.getSingleTask(pt.task_key);

        if (task.archived_flag || task.admin_only_flag) {
          recentProjectTasks[user_key].splice(i, 1);
        }
      }
    }

    this.recentProjectTasks = recentProjectTasks;
  }

  updateRecentProjectTasks(segments: InvSegment[]) {
    const projectTaskIsNewerThanExistingOne = (newPt: any, existingPt: any): boolean => {
      if (!existingPt || newPt.project_task_date_value > existingPt.project_task_date_value) {
        return true;
      }
      return false;
    };
    const isNewProjectOrTask = (newPt: any, projectTasks: any[]): boolean => {
      for (const existingPt of projectTasks) {
        if (existingPt.project_key === newPt.project_key && existingPt.task_key === newPt.task_key) {
          return false;
        }
      }
      return true;
    };

    const expensesEnabled = this.invCompanyService.expensesEnabled();

    for (const segment of segments) {
      if (segment.task.archived_flag || segment.task.admin_only_flag) {
        continue;
      }

      const user_key = segment.user.user_key;

      // Setup projectTask map for new user
      if (!this.recentProjectTasks[user_key]) {
        this.recentProjectTasks[user_key] = [];
      }
      const projectTasks = this.recentProjectTasks[user_key];
      const segmentDateValue = segment.segment_date.valueOf();

      const pt = {
        project_key: segment.inv_project.project_key,
        project_name: segment.inv_project.project_name,
        task_key: segment.task.task_key,
        task_display_name: segment.work_name,
        task_colour: segment.colour,
        project_task_date_value: segmentDateValue
      };

      if (!isNewProjectOrTask(pt, projectTasks) ||
        !InvTaskUtilService.isTimesheetTask(segment.task, expensesEnabled)) {
        continue;
      }

      // Most recent
      if (projectTaskIsNewerThanExistingOne(pt, projectTasks[0])) {
        projectTasks.splice(0, 0, pt);
      }
      else if (projectTaskIsNewerThanExistingOne(pt, projectTasks[1])) {
        projectTasks.splice(1, 0, pt);
      }
      // Least recent
      else if (projectTaskIsNewerThanExistingOne(pt, projectTasks[2])) {
        projectTasks.splice(2, 0, pt);
      }

      // Limit projectTasks to 3 per user
      if (projectTasks.length > 3) {
        projectTasks.splice(-1, 1);
      }
    }

    this.stateDataService.cacheComponentSessionData('InvProjectService', 'recentProjectTasks', this.recentProjectTasks);
  }

}
