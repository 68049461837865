import { Component, Input, Output, EventEmitter, HostBinding, HostListener, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'slider-button',
  templateUrl: './slider-button.component.html',
  styleUrls: ['./slider-button.component.scss']
})
export class SliderButtonComponent implements OnInit {

  @Input() btnDisabled: boolean;
  @Input() btnShort: boolean;
  @Input() btnText: string;
  @Input() btnIconName: string;
  @Input() btnType: string;
  @Input() btnSmall: boolean;

  @Output() btnClicked = new EventEmitter();

  // Conditionally add classes to slider-button container based on provided variables or user interaction
  @HostBinding('class.sliderButton-short') isBtnShort: boolean = false;
  @HostBinding('class.sliderButton-left') isBtnLeft: boolean = false;
  @HostBinding('class.sliderButton-revealed') btnRevealed: boolean = false;

  // Close slider button on outside click
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.btnRevealed = false;
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnInit(){
    this.isBtnShort = this.btnShort;
    this.isBtnLeft = this.btnType === 'danger';
  }

  buttonClicked(){
    if (!this.btnDisabled){
      if (!this.btnRevealed){
        this.btnRevealed = true;
      }
      else {
        this.btnClicked.emit();
      }
    }
  }

  closeSlider(){
    this.btnRevealed = false;
  }

}
