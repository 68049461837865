<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button [statesToSkip]="['app.expense.camera']"></back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              *ngIf="expense && showDeleteBtn"
              [disabled]="errorMessage || loading"
              (click)="deleteExpense()">
        <i class="ion ion-md-trash"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<slider-button *ngIf="expense && showSaveBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="false"
               [btnText]="'Save Expense'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveExpense()">
</slider-button>

<slider-button *ngIf="expense && showApproveBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="showDeclineBtn"
               [btnText]="'Approve for Payroll'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveExpense(true)">
</slider-button>

<slider-button *ngIf="expense && showDeclineBtn"
               [btnDisabled]="errorMessage || loading || !expenseAmountTouched"
               [btnShort]="showApproveBtn"
               [btnText]="'Decline for Payroll'"
               [btnIconName]="'ios-close'"
               [btnType]="'danger'"
               (btnClicked)="saveExpense(false, true)">
</slider-button>

<ion-content>

  <div class="inv-expenseHeader">
    <div class="-header-title">
      Expense
      <i class="ion ion-md-lock"
         *ngIf="expense && (expense.inv_locked || expense.task.admin_only_flag)">
      </i>
    </div>

    <div class="-header-subTitle"
         *ngIf="resourceExpenseStatus">
      <i class="ion ion-md-lock"
         *ngIf="expense.ph_paid_flag || expense.ph_approved_flag || expense.inv_approved_flag || expense.ph_declined_flag">
      </i>
      <span [ngClass]="{
                '-responseApproved': expense.ph_paid_flag || (expense.ph_approved_flag && expense.inv_approved_flag),
                '-responseDeclined': expense.ph_declined_flag }">
        {{resourceExpenseStatus}}</span>
    </div>

    <div class="-header-subTitle"
         *ngIf="phStatus">
      <span [ngClass]="{
                '-responseApproved': expense.ph_paid_flag || expense.ph_approved_flag,
                '-responseDeclined': expense.ph_declined_flag }">
        {{phStatus}}</span>
      for Payroll
    </div>
    <div class="-header-subTitle"
         *ngIf="invStatus">
      <div class="ph-segmentStatusBlock"
           [ngClass]="{
            '-responseUnapproved': expense.inv_new_flag,
            '-responsePending': expense.inv_pending_flag,
            '-responseApproved': expense.inv_approved_flag }">
      </div>
      <span [ngClass]="{
              '-responseUnapproved': expense.inv_new_flag,
              '-responsePending': expense.inv_pending_flag,
              '-responseApproved': expense.inv_approved_flag }">
        {{invStatus}}
      </span>
      {{isPayHeroIntegrated ? 'for Billing' : ''}}
    </div>
  </div>

  <coloured-item-selector *ngIf="expense"
                          [allItems]="allProjects"
                          [itemType]="'Placement'"
                          [itemKeyProperty]="'project_key'"
                          [itemNameProperty]="'project_name'"
                          [selectorColour]="expense.task.task_colour"
                          [disabled]="!editingUnlocked"
                          [selectedItem]="expense.inv_project"
                          (itemSelected)="projectSelected($event)">
  </coloured-item-selector>

  <coloured-item-selector class="inv-expenseEdit-taskSelector"
                          *ngIf="expense && expense.inv_project"
                          [allItems]="expense.project_tasks"
                          [itemType]="'Work'"
                          [itemKeyProperty]="'task_key'"
                          [itemNameProperty]="'task_display_name'"
                          [itemColourProperty]="'task_colour'"
                          [disabled]="!editingUnlocked"
                          [selectedItem]="expense.task"
                          (itemSelected)="taskSelected($event)">
  </coloured-item-selector>

  <div class="app-expenseEdit-documentContainer"
       *ngIf="expense"
       (click)="expandImage()"
       [ngStyle]="{ 'height': imageFullSize ? imageHeight + 'px' : '' }">

    <button class="-color-white-clear -type-icon app-expenseEdit-documentChange"
            *ngIf="editingUnlocked"
            [hidden]="!imageUrl"
            (click)="changeDocument()">
      <i class="ion ion-md-camera"></i>
    </button>

    <button class="-color-white-clear -type-icon app-expenseEdit-documentRemove"
            *ngIf="editingUnlocked"
            [hidden]="!imageUrl && !imageBlob"
            (click)="deleteDocument($event)">
      <i class="ion ion-md-close"></i>
    </button>

    <img *ngIf="imageUrl"
         id="app-expenseEdit-documentImage"
         (load)="documentUrlChanged()"
         [src]="imageUrl">

    <div class="ph-emptyMessage"
         (click)="changeDocument()"
         *ngIf="!imageUrl">
      <i class="ion ion-md-paper"></i>
      <div>{{editingUnlocked ? "Tap to add receipt" : "No receipt"}}</div>
    </div>

  </div>

  <ion-list class="app-expenseEdit-detailsList"
            *ngIf="expense">

    <div class="ph-errorMessage"
         [ngClass]="{ 'ph-errorMessageHidden': !errorMessage }">
      {{errorMessage}}
    </div>

    <div class="ph-formItem"
         *ngIf="isManager || isAdmin">
      <div class="ph-formItem-label">
        Resource
      </div>
      <button class="ph-formItem-inputButton"
              [ngClass]="{ 'ph-inputLocked': !editingUnlocked || expense.segment_key !== null }"
              (click)="openUserList()">
        {{expense.user.display_name}}
        <i class="ion ion-md-arrow-dropdown"></i>
      </button>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Expense {{currencySymbol}}
      </div>
      <input class="ph-formItem-input"
             type="number"
             [ngClass]="{ 'ph-inputLocked': !editingUnlocked }"
             [(ngModel)]="expense.expense_amount"
             min="1"
             step="1"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="expenseAmountUpdated()">
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Date
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDDD, D MMM YYYY"
                    max="2030"
                    [ngClass]="{ 'ph-inputLocked': !editingUnlocked }"
                    [(ngModel)]="expenseDateString"
                    (ngModelChange)="expenseDateStringUpdated()">
      </ion-datetime>
    </div>

    <textarea class="text-input ph-textArea app-expenseEdit-description"
              [(ngModel)]="expense.description"
              [ngClass]="{ 'ph-inputLocked': !editingUnlocked }"
              placeholder="{{editingUnlocked ? 'Tap to Add Description...' : 'No Description'}}">
    </textarea>



  </ion-list>

</ion-content>
