<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button [statesToSkip]="['app.inv.time.work']"></back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              *ngIf="segment && segment.segment_key && !segment.is_locked && !editingDisabled"
              [disabled]="errorMessage || loading"
              (click)="deleteSegment()">
        <i class="ion ion-md-trash"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<slider-button *ngIf="segment && !segment.is_locked && !editingDisabled"
               [btnDisabled]="errorMessage || loading || !segmentUnitsTouched"
               [btnShort]="false"
               [btnText]="'Save Time'"
               [btnIconName]="'ios-checkmark'"
               [btnType]="'success'"
               (btnClicked)="saveSegment()">
</slider-button>

<ion-content>

  <div class="ph-pageHeaderRow">
    <div class="ph-pageHeaderText">
      <div class="ph-segmentStatusBlock"
           *ngIf="segment"
           [ngStyle]="{ 'background-color': (isAdmin || isManager) ? segment.status_color : segment.resource_status_color }">
      </div>
      {{pageTitle}}
      <i class="ion ion-md-lock"
         *ngIf="segment && (segment.task.archived_flag || segment.task.admin_only_flag)">
      </i>
    </div>
  </div>

  <coloured-item-selector *ngIf="segment"
                          [allItems]="allProjects"
                          [itemType]="'Placement'"
                          [itemKeyProperty]="'project_key'"
                          [itemNameProperty]="'project_name'"
                          [selectorColour]="segment.task.task_colour"
                          [disabled]="segment.is_locked || editingDisabled"
                          [selectedItem]="segment.inv_project"
                          (itemSelected)="projectSelected($event)">
  </coloured-item-selector>

  <coloured-item-selector class="inv-timeEdit-taskSelector"
                          *ngIf="segment && segment.inv_project"
                          [allItems]="segment.project_tasks"
                          [itemType]="'Work'"
                          [itemKeyProperty]="'task_key'"
                          [itemNameProperty]="'task_display_name'"
                          [itemColourProperty]="'task_colour'"
                          [disabled]="segment.is_locked || editingDisabled"
                          [selectedItem]="segment.task"
                          (itemSelected)="projectTaskSelected($event)">
  </coloured-item-selector>

  <div class="app-timeEdit-segmentDetails"
       *ngIf="segment">

    <div class="ph-errorMessage"
         [ngClass]="{ 'ph-errorMessageHidden': !errorMessage }">
      {{errorMessage}}
    </div>

    <div class="app-timeEdit-publicHoliday"
         [ngClass]="{ '-hidden': !currentPublicHoliday }">
      {{currentPublicHoliday ? currentPublicHoliday.public_holiday_name : ''}}
    </div>

    <div class="ph-formItem"
         *ngIf="isManager || isAdmin">
      <div class="ph-formItem-label">
        Resource
      </div>
      <button class="ph-formItem-inputButton"
              [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled || segment.segment_key !== null }"
              (click)="openUserList()">
        {{segment.user.display_name}}
        <i class="ion ion-md-arrow-dropdown"
           [hidden]="segment.is_locked || editingDisabled || segment.segment_key"></i>
      </button>
    </div>

    <div class="ph-formItem -halfWidth"
         *ngIf="!segment.unit_flag">
      <div class="ph-formItem-label">
        Start
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="hh:mm A"
                    [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
                    [(ngModel)]="startTimeString"
                    (ngModelChange)="startTimeStringUpdated()">
      </ion-datetime>
    </div>

    <div class="ph-formItem -halfWidth"
         *ngIf="!segment.unit_flag">
      <div class="ph-formItem-label">
        End
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="hh:mm A"
                    [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
                    [(ngModel)]="endTimeString"
                    (ngModelChange)="endTimeStringUpdated()">
      </ion-datetime>
    </div>

    <div class="ph-formItem">
      <div class="ph-formItem-label">
        Date
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="DDDD, D MMM YYYY"
                    max="2030"
                    [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
                    [(ngModel)]="segmentDateString"
                    (ngModelChange)="segmentDateStringUpdated()">
      </ion-datetime>
    </div>

    <!-- Duration -->
    <div class="ph-formItem -halfWidth"
         *ngIf="!segment.unit_flag">
      <div class="ph-formItem-label">
        Duration
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="HH:mm"
                    [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
                    [(ngModel)]="durationString"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="durationUpdated()">
      </ion-datetime>
    </div>
    <div class="ph-formItem -halfWidth -noPadding"
         *ngIf="!segment.unit_flag && !segment.is_locked && !editingDisabled">
      <button class="ph-formItem-button -color-success-clear"
              (click)="incrementDurationField(false, true)">
        <i class="ion ion-ios-add"></i>
      </button>
      <button class="ph-formItem-button -color-grey-clear"
              (click)="incrementDurationField(false, false)">
        <i class="ion ion-ios-remove"></i>
      </button>
    </div>

    <!-- Break Duration -->
    <div class="ph-formItem -halfWidth"
         *ngIf="!segment.unit_flag">
      <div class="ph-formItem-label">
        Break Duration
      </div>
      <ion-datetime class="ph-formItem-input"
                    displayFormat="HH:mm"
                    [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
                    [(ngModel)]="breakDurationString"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="breakDurationUpdated()">
      </ion-datetime>
    </div>
    <div class="ph-formItem -halfWidth -noPadding"
         *ngIf="!segment.unit_flag && !segment.is_locked && !editingDisabled">
      <button class="ph-formItem-button -color-success-clear"
              (click)="incrementDurationField(true, true)">
        <i class="ion ion-ios-add"></i>
      </button>
      <button class="ph-formItem-button -color-grey-clear"
              (click)="incrementDurationField(true, false)">
        <i class="ion ion-ios-remove"></i>
      </button>
    </div>

    <!-- Units -->
    <div class="ph-formItem -halfWidth"
         *ngIf="segment.unit_flag">
      <div class="ph-formItem-label"
           *ngIf="segment.unit_type === 'expense'">
        {{segment.credit_flag ? 'Credited ' : ''}}{{currencySymbol}}
      </div>
      <div class="ph-formItem-label"
           *ngIf="segment.unit_type !== 'expense'">
        {{segment.credit_flag ? 'Credited ' : ''}}{{segment.unit_type}}
      </div>
      <div class="timeEdit-creditPrefix"
           *ngIf="segment.credit_flag">
        CR
      </div>
      <input *ngIf="segment.unit_type !== 'hours'"
             class="ph-formItem-input"
             type="number"
             min="1"
             step="1"
             [ngClass]="{
               'ph-inputLocked': segment.is_locked || editingDisabled,
               '-credit': segment.credit_flag
             }"
             [ngModel]="segment.units | absValuePipe"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="updateUnits($event)">
      <ion-datetime *ngIf="segment.unit_type === 'hours'"
                    class="ph-formItem-input"
                    displayFormat="HH:mm"
                    [ngClass]="{
                      'ph-inputLocked': segment.is_locked || editingDisabled,
                      '-credit': segment.credit_flag
                    }"
                    [(ngModel)]="unitsString"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="unitsStringUpdated()">
      </ion-datetime>
    </div>
    <div class="ph-formItem -halfWidth -noPadding"
         *ngIf="segment.unit_flag && !segment.is_locked && !editingDisabled">
      <button class="ph-formItem-button -color-success-clear"
              (click)="incrementUnitsField(true)">
        <i class="ion ion-ios-add"></i>
      </button>
      <button class="ph-formItem-button -color-grey-clear"
              (click)="incrementUnitsField(false)">
        <i class="ion ion-ios-remove"></i>
      </button>
    </div>

    <textarea class="text-input ph-textArea app-timeEdit-notes"
              [(ngModel)]="segment.notes"
              [ngClass]="{ 'ph-inputLocked': segment.is_locked || editingDisabled }"
              placeholder="{{segment.is_locked || editingDisabled ? 'No Notes' : 'Tap to Add Note...'}}">
    </textarea>

  </div>

</ion-content>
