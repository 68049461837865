import { TimeUtilService } from './../../services/time-util/time-util.service';

import { SegmentInterface } from '../../interfaces/segment.interface';
import { Segment } from './segment';

import * as moment from 'moment';
import * as _ from 'lodash';

export class InvSegment extends Segment implements SegmentInterface {

  company_key: number;
  notes: string;

  new_flag: boolean;
  pending_flag: boolean;
  approved_flag: boolean;
  invoiced_flag: boolean;
  paid_flag: boolean;
  admin_only_flag: boolean;

  private _inv_project: any;
  private _task: any;
  user: any;

  project_tasks: any[];
  project_users: any[];

  private _is_active_project: boolean;

  private _company_expenses_enabled: boolean;

  constructor(
    segment_key: number = null, deleted_flag: boolean = false, row_version: string, unit_flag: boolean, credit_flag: boolean,
    segment_date: Date, start_time: Date = null, end_time: Date = null, break_duration: number = null, units: number = null,
    company_key: number, notes: string = '', inv_project: any, user_key: number, task_key: number,
    company_expenses_enabled: boolean,
    new_flag: boolean = true, pending_flag: boolean = false, approved_flag: boolean = false, invoiced_flag: boolean = false,
    paid_flag: boolean = false, admin_only_flag: boolean = false
  ) {
    const task = inv_project.projectTasksMap[task_key];
    const user = inv_project.projectUsersMap[user_key];

    if (start_time === null || end_time === null || break_duration === null){
      const d = InvSegment._generateInvSegmentTimeDefaults(segment_date, user, task);

      start_time = d.start_time;
      end_time = d.end_time;
      break_duration = d.break_duration;
    }

    super(
      segment_key, deleted_flag, row_version, unit_flag, credit_flag,
      segment_date, start_time, end_time, break_duration, units || 0
    );

    this.company_key = company_key;
    this.notes = notes;

    this.new_flag = new_flag;
    this.pending_flag = pending_flag;
    this.approved_flag = approved_flag;
    this.invoiced_flag = invoiced_flag;
    this.paid_flag = paid_flag;
    this.admin_only_flag = admin_only_flag;

    this._inv_project = inv_project;
    this._task = task;
    this.user = user;

    this._company_expenses_enabled = company_expenses_enabled;
    this.unit_flag = this.unit_type !== 'hours' || this.units < 0;

    this._updateTasksAndUsersOnProjectChange();
    this._updateIsActiveProject();
  }

  // Super class function extensions /////////////////////////////////
  get segment_date(): Date {
    return super.segment_date;
  }
  set segment_date(segment_date: Date) {
    super.segment_date = segment_date;
    this._updateIsActiveProject();
  }

  // Interface getters/setters //////////////////////////////////////
  get colour(): string {
    return this.task.task_colour;
  }
  get work_name(): string {
    return this.task.task_display_name;
  }
  get unit_type(): string {
    return this.task.unit_type || this.inv_project.rate_type;
  }
  get is_locked(): boolean {
    return this.task.archived_flag ||
      this.approved_flag ||
      this.invoiced_flag ||
      this.paid_flag ||
      this.admin_only_flag;
  }
  get is_active_project(): boolean {
    return this._is_active_project;
  }

  get is_ph_segment(): boolean {
    return false;
  }
  get is_inv_segment(): boolean {
    return true;
  }

  // Class getters/setters //////////////////////////////////////
  get inv_project(): any {
    return this._inv_project;
  }
  set inv_project(inv_project: any) {
    this._inv_project = inv_project;
    this.unit_flag = this.unit_type !== 'hours' || this.units < 0;

    this._updateTasksAndUsersOnProjectChange();
    this._updateIsActiveProject();
  }

  get task(): any {
    return this._task;
  }
  set task(task: any){
    this._task = task;
    this.unit_flag = this.unit_type !== 'hours' || this.units < 0;
  }

  get is_active_task(): boolean {
    return !this.task.archived_flag;
  }
  get status_color(): string {
    if (this.paid_flag) {
      return '#005a7d';
    }
    else if (this.invoiced_flag) {
      return '#f60';
    }
    else if (this.approved_flag) {
      return '#5eb22e';
    }
    else if (this.pending_flag) {
      return '#ffce00';
    }
    else {
      return '#00adef';
    }
  }
  get status(): string {
    if (this.paid_flag) {
      return 'Paid';
    }
    else if (this.invoiced_flag) {
      return 'Invoiced';
    }
    else if (this.approved_flag) {
      return 'Approved';
    }
    else if (this.pending_flag) {
      return 'Pending';
    }
    else {
      return 'New';
    }
  }
  get resource_status_color(): string {
    if (this.approved_flag || this.invoiced_flag || this.paid_flag) {
      return '#5eb22e';
    }
    else if (this.pending_flag) {
      return '#ffce00';
    }
    else {
      return '#00adef';
    }
  }
  get resource_status(): string {
    if (this.approved_flag || this.invoiced_flag || this.paid_flag) {
      return 'Approved';
    }
    else if (this.pending_flag) {
      return 'Pending';
    }
    else {
      return 'New';
    }
  }

  // Interface functions //////////////////////////////////////
  formatSegmentForPosting() {
    if (this.unit_flag) {
      return {
        segment_key: this.segment_key,
        row_version: this.row_version,
        company_key: this.company_key,
        user_key: this.user.user_key,
        project_key: this.inv_project.project_key,
        task_key: this.task.task_key,
        segment_date: TimeUtilService.formatDateForPosting(this.segment_date, false),
        notes: this.notes,
        deleted_flag: this.deleted_flag,
        units: this.units,
        unit_type: this.task.unit_flag ? this.task.unit_type : this.inv_project.rate_type
      };
    }
    else {
      return {
        segment_key: this.segment_key,
        row_version: this.row_version,
        company_key: this.company_key,
        user_key: this.user.user_key,
        project_key: this.inv_project.project_key,
        task_key: this.task.task_key,
        segment_date: TimeUtilService.formatDateForPosting(this.segment_date, false),
        start_time: TimeUtilService.formatDateForPosting(this.start_time, true),
        end_time: TimeUtilService.formatDateForPosting(this.end_time, true),
        notes: this.notes,
        deleted_flag: this.deleted_flag,
        duration: this.duration,
        break_duration: this.break_duration
      };
    }
  }

  // Class functions //////////////////////////////////////
  private _updateIsActiveProject() {
    const segmentDate = moment(this.segment_date);
    const projectStartDate = moment(this.inv_project.start_date);
    const projectEndDate = this.inv_project.end_date ? moment(this.inv_project.end_date) : null;

    this._is_active_project = segmentDate.isSameOrAfter(projectStartDate, 'day') &&
      (projectEndDate === null || segmentDate.isSameOrBefore(projectEndDate, 'day'));
  }

  private _updateTasksAndUsersOnProjectChange() {
    this.project_users = [];

    for (const pu of this._inv_project.users) {
      if (pu.resource_flag) {
        this.project_users.push(pu);
      }
    }

    if (this._company_expenses_enabled) {
      this.project_tasks = [];

      for (const pt of this._inv_project.tasks) {
        if (!pt.archived_flag) {
          if (!pt.unit_type || pt.unit_type !== 'expense') {
            this.project_tasks.push(pt);
          }
        }
      }
    }
    else {
      this.project_tasks = this._inv_project.tasks;
    }

    this._updateUserAndTask();
  }

  private _updateUserAndTask() {
    let projectUser = null;
    let projectTask = null;

    for (const pu of this._inv_project.users) {
      if (pu.resource_flag) {
        if (pu.user_key === this.user.user_key) {
          projectUser = pu;
        }
      }
    }
    for (const pt of this._inv_project.tasks) {
      if (!pt.unit_type || pt.unit_type !== 'expense') {
        // Don't allow archived tasks to be set on new segments
        if (this.segment_key || !pt.archived_flag) {
          if (pt.task_key === this.task.task_key) {
            projectTask = pt;
          }
        }
      }
    }

    if (projectUser !== null) {
      this.user = projectUser;
    }
    else if (this.project_users.length) {
      this.user = this.project_users[0];
    }
    else {
      this.user = null;
    }

    if (projectTask !== null) {
      this.task = projectTask;
    }
    else if (this.project_tasks.length) {
      this.task = this.project_tasks[0];
    }
    else {
      this.task = null;
    }
  }

  private static _generateInvSegmentTimeDefaults(segment_date: Date, user: any, task: any): any {
    const start_time = _.cloneDeep(segment_date);
    if (user.default_start_time !== null) {
      start_time.setHours(user.default_start_time.getHours(), user.default_start_time.getMinutes(), 0, 0);
    }
    else {
      start_time.setHours(9, 0, 0, 0);
    }

    const end_time = TimeUtilService.incrementHours(start_time, 8);

    let break_duration = task.default_break_duration;
    const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(start_time, end_time);
    // Ensure break duration isn't greater than the total segment duration
    if (break_duration > totalDurationDec) {
      break_duration = totalDurationDec;
    }

    return {
      start_time,
      end_time,
      break_duration
    };
  }

}
