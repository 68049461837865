import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { InvTaskUtilService } from './../inv-task-util/inv-task-util.service';
import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { StateDataService } from './../state-data/state-data.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InvTaskService {

  allTasks: any[] = [];
  allInactiveTasks: any[] = [];

  allExpenseTasks: any[] = [];
  allInactiveExpenseTasks: any[] = [];

  allTasksMap: any = {};

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public stateDataService: StateDataService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadAllTasks()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.allTasks = [];
    this.allInactiveTasks = [];

    this.allExpenseTasks = [];
    this.allInactiveExpenseTasks = [];

    this.allTasksMap = {};

    this.serviceSetup = false;
  }

  getAllTasks(includeArchived: boolean = false): any[] {
    return includeArchived ?
      InvTaskUtilService.sortTasks(_.cloneDeep(this.allTasks.concat(this.allInactiveTasks))) :
      InvTaskUtilService.sortTasks(_.cloneDeep(this.allTasks));
  }

  getAllExpenseTasks(includeArchived: boolean = false): any[] {
    return includeArchived ?
      InvTaskUtilService.sortTasks(_.cloneDeep(this.allExpenseTasks.concat(this.allInactiveExpenseTasks))) :
      InvTaskUtilService.sortTasks(_.cloneDeep(this.allExpenseTasks));
  }

  getSingleTask(task_key: number): any {
    return _.cloneDeep(this.allTasksMap[task_key]) || null;
  }

  loadAllTasks() {
    return new Promise((resolve, reject) => {

      this.apiService.invGet('task')
        .then((data) => {
          this.allTasks = [];
          this.allExpenseTasks = [];
          this.allTasksMap = {};

          for (let task of data) {
            task = InvTaskUtilService.setupTask(task);

            this.allTasksMap[task.task_key] = task;
            this.allTasks.push(task);

            if (task.unit_type === 'expense') {
              this.allExpenseTasks.push(task);
            }
          }

          InvTaskUtilService.sortTasks(this.allTasks);
          InvTaskUtilService.sortTasks(this.allExpenseTasks);

          resolve(this.allTasks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
