import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { forkJoin } from 'rxjs';

import { TimeService } from '../../../services/time/time.service';
import { ClockService } from '../../../services/clock/clock.service';
import { UtilService } from '../../../services/util/util.service';
import { ProjectService } from '../../../services/project/project.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { CompanyService } from './../../../services/company/company.service';
import { TeamService } from '../../../services/team/team.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { ActionService } from './../../../services/action/action.service';
import { TimesheetApprovalsService } from './../../../services/timesheet-approvals/timesheet-approvals.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-time-employee',
  templateUrl: './time-employee.component.html',
  styleUrls: ['./time-employee.component.scss']
})
export class TimeEmployeeComponent implements OnInit {

  readonly backSkipStates: string[] = ['app.time.work', 'app.time.edit'];

  gpsClockInEnabled: boolean = false;

  employee: any = null;
  employeeHasTeam: boolean = false;
  loggedInEmployeeKey: number = null;

  timeHistory: any[] = [];
  groupedTimeHistory: any[] = [];

  allProjects: any[] = [];
  publicHolidaysMap: any;

  loadFromDate: Date = null;

  clock: any = null;

  approvals: any[] = [];
  selectedEmployees: any[] = [];
  weekApproved: boolean = false;
  todayApproved: boolean = false;

  menuItems: any[];
  private _copyMenuItem: any = {
    label: 'COPY LAST WEEK\'S TIME',
    icon_class: 'ion-ios-copy',
    color_class: '-color-primary-clear',
    select_value: 'COPY',
    disabled: false
  };
  private _recordMenuItem: any = {
    label: 'RECORD TIME',
    icon_class: 'ion-ios-add-circle',
    color_class: '-color-success-clear',
    select_value: 'RECORD',
    disabled: false
  };
  private _clockMenuItem: any = {
    label: 'START CLOCK',
    icon_class: 'ion-ios-play',
    color_class: '-color-success-clear',
    select_value: 'CLOCK',
    disabled: false
  };

  loading: boolean = true;

  constructor(
    public stateService: StateService,
    public timeService: TimeService,
    public utilService: UtilService,
    public clockService: ClockService,
    public projectService: ProjectService,
    public stateAccessService: StateAccessService,
    public employeeService: EmployeeService,
    public companyService: CompanyService,
    public teamService: TeamService,
    public stateChangeService: StateChangeService,
    public actionService: ActionService,
    public timesheetApprovalsService: TimesheetApprovalsService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    const employee_key = this.stateService.params.employee_key || this.loggedInEmployeeKey;
    this.employee = this.employeeService.getSingleEmployee(employee_key);

    this.allProjects = this.projectService.getAllTimesheetHoursProjects(false);
    this.publicHolidaysMap = this.companyService.getPublicHolidaysMap();

    this.employeeHasTeam = this.teamService.employeeHasTeam(this.employee.employee_key);
    this.gpsClockInEnabled = this.actionService.isActive('GPS_CLOCK_IN');

    forkJoin([
      this.loadEmployeeClock(),
      this.loadTimeHistory()
    ])
      .toPromise()
      .finally(() => {
        this.updateMenuItems();
        this.loading = false;
      });
  }

  back() {
    this.stateChangeService.back(this.backSkipStates);
  }

  updateMenuItems() {
    this._recordMenuItem.disabled = !this.allProjects.length;
    this._clockMenuItem.disabled = !this.allProjects.length || this.clock !== null || this.todayApproved;
    this._copyMenuItem.disabled = this.weekApproved;

    this.menuItems = [
      this._copyMenuItem,
      null,
      this._recordMenuItem
    ];

    if (this.gpsClockInEnabled && this.employeeHasTeam) {
      this.menuItems.push(this._clockMenuItem);
    }
  }

  menuItemSelected(event: any) {
    switch (event.select_value) {
      case 'COPY':
        this.copyLastWeek();
        break;
      case 'RECORD':
        this.newSegment();
        break;
      case 'CLOCK':
        this.clockIn();
        break;
    }
  }

  editSegment(segment: any) {
    this.stateService.go('app.time.edit', {
      segment,
      segment_key: segment.segment_key,
      segments: this.timeHistory
    });
  }

  newSegment() {
    if (this.allProjects.length === 1) {
      this.stateService.go('app.time.edit', {
        project_key: this.allProjects[0].project_key,
        employee_key: this.employee.employee_key,
        segments: this.timeHistory
      });
    }
    else {
      this.stateService.go('app.time.work', {
        segments: this.timeHistory,
        employee_key: this.employee.employee_key
      });
    }
  }

  clockIn() {
    if (!this.loading) {
      this.loading = true;

      this.clockService.clockIn(this.employee)
        .finally(() => {
          this.loadEmployeeClock();
        });
    }
  }

  clockOut(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.clockService.clockOut(clock)
        .finally(() => {
          this.refreshTime();
        });
    }
  }

  breakIn(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.clockService.breakIn(clock)
        .finally(() => {
          this.loadEmployeeClock();
        });
    }
  }

  breakOut(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.clockService.breakOut(clock)
        .finally(() => {
          this.loadEmployeeClock();
        });
    }
  }

  viewClock(event: any) {
    const clock = event.clock;

    this.stateService.go('app.time.clock', {
      clock,
      clock_key: clock.clock_key
    });
  }

  copyLastWeek() {
    this.utilService.showLoadingSpinner();

    this.timeService.copyPreviousWeeksSegments()
      .then(() => {
        this.groupedTimeHistory = [];

        this.loadTimeHistory()
          .then(() => {
            this.utilService.hideLoadingSpinner();
          })
          .catch(() => {
            this.utilService.hideLoadingSpinner();
          });
      })
      .catch(() => {
        this.utilService.hideLoadingSpinner();
      });
  }

  loadEmployeeClock() {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      this.clockService.loadEmployeeClock(this.employee.employee_key)
        .then((clock) => {
          this.clock = clock;
          this.loading = false;
          resolve();
        })
        .catch(() => {
          this.loading = false;
          reject();
        });
    });
  }

  /**
   * Initially load all time for current and previous month
   */
  loadTimeHistory() {
    return new Promise<void>((resolve, reject) => {
      // Start of previous month
      this.loadFromDate = new Date();
      this.loadFromDate.setMonth(this.loadFromDate.getMonth() - 1);
      this.loadFromDate.setDate(1);

      // End of next month
      const toDate = new Date();
      toDate.setMonth(toDate.getMonth() + 2);
      toDate.setDate(0);

      this.timeService.loadSegments(this.loadFromDate, toDate, this.employee.employee_key)
        .then((history) => {
          this.timeHistory = history;
          this.groupedTimeHistory = TimeUtilService.setupGroupedHistory(history, this.publicHolidaysMap);

          this.ensureEnoughTimeLoaded()
            .then(() => {
              this.getTimeApprovals(toDate).then(() => {
                resolve();
              })
            });
        })
        .catch(() => {
          reject();
        });
            
    });
  }

  refreshTime(event: any = null) {
    forkJoin([
      this.loadEmployeeClock(),
      this.loadTimeHistory()
    ])
      .toPromise()
      .finally(() => {
        if (event) {
          event.target.complete();
        }
        this.updateMenuItems();
        this.loading = false;
      });
  }

  /**
   * Load another full month of time
   */
  loadMoreTimeHistory(event: any = null) {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      // End of previous month
      const toDate = _.cloneDeep(this.loadFromDate);
      toDate.setDate(0);

      // Start of previous month
      this.loadFromDate.setMonth(this.loadFromDate.getMonth() - 1);
      this.loadFromDate.setDate(1);

      this.timeService.loadSegments(this.loadFromDate, toDate, this.employee.employee_key)
        .then((history) => {
          this.timeHistory = this.timeHistory.concat(history);
          const groupedMonths = TimeUtilService.setupGroupedHistory(history, this.publicHolidaysMap);

          // Length will be 0 if there are no segments in the month
          if (groupedMonths.length) {
            this.groupedTimeHistory.push(groupedMonths[0]);
          }

          this.getTimeApprovals(toDate);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          this.loading = false;

          if (event !== null) {
            event.target.complete();
          }
        });
    });
  }

  /**
   * If we get less than 8 time segments back on initial time history load,
   * try to load up to two more months of time history to try and fill the page
   */
  ensureEnoughTimeLoaded() {
    return new Promise<void>((resolve, reject) => {
      if (this.timeHistory.length < 8) {

        this.loadMoreTimeHistory()
          .then(() => {

            if (this.timeHistory.length < 8) {
              this.loadMoreTimeHistory()
                .then(() => { resolve(); })
                .catch(() => { resolve(); });
            }
            else {
              resolve();
            }
          })
          .catch(() => {
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  getTimeApprovals(toDate: Date) {
    return new Promise<void>((resolve, reject) => {
      this.timesheetApprovalsService.getTimeApprovals(this.employee.employee_key, this.loadFromDate, toDate)
      .then((approvals) => {
        this.approvals = this.setupTimeApprovals(approvals);
        resolve(approvals);
      })
      .catch(() => {
        reject();
      });
    });
  }

  setupTimeApprovals(approvals: any) {
    const weekStart = TimeUtilService.getMonday(new Date());
    const weeksDates = TimeUtilService.generateWeeksDates(weekStart);
    let weekDaysApproved = [];

    let approvedDays = _.map(approvals, 'timesheet_date');
    approvedDays.forEach((day: string) => { 
      approvedDays[approvedDays.indexOf(day)] = moment(day).format('YYYY-MM-DD');
    });

    weeksDates.forEach((weekDay) => {
      if (approvedDays.includes(TimeUtilService.dateToDateString(weekDay))) {
        weekDaysApproved.push(weekDay);
      }
      if (approvedDays.includes(TimeUtilService.dateToDateString(new Date()))) {
        this.todayApproved = true;
      }
    });
    //if any one day is approved, disallow copy prev weeks time
    if (weekDaysApproved.length > 0) { this.weekApproved = true; }

    this.groupedTimeHistory.forEach((history) => {
      history.days.forEach((date: any) => {
        if (approvedDays.includes(TimeUtilService.dateToDateString(date.day))) {          
          date.approved = true;          
        }
      })
    });
    return approvals;
  }

}
