import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { AuthService } from '../../../services/auth/auth.service';
import { UtilService } from '../../../services/util/util.service';
import { TokenService } from './../../../services/token/token.service';

import * as _ from 'lodash';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';

import { env } from './../../../../environments/environment';


@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  isInvoxyUser: boolean;

  refreshStateName: string = null;
  refreshStateParams: any = null;
  checkOnboard: boolean = false;

  constructor(
    public stateService: StateService,
    public authService: AuthService,
    public utilService: UtilService,
    public stateAccessService: StateAccessService,
    public tokenService: TokenService,
    public invCompanyService: InvCompanyService,
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;

    this.refreshStateName = this.uiRouterGlobals.params.refreshStateName || null;
    this.refreshStateParams = this.uiRouterGlobals.params.refreshStateParams || null;
    this.checkOnboard = this.uiRouterGlobals.params.checkOnboard || false

    this.initialiseAppServices();
  }

  initialiseAppServices() {
    this.stateAccessService.ensureServicesInitialised()
      .then(() => {
        if (!this.isInvoxyUser) {
          this.tokenService.loadNewReturnLoginToken().catch(() => { });
        }
        this.stateAccessService.reloadStateAccess(this.refreshStateName, this.refreshStateParams, this.checkOnboard);

        if (this.isInvoxyUser) {
          const contractor_intercom_settings = this.invCompanyService.getCurrentCompanyUser().contractor_intercom_settings;

          if (!!contractor_intercom_settings) {
            contractor_intercom_settings.app_id = env.contractor_app_id;
            contractor_intercom_settings.last_mobile_login = new Date();
            window.Intercom('boot', contractor_intercom_settings);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.authService.tryTokenLogin();
      });
  }

}
