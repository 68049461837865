import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { ExpenseService } from '../../../services/expense/expense.service';
import { ProjectService } from '../../../services/project/project.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { CompanyService } from './../../../services/company/company.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

import { PhExpense } from './../../../models/expense/ph-expense';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-expense-employee',
  templateUrl: './expense-employee.component.html',
  styleUrls: ['./expense-employee.component.scss']
})
export class ExpenseEmployeeComponent extends PageMonthYearView implements OnInit {

  employee: any;
  loggedInEmployeeKey: number;

  allProjects: any[] = [];
  monthsExpenses: any;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateDataService: StateDataService,
    public expenseService: ExpenseService,
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public companyService: CompanyService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    const employee_key = this.stateService.params.employee_key || this.loggedInEmployeeKey;
    this.employee = this.employeeService.getSingleEmployee(employee_key);

    this.allProjects = this.projectService.getAllExpenseProjects(false);

    this.loadCurrentMonthOfExpenses();
  }

  newExpense() {
    const expense_date = _.cloneDeep(this.currentMonth);

    if (moment(expense_date).isSame(moment(), 'month')) {
      const today = new Date();
      expense_date.setDate(today.getDate());
    }

    this.stateService.go('app.expense.camera', {
      expense_date,
      employee_key: this.employee.employee_key
    });
  }

  editExpense(expense: PhExpense) {
    if (!expense.ph_project.non_team_project_flag) {
      this.stateService.go('app.expense.edit', {
        expense,
        expense_key: expense.expense_key
      });
    }
  }

  loadCurrentMonthOfExpenses() {
    return new Promise<void>((resolve, reject) => {

      this.expenseService.loadMonthOfExpenses(this.currentMonth, this.employee.employee_key)
        .then((expenses) => {
          this.monthsExpenses = ExpenseService.groupExpensesByStatus(expenses);
          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  refreshExpenses(event: any) {
    this.loadCurrentMonthOfExpenses()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfExpenses();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfExpenses();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfExpenses();
  }

}
