import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { UtilService } from './../../../services/util/util.service';
import { InvApprovalService } from './../../../services/inv-approval/inv-approval.service';
import { InvApprovalUtilService } from './../../../services/inv-approval-util/inv-approval-util.service';

@Component({
  selector: 'app-inv-approval-view',
  templateUrl: './inv-approval-view.component.html',
  styleUrls: ['./inv-approval-view.component.scss']
})
export class InvApprovalViewComponent implements OnInit {

  menuItems: any[] = [];

  currrent_uac_key: number = null;
  userApprovalKey: number = null;

  currencySymbol: string;

  approvalProject: any = null;
  approvalSegments: any[] = [];

  unitGroups: any[];

  segmentUnitTotals: any = {};
  segmentModified: boolean = false;
  segmentModifiedMessage: string = 'One or more records have been edited since the request was made.';

  constructor(
    public stateService: StateService,
    public stateChangeService: StateChangeService,
    public utilService: UtilService,
    public invApprovalService: InvApprovalService,
    public invCompanyService: InvCompanyService,
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit() {
    this.currrent_uac_key = this.invCompanyService.getUserAccessCompanyKey();
    this.currencySymbol = this.utilService.currencySymbol;
    this.approvalProject = this.uiRouterGlobals.params.approval_project;
    this.approvalSegments = this.uiRouterGlobals.params.approval_segments;

    if (this.approvalProject && this.approvalSegments) {
      this.initGroupedSegments();
      this.initMenuItems();
    }
    else {
      this.back();
    }
  }

  back() {
    this.stateChangeService.back();
  }

  initGroupedSegments() {
    this.calculateSegmentUnitTotals();
    this.checkApprovalSegmentsHaveBeenModified();

    const projects = InvApprovalUtilService.groupApprovalRequestSegments(this.approvalSegments, this.currencySymbol);
    this.unitGroups = projects.length ? projects[0].unitTypeGroups : [];
  }

  checkApprovalSegmentsHaveBeenModified() {
    for (const segment_summary of this.approvalProject.segment_summary) {
      const segUnitTotal = this.segmentUnitTotals[segment_summary.unit_type] || null;

      if (segUnitTotal !== segment_summary.quantity) {
        this.segmentModified = true;
      }
    }
  }

  calculateSegmentUnitTotals() {
    this.segmentUnitTotals = {};

    for (const segment of this.approvalSegments) {
      const unitValue = segment.unit_flag ? segment.units : segment.duration;

      if (this.segmentUnitTotals[segment.unit_type]) {
        this.segmentUnitTotals[segment.unit_type] += unitValue;
      }
      else {
        this.segmentUnitTotals[segment.unit_type] = unitValue;
      }
    }
  }

  initMenuItems() {
    let requestHasResponse = false;

    for (const approvalUser of this.approvalProject.approval_users) {
      if (approvalUser.user_access_company_key === this.currrent_uac_key && !!approvalUser.response_date) {
        requestHasResponse = true;
        this.userApprovalKey = approvalUser.user_approval_key;
        break;
      }
    }

    if (requestHasResponse) {
      this.menuItems = [
        {
          label: 'Remove Response',
          icon_class: 'ion-md-close',
          color_class: '-color-primary-clear',
          select_value: 'REMOVE',
          disabled: false
        }
      ];
    }
    else {
      this.menuItems = [
        {
          label: 'Approve',
          icon_class: 'ion-md-checkmark',
          color_class: '-color-success-clear',
          select_value: 'APPROVE',
          disabled: false
        },
        {
          label: 'Reject',
          icon_class: 'ion-md-close',
          color_class: '-color-danger-clear',
          select_value: 'REJECT',
          disabled: false
        }
      ];
    }
  }

  menuItemSelected(event: any) {
    switch (event.select_value) {
      case 'APPROVE':
        this.approveRequest();
        break;
      case 'REJECT':
        this.rejectRequest();
        break;
      case 'REMOVE':
        this.removeResponse();
        break;
    }
  }

  approveRequest() {
    this.invApprovalService.respondToApprovalRequests([this.approvalProject], true)
      .finally(() => {
        this.back();
      });
  }

  rejectRequest() {
    this.invApprovalService.respondToApprovalRequests([this.approvalProject], false)
      .finally(() => {
        this.back();
      });
  }

  removeResponse() {
    if (this.userApprovalKey) {
      this.invApprovalService.removeApprovalResponse(
        this.userApprovalKey,
        this.approvalProject.approval_request_key
      )
        .finally(() => {
          this.back();
        });
    }
  }

}
