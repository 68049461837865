<div class="onboard-step-type">
  Download
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">

  <a class="-color-payhero-clear -size-btn-lg -size-text-lg onboard-step-contentsLinkBtn"
     target="_blank"
     [href]="inv_blob_url + stepTemplate.document_url + '?' + invOnboardService.blobSASKey"
     (click)="downloadClickedToggled()">
    <i class="ion ion-md-download"></i>
    Download
  </a>

  <div class="onboard-step-download-fileDescription"
       *ngIf="stepTemplate.description">
    {{stepTemplate.description}}
  </div>

  <div class="onboard-step-download-signature"
       *ngIf="stepTemplate.signature_flag">

    <div class="-signatureDescription"
         *ngIf="stepTemplate.signature_description">
      {{stepTemplate.signature_description}}
    </div>

    <div class="-signatureCheckbox">
      <ph-checkbox [(ngModel)]="signatureProvided"
                   (ngModelChange)="signatureProvidedToggled()">
      </ph-checkbox>
      <span>Sign this document</span>
    </div>

    <div class="-signatureResult"
         *ngIf="signatureProvided">
      Signed by <span>{{display_name}}</span>
    </div>
    <div class="-signatureResult"
         *ngIf="signatureProvided">
      Signed on <span>{{signatureDate | date : 'dd/MM/yyyy'}}</span>
    </div>

  </div>
</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
