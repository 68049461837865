<div class="onboard-step-type">
  Link
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">

  <a class="-color-payhero-clear -size-btn-lg -size-text-lg onboard-step-contentsLinkBtn"
     target="_blank"
     [href]="parsedTemplateLink"
     (click)="clickLink()">
    <i class="ion ion-ios-link"></i>
    View Link
  </a>
  <div class="onboard-step-contentsInfo">
    View the link to complete this step
  </div>

</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
