import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absValuePipe',
})
export class AbsValuePipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: number, ...args) {
    return Math.abs(value);
  }
}
