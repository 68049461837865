import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';

@Component({
  selector: 'onboard-checkbox',
  templateUrl: './onboard-checkbox.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-checkbox.component.scss']
})
export class OnboardCheckboxComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  checkboxSelected: boolean = false;

  constructor(public invOnboardService: InvOnboardService){

  }

  ngOnInit(){
    this.checkboxSelected = this.stepAnswer ? this.stepAnswer.checkbox_flag : false;
  }

  checkboxSelectedToggled(){
    this.updateStepAnswer();
  }

  stepAnswerNeedsUpdating(): boolean {
    if (this.stepAnswer){
      return this.checkboxSelected !== this.stepAnswer.checkbox_flag;
    }
    else {
      return this.checkboxSelected === true;
    }
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (this.checkboxSelected){
      stepData.checkbox_flag = true;
    }
    else {
      stepData.deleted_flag = true;
    }

    return stepData;
  }

  updateStepAnswer(){
    if (this.stepAnswerNeedsUpdating()){

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
