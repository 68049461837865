import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { PayService } from '../../../services/pay/pay.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { CompanyService } from './../../../services/company/company.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

import * as  _ from 'lodash';

@Component({
  selector: 'app-pay-employee',
  templateUrl: './pay-employee.component.html',
  styleUrls: ['./pay-employee.component.scss']
})
export class PayEmployeeComponent extends PageMonthYearView implements OnInit {

  employee: any;
  loggedInEmployeeKey: number;

  monthsPays: any[] = [];

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateDataService: StateDataService,
    public payService: PayService,
    public employeeService: EmployeeService,
    public companyService: CompanyService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;

    const employee_key = this.stateService.params.employee_key;
    this.employee = this.employeeService.getSingleEmployee(employee_key) || this.employeeService.getLoggedInEmployee();

    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    this.loadCurrentMonthOfPays();
  }

  viewPay(pay: any) {
    if (!!pay.pay_key) {
      this.stateService.go('app.pay.view', { pay_key: pay.pay_key });
    }
  }

  loadCurrentMonthOfPays() {
    return new Promise<any>((resolve, reject) => {

      this.payService.loadMonthOfPays(this.currentMonth)
        .then((pays) => {
          this.monthsPays = pays;
          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  refreshPays(event: any) {
    this.loadCurrentMonthOfPays()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfPays();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfPays();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfPays();
  }

}
