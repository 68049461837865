<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>My Payslips</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshPays($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-payEmployee-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible && !monthChanging &&
                !monthsPays.length">
      <i class="ion ion-md-card"></i>
      <div>No payslips this month</div>
    </div>

    <div class="ph-payEmployee-monthContainer"
         *ngIf="!yearGridVisible">

      <div class="ph-payEmployee-slip"
           *ngFor="let pay of monthsPays"
           [ngClass]="{ '-locked': !pay.pay_key }"
           (click)="viewPay(pay)">

        <div class="ph-payEmployee-slipPeriod">

          <div class="ph-payEmployee-slipPeriodDates"
               *ngIf="pay.pay_key">

            {{pay.pay_start_date | date : "dd"}} -
            {{pay.pay_end_date | date : "dd"}}
            <span>{{pay.pay_end_date | date : "MMM"}}</span>
          </div>
          <div class="ph-payEmployee-slipPeriodDates"
               *ngIf="!pay.pay_key">

            {{pay.pay_end_date | date : "dd"}}
            <span>{{pay.pay_end_date | date : "MMM"}}</span>
          </div>
          <div class="ph-payEmployee-slipPeriodHours">
            {{pay.hours_worked}} <span>Hours</span>
          </div>
        </div>

        <div class="ph-payEmployee-slipAmount">
          <div class="ph-payEmployee-slipAmountLabel">
            {{pay.pay_key ? "Take Home Pay:" : "Earnings"}}
          </div>
          <div class="ph-payEmployee-slipAmountValue">
            {{(pay.pay_key ? pay.payments : pay.earnings) | currency}}
          </div>
        </div>

        <i class="ion ion-ios-arrow-forward"
           *ngIf="pay.pay_key">
        </i>

      </div>
    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
