import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';

@Component({
  selector: 'onboard-link',
  templateUrl: './onboard-link.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-link.component.scss']
})
export class OnboardLinkComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  parsedTemplateLink: string;
  linkClicked: boolean = false;

  readonly urlProtocolParser: any = /^https?:\/\//i;

  constructor(public invOnboardService: InvOnboardService){

  }

  ngOnInit(){
    if (!this.urlProtocolParser.test(this.stepTemplate.url)){
      this.parsedTemplateLink = 'http://' + this.stepTemplate.url;
    }
    else {
      this.parsedTemplateLink = this.stepTemplate.url;
    }
    this.linkClicked = this.stepAnswer !== null;
  }

  clickLink(){
    this.linkClicked = true;
    this.updateStepAnswer();
  }

  stepAnswerNeedsUpdating(): boolean {
    if (this.stepAnswer){
      return false;
    }
    else {
      return this.linkClicked;
    }
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (!this.linkClicked){
      stepData.deleted_flag = true;
    }

    return stepData;
  }

  updateStepAnswer(){
    if (this.stepAnswerNeedsUpdating()){

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
