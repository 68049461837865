import { TimeUtilService } from './../../services/time-util/time-util.service';

import { Expense } from './expense';
import { ExpenseInterface } from './../../interfaces/expense.interface';

import * as moment from 'moment';

export class PhExpense extends Expense implements ExpenseInterface {

  expense_key: number;

  private _employee: any;
  ph_project: any;

  document_key: number;
  document_name: string;
  document_url: string;

  gst_amount: number = 0;

  private _is_active_employee: boolean;

  constructor(
    expense_key: number = null, employee: any, ph_project: any,
    document_key: number = null, document_name: string = null, document_url: string = null,
    expense_amount: number = 0, expense_date: Date, description: string = '',
    ph_approval_date: Date = null, ph_declined_date: Date = null, ph_paid_flag: boolean = false
  ) {

    super(
      expense_amount, expense_date, description,
      ph_approval_date, ph_declined_date, ph_paid_flag, true
    );

    this.expense_key = expense_key;

    this._employee = employee;
    this.ph_project = ph_project;

    this.document_key = document_key;
    this.document_name = document_name;
    this.document_url = document_url;

    this._updateIsActiveEmployee();
  }

  // Super class function extensions /////////////////////////////////
  get expense_date(): Date {
    return super.expense_date;
  }
  set expense_date(expense_date: Date) {
    super.expense_date = expense_date;
    this._updateIsActiveEmployee();
  }

  // Interface getters/setters //////////////////////////////////////
  get is_active_project(): boolean {
    return this.ph_project.active_flag;
  }

  get is_locked(): boolean {
    return !this.is_active_project ||
      this.ph_project.non_team_project_flag ||
      this._ph_response_step > 2 ||
      (this._ph_response_step === 2 && !this.employee.manageable_flag);
  }

  get is_ph_expense(): boolean {
    return true;
  }
  get is_inv_expense(): boolean {
    return false;
  }

  // Class getters/setters //////////////////////////////////////
  get is_active_employee(): boolean {
    return this._is_active_employee;
  }

  get employee(): any {
    return this._employee;
  }
  set employee(employee: any) {
    this._employee = employee;

    this._updateIsActiveEmployee();
  }

  // Interface functions //////////////////////////////////////
  formatExpenseForPosting(): any {
    return {
      expense_key: this.expense_key,
      employee_key: this.employee.employee_key,
      project_key: this.ph_project.project_key,
      document_key: this.document_key,
      expense_amount: this.expense_amount,
      gst_amount: this.gst_amount,
      expense_date: TimeUtilService.dateToDateString(this.expense_date),
      description: this.description || '',
      approval_date: this.ph_declined_date ? null : TimeUtilService.dateToDateTimeString(this.ph_approval_date),
      declined_date: TimeUtilService.dateToDateTimeString(this.ph_declined_date),
      deleted_flag: this.deleted_flag
    };
  }

  // Class functions //////////////////////////////////////
  private _updateIsActiveEmployee() {
    const expenseDate = moment(this.expense_date);
    const employeeStartDate = moment(this.employee.employment_start_date);
    const employeeEndDate = this.employee.employment_end_date ? moment(this.employee.employment_end_date) : null;

    this._is_active_employee = expenseDate.isSameOrAfter(employeeStartDate, 'day') &&
      (employeeEndDate === null || expenseDate.isSameOrBefore(employeeEndDate, 'day'));
  }

}
