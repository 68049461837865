<div class="onboard-step-type">
  Task
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents"
     *ngIf="!loading">

  <ph-checkbox [(ngModel)]="checkboxSelected"
               (ngModelChange)="checkboxSelectedToggled()">
  </ph-checkbox>
  <div class="onboard-step-contentsInfo">
    Tap to confirm you've completed this task
  </div>

</div>
<div class="onboard-step-loading"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
