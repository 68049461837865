import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { InvTaskUtilService } from './../../../services/inv-task-util/inv-task-util.service';
import { UtilService } from './../../../services/util/util.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { InvUserService } from './../../../services/inv-user/inv-user.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvTaskService } from './../../../services/inv-task/inv-task.service';
import { InvTimeService } from './../../../services/inv-time/inv-time.service';
import { InvUserUtilService } from 'src/app/services/inv-user-util/inv-user-util.service';

import { InvSegment } from './../../../models/segment/inv-segment';

@Component({
  selector: 'app-inv-time-manager',
  templateUrl: './inv-time-manager.component.html',
  styleUrls: ['./inv-time-manager.component.scss']
})
export class InvTimeManagerComponent implements OnInit {

  currencySymbol: string;

  weekStart: Date;
  weekEnd: Date;

  activeResources: any[] = [];
  filteredResources: any[] = [];
  timeSummaryMap: any = {};

  search: string = '';

  loading: boolean = true;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateDataService: StateDataService,
    public invUserService: InvUserService,
    public invTimeService: InvTimeService,
    public invProjectService: InvProjectService,
    public invTaskService: InvTaskService,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.currencySymbol = this.utilService.currencySymbol;
    this.weekStart = this.stateDataService.selectedWeekStart;
    this.loadWeeksTime();
  }

  searchUpdated() {
    this.filteredResources = this.getFilteredResources();
  }

  clearSearch() {
    this.search = '';
    this.searchUpdated();
  }

  viewResourceTime(user: any) {
    this.stateService.go('app.inv.time.resource', {
      user_key: user.user_key
    });
  }

  refreshWeeksTime(event: any) {
    this.loadWeeksTime()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  loadWeeksTime() {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      this.weekEnd = TimeUtilService.getWeekEnd(this.weekStart);
      this.activeResources = this.invUserService.getAllActiveResources(this.weekStart, this.weekEnd);
      this.filteredResources = this.getFilteredResources();

      this.invTimeService.loadSegments(this.weekStart, this.weekEnd)
        .then((segments) => {
          this.loadSegmentsIntoTimeSummaryMap(segments);
          this.loading = false;
          resolve();
        })
        .catch(() => {
          this.loading = false;
          reject();
        });
    });
  }

  getFilteredResources() {
    const filtered = [];
    const search = this.search.toUpperCase();

    for (const user of this.activeResources) {
      if (user.display_name.toUpperCase().indexOf(search) !== -1) {
        filtered.push(user);
      }
      else {
        const userProjects = this.timeSummaryMap[user.user_key];

        for (const userProject of userProjects) {

          if (userProject.project.project_name.toUpperCase().indexOf(search) !== -1 ||
            userProject.task.task_name.toUpperCase().indexOf(search) !== -1) {

            filtered.push(user);
            break;
          }
        }
      }
    }

    InvUserUtilService.sortUsersByName(filtered);

    return filtered;
  }

  loadSegmentsIntoTimeSummaryMap(segments: InvSegment[]) {
    this.timeSummaryMap = {};

    for (const user of this.activeResources) {
      this.timeSummaryMap[user.user_key] = [];
    }

    for (const segment of segments) {
      const userProjectTasks = this.timeSummaryMap[segment.user.user_key] || null;

      if (userProjectTasks !== null) {
        let foundMatchingProjectTask = false;

        for (const userProjectTask of userProjectTasks) {
          if (userProjectTask.project.project_key === segment.inv_project.project_key &&
            userProjectTask.task.task_key === segment.task.task_key) {

            userProjectTask.segments.push(segment);
            userProjectTask.total += (segment.unit_flag ? segment.units : segment.duration);
            foundMatchingProjectTask = true;
          }
        }

        if (!foundMatchingProjectTask) {
          userProjectTasks.push({
            project: this.invProjectService.getSingleProject(segment.inv_project.project_key),
            task: InvTaskUtilService.getTaskFromList(segment.task.task_key, segment.inv_project.tasks),
            unit_type: segment.unit_type,
            segments: [segment],
            total: (segment.unit_flag ? segment.units : segment.duration)
          });
        }
      }
    }

    for (const user_key of Object.keys(this.timeSummaryMap)) {
      const userInMap = this.timeSummaryMap[user_key];
      // Sort user projects
      userInMap.sort((a: any, b: any) => {
        const taskNameA = a.task.task_display_name.toUpperCase();
        const taskNameB = b.task.task_display_name.toUpperCase();

        if (taskNameA > taskNameB) {
          return 1;
        }
        else if (taskNameA < taskNameB) {
          return -1;
        }
        return 0;
      });
    }
  }

  goForward() {
    this.forwardWeek();
  }

  goBack() {
    this.backWeek();
  }

  forwardWeek() {
    this.weekStart = TimeUtilService.incrementDate(this.weekStart, 7);
    this.stateDataService.selectedWeekStart = this.weekStart;
    this.loadWeeksTime();
  }

  backWeek() {
    this.weekStart = TimeUtilService.incrementDate(this.weekStart, -7);
    this.stateDataService.selectedWeekStart = this.weekStart;
    this.loadWeeksTime();
  }

}
