import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { StateDataService } from './../../services/state-data/state-data.service';
import { UtilService } from './../../services/util/util.service';
import { LeaveService } from './../../services/leave/leave.service';
import { LeaveUtilService } from './../../services/leave-util/leave-util.service';
import { TimeUtilService } from './../../services/time-util/time-util.service';
import { EmployeeService } from './../../services/employee/employee.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'calendar-slides',
  templateUrl: './calendar-slides.component.html',
  styleUrls: ['./calendar-slides.component.scss']
})
export class CalendarSlidesComponent implements OnInit {

  @ViewChild('ionSlides') ionSlides: any;

  @Input() employee_key: number;

  @Output() leaveRequestSelected = new EventEmitter();
  @Output() yearCalendarVisibleUpdated = new EventEmitter();
  @Output() currentMonthUpdated = new EventEmitter();
  @Output() leaveRequestsUpdated = new EventEmitter();

  private _yearCalendarVisible: boolean = false;
  get yearCalendarVisible(): boolean {
    return this._yearCalendarVisible;
  }
  set yearCalendarVisible(yearCalendarVisible: boolean){
    this._yearCalendarVisible = yearCalendarVisible;
    this.yearCalendarVisibleUpdated.emit({ yearCalendarVisible });
  }

  private _currentMonth: Date = null;
  get currentMonth(): Date {
    return this._currentMonth;
  }
  @Input() set currentMonth(currentMonth: Date){
    this._currentMonth = currentMonth;
    this.currentMonthUpdated.emit({ currentMonth });
  }

  private _leaveRequests: any[] = null;
  get leaveRequests(): any[] {
    return this._leaveRequests;
  }
  set leaveRequests(leaveRequests: any[]){
    this._leaveRequests = leaveRequests;
    this.leaveRequestsUpdated.emit({ leaveRequests });
  }

  readonly dayLabels: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  slideOptions: any = null;

  slideMonthsMap: any = {};
  slideMonths: any[] = [];
  yearCalendar: any[] = [];

  currentMonthIndex: number = null;

  yearsInitialised: any = {};
  yearsLoaded: any = {};

  loading: boolean = true;
  calendarInitialised: boolean = false;
  monthChanging: boolean = false;
  yearChanging: boolean = false;

  constructor(public stateDataService: StateDataService,
              public leaveService: LeaveService,
              public employeeService: EmployeeService) {

  }

  ngOnInit() {
    this.currentMonthIndex = this.currentMonth.getMonth();
    this.leaveRequests = [];

    const currentYear = this.currentMonth.getFullYear();
    const years = [currentYear];

    if (this.currentMonth.getMonth() === 0){
      years.push(currentYear - 1);
      this.currentMonthIndex += 12;
    }
    else if (this.currentMonth.getMonth() === 11){
      years.push(currentYear + 1);
    }

    this.initSlideMonthsInMap(years);
    this.loadLeaveRequestsForYears(years)
      .finally(() => {
        this.slideOptions = {
          initialSlide: this.currentMonthIndex
        };
        setTimeout(() => {
          this.calendarInitialised = true;
        });
      });
  }

  refreshCalendar(){
    return new Promise<any>((resolve) => {
      this.loading = true;

      this.slideMonthsMap = {};
      this.slideMonths = [];
      this.yearCalendar = [];

      this.yearsInitialised = {};
      this.yearsLoaded = {};

      const currentYear = this.currentMonth.getFullYear();
      const years = [currentYear];

      this.initSlideMonthsInMap(years);
      this.loadLeaveRequestsForYears(years)
        .finally(() => {
          if (this.yearCalendarVisible){
            this.generateYearCalendar();
          }
          resolve();
        });
    });
  }

  goForward(){
    if (this.yearCalendarVisible){
      this.forwardYear();
    }
    else {
      this.forwardMonth();
    }
  }

  goBack(){
    if (this.yearCalendarVisible){
      this.backYear();
    }
    else {
      this.backMonth();
    }
  }

  forwardMonth(){
    this.monthChanging = true;
    this.ionSlides.slideNext();
  }

  backMonth(){
    this.monthChanging = true;
    this.ionSlides.slidePrev();
  }

  forwardYear(){
    this.yearChanging = true;

    const lastSlideMonth = this.slideMonths[this.slideMonths.length - 1].date;
    const nextYear = lastSlideMonth.getFullYear() + 1;

    if (lastSlideMonth.getFullYear() === this.currentMonth.getFullYear()){

      this.initSlideMonthsInMap([nextYear]);
      this.loadLeaveRequestsForYears([nextYear])
        .then(() => {
          this.updateYearCalendar(true);
        });
    }
    else {
      this.updateYearCalendar(true);
    }
  }

  backYear(){
    this.yearChanging = true;

    const firstSlideMonth = this.slideMonths[0].date;
    const previousYear = firstSlideMonth.getFullYear() - 1;

    if (firstSlideMonth.getFullYear() === this.currentMonth.getFullYear()){

      this.initSlideMonthsInMap([previousYear]);
      this.loadLeaveRequestsForYears([previousYear])
        .then(() => {
          this.updateYearCalendar(false);
        });
    }
    else {
      this.updateYearCalendar(false);
    }
  }

  updateYearCalendar(forward: boolean){
    this.currentMonth = TimeUtilService.incrementYear(this.currentMonth, (forward ? 1 : -1));
    this.generateYearCalendar();
  }

  generateYearCalendar(){
    this.yearCalendarVisible = true;

    this.yearCalendar = [];
    let currentMonth = new Date(this.currentMonth.getFullYear(), 0, 1);

    for (let i = 0; i < 12; i++){
      this.yearCalendar.push({
        monthLabel: moment(currentMonth).format('MMMM'),
        date: currentMonth,
        weeks: this.slideMonthsMap[currentMonth.getFullYear() + '_' + currentMonth.getMonth()].weeks
      });

      currentMonth = TimeUtilService.incrementMonth(currentMonth, 1);
    }

    this.yearChanging = false;
  }

  editLeave(day: any) {
    this.leaveRequestSelected.emit({
      leaveRequest: day.leaveRequests[0]
    });
  }

  toggleYearCalendarVisibility(){
    this.yearCalendarVisible = !this.yearCalendarVisible;

    if (this.yearCalendarVisible){
      this.generateYearCalendar();
    }
    else {
      this.showCalendarMonth(this.currentMonth);
    }
  }

  showCalendarMonth(month: Date){
    this.yearCalendarVisible = false;
    this.yearChanging = false;
    this.yearCalendar = [];

    const monthIndex = this.getSlideIndexForMonth(month);
    this.ionSlides.slideTo(monthIndex, 0);
  }

  getSlideIndexForMonth(month: Date){
    for (let i = 0; i < this.slideMonths.length; i++){
      const date = this.slideMonths[i].date;

      if (date.getFullYear() === month.getFullYear() &&
          date.getMonth() === month.getMonth()){
        return i;
      }
    }
    return 0;
  }

  slideChanged() {
    this.monthChanging = false;

    this.ionSlides.getActiveIndex()
      .then((index: number) => {
        this.currentMonthIndex = index;
        this.currentMonth = this.slideMonths[this.currentMonthIndex].date;

        const loadPreviousYear = this.currentMonthIndex === 0;
        const loadNextYear = this.currentMonthIndex === this.slideMonths.length - 1;

        // Load another year of leave if we slide to the newest or oldest month
        if (loadPreviousYear || loadNextYear){
          const currentYear = this.currentMonth.getFullYear() + (loadPreviousYear ? -1 : 1);

          if (loadPreviousYear){
            this.currentMonthIndex += 12;
          }

          this.initSlideMonthsInMap([currentYear]);
          this.reloadSlideMonthsFromMap();

          this.currentMonth = this.slideMonths[this.currentMonthIndex].date;

          setTimeout(() => {
            this.ionSlides.slideTo(this.currentMonthIndex, 0, false);
          });

          this.loadLeaveRequestsForYears([currentYear])
            .then(() => {
              // Loaded previous year so current monthSlide is pushed forward by 12
              if (currentYear < this.currentMonth.getFullYear()){
                setTimeout(() => {
                  this.ionSlides.slideTo(this.currentMonthIndex, 0, false);
                });
              }
            });
        }
    });
  }

  initSlideMonthsInMap(years: number[]) {
    for (const year of years){
      let monthStart = new Date(year, 0, 1);

      // Iterate months
      for (let i = 0; i < 12; i++) {
        const firstDay = _.cloneDeep(monthStart);
        firstDay.setDate(2 - (monthStart.getDay() === 0 ? 7 : monthStart.getDay()));

        const slideMonth = {
          date: monthStart,
          weeks: []
        };
        let currentDay = _.cloneDeep(firstDay);

        // Iterate weeks
        for (let j = 0; j < 6; j++) {
          const week = [];

          // Iterate days
          for (let k = 0; k < 7; k++) {
            week.push({
              date: currentDay,
              dateLabel: currentDay.getDate(),
              currentMonth: currentDay.getMonth() === monthStart.getMonth(),
              leaveRequests: [],
              startOfLeave: false,
              endOfLeave: false,
              startOfMonth: currentDay.getDate() === 1,
              endOfMonth: TimeUtilService.isLastDayInMonth(currentDay),
              selectedLeaveToday: false
            });

            currentDay = _.cloneDeep(currentDay);
            currentDay.setDate(currentDay.getDate() + 1);
          }
          slideMonth.weeks.push(week);
        }
        monthStart = _.cloneDeep(monthStart);
        monthStart.setMonth(monthStart.getMonth() + 1);

        this.addSlideMonthToMap(slideMonth);
      }
      this.yearsInitialised[year] = true;
    }
  }

  addSlideMonthToMap(slideMonth: any) {
    const slideMonthKey = slideMonth.date.getFullYear() + '_' + slideMonth.date.getMonth();
    this.slideMonthsMap[slideMonthKey] = slideMonth;
  }

  reloadSlideMonthsFromMap() {
    const slideMonthData = [];

    for (const slideMonthKey of Object.keys(this.slideMonthsMap)) {
      const year = parseInt(slideMonthKey.slice(0, 4), null);
      const month = parseInt(slideMonthKey.slice(5, slideMonthKey.length), null);
      const date = new Date(year, month, 1);

      slideMonthData.push({
        date,
        slideMonth: this.slideMonthsMap[slideMonthKey]
      });
    }

    const sortedSlideMonthData = slideMonthData.sort((a, b) => {
      if (a.date.valueOf() > b.date.valueOf()) {
        return 1;
      }
      else if (a.date.valueOf() < b.date.valueOf()) {
        return -1;
      }
      return 0;
    });

    const slideMonths = [];
    for (const data of sortedSlideMonthData) {
      slideMonths.push(data.slideMonth);
    }

    this.slideMonths = slideMonths;
  }

  loadLeaveRequestsForYears(years: number[]) {
    return new Promise<any>((resolve, reject) => {
      this.loading = true;

      const startDate = LeaveUtilService.getFirstDateInListOfYears(years);
      const endDate = LeaveUtilService.getLastDateInListOfYears(years);

      this.leaveService.loadLeaveRequests(startDate, endDate, this.employee_key)
        .then((leaveRequests) => {
          this.leaveRequests = this.leaveRequests.concat(leaveRequests);
          this.leaveRequests = LeaveUtilService.sortLeaveRequests(this.leaveRequests);

          this.reloadLeaveRequestsIntoSlideMonths();

          for (const year of years){
            this.yearsLoaded[year] = true;
          }
          this.loading = false;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  reloadLeaveRequestsIntoSlideMonths(){
    this.reloadSlideMonthsFromMap();

    for (const leaveRequest of this.leaveRequests) {
      this.loadLeaveRequestIntoSlideMonths(leaveRequest);
    }
  }

  getSlideMonthsForLeaveRequest(leaveRequest: any) {
    const leaveStartMonth = moment(leaveRequest.start_date);
    const leaveEndMonth = moment(leaveRequest.end_date);

    const slideMonths = [];

    for (const slideMonth of this.slideMonths) {
      const currentMonth = moment(slideMonth.date);

      if (currentMonth.isSameOrAfter(leaveStartMonth, 'month')) {
        slideMonths.push(slideMonth);

        if (currentMonth.isSame(leaveEndMonth, 'month')) {
          break;
        }
      }
    }

    return slideMonths;
  }

  loadLeaveRequestIntoSlideMonths(leaveRequest: any) {
    if (leaveRequest.declined_flag) {
      return;
    }

    const monthGrids = this.getSlideMonthsForLeaveRequest(leaveRequest);
    const requestStart = moment(leaveRequest.start_date);
    const requestEnd = moment(leaveRequest.end_date);

    for (const monthGrid of monthGrids) {
      for (const week of monthGrid.weeks) {
        for (const day of week) {
          const date = moment(day.date);

          if (requestStart.isSameOrBefore(date, 'day') &&
              requestEnd.isSameOrAfter(date, 'day')) {

            this.addLeaveRequestToSlideMonthDay(day, leaveRequest);

            if (requestStart.isSame(date, 'day')) {
              day.startOfLeave = true;
            }
            if (requestEnd.isSame(date, 'day')) {
              day.endOfLeave = true;
            }
          }
        }
      }
    }
  }

  addLeaveRequestToSlideMonthDay(day: any, leaveRequest: any){
    for (let i = 0; i < day.leaveRequests.length; i++){
      const l = day.leaveRequests[i];

      if (l.leave_request_key === leaveRequest.leave_request_key){
        day.leaveRequests[i] = leaveRequest;
        return;
      }
    }

    day.leaveRequests.push(leaveRequest);
  }

}
