import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { ActionService } from './../action/action.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  locationSupported: boolean = !!navigator.geolocation;

  allLocations: any[] = [];
  allLocationsMap: any = {};

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public actionService: ActionService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadLocations()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.allLocations = [];
    this.allLocationsMap = {};

    this.serviceSetup = false;
  }

  getAllLocations(): any[] {
    return this.allLocations;
  }

  getSingleLocation(location_key: number): any {
    return this.allLocationsMap[location_key] || null;
  }

  loadLocations() {
    return new Promise<void>((resolve, reject) => {
      if (this.actionService.isActive('GPS_CLOCK_IN')) {
        this.apiService.phGet('location', null)
          .then((data) => {
            this.allLocations = [];
            this.allLocationsMap = {};

            for (let location of data) {
              location = LocationService.setupLocation(location);

              this.allLocationsMap[location.location_key] = location;
              this.allLocations.push(location);
            }

            this.allLocations = LocationService.sortLocations(this.allLocations);

            this.serviceSetup = true;

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        this.clearServiceData();
        resolve();
      }
    });
  }

  getCoordinates() {
    return new Promise<any>((resolve, reject) => {
      if (this.actionService.isActive('GPS_CLOCK_IN')) {
        const success = (location: any) => {
          resolve(location);
        };

        const error = (err: any) => {
          let errorMessage: string;

          switch (err.code) {
            case err.PERMISSION_DENIED:
              errorMessage = 'User denied the request for Geolocation.';
              break;
            case err.POSITION_UNAVAILABLE:
              errorMessage = 'Location information is unavailable.';
              break;
            case err.TIMEOUT:
              errorMessage = 'The request to get user location timed out.';
              break;
            case err.UNKNOWN_ERROR:
              errorMessage = 'An unknown error occurred.';
              break;
          }
          reject(err);
        };

        const options = {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 10000,
        };

        if (this.locationSupported) {
          navigator.geolocation.getCurrentPosition(
            success,
            error,
            options
          );
        }
        else {
          reject();
        }
      }
      else {
        reject();
      }
    });
  }

  static sortLocations(locations: any[]): any {
    return locations.sort((a: any, b: any) => {
      const locationNameA = a.location_name.toUpperCase();
      const locationNameB = b.location_name.toUpperCase();

      if (locationNameA > locationNameB) {
        return 1;
      }
      else if (locationNameA < locationNameB) {
        return -1;
      }
      return 0;
    });
  }

  static setupLocation(location: any): any {

    return location;
  }

}
