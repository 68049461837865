<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Team Time</ion-title>
    <ion-buttons slot="end">
      <button class="-color-white-clear -type-icon"
              *ngIf="approvalsEnabledFlag"
              (click)="openFiltersPopup()">
        <i class="ion ion-md-options"></i>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="ph-popover"
     *ngIf="filtersOpen">

     <div class="ph-timeManager-filter"
          (click)="approveSelected(true)">
      Approve Time
    </div>
    <div class="ph-timeManager-filter"
          (click)="approveSelected(false)">
      Unapprove Time
    </div>
</div>

  <div class="ph-pageOverlay"
     (click)="closeFilterPopup()"
     *ngIf="filtersOpen">
</div>

<ion-content>
  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshWeeksTime($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-spinner"
       *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && activeEmployees.length === 0">
    <i class="ion ion-ios-contacts"></i>
    <div>No active employees in your team</div>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && activeEmployees.length !== 0 && filteredEmployees.length === 0">
    <i class="ion ion-ios-search"></i>
    <div>No employees match your search</div>
  </div>

  <div class="ph-timeManager-periodSelector">
    <button class="-color-default-clear -type-icon"
            [disabled]="loading"
            (click)="goBack()">
      <i class="ion ion-ios-arrow-back"></i>
    </button>

    <div>
      <span>
        {{weekStart | date: 'd'}} - {{weekEnd | date: 'd LLLL,'}}
      </span>
      <span>
        {{weekEnd | date: 'yyyy'}}
      </span>
    </div>

    <button class="-color-default-clear -type-icon"
            [disabled]="loading"
            (click)="goForward()">
      <i class="ion ion-ios-arrow-forward"></i>
    </button>
  </div>

  <div class="ph-search">
    <i class="ion ion-ios-search"></i>
    <input type="search"
           placeholder="Search employees or work..."
           [(ngModel)]="search"
           (ngModelChange)="searchUpdated()">
    <i class="ion ion-ios-close"
       *ngIf="search.length"
       (click)="clearSearch()">
    </i>
  </div>

  <div class="ph-timeManager-weekContainer"
       *ngIf="!loading">

    <div class="ph-groupedList-container -timeManager"
        [ngStyle]="{ 'padding-left': approvalsEnabledFlag && currentEmployeeKey != employee.employee_key && employee.manageable_flag ? '30px' : ''}"
         *ngFor="let employee of filteredEmployees">

         <ph-checkbox class="ph-timeManager-employeeSelect"
                      *ngIf="approvalsEnabledFlag && currentEmployeeKey != employee.employee_key && employee.manageable_flag"
                      [btnSmall]="true"
                      [(ngModel)]="selectedEmployees[employee.employee_key]">
          </ph-checkbox>

      <div class="ph-groupedList-groupHeader"
          (click)="viewEmployeeTime(employee)">
        <div class="ph-groupedList-groupHeaderTitle">
          {{employee.employee_code}}
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
      </div>

      <div class="ph-groupedList-item"
           [ngClass]="{ '-timeBlock': approvalsEnabledFlag && currentEmployeeKey !== employee.employee_key && employee.manageable_flag }"
           *ngFor="let employeeProject of timeSummaryMap[employee.employee_key]"
           (click)="viewEmployeeTime(employee)">

        <div class="ph-groupedList-itemColor"
             [ngStyle]="{'background-color': employeeProject.project.project_colour}">
        </div>

        <div class="ph-groupedList-itemTitle">
          <span>{{employeeProject.project.project_title}}</span>
        </div>

        <div class="ph-groupedList-itemDetails">
          <div *ngIf="employeeProject.project.pay_code_unit === 'Hours'">
            {{employeeProject.total | hoursToTimePipe}} Hours
          </div>
          <div *ngIf="employeeProject.project.pay_code_unit === '$'">
            {{employeeProject.total | currency}}
          </div>
          <div *ngIf="employeeProject.project.pay_code_unit !== 'Hours' && employeeProject.project.pay_code_unit !== '$'">
            {{employeeProject.total}} {{employeeProject.project.pay_code_unit}}
          </div>

          <span>
            ({{employeeProject.segments.length}}
            Record{{employeeProject.segments.length > 1 ? 's' : ''}})
          </span>
        </div>

      </div>

      <div class="ph-groupedList-item -itemEmpty"
           *ngIf="timeSummaryMap[employee.employee_key].length === 0"
           (click)="viewEmployeeTime(employee)">
        No Time
      </div>

      <i class="ion ion-ios-arrow-forward"></i>
    </div>
  </div>

</ion-content>
