import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './../../components/components.module';
import { PipesModule } from './../../pipes/pipes.module';

import { InvOnboardResourceComponent } from './inv-onboard-resource/inv-onboard-resource.component';
import { InvOnboardComponent } from './inv-onboard.component';

export const INV_ONBOARD_CONFIG: any[] = [
  {
    name: 'app.inv.onboard',
    url: '/onboard',
    abstract: true,
    component: InvOnboardComponent
  },
  {
    name: 'app.inv.onboard.resource',
    url: '/resource?{user_key:int}',
    component: InvOnboardResourceComponent,
    params: {
      user_key: null
    }
  }
];

@NgModule({
  declarations: [
    InvOnboardResourceComponent,
    InvOnboardComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    ComponentsModule,
    FormsModule,
    UIRouterModule.forChild({ states: INV_ONBOARD_CONFIG })
  ]
})
export class InvOnboardModule { }
