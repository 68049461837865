import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';

import { InvSegment } from './../../models/segment/inv-segment';

@Injectable({
  providedIn: 'root'
})
export class InvApprovalUtilService {

  static setupApprovalRequests(approvalRequests: any[]): any[] {

    for (const approvalRequest of approvalRequests) {
      approvalRequest.request_date = TimeUtilService.dateTimeStringToDate(approvalRequest.request_date, null, false);
      approvalRequest.to_date = TimeUtilService.dateStringToDate(approvalRequest.to_date, null, false);
      approvalRequest.from_date = TimeUtilService.dateStringToDate(approvalRequest.from_date, null, false);

      for (const approvalProject of approvalRequest.approval_projects) {
        approvalProject.request_date = TimeUtilService.dateTimeStringToDate(approvalProject.request_date, null, false);
        approvalProject.to_date = TimeUtilService.dateStringToDate(approvalProject.to_date, null, false);
        approvalProject.from_date = TimeUtilService.dateStringToDate(approvalProject.from_date, null, false);

        for (const approvalUser of approvalProject.approval_users) {
          approvalUser.response_date = TimeUtilService.dateTimeStringToDate(approvalUser.response_date, null, false);

          approvalUser.initials = approvalUser.display_name.split(' ');
          approvalUser.initials = approvalUser.initials[0][0] + (approvalUser.initials[1] ? approvalUser.initials[1][0] : '');
        }
        InvApprovalUtilService.sortApprovalUsers(approvalProject.approval_users);
      }
    }

    InvApprovalUtilService.sortApprovalRequests(approvalRequests);

    return approvalRequests;
  }

  static groupApprovalRequestsByStatus(approvalRequests: any[]): any[] {
    const groupedApprovalRequests = [
      { status: 'Pending', approvalRequests: [] },
      { status: 'Complete', approvalRequests: [] }
    ];

    for (const approvalRequest of approvalRequests) {
      // Pending
      if (approvalRequest.pending_flag) {
        groupedApprovalRequests[0].approvalRequests.push(approvalRequest);
      }
      // Complete
      else {
        groupedApprovalRequests[1].approvalRequests.push(approvalRequest);
      }
    }

    InvApprovalUtilService.sortApprovalRequests(groupedApprovalRequests[0].approvalRequests);
    InvApprovalUtilService.sortApprovalRequests(groupedApprovalRequests[1].approvalRequests);

    return groupedApprovalRequests;
  }

  static sortApprovalUsers(approval_users: any[]): any[] {
    return approval_users.sort((a: any, b: any) => {
      if (a.approved_flag) {
        return -1;
      }
      else if (b.approved_flag) {
        return 1;
      }
      else if (a.rejected_flag) {
        return -1;
      }
      else if (b.rejected_flag) {
        return 1;
      }
      return 0;
    });
  }

  static sortApprovalRequests(approvalRequests: any[]): any[] {
    return approvalRequests.sort((a: any, b: any) => {
      if (a.request_date.valueOf() < b.request_date.valueOf()) {
        return 1;
      }
      else if (a.request_date.valueOf() > b.request_date.valueOf()) {
        return -1;
      }
      return 0;
    });
  }



  static groupApprovalRequestSegments(segments: InvSegment[], currencySymbol: string): any[] {

    const sortUnitTypeGroupsByUnit = (approvalProject: any) => {
      approvalProject.unitTypeGroups.sort((a: any, b: any) => {
        a = a.unit_type.toUpperCase();
        a = a.replace(' ', '');

        b = b.unit_type.toUpperCase();
        b = b.replace(' ', '');

        if (a > b) {
          return 1;
        }
        else if (a < b) {
          return -1;
        }
        return 0;
      });

      let i: number, group: any;
      for (i = 0; i < approvalProject.unitTypeGroups.length; i++) {
        group = approvalProject.unitTypeGroups[i];

        if (group.unit_type === 'expense') {
          approvalProject.unitTypeGroups.splice(i, 1);
          approvalProject.unitTypeGroups.unshift(group);
          break;
        }
      }
      for (i = 0; i < approvalProject.unitTypeGroups.length; i++) {
        group = approvalProject.unitTypeGroups[i];

        if (group.unit_type === 'days') {
          approvalProject.unitTypeGroups.splice(i, 1);
          approvalProject.unitTypeGroups.unshift(group);
          break;
        }
      }
      for (i = 0; i < approvalProject.unitTypeGroups.length; i++) {
        group = approvalProject.unitTypeGroups[i];

        if (group.unit_type === 'hours') {
          approvalProject.unitTypeGroups.splice(i, 1);
          approvalProject.unitTypeGroups.unshift(group);
          break;
        }
      }
    };
    const sortUnitTypeGroupSegmentsByDate = (group: any) => {
      group.segments.sort((a: any, b: any) => {
        if (a.segment_date.valueOf() > b.segment_date.valueOf()) {
          return 1;
        }
        else if (a.segment_date.valueOf() < b.segment_date.valueOf()) {
          return -1;
        }
        return 0;
      });
    };
    const getProjectUnitTypeGroup = (approvalProject: any, unitType: string) => {
      for (const group of approvalProject.unitTypeGroups) {
        if (group.unit_type === unitType) {
          return group;
        }
      }
      return null;
    };

    const projects = [];

    for (const segment of segments) {
      let segmentProject = null;

      for (const project of projects) {
        if (project.project_key === segment.inv_project.project_key) {
          segmentProject = project;
          break;
        }
      }

      if (segmentProject === null) {
        segmentProject = {
          project_name: segment.inv_project.project_name,
          project_key: segment.inv_project.project_key,
          client_name: segment.inv_project.client_name,
          unitTypeGroups: []
        };
        projects.push(segmentProject);
      }

      let unit_type: string;
      // Expense
      if (segment.unit_type === 'expense') {
        unit_type = currencySymbol + ' Expense';
      }
      // Hours, Days or Custom Unit
      else {
        unit_type = segment.unit_type;
      }

      const group = getProjectUnitTypeGroup(segmentProject, unit_type);

      if (group !== null) {
        group.segments.push(segment);
        group.total += segment.unit_flag ? segment.units : segment.duration;
      }
      else {
        segmentProject.unitTypeGroups.push({
          unit_type,
          segments: [segment],
          total: segment.unit_flag ? segment.units : segment.duration
        });
      }
    }

    // Sort project tasks and segments
    for (const project of projects) {
      sortUnitTypeGroupsByUnit(project);

      for (const unitTypeGroup of project.unitTypeGroups) {
        sortUnitTypeGroupSegmentsByDate(unitTypeGroup);
      }
    }

    return projects;
  }

}
