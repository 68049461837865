import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateDataService } from './../../../services/state-data/state-data.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { InvExpenseService } from './../../../services/inv-expense/inv-expense.service';
import { ExpenseService } from './../../../services/expense/expense.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvTaskService } from './../../../services/inv-task/inv-task.service';
import { UtilService } from './../../../services/util/util.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { InvUserService } from './../../../services/inv-user/inv-user.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-inv-expense-resource',
  templateUrl: './inv-expense-resource.component.html',
  styleUrls: ['./inv-expense-resource.component.scss']
})
export class InvExpenseResourceComponent extends PageMonthYearView implements OnInit {

  currencySymbol: string;

  user: any;
  loggedInUserKey: number;

  allProjects: any[] = [];
  monthsExpenses: any;

  constructor(
    public stateService: StateService,
    public stateDataService: StateDataService,
    public invExpenseService: InvExpenseService,
    public invProjectService: InvProjectService,
    public invTaskService: InvTaskService,
    public utilService: UtilService,
    public invCompanyService: InvCompanyService,
    public invUserService: InvUserService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;
    this.loggedInUserKey = this.invCompanyService.getUserKey();

    const user_key = this.stateService.params.employee_key || this.loggedInUserKey;
    this.user = this.invUserService.getSingleUser(user_key);

    this.allProjects = this.invProjectService.getAllActiveProjects(null, null, false, false, true, this.user.user_key);

    this.loadCurrentMonthOfExpenses();
  }

  newExpense() {
    const expense_date = _.cloneDeep(this.currentMonth);

    if (moment(expense_date).isSame(moment(), 'month')) {
      const today = new Date();
      expense_date.setDate(today.getDate());
    }

    this.stateService.go('app.expense.camera', {
      expense_date,
      user_key: this.user.user_key
    });
  }

  editExpense(expense: any) {
    this.stateService.go('app.inv.expense.edit', {
      expense,
      segment_key: expense.segment_key
    });
  }

  loadCurrentMonthOfExpenses() {
    return new Promise<any>((resolve, reject) => {

      this.invExpenseService.loadMonthOfExpenses(this.currentMonth, this.user.user_key)
        .then((expenses) => {
          this.monthsExpenses = expenses;
          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          this.monthsExpenses = [];
          this.monthChanging = false;
          reject();
        });
    });
  }

  refreshExpenses(event: any) {
    this.loadCurrentMonthOfExpenses()
      .finally(() => {
        event.target.complete();
      });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfExpenses();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfExpenses();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfExpenses();
  }

}
