import { ApiService } from './../../../services/api/api.service';
import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { UtilService } from '../../../services/util/util.service';
import { AlertService } from '../../../services/alert/alert.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { DomService } from './../../../services/dom/dom.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { InvExpenseService } from './../../../services/inv-expense/inv-expense.service';
import { InvUserService } from './../../../services/inv-user/inv-user.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvIntegrationService } from './../../../services/inv-integration/inv-integration.service';
import { InvProjectUtilService } from 'src/app/services/inv-project-util/inv-project-util.service';

import { PageExpenseEdit } from '../../../classes/abstract/page-expense-edit/page-expense-edit';

import { InvExpense } from './../../../models/expense/inv-expense';

import * as _ from 'lodash';

@Component({
  selector: 'app-inv-expense-edit',
  templateUrl: './inv-expense-edit.component.html',
  styleUrls: ['./inv-expense-edit.component.scss']
})
export class InvExpenseEditComponent extends PageExpenseEdit implements OnInit {

  readonly errorMessages: any = {
    noProjects: 'At least one placement must be active on the selected date to record time',
    inactiveProject: 'Time can\'t be recorded outside the period that the placement is active'
  };

  isPayHeroIntegrated: boolean;
  hasPayHeroLogin: boolean;

  invStatus: string;
  phStatus: string;
  resourceExpenseStatus: string;

  approvalUnlocked: boolean;
  editingUnlocked: boolean;

  expense: InvExpense;

  loggedInUserKey: number;
  userIsLabourHireOrClient: boolean;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public domSanitizer: DomSanitizer,
    public domService: DomService,
    public alertService: AlertService,
    public utilService: UtilService,
    public invCompanyService: InvCompanyService,
    public invExpenseService: InvExpenseService,
    public invUserService: InvUserService,
    public invProjectService: InvProjectService,
    public invIntegrationService: InvIntegrationService,
    public apiService: ApiService
  ) {
    super(
      stateService, domSanitizer, utilService, alertService, apiService
    );
  }

  ngOnInit() {
    this.isAdmin = this.invCompanyService.isAdminOrOwner();
    this.isManager = this.invCompanyService.isTimeManager();
    this.isPayHeroIntegrated = this.invIntegrationService.isPayHeroIntegrated() && this.invIntegrationService.hasPayHeroLogin();
    this.hasPayHeroLogin = this.invIntegrationService.hasPayHeroLogin();
    this.currencySymbol = this.utilService.currencySymbol;
    this.loggedInUserKey = this.invCompanyService.getUserKey();

    this.expense = _.cloneDeep(this.stateService.params.expense) || null;
    const segment_key = this.stateService.params.segment_key || null;

    if (this.expense) {
      this.initialiseData();
      this.initialiseExpenseImage();
    }
    else if (segment_key) {

      this.invExpenseService.loadExpense(segment_key)
        .then((expense) => {
          this.expense = expense;
          this.initialiseData();
          this.initialiseExpenseImage();
        })
        .catch(() => {
          this.back();
        });
    }
    else {
      const user_key = this.stateService.params.user_key || null;
      const project = this.getDefaultProject(user_key);

      if (project === null) {
        this.utilService.toastMessage('An active placement with expense work is required for recording expenses');
        this.back();
      }
      else {
        const user = this.getDefaultUser(project);
        const task = this.getDefaultTask(project);

        if (user !== null && task !== null) {
          this.expense = this.invExpenseService.initNewExpense(
            user.user_key,
            project.project_key,
            task.task_key,
            this.defaultExpenseDate
          );
          this.initialiseData();
          this.initialiseExpenseImage();
        }
        else {
          this.back();
        }
      }
    }
  }

  back() {
    this.stateChangeService.back(['app.expense.camera']);
  }

  initialiseData() {
    this.loading = true;

    this.initExpenseAmountTouched();
    this.setupEditingAndApprovalLocks();
    this.initUserIsLabourHireOrClient();
    this.initAllProjects();

    this.updateDateStrings();

    this.checkForErrors();
    this.setupModalButtonConfig();

    this.updatePageTitle();
    this.updateExpenseStatuses();

    this.loading = false;
  }

  initExpenseAmountTouched() {
    this.expenseAmountTouched = this.expense.segment_key !== null;
  }

  getDefaultProject(user_key: number = null): any {
    let project_key: number = null;

    if (this.stateService.params.project_key) {
      project_key = this.stateService.params.project_key;
    }
    else {
      // Get all projects active on default date
      let allProjects = this.invProjectService.getAllActiveProjects(
        this.defaultExpenseDate, this.defaultExpenseDate, false, false, true, user_key
      );

      if (allProjects.length) {
        project_key = allProjects[0].project_key;
      }
      else {
        // If no projects are active on default date, get all projects available
        // A warning will be provided to the user
        allProjects = this.invProjectService.getAllActiveProjects(
          null, null, false, false, true, user_key
        );

        if (allProjects.length) {
          project_key = allProjects[0].project_key;
        }
      }
    }

    return this.invProjectService.getSingleProject(project_key);
  }

  getDefaultUser(project: any): any {
    const user_key = this.stateService.params.user_key;
    const projectUser = project.projectUsersMap[user_key] || null;

    if (projectUser !== null && projectUser.resource_flag) {
      return projectUser;
    }
    // Default to first resource in project.users
    else {
      for (const pu of project.users) {
        if (pu.resource_flag) {

          return pu;
        }
      }
    }

    return null;
  }

  getDefaultTask(project: any): any {
    const task_key = this.stateService.params.task_key;
    const projectTask = project.projectTasksMap[task_key] || null;

    if (projectTask !== null && projectTask.unit_type === 'expense') {
      return projectTask;
    }
    // Default to first expense task in project.tasks
    else {
      const activeTasks = InvProjectUtilService.getActiveExpenseTasks(project.tasks);

      for (const pt of activeTasks) {
        if (pt.unit_type === 'expense') {

          return pt;
        }
      }
    }

    return null;
  }

  initAllProjects() {
    this.allProjects = this.invProjectService.getAllActiveProjects(
      this.expense.expense_date, this.expense.expense_date, false, false, true, this.expense.user.user_key
    );

    this.checkForErrors();
  }

  changeDocument() {
    if (this.editingUnlocked) {
      super.changeDocument();
    }
  }

  setupEditingAndApprovalLocks() {
    this.approvalUnlocked =
      this.hasPayHeroLogin &&
      !this.expense.ph_locked &&
      !this.expense.task.admin_only_flag &&
      (this.isAdmin || this.expense.ph_employee_manageable_flag);

    this.editingUnlocked =
      !this.expense.inv_locked &&
      !this.expense.task.admin_only_flag &&
      !(this.isPayHeroIntegrated && this.expense.ph_locked) &&
      (
        this.isAdmin ||
        (this.hasPayHeroLogin && this.expense.ph_employee_manageable_flag) ||
        // Consultant or Resource with manage_time_flag
        (!this.isPayHeroIntegrated && this.isManager) ||
        this.expense.user.user_key === this.loggedInUserKey
      );
  }

  initUserIsLabourHireOrClient() {
    const isClient = this.invCompanyService.isClient();
    const highestAuthIsResource = this.invCompanyService.getAuthValue() === 3;

    this.userIsLabourHireOrClient = isClient ||
      (highestAuthIsResource && this.expense.inv_project.project_type === 'Labour Hire');
  }

  setupModalButtonConfig() {
    this.showApproveBtn = false;
    this.showDeclineBtn = false;
    this.showSaveBtn = false;
    this.showDeleteBtn = false;

    if (this.expense.is_active_project) {
      if (this.approvalUnlocked) {
        this.showApproveBtn = true;

        // Existing expense
        if (this.expense.segment_key) {
          this.showDeclineBtn = true;
        }
      }
      else if (this.editingUnlocked) {
        this.showSaveBtn = true;

        // Existing expense
        if (this.expense.segment_key) {
          this.showDeleteBtn = true;
        }
      }
    }
  }

  expenseDateStringUpdated() {
    super.expenseDateStringUpdated();
    this.initAllProjects();
  }

  projectSelected(event: any) {
    this.expense.inv_project = event.item;

    this.updateDateStrings();
    this.setupModalButtonConfig();
  }

  taskSelected(event: any) {
    this.expense.task = event.item;
  }

  userSelected(user: any) {
    this.expense.user = user;

    this.updateDateStrings();
    this.setupModalButtonConfig();
  }

  openUserList() {
    if (this.editingUnlocked && !this.expense.segment_key) {

      this.domService.openSlideUpList(
        this.expense.project_users,
        this.expense.user,
        'display_name',
        null,
        (user: any) => {
          this.userSelected(user);
        }
      );
    }
  }

  saveExpense(to_approve: boolean = false, to_decline: boolean = false, to_delete: boolean = false) {
    const expense = _.cloneDeep(this.expense);
    this.loading = true;

    if (to_delete) {
      expense.deleted_flag = true;
    }
    else if (to_approve) {
      expense.ph_approval_date = new Date();
    }
    else if (to_decline) {
      expense.ph_declined_date = new Date();
    }

    this.invExpenseService.saveExpense(expense, this.imageBlob)
      .then(() => {
        this.loading = false;
        this.back();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  deleteExpense() {
    this.alertService.confirmDeleteAlert('Are you sure you want to delete this expense?')
      .then(() => {
        this.saveExpense(false, false, true);
      });
  }

  updatePageTitle() {
    this.pageTitle = (this.expense.segment_key ? '' : 'New ') + 'Expense';
  }

  updateExpenseStatuses() {
    this.resourceExpenseStatus = null;
    this.phStatus = null;
    this.invStatus = null;

    if (!this.isAdmin && !this.expense.ph_employee_manageable_flag){
      if (this.expense.ph_paid_flag) {
        this.resourceExpenseStatus = 'Paid';
      }
      else if (this.expense.ph_approved_flag && this.expense.inv_approved_flag) {
        this.resourceExpenseStatus = 'Approved';
      }
      else if (this.expense.ph_declined_flag){
        this.resourceExpenseStatus = 'Declined';
      }
      else if (this.expense.inv_approved_flag || this.expense.ph_approved_flag) {
        this.resourceExpenseStatus = 'Processing';
      }
      else {
        this.resourceExpenseStatus = 'Pending';
      }
    }
    else {
      if (this.expense.ph_declined_flag) {
        this.phStatus = 'Declined';
      }
      else if (this.expense.ph_paid_flag) {
        this.phStatus = 'Paid';
      }
      else if (this.expense.ph_approved_flag) {
        this.phStatus = 'Approved';
      }
      else if (this.expense.segment_key && this.expense.ph_pending_flag) {
        this.phStatus = 'Pending Approval';
      }

      if (this.expense.inv_approved_flag) {
        this.invStatus = 'Approved';
      }
      else if (this.expense.inv_pending_flag) {
        this.invStatus = 'Pending Approval';
      }
      else if (this.expense.segment_key && this.expense.inv_new_flag) {
        this.invStatus = 'Unapproved';
      }
    }
  }

  checkForErrors() {
    // No need to show errors if the expense is locked
    // due to the user being a client or labour hire resource
    if (this.userIsLabourHireOrClient) {
      this.errorMessage = null;
    }
    else {
      super.checkForErrors();
      this._checkIsActiveProject();
    }
  }

  private _checkIsActiveProject() {
    this.clearErrorIfMatches(this.errorMessages.inactiveProject);
    if (!this.expense.is_active_project) {
      this.errorMessage = this.errorMessages.inactiveProject;

      for (const project of this.allProjects) {
        if (project.project_key === this.expense.inv_project.project_key) {
          return;
        }
      }
      this.allProjects.unshift(this.invProjectService.getSingleProject(this.expense.inv_project.project_key));
    }
  }

}
