import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { TimeUtilService } from './../../services/time-util/time-util.service';

@Component({
  selector: 'calendar-month-grid',
  templateUrl: './calendar-month-grid.component.html',
  styleUrls: ['./calendar-month-grid.component.scss']
})
export class CalendarMonthGridComponent {

  yearLabels: string[] = TimeUtilService.getYearLabels();

  @Output() monthSelected = new EventEmitter();

  selectMonth(monthIndex: number){
    this.monthSelected.emit({ monthIndex });
  }
}
