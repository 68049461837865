<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <back-button></back-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>
  <div *ngFor="let balanceGroup of leaveBalances">
    <div class="ph-pageHeaderRow">
      <div class="ph-pageHeaderText">
        {{balanceGroup.title}}
      </div>
    </div>

    <div class="ph-contentContainer"
         *ngFor="let balance of balanceGroup.stats">
      <div class="ph-statRow textLeft">
        <div class="ph-stat">
          <div class="ph-statTitle">
            {{balance.label}}
          </div>
          <div class="ph-statValue">
            {{balance.value}} <span>{{balance.unit}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</ion-content>
