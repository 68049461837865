import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public alertCtrl: AlertController
  ) {

  }

  messageAlert(message: string, header: string = null) {
    this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ph-alert-cancelBtn'
        }
      ]
    })
      .then((alert) => {
        alert.present();
      });
  }

  unsavedChangesAlert() {
    return new Promise<any>((resolve, reject) => {
      this.alertCtrl.create({
        header: 'Unsaved Changes',
        message: 'Do you want to save your changes?',
        buttons: [
          {
            text: 'Ignore Changes',
            cssClass: 'ph-alert-deleteBtn',
            handler: () => resolve(false)
          },
          {
            text: 'Cancel',
            cssClass: 'ph-alert-cancelBtn',
            handler: reject
          },
          {
            text: 'Save Changes',
            cssClass: 'ph-alert-saveBtn',
            handler: () => resolve(true)
          }
        ]
      })
        .then((alert) => {
          alert.present();
        });
    });
  }

  confirmAlert(header: string = null, message: string) {
    return new Promise<any>((resolve, reject) => {
      this.alertCtrl.create({
        header,
        message,
        buttons: [
          {
            text: 'Confirm',
            cssClass: 'ph-alert-confirmBtn',
            handler: resolve
          },
          {
            text: 'Cancel',
            cssClass: 'ph-alert-cancelBtn',
            handler: reject
          }
        ]
      })
        .then((alert) => {
          alert.present();
        });
    });
  }

  confirmDeleteAlert(message: string) {
    return new Promise<any>((resolve, reject) => {
      this.alertCtrl.create({
        header: 'Confirm Delete',
        message,
        buttons: [
          {
            text: 'Delete',
            cssClass: 'ph-alert-deleteBtn',
            handler: resolve
          },
          {
            text: 'Cancel',
            cssClass: 'ph-alert-cancelBtn',
            handler: reject
          }
        ]
      })
        .then((alert) => {
          alert.present();
        });
    });
  }

}
