import { ApiService } from './../../../services/api/api.service';
import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ProjectService } from '../../../services/project/project.service';
import { UtilService } from '../../../services/util/util.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { AlertService } from '../../../services/alert/alert.service';
import { ExpenseService } from '../../../services/expense/expense.service';
import { EmployeeService } from '../../../services/employee/employee.service';
import { CompanyService } from './../../../services/company/company.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { DomService } from './../../../services/dom/dom.service';

import { PageExpenseEdit } from '../../../classes/abstract/page-expense-edit/page-expense-edit';

import { PhExpense } from './../../../models/expense/ph-expense';

import * as _ from 'lodash';

@Component({
  selector: 'app-expense-edit',
  templateUrl: './expense-edit.component.html',
  styleUrls: ['./expense-edit.component.scss']
})
export class ExpenseEditComponent extends PageExpenseEdit implements OnInit {

  readonly errorMessages: any = {
    noProjects: 'At least one work with an expense pay item must be available to record expenses',
    inactiveProject: 'The work attached to this expense is archived',
    noEmployees: 'At least one employee must be active on the selected date to record an expense',
    inactiveEmployee: 'Expenses can\'t be recorded outside an employee\'s employment period'
  };

  expense: PhExpense;

  allEmployees: any[] = [];

  loggedInEmployeeKey: number;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public projectService: ProjectService,
    public domSanitizer: DomSanitizer,
    public domService: DomService,
    public alertService: AlertService,
    public expenseService: ExpenseService,
    public employeeService: EmployeeService,
    public utilService: UtilService,
    public companyService: CompanyService,
    public apiService: ApiService
  ) {
    super(
      stateService, domSanitizer, utilService, alertService, apiService
    );
  }

  ngOnInit() {
    this.isAdmin = this.companyService.isAdmin();
    this.isManager = this.companyService.isManager();
    this.currencySymbol = this.utilService.currencySymbol;
    this.loggedInEmployeeKey = this.companyService.getEmployeeKey();

    this.expense = _.cloneDeep(this.stateService.params.expense) || null;
    const expense_key = this.stateService.params.expense_key || null;

    if (this.expense) {
      this.initialiseData();
      this.initialiseExpenseImage();
    }
    else if (expense_key) {

      this.expenseService.loadExpense(expense_key)
        .then((expense) => {
          this.expense = expense;
          this.initialiseData();
          this.initialiseExpenseImage();
        })
        .catch(() => {
          this.back();
        });
    }
    else {
      const employee = this.getDefaultEmployee();
      const project = this.getDefaultProject();

      if (project !== null && employee !== null) {
        this.expense = this.expenseService.initNewExpense(
          employee.employee_key,
          project.project_key,
          this.defaultExpenseDate
        );
        this.initialiseData();
        this.initialiseExpenseImage();
      }
      else {
        this.back();
      }
    }
  }

  back() {
    this.stateChangeService.back(['app.expense.camera']);
  }

  initialiseData() {
    this.loading = true;

    this.initExpenseAmountTouched();
    this.initAllEmployees();
    this.initAllProjects();

    this.updateDateStrings();

    this.checkForErrors();
    this.setupModalButtonConfig();

    this.updatePageTitle();

    this.loading = false;
  }

  initExpenseAmountTouched(){
    this.expenseAmountTouched = this.expense.expense_key !== null;
  }

  getDefaultEmployee() {
    let employee_key: number = null;

    if (this.stateService.params.employee_key) {
      employee_key = this.stateService.params.employee_key;
    }
    else {
      const allEmployees = this.employeeService.getAllEmployees();

      if (allEmployees.length) {
        employee_key = allEmployees[0].employee_key;
      }
    }

    return this.employeeService.getSingleEmployee(employee_key);
  }

  getDefaultProject() {
    let project_key: number = null;

    if (this.stateService.params.project_key) {
      project_key = this.stateService.params.project_key;
    }
    else {
      const allProjects = this.projectService.getAllExpenseProjects();

      if (allProjects.length) {
        project_key = allProjects[0].project_key;
      }
    }

    return this.projectService.getSingleProject(project_key);
  }

  initAllEmployees() {
    this.allEmployees = this.employeeService.getAllEmployees();

    this.checkForErrors();
  }

  initAllProjects() {
    this.allProjects = this.projectService.getAllExpenseProjects();

    this.checkForErrors();
  }

  setupModalButtonConfig() {
    this.showApproveBtn = false;
    this.showDeclineBtn = false;
    this.showSaveBtn = false;
    this.showDeleteBtn = false;

    if (this.expense.is_active_project && this.expense.is_active_employee) {

      // Admin or Manager user
      if (this.expense.employee.manageable_flag || this.isAdmin) {

        // Existing expense
        if (this.expense.expense_key) {
          // Pending expense
          if (this.expense.ph_pending_flag) {
            this.showApproveBtn = true;
            this.showDeclineBtn = true;
          }
          // Approved but unpaid expense
          else if (this.expense.ph_approved_flag && !this.expense.ph_paid_flag) {
            this.showApproveBtn = true;
            this.showDeclineBtn = true;
          }
        }
        // New expense
        else {
          this.showApproveBtn = true;
        }
      }
      // Employee user
      else {
        // New or pending expense
        if (!this.expense.expense_key || this.expense.ph_pending_flag) {
          this.showSaveBtn = true;
        }
        // Existing expense that has not yet been approved or declined
        if (this.expense.expense_key !== null && this.expense.ph_pending_flag) {
          this.showDeleteBtn = true;
        }
      }
    }
  }

  projectSelected(event: any) {
    this.expense.ph_project = event.item;

    this.updateDateStrings();
    this.setupModalButtonConfig();
  }

  employeeSelected(employee: any) {
    this.expense.employee = employee;

    this.checkForErrors();
    this.updateDateStrings();
    this.setupModalButtonConfig();
  }

  openEmployeeList() {
    if (!this.expense.is_locked && this.expense.expense_key === null) {

      this.domService.openSlideUpList(
        this.allEmployees,
        this.expense.employee,
        'employee_code',
        null,
        (employee: any) => {
          this.employeeSelected(employee);
        }
      );
    }
  }

  saveExpense(to_approve: boolean = false, to_decline: boolean = false, to_delete: boolean = false) {
    this.loading = true;
    const expense = _.cloneDeep(this.expense);

    if (to_delete) {
      expense.deleted_flag = true;
    }
    else if (to_approve) {
      expense.ph_approval_date = new Date();
    }
    else if (to_decline) {
      expense.ph_declined_date = new Date();
    }

    this.expenseService.saveExpense(expense, this.imageBlob)
      .then(() => {
        this.loading = false;
        this.back();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  deleteExpense() {
    this.alertService.confirmDeleteAlert('Are you sure you want to delete this expense?')
      .then(() => {
        this.saveExpense(false, false, true);
      });
  }

  updatePageTitle() {
    let prefix: string;

    if (!this.expense.expense_key) {
      prefix = 'New';
    }
    else if (this.expense.ph_paid_flag) {
      prefix = 'Paid';
    }
    else if (this.expense.ph_declined_flag) {
      prefix = 'Declined';
    }
    else if (this.expense.ph_approved_flag) {
      prefix = 'Approved';
    }
    else {
      prefix = 'Pending';
    }
    this.pageTitle = prefix + ' Expense';
  }

  checkForErrors(){
    super.checkForErrors();
    this._checkAnyEmployeesAvailable();
    this._checkIsActiveEmployee();
    this._checkIsActiveProject();
  }

  private _checkAnyEmployeesAvailable(){
    this.clearErrorIfMatches(this.errorMessages.noEmployees);
    if (this.allEmployees.length === 0) {
      this.errorMessage = this.errorMessages.noEmployees;
    }
  }

  private _checkIsActiveEmployee() {
    this.clearErrorIfMatches(this.errorMessages.inactiveEmployee);
    if (!this.expense.is_active_employee) {
      this.errorMessage = this.errorMessages.inactiveEmployee;
    }
  }

  private _checkIsActiveProject() {
    this.clearErrorIfMatches(this.errorMessages.inactiveProject);
    if (!this.expense.is_active_project) {
      this.errorMessage = this.errorMessages.inactiveProject;

      for (const project of this.allProjects){
        if (project.project_key === this.expense.ph_project.project_key){
          return;
        }
      }
      this.allProjects.unshift(this.projectService.getSingleProject(this.expense.ph_project.project_key));
    }
  }

}
