import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inv-expense',
  templateUrl: './inv-expense.component.html',
  styleUrls: ['./inv-expense.component.scss']
})
export class InvExpenseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
