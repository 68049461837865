import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { PipesModule } from './../../pipes/pipes.module';
import { ComponentsModule } from './../../components/components.module';

import { TimeWorkComponent } from './time-work/time-work.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { TimeManagerClocksComponent } from './time-manager-clocks/time-manager-clocks.component';
import { TimeManagerComponent } from './time-manager/time-manager.component';
import { TimeEmployeeComponent } from './time-employee/time-employee.component';
import { TimeEditComponent } from './time-edit/time-edit.component';
import { TimeComponent } from './time.component';

export const TIME_CONFIG: any[] = [
  {
    name: 'app.time',
    url: '/time',
    abstract: true,
    component: TimeComponent
  },
  {
    name: 'app.time.clock',
    url: '/clock?{clock_key:int}',
    component: TimeClockComponent,
    params: {
      clock: null,
      clock_key: null
    }
  },
  {
    name: 'app.time.edit',
    url: '/edit?{segment_key:int}&{project_key:int}&{employee_key:int}',
    component: TimeEditComponent,
    params: {
      segment_key: null,
      project_key: null,
      employee_key: null,
      segment: null,
      segments: null
    }
  },
  {
    name: 'app.time.employee',
    url: '/employee?{employee_key:int}',
    component: TimeEmployeeComponent,
    params: {
      employee_key: null
    }
  },
  {
    name: 'app.time.manager',
    url: '/manager',
    component: TimeManagerComponent
  },
  {
    name: 'app.time.managerClocks',
    url: '/managerClocks',
    component: TimeManagerClocksComponent
  },
  {
    name: 'app.time.work',
    url: '/work?{employee_key:int}',
    component: TimeWorkComponent,
    params: {
      segments: null,
      employee_key: null
    }
  },
];

@NgModule({
  declarations: [
    TimeComponent,
    TimeClockComponent,
    TimeEditComponent,
    TimeEmployeeComponent,
    TimeManagerComponent,
    TimeManagerClocksComponent,
    TimeWorkComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    ComponentsModule,
    FormsModule,
    UIRouterModule.forChild({ states: TIME_CONFIG })
  ]
})
export class TimeModule { }
