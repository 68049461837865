import { ApiService } from './../api/api.service';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { AuthService } from '../auth/auth.service';
import { StateDataService } from './../state-data/state-data.service';
import { ActionService } from './../action/action.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  company: any = null;
  userCompany: any = null;

  publicHolidays: any[] = null;
  publicHolidaysMap: any = null;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public stateDataService: StateDataService,
    public actionService: ActionService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadCurrentCompanyDetails()
          .then(() => {
            this.stateDataService.initSelectedWeekStart(this.company.week_starts_on);
            this.actionService.initActionMap(this.userCompany.user_actions);
            this.loadUserCompany()
              .then(() => {
                this.serviceSetup = true;
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  // Clear service data on logout
  clearServiceData() {
    this.company = null;
    this.userCompany = null;
    this.publicHolidays = null;
    this.publicHolidaysMap = null;

    this.serviceSetup = false;
  }

  getCurrentCompany() {
    return this.company;
  }


  timesheetApprovalsEnabled(){
    return this.company.timesheet_approvals_flag && this.actionService.isActive('TIMESHEET_APPROVALS');
  }


  getUserCompany() {
    return this.userCompany;
  }

  getTeamManagerFlag() {
    return this.userCompany?.team_manager_flag || false;
  }

  getEmployeeKey() {
    return this.userCompany ? this.userCompany.employee_key : null;
  }

  getEmployeeCode() {
    return this.userCompany?.employee_code || null;
  }

  isAdmin() {
    return this.userCompany?.admin_flag || false;
  }

  isManager() {
    return this.userCompany.team_manager_flag || false;
  }

  isEmployee(): boolean {
    return !!this.userCompany?.employee_key || false;
  }

  isAdminOrPayCycleAdmin(): boolean {
    return !!this.userCompany?.admin_flag || !!this.userCompany?.pay_cycle_admin_flag;
  }

  employeeIsFinished() {
    return moment(this.userCompany.employment_finish_date).isBefore(moment(), 'd');
  }

  getSinglePublicHoliday(date: Date): string {
    const dateString = TimeUtilService.dateToDateString(date, null);
    return this.publicHolidaysMap[dateString] || null;
  }

  getPublicHolidaysMap(): any {
    return this.publicHolidaysMap || {};
  }

  loadPublicHolidays() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.phGet('company/publicholidays')
        .then((data) => {
          this.publicHolidays = CompanyService.setupPublicHolidays(data);
          this.publicHolidaysMap = CompanyService.setupPublicHolidaysMap(this.publicHolidays);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadCurrentCompanyDetails() {
    return new Promise<void>((resolve, reject) => {

      forkJoin([
        this.loadCompany(),
        this.loadUserCompany()
      ])
        .toPromise()
        .then(() => {
          this.loadPublicHolidays()
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.phGet('company')
        .then((data) => {
          this.company = CompanyService.setupCompany(data[0]);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadUserCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.phGet('useraccesscompany', null, { session_only: true })
        .then((data) => {
          this.userCompany = data;
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static setupCompany(company: any): any {
    company.employment_finish_date = TimeUtilService.dateStringToDate(company.employment_finish_date, null);
    company.subscription_trial_date = TimeUtilService.dateStringToDate(company.subscription_trial_date, null);
    company.suspension_date = TimeUtilService.dateStringToDate(company.suspension_date, null);
    company.latest_pay = TimeUtilService.dateStringToDate(company.latest_pay, null);
    company.cancelled_date = TimeUtilService.dateStringToDate(company.cancelled_date, null);

    return company;
  }

  static setupUserCompany(userCompany: any): any {

    return userCompany;
  }

  static setupPublicHolidays(publicHolidays: any[]): any[] {
    for (const publicHoliday of publicHolidays) {
      publicHoliday.holiday_date = TimeUtilService.dateStringToDate(publicHoliday.holiday_date);
    }

    return publicHolidays;
  }

  static setupPublicHolidaysMap(publicHolidays: any[]): any {
    const publicHolidaysMap = {};

    for (const publicHoliday of publicHolidays) {
      const dateString = TimeUtilService.dateToDateString(publicHoliday.holiday_date, null);
      publicHolidaysMap[dateString] = publicHoliday;
    }

    return publicHolidaysMap;
  }

}
