<!-- <app-ph-login-external *ngIf="!isInvoxyUser"></app-ph-login-external> -->

<img *ngIf="!isInvoxyUser"
     class="app-splash-logo"
     src="assets/product-imgs/payhero/PayHero_Full.svg">

<img *ngIf="isInvoxyUser"
     class="app-splash-logo"
     src="assets/product-imgs/invoxy/Invoxy_Full.svg">

<div class="ph-spinner app-splash-spinner">
  <ion-spinner></ion-spinner>
</div>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime">
    <img src="../../../../assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg" />
  </div>
</footer>
