import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { PayService } from '../../../services/pay/pay.service';
import { UtilService } from '../../../services/util/util.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';

@Component({
  selector: 'app-pay-view',
  templateUrl: './pay-view.component.html',
  styleUrls: ['./pay-view.component.scss']
})
export class PayViewComponent implements OnInit {

  payEmployee: any;
  payLineTotals: any[];
  loading = true;

  constructor(public stateService: StateService,
              public stateAccessService: StateAccessService,
              public stateChangeService: StateChangeService,
              public utilService: UtilService,
              public payService: PayService) {

  }

  ngOnInit(){
    const pay_key = this.stateService.params.pay_key;

    if (!!pay_key){
      this.payService.getPayEmployee(pay_key)
        .then((pay) => {
          this.payEmployee = pay;
          this.payLineTotals = PayService.getPayLineGroupTotals(this.payEmployee.employee_lines);
          this.loading = false;
        })
        .catch(() => {
          this.stateService.go('app.pay.employee');
          this.loading = false;
        });
    }
    else {
      this.stateService.go('app.pay.employee');
    }
  }

  back(){
    this.stateChangeService.back();
  }

  downloadPay(){
    this.utilService.showLoadingSpinner();

    this.payService.downloadPDF(this.payEmployee)
      .then(() => {
        this.utilService.hideLoadingSpinner();
      })
      .catch(() => {
        this.utilService.hideLoadingSpinner();
      });
  }

}
