import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { InvTimeService } from './../inv-time/inv-time.service';
import { InvApprovalUtilService } from './../inv-approval-util/inv-approval-util.service';

import { InvSegment } from './../../models/segment/inv-segment';

import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvApprovalService {

  constructor(
    public utilService: UtilService,
    public invTimeService: InvTimeService,
    public apiService: ApiService
  ) { }

  loadMonthOfApprovalRequests(month: Date) {
    const start = _.cloneDeep(month);
    const end = _.cloneDeep(month);

    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    return this.loadApprovalRequests(start, end);
  }

  loadApprovalRequests(start_date: Date, end_date: Date) {
    return new Promise<any>((resolve, reject) => {
      const params = {
        start_date: TimeUtilService.dateToDateString(start_date, null),
        end_date: TimeUtilService.dateToDateString(end_date, null),
      };

      this.apiService.invGet('approval', params)
        .then((approvalRequests) => {
          resolve(InvApprovalUtilService.setupApprovalRequests(approvalRequests));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadApprovalRequestProjectSegments(approval_request_key: number, project_key: number) {
    return new Promise<any>((resolve, reject) => {
      const params = {
        approval_request_key,
        project_key
      };

      this.apiService.invGet('approval/requested', params)
        .then((segments) => {
          resolve(this.invTimeService.setupSegments(segments, true));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadApprovalSegments(user_key: number) {
    return new Promise<InvSegment[]>((resolve, reject) => {
      const params = {
        user_key
      };

      this.apiService.invGet('segment/new', params, { hide_error: true })
        .then((segments) => {
          resolve(this.invTimeService.setupSegments(segments, true));
        })
        .catch((error) => {
          if (error.message === 'No Time found for given parameters.') {
            this.utilService.toastMessage('No unapproved time found');
          }
          reject(error);
        });
    });
  }

  requestApproval(user_key: number, from_date: Date, to_date: Date) {
    return new Promise((resolve, reject) => {
      const data = {
        user_key_list: user_key.toString(),
        start_date: TimeUtilService.dateToDateString(from_date, null),
        end_date: TimeUtilService.dateToDateString(to_date, null),
        request_date: TimeUtilService.dateToDateTimeString(new Date(), null)
      };

      this.apiService.invPost('segment/requestapproval', data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  respondToApprovalRequests(approval_requests: any[], approve: boolean) {
    return new Promise((resolve, reject) => {
      approval_requests = _.cloneDeep(approval_requests);

      for (const approval_request of approval_requests) {
        approval_request.approve = approve;
        approval_request.response_date = TimeUtilService.dateToDateTimeString(new Date(), null);
      }

      const data = {
        approval_requests
      };

      this.apiService.invPost('approval/answer', data)
        .then(() => {
          this.utilService.toastMessage('Request ' + (approve ? 'approved' : 'rejected'));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  removeApprovalResponse(user_approval_key: number, approval_request_key: number) {
    return new Promise((resolve, reject) => {

      const data = {
        user_approval_key,
        approval_request_key,
        deleted_flag: true
      };

      this.apiService.invPost('approval/userresponse', data)
        .then(() => {
          this.utilService.toastMessage('Approval request response removed');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


}
