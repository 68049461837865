import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from 'src/app/services/state-change/state-change.service';
import { InvProjectService } from '../../../services/inv-project/inv-project.service';
import { ClockService } from '../../../services/clock/clock.service';
import { InvClockService } from './../../../services/inv-clock/inv-clock.service';
import { AlertService } from '../../../services/alert/alert.service';
import { UtilService } from '../../../services/util/util.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { InvProjectUtilService } from './../../../services/inv-project-util/inv-project-util.service';
import { InvTaskUtilService } from './../../../services/inv-task-util/inv-task-util.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-inv-time-clock',
  templateUrl: './inv-time-clock.component.html',
  styleUrls: ['./inv-time-clock.component.scss']
})
export class InvTimeClockComponent implements OnInit {

  @ViewChild('noteTextArea') noteTextArea: any;

  isAdmin: boolean;
  isManager: boolean;

  loggedInUserKey: number;

  clock_key: number;
  clock: any;
  clockTimeData: any;

  timer: any;
  timerFlasher: boolean;

  readonly longClockMessage: string = 'This clock entry will be trimmed when you clock out due to the total duration exceeding 24 hours';

  allProjects: any[] = [];
  allTasks: any[] = [];

  note: string;
  addingNote: boolean = false;

  loading: boolean = true;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public invProjectService: InvProjectService,
    public invClockService: InvClockService,
    public alertService: AlertService,
    public utilService: UtilService,
    public invcompanyService: InvCompanyService
  ) {

  }

  ngOnInit() {
    this.isAdmin = this.invcompanyService.isAdminOrOwner();
    this.isManager = this.invcompanyService.isTimeManager();
    this.loggedInUserKey = this.invcompanyService.getUserKey();

    this.clock = _.cloneDeep(this.stateService.params.clock) || null;
    const clock_key = this.stateService.params.clock_key;

    if (!isNaN(clock_key) && !this.clock) {
      this.clock_key = clock_key;

      this.invClockService.loadClock(this.clock_key)
        .then((clock) => {
          this.clock = clock;
          this.clock_key = this.clock.clock_key;

          this.initialiseData();
        })
        .catch(() => {
          this.back();
        });
    }
    else if (this.clock) {
      this.clock_key = this.clock.clock_key;

      this.initialiseData();
    }
    else {
      this.back();
    }
  }

  back() {
    this.stateChangeService.back();
  }

  initialiseData() {
    const today = new Date();
    this.allProjects = this.invProjectService.getAllActiveProjects(
      today, today, true, true, false, this.clock.user_key
    );
    this.allTasks = InvTaskUtilService.filterOutUnitTasks(this.clock.project.tasks);
    this.allTasks = InvTaskUtilService.filterOutArchivedAndAdminOnlyTasks(this.allTasks);


    this.setupClockTimeData();
    this.startTimer();

    this.loading = false;
  }

  projectSelected(event: any) {
    this.clock.project = event.item;
    this.selectedProjectChanged();
  }

  selectedProjectChanged() {
    this.allTasks = InvTaskUtilService.filterOutUnitTasks(this.clock.project.tasks);
    this.allTasks = InvTaskUtilService.filterOutArchivedAndAdminOnlyTasks(this.allTasks);

    if (!this.allTasks.length) {
      return;
    }

    this.clock.tasks = InvTaskUtilService.getTaskFromList(this.clock.task.task_key, this.allTasks);

    if (!this.clock.task || !InvProjectUtilService.projectHasSelectedTask(this.clock.project, this.clock.task.task_key)) {
      this.clock.task = this.allTasks[0];
    }

    this.invClockService.postClockProjectTask(
      this.clock_key, this.clock.user.user_key, this.clock.project.project_key, this.clock.task.task_key
    );
  }

  taskSelected(event: any) {
    this.clock.task = event.item;

    this.invClockService.postClockProjectTask(
      this.clock_key, this.clock.user.user_key, this.clock.project.project_key, this.clock.task.task_key
    );
  }

  startTimer() {
    if (!this.timer) {
      this.timerFlasher = true;

      this.timer = setInterval(() => {
        this.updateClockTimeData();
      }, 1000);
    }
  }

  stopTimer() {
    if (!!this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  setupClockTimeData() {
    this.clockTimeData = ClockService.setupClockTimeData(this.clock);
  }

  updateClockTimeData() {
    const nowSeconds = Math.floor(new Date().valueOf() / 1000);
    this.timerFlasher = nowSeconds % 2 === 0;

    ClockService.updateClockTimeData(this.clockTimeData, this.clock);
  }

  breakOut() {
    if (!this.loading) {
      this.loading = true;

      this.invClockService.breakOut(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  breakIn() {
    if (!this.loading) {
      this.loading = true;

      this.invClockService.breakIn(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  clockOut() {
    if (!this.loading) {
      this.loading = true;

      this.invClockService.clockOut(this.clock)
        .finally(() => {
          this.back();
        });
    }
  }

  refreshClock() {
    this.loading = true;

    this.invClockService.loadClock(this.clock_key)
      .then((clock) => {
        this.clock = clock;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  newNote() {
    this.note = this.note || '';
    this.addingNote = true;

    setTimeout(() => {
      this.noteTextArea.nativeElement.focus();
    });
  }

  cancelNote() {
    this.addingNote = false;
  }

  saveNote() {
    this.loading = true;

    this.invClockService.postClockNote(this.clock, this.note)
      .then(() => {
        this.note = '';
        this.addingNote = false;
        this.refreshClock();
      })
      .catch(() => {
        this.refreshClock();
      });
  }

  deleteNote(clockNote: any) {
    this.loading = true;

    this.invClockService.postClockNote(this.clock, clockNote.clock_note_key, clockNote.note, true)
      .then(() => {
        this.note = '';
        this.addingNote = false;
        this.refreshClock();
      })
      .catch(() => {
        this.refreshClock();
      });
  }

}
