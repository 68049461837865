import { Injectable } from '@angular/core';
import { ApiService } from './../api/api.service';
import { AuthService } from '../auth/auth.service';
import { StateDataService } from './../state-data/state-data.service';
import { TimeUtilService } from '../time-util/time-util.service';
import { PhOnboardStub } from '../../models/onboard-stub/ph-onboard-stub';
import { env } from '../../../environments/env.json';
import { CompanyService } from '../company/company.service';

declare var Base64: any;
export class PhOnboardParams {
  refresh_token: string
  route_params: {
    show_spinner: boolean
    from_mobile: boolean
  }
}

@Injectable({
  providedIn: 'root'
})

export class PhOnboardService {


  serviceSetup: boolean = false;
  onboards: PhOnboardStub[];


  onboardingRouteParams: PhOnboardParams = null;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public stateDataService: StateDataService,
    public companyService: CompanyService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.serviceSetup = false;
        const promises = [];

        promises.push(this.loadOnboards());
        promises.push(this.generatePayheroOnboardingToken());

        Promise.all(promises)
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
      }
    });
  }

  loadOnboards(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const params = {
        employee_key: this.companyService.getEmployeeKey()
      };

      this.apiService.phGet('onboarding/stub', params)
        .then((res) => {
          this.onboards = this.setUpOnboardStubs(res);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  generatePayheroOnboardingToken() {
    return new Promise<void>((resolve, reject) => {

      const _auth = this.stateDataService.auth;

      const params = {
        token_type: 'PAYHERO ONBOARDING',
        additional_data: {
          session_key: _auth.PH.session_key,
          return_session_key: _auth.SUB.return_session_key,
          company_code: _auth.PH.company_code,
          user_access_company_key: _auth.SUB.user_access_company_key
        }
      };

      this.apiService.subPost('user_access/refresh/generate', params)
        .then((res) => {
         this.onboardingRouteParams =  {
          refresh_token: res.refresh_token,
          route_params:  {
            show_spinner: true,
            from_mobile: true
          }
         }
          resolve()
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  setUpOnboardStubs(onboard_stubs: any[]): PhOnboardStub[] {
    return onboard_stubs.map(onboard_stub => this.setUpOnboardStub(onboard_stub)).filter(onboard_stub => !!onboard_stub);
  }

  setUpOnboardStub(onboard_stub: any): PhOnboardStub {
    return new PhOnboardStub(
      onboard_stub.onboard_key,
      onboard_stub.employee_key,
      onboard_stub.onboard_template_key,
      TimeUtilService.utcDateTimeStringToDate(onboard_stub.completed_date),
      TimeUtilService.utcDateTimeStringToDate(onboard_stub.approved_date),
      onboard_stub.template_name,
      onboard_stub.display_name
    );
  }

  getOnboardingUrl() {
    return env.payhero.onboarding_app_url + 'loginExternal?url_params=' + Base64.encode(JSON.stringify(this.onboardingRouteParams));
  }

  onboardsToComplete(): boolean {
    return !!this.onboards.find((onboard) => !onboard.completed_date);
  }
}
