const loadTitle = (title: string) => {
  document.querySelector('#appTitle').innerHTML = title;
};
const loadDescription = (description: string) => {
  document.querySelector('#appDescription').setAttribute('content', description);
};
const loadManifest = (manifest: any) => {
  const manifestString = JSON.stringify(manifest);
  const manifestBlob = new Blob([manifestString], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(manifestBlob);

  document.querySelector('#appManifest').setAttribute('href', manifestURL);
};
const loadIcon = (iconId: string, iconURL: string) => {
  document.querySelector('#' + iconId).setAttribute('href', iconURL);
};

export const envLoader = {
  load: (env: any) => {
    loadTitle(env.index.title);
    loadDescription(env.index.description);
    loadManifest(env.index.manifest);
    loadIcon('appAppleTouchIcon', env.index.apple_touch_icon);
    loadIcon('appIconX', env.index.icon_x);
    loadIcon('appIconPng', env.index.icon_png);

    document.documentElement.style.setProperty('--app-theme-color', env.theme_color);
    document.documentElement.style.setProperty('--app-theme-color-dark', env.theme_color_dark);
    document.documentElement.style.setProperty('--app-theme-color-translucent', env.theme_color_translucent);
  }
};
