export class PhOnboardStub {

  onboard_key: number;
  employee_key: number;
  onboard_template_key: number;
  completed_date: Date;
  approved_date: Date;
  template_name: string;
  display_name: string;

  constructor(
    onboard_key: number,
    employee_key: number,
    onboard_template_key: number,
    completed_date: Date,
    approved_date: Date,
    template_name: string,
    display_name: string,

  ){
    this.onboard_key = onboard_key;
    this.employee_key = employee_key;
    this.onboard_template_key = onboard_template_key;
    this.completed_date = completed_date;
    this.approved_date = approved_date;
    this.template_name = template_name;
    this.display_name = display_name;

  }
}
