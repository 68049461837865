import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';

import { CompanyService } from '../company/company.service';
import { UtilService } from '../util/util.service';
import { EmployeeUtilService } from './../employee-util/employee-util.service';
import { TeamService } from './../team/team.service';

import { env } from '../../../environments/environment';

import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  employees: any[];
  finishedEmployees: any[];
  employeesMap: any;

  private _blobSASKey: string;

  serviceSetup: boolean = false;

  constructor(
    public companyService: CompanyService,
    public utilService: UtilService,
    public teamService: TeamService,
    public apiService: ApiService
    ) {
  }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadEmployeeData()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.employees = null;
    this.finishedEmployees = null;
    this.employeesMap = null;
    this.serviceSetup = false;
  }

  get blobSASKey(): string {
    return this._blobSASKey;
  }

  getLoggedInEmployee() {
    const employee_key = this.companyService.getEmployeeKey();
    return employee_key ? this.getSingleEmployee(employee_key) : null;
  }

  getSingleEmployee(employee_key: number): any {
    return _.cloneDeep(this.employeesMap[employee_key]) || null;
  }

  getAllEmployees(includeFinished: boolean = false, ignoreSelectedTeam: boolean = false, excludeContractors: boolean = false): any[] {
    let allEmployees: any[];

    if (!includeFinished) {
      allEmployees = _.cloneDeep(this.employees);
    }
    else {
      allEmployees = this.employees.concat(this.finishedEmployees);
      allEmployees = _.cloneDeep(EmployeeUtilService.sortEmployeesByName(allEmployees));
    }

    if (!ignoreSelectedTeam) {
      allEmployees = this.filterEmployeesForSelectedTeam(allEmployees);
    }
    if (excludeContractors) {
      allEmployees = EmployeeUtilService.excludeContractorsFromEmployees(allEmployees);
    }

    return allEmployees;
  }

  getActiveEmployees(periodStart: Date, periodEnd: Date): any[] {
    const activeEmployees = [];
    const orderedEmployees = this.getAllEmployees(true);

    for (const employee of orderedEmployees) {
      const employeeStart = employee.employment_start_date;
      const employeeFinish = employee.employment_finish_date;

      if (EmployeeUtilService.activeWithinRange(periodStart, periodEnd, employeeStart, employeeFinish)) {
        activeEmployees.push(employee);
      }
    }

    return activeEmployees;
  }

  loadEmployeeData() {
    return new Promise((resolve, reject) => {

      this.loadEmployees()
        .then(() => {
          this.loadBlobSASKey()
            .finally(() => {
              this.serviceSetup = true;
              resolve();
            })
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadEmployeeDetails(employee_key: number) {
    return new Promise((resolve, reject) => {
      const params = {
        employee_key
      };

      this.apiService.phGet('employee', params)
        .then((employee) => {
          employee = EmployeeUtilService.setupEmployee(employee[0]);
          resolve(employee);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  loadEmployees() {
    return new Promise((resolve, reject) => {
      this.apiService.phGet('employee/all', null)
        .then((employees) => {
          this.employees = [];
          this.finishedEmployees = [];
          this.employeesMap = {};

          for (let employee of employees) {
            employee = EmployeeUtilService.setupEmployee(employee);

            this.employeesMap[employee.employee_key] = employee;

            if (!employee.has_final_pay) {
              this.employees.push(employee);
            }
            else {
              this.finishedEmployees.push(employee);
            }
          }

          EmployeeUtilService.sortEmployeesByName(this.employees);
          EmployeeUtilService.sortEmployeesByName(this.finishedEmployees);

          resolve(this.employees);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  postEmployeeDocument(documentBlob: Blob, employee_key: number, document_name: string, document_description: string = '') {
    return new Promise<any>((resolve, reject) => {

      const data = {
        employee_key: employee_key + '',
        document_name: document_name,
        document_description: document_description,
        file_extension: document_name.split('.').pop()
      }

      this.apiService.phPost('document', data)
        .then((document) => {
          this.saveDocumentBlob(document, documentBlob)
            .then((result) => {
              resolve(document);
            })
            .catch((err) => {
              reject(err);
            })
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadBlobSASKey() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.phGet('document/sas', null, { response_type: 'string' })
        .then((blobSASKey) => {
          this._blobSASKey = blobSASKey;
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  saveDocumentBlob(document, blob) {
    const blobServiceClient = new BlobServiceClient(env.payhero.blob_url + '?' + document.sas_key);
    const containerClient = blobServiceClient.getContainerClient('documents')
    const blobClient = containerClient.getBlockBlobClient(document.document_url.split('/')[1]);

    return blobClient.upload(blob, blob.size);
  }

  filterEmployeesForSelectedTeam(employees: any[]): any[] {
    const teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    if (teamEmployeeMap === null) {
      return employees;
    }
    else {
      const teamEmployees = [];

      for (const employee of employees) {
        if (!!teamEmployeeMap[employee.employee_key]) {
          teamEmployees.push(employee);
        }
      }

      return teamEmployees;
    }
  }

}
