import { Component, OnInit } from '@angular/core';

import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';

@Component({
  selector: 'app-inv-onboard-resource',
  templateUrl: './inv-onboard-resource.component.html',
  styleUrls: ['./inv-onboard-resource.component.scss']
})
export class InvOnboardResourceComponent implements OnInit {

  incompleteOnboards: any[];

  onboard_key: number;

  display_name: string;
  company_name: string;

  constructor(public invOnboardService: InvOnboardService,
              public invCompanyService: InvCompanyService,
              public stateAccessService: StateAccessService){

  }

  ngOnInit() {
    this.display_name = this.invCompanyService.getDisplayName();
    this.company_name = this.invCompanyService.getCompanyName();
    this.incompleteOnboards = this.invOnboardService.getIncompleteOnboards();

    if (this.incompleteOnboards.length){
      this.onboard_key = this.incompleteOnboards[0].onboard_key;
    }
    else {
      this.stateAccessService.reloadStateAccess();
    }
  }

  onboardComplete(){
    this.stateAccessService.reloadStateAccess();
  }

}
