import { Component, OnInit } from '@angular/core';

import { InvUserService } from 'src/app/services/inv-user/inv-user.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { UtilService } from './../../../services/util/util.service';

declare var Sha1;

@Component({
  selector: 'app-inv-account-password',
  templateUrl: './inv-account-password.component.html',
  styleUrls: ['./inv-account-password.component.scss']
})
export class InvAccountPasswordComponent implements OnInit {

  userDetails: any;

  oldPassword: string = '';
  newPassword: string = '';
  newPasswordConfirm: string = '';

  noPassword: boolean = false;

  loading: boolean = true;
  errorMessage: string = null;

  constructor(
    public invUserService: InvUserService,
    public stateChangeService: StateChangeService,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.userDetails = this.invUserService.getUserAccountDetails();
    this.loading = false;
    this.noPassword = !this.userDetails.set_password;
  }

  savePassword() {
    this.clearErrorMessage();
    
    if ((!this.oldPassword && !this.noPassword) || !this.newPassword || !this.newPasswordConfirm) {
      return;
    }
    else if (this.newPassword !== this.newPasswordConfirm) {
      this.errorMessage = 'Passwords don\'t match';
    }
    else if (this.newPassword.length < 8) {
      this.errorMessage = 'Password must be 8 characters or more';
    }
    else {
      this.loading = true;

      const data = {
        old_password: !this.noPassword ? Sha1.hash(this.oldPassword) : null,
        new_password: Sha1.hash(this.newPassword),
        user_key: this.userDetails.user_key
      };

      this.invUserService.postUserAccount(data)
        .then(() => {
          this.stateChangeService.back();
          this.utilService.toastMessage('Password successfully updated');
        })
        .finally(() => {
          this.loading = false;
        });
    }


  }

  clearErrorMessage() {
    this.errorMessage = null;
  }

}
