import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';

@Injectable({
  providedIn: 'root'
})
export class InvUserUtilService {

  static setupUser(user: any): any {
    user.user_dob = TimeUtilService.dateStringToDate(user.user_dob, null, false);
    user.default_start_time = TimeUtilService.dateTimeStringToDate(user.default_start_time, 'HH:mm', false);

    if (user.integrations){
      for (const integration of user.integrations) {

        if (integration.additional_data) {
          integration.additional_data = UtilService.parseJSON(integration.additional_data);

          if (integration.additional_data.next_public_holiday_date) {
            integration.additional_data.next_public_holiday_date =
              TimeUtilService.dateStringToDate(integration.additional_data.next_public_holiday_date);
          }
        }
      }
    }

    return user;
  }

  static sortUsersByName(users: any[]): any[] {
    return users.sort((a: any, b: any) => {
      const displayNameA = a.display_name.toUpperCase();
      const displayNameB = b.display_name.toUpperCase();

      if (displayNameA > displayNameB) {
        return 1;
      }
      else if (displayNameA < displayNameB) {
        return -1;
      }
      return 0;
    });
  }

  static getUserFromList(user_key: number, users: any[]): any {
    for (const user of users) {
      if (user.user_key === user_key) {
        return user;
      }
    }
    return null;
  }
}
