<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Team Expenses</ion-title>
  </ion-toolbar>
</ion-header>


<button class="ph-toggleAddBtn"
        (click)="newExpense()"
        *ngIf="!yearGridVisible && allProjects.length">
  <i class="ion ion-md-add"></i>
</button>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshExpenses($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-expenseManager-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible && !monthChanging &&
                !monthsExpenses[0].expenses.length &&
                !monthsExpenses[1].expenses.length &&
                !monthsExpenses[2].expenses.length">
      <i class="ion ion-md-paper"></i>
      <div>No expenses this month</div>
    </div>

    <div class="ph-expenseManager-monthContainer"
         *ngIf="!yearGridVisible">

      <div class="ph-groupedList-container -expenseManager"
           *ngFor="let group of monthsExpenses"
           [hidden]="!group.expenses.length">

        <div class="ph-groupedList-groupHeader">
          <div class="ph-groupedList-groupHeaderTitle">
            {{group.status}}
          </div>
          <div class="ph-groupedList-groupHeaderLine"></div>
        </div>

        <div class="ph-groupedList-item"
             *ngFor="let expense of group.expenses"
             (click)="viewExpense(expense)">

          <div class="ph-groupedList-itemColor"
               [ngStyle]="{'background-color': expense.ph_project.project_colour }">
          </div>
          <div class="ph-groupedList-itemTitle">
            {{expense.employee.employee_code}}
            <span class="ph-paidTag"
                  *ngIf="expense.ph_paid_flag">
              Paid
            </span>
            <i class="ion ion-ios-camera"
               *ngIf="expense.document_key">
            </i>
          </div>
          <div class="ph-groupedList-itemDescription"
               *ngIf="expense.description">
            {{expense.description}}
          </div>
          <div class="ph-groupedList-itemDetails">
            <div>{{expense.expense_date | date : 'd MMM yy'}}</div>
            <span>
              ({{expense.ph_project.project_title}})
            </span>
          </div>

          <div *ngIf="expense.ph_project.expense_pay_code_unit === '$'"
               class="ph-groupedList-itemValueRight">
            {{expense.expense_amount | currency}}
          </div>
          <div *ngIf="expense.ph_project.expense_pay_code_unit !== '$'"
               class="ph-groupedList-itemValueRight">
            {{expense.expense_amount}} {{expense.ph_project.expense_pay_code_unit}}
          </div>

          <i class="ion ion-ios-arrow-forward"></i>
        </div>

      </div>

    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
