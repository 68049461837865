<div class="onboard-step-type">
  Video
  <i class="ion ion-ios-medical"
     *ngIf="stepTemplate.required_flag">
  </i>
</div>
<div class="onboard-step-title">
  {{stepTemplate.step_description}}
  <i class="ion ion-ios-checkmark-circle-outline"
     *ngIf="stepAnswer">
  </i>
</div>
<div class="onboard-step-contents -noBorder"
     *ngIf="!loading">

  <div class="onboard-step-video-iframeContainer">
    <iframe *ngIf="sanitizedVideoUrl"
            [src]="sanitizedVideoUrl"
            frameborder="0"
            allowfullscreen>
    </iframe>
  </div>

  <div class="onboard-step-video-confirm">
    <ph-checkbox [(ngModel)]="videoWatched"
                 (ngModelChange)="videoWatchedToggled()">
    </ph-checkbox>
    <span>Tap to confirm you've watched the video</span>
  </div>

</div>
<div class="onboard-step-loading -noBorder"
     *ngIf="loading">
  <div class="ph-spinner">
    <ion-spinner></ion-spinner>
  </div>
</div>
