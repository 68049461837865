import {Component, Input} from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ph-checkbox',
  templateUrl: './ph-checkbox.component.html',
  styleUrls: ['./ph-checkbox.component.scss'],
  providers: [MakeProvider(PhCheckboxComponent)]
})
export class PhCheckboxComponent extends AbstractValueAccessor {
  _value: boolean;

  @Input() btnSmall: boolean = false;
}
