<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Select Team</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="ph-teamSelect-container">

    <div class="ph-teamSelect-teamCard -allTeams"
         *ngIf="multipleCompanyTeams"
         (click)="setSelectedTeam(null)"
         [ngClass]="{ '-selected': selectedTeam === null }">

      <i class="ion ion-ios-people"></i>

      <div class="ph-teamSelect-teamDetails">
        <div class="-name">
          All Employees
        </div>
        <div class="-employees">
          {{allEmployees.length}} Employees
        </div>
      </div>

      <i class="ion ion-ios-arrow-forward"></i>

    </div>

    <div class="ph-teamSelect-teamCard"
         *ngFor="let team of managerTeams"
         (click)="setSelectedTeam(team)"
         [ngClass]="{ '-selected': selectedTeam && selectedTeam.team_key === team.team_key }">

      <i class="ion ion-ios-people"></i>

      <div class="ph-teamSelect-teamWorkColour"
           [ngStyle]="{ 'background-color': team.default_project_colour }">
      </div>

      <div class="ph-teamSelect-teamDetails">
        <div class="-name">
          {{team.team_name}}
        </div>
        <div class="-work">
          <span>{{team.default_project_title}}</span>
        </div>
        <div class="-employees">
          {{team.employees.length}} Employee{{team.employees.length > 1 ? 's' : ''}}
        </div>
      </div>

      <i class="ion ion-ios-arrow-forward"></i>

    </div>

  </div>

</ion-content>
