import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { LeaveService } from './../../../services/leave/leave.service';
import { LeaveUtilService } from './../../../services/leave-util/leave-util.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-leave-manager',
  templateUrl: './leave-manager.component.html',
  styleUrls: ['./leave-manager.component.scss']
})
export class LeaveManagerComponent extends PageMonthYearView implements OnInit {

  isInvoxyUser: boolean;

  allLeaveTypes: any[] = [];
  monthsLeave: any[] = [];

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateDataService: StateDataService,
    public leaveService: LeaveService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;

    this.allLeaveTypes = this.leaveService.getLeaveTypes(false);
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;

    this.loadCurrentMonthOfLeave();
  }

  newLeave() {
    const startDate = _.cloneDeep(this.currentMonth);

    if (moment(startDate).isSame(moment(), 'month')) {
      const today = new Date();
      startDate.setDate(today.getDate());
    }

    this.stateService.go('app.leave.edit', {
      start: startDate
    });
  }

  viewLeave(leave: any) {
    if (!!leave.leave_request_key) {
      this.stateService.go('app.leave.edit', {
        leave_request_key: leave.leave_request_key,
        leaveRequest: leave
      });
    }
  }

  loadCurrentMonthOfLeave() {
    return new Promise<any>((resolve, reject) => {
      this.monthChanging = true;

      this.leaveService.loadMonthOfLeaveRequests(this.currentMonth)
        .then((leave: any[]) => {
          this.monthsLeave = LeaveUtilService.groupLeaveRequestByStatus(leave);
          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  refreshLeave(event: any) {
    this.loadCurrentMonthOfLeave()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfLeave();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfLeave();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfLeave();
  }

}
