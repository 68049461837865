import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from './../../components/components.module';
import { PipesModule } from './../../pipes/pipes.module';

import { PayComponent } from './pay.component';
import { PayViewComponent } from './pay-view/pay-view.component';
import { PayEmployeeComponent } from './pay-employee/pay-employee.component';

export const PAY_CONFIG: any[] = [
  {
    name: 'app.pay',
    url: '/pay',
    abstract: true,
    component: PayComponent
  },
  {
    name: 'app.pay.employee',
    url: '/employee?{employee_key:int}',
    component: PayEmployeeComponent,
    params: {
      selected_month: null,
      employee_key: null
    }
  },
  {
    name: 'app.pay.view',
    url: '/view?{pay_key:int}',
    component: PayViewComponent,
    params: {
      pay_key: null
    }
  }
];

@NgModule({
  declarations: [
    PayComponent,
    PayEmployeeComponent,
    PayViewComponent
  ],
  imports: [
    ComponentsModule,
    PipesModule,
    IonicModule,
    CommonModule,
    UIRouterModule.forChild({ states: PAY_CONFIG })
  ]
})
export class PayModule { }
