<div class="colouredItemSelector-container"
     [ngClass]="{
       '-disabled': disabled,
       '-showConfirmButton': showConfirmButton }">
  <div class="colouredItemSelector-label">
    {{itemType}}
  </div>
  <button class="colouredItemSelector-button"
          [disabled]="disabled"
          (click)="openList()">
    {{selectedItem ? selectedItem[itemNameProperty] : 'Select ' + itemType}}
    <i class="ion ion-md-arrow-dropdown"></i>
  </button>
</div>

<button class="colouredItemSelector-confirm"
        *ngIf="showConfirmButton && !disabled"
        [ngClass]="{ '-disabled': disabled }"
        (click)="confirmItem()">
  <i class="ion ion-ios-arrow-forward"></i>
</button>
