import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { ClockService } from './../../services/clock/clock.service';

@Component({
  selector: 'time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.scss']
})
export class TimeClockComponent implements OnInit, OnDestroy {

  @Input() clock: any;

  clockTimeData: any = null;
  timer: any = null;
  timerFlasher: boolean = false;

  @Output() viewClockClicked = new EventEmitter();
  @Output() breakInClicked = new EventEmitter();
  @Output() breakOutClicked = new EventEmitter();
  @Output() clockOutClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.setupClockTimeData();
    this.startTimer();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer() {
    if (!this.timer) {
      this.timerFlasher = true;

      this.timer = setInterval(() => {
        this.updateClockTimeData();
      }, 1000);
    }
  }

  stopTimer() {
    if (!!this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  setupClockTimeData() {
    this.clockTimeData = ClockService.setupClockTimeData(this.clock);
  }

  updateClockTimeData() {
    const nowSeconds = Math.floor(new Date().valueOf() / 1000);
    this.timerFlasher = nowSeconds % 2 === 0;

    ClockService.updateClockTimeData(this.clockTimeData, this.clock);
  }

  viewClock() {
    this.viewClockClicked.emit({ clock: this.clock });
  }

  breakIn(event: any) {
    event.stopPropagation();
    this.breakInClicked.emit({ clock: this.clock });
  }

  breakOut(event: any) {
    event.stopPropagation();
    this.breakOutClicked.emit({ clock: this.clock });
  }

  clockOut(event: any) {
    event.stopPropagation();
    this.clockOutClicked.emit({ clock: this.clock });
  }

}
