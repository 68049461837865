import { StateDataService } from './../../../services/state-data/state-data.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';

export abstract class PageMonthYearView {

  monthChanging: boolean = true;
  yearGridVisible: boolean = false;
  currentMonth: Date;

  constructor(
    public stateDataService: StateDataService
  ) { }

  toggleYearGridVisibility(){
    this.yearGridVisible = !this.yearGridVisible;
  }

  goForward(){
    if (this.yearGridVisible){
      this.forwardYear();
    }
    else {
      this.forwardMonth();
    }
  }

  goBack(){
    if (this.yearGridVisible){
      this.backYear();
    }
    else {
      this.backMonth();
    }
  }

  forwardYear(){
    this.currentMonth = TimeUtilService.incrementYear(this.currentMonth, 1);
    this.stateDataService.selectedMonth = this.currentMonth;
  }

  backYear(){
    this.currentMonth = TimeUtilService.incrementYear(this.currentMonth, -1);
    this.stateDataService.selectedMonth = this.currentMonth;
  }

  forwardMonth(){
    this.currentMonth = TimeUtilService.incrementMonth(this.currentMonth, 1);
    this.stateDataService.selectedMonth = this.currentMonth;
  }

  backMonth(){
    this.currentMonth = TimeUtilService.incrementMonth(this.currentMonth, -1);
    this.stateDataService.selectedMonth = this.currentMonth;
  }

  showCalendarMonth(event: any){
    this.yearGridVisible = false;
    this.currentMonth = TimeUtilService.updateMonth(this.currentMonth, event.monthIndex);
    this.stateDataService.selectedMonth = this.currentMonth;
  }

}
