import { Injectable } from '@angular/core';

import { ApiService } from './../api/api.service';
import { UtilService } from '../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { CompanyService } from './../company/company.service';


@Injectable({
  providedIn: 'root'
})
export class TimesheetApprovalsService {

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService,
    public apiService: ApiService
  ) {

   }

  getTimeApprovals(employee_key: number, from_date: Date, to_date: Date) {
    return new Promise<any>((resolve, reject) => {
      const params = {
        employee_key: employee_key,
        from_date: TimeUtilService.formatDateForPosting(from_date, false),
        to_date: TimeUtilService.formatDateForPosting(to_date, false),
      };

      this.apiService.phGet('timesheetapproval', params)
        .then((approvals) => {
          resolve(approvals);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  approveTime(selectedEmployees: any[], startDate: Date, endDate: Date, approving: boolean) {
    return new Promise<any>((resolve, reject) => {
      const formatted_approval ={ 
        employee_key_list: selectedEmployees, 
        start_date: TimeUtilService.formatDateForPosting(startDate, false), 
        end_date: TimeUtilService.formatDateForPosting(endDate, false), 
        approved_date: approving ? TimeUtilService.formatDateForPosting(new Date(), true) : null
      };
      
      this.apiService.phPost('timesheetapproval/approve', formatted_approval)
      .then((res) => {
        resolve(res);
        let errors = [];
        res.forEach((data) => {
          if (data.error) {
            errors.push(data.error);
          }
        });

        if (errors.length > 0) {
          res.forEach((data) => {
            this.utilService.toastMessage(data.error);
          });
        } else {
          this.utilService.toastMessage('Week successfully ' + (approving ? 'approved' : 'unapproved'));
        }
      })
      .catch((error) => {
        reject(error);
      })
    })
  }
}