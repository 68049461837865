import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateDataService } from './../../../services/state-data/state-data.service';
import { InvExpenseService } from './../../../services/inv-expense/inv-expense.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvTaskService } from './../../../services/inv-task/inv-task.service';
import { UtilService } from './../../../services/util/util.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { InvIntegrationService } from './../../../services/inv-integration/inv-integration.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-inv-expense-manager',
  templateUrl: './inv-expense-manager.component.html',
  styleUrls: ['./inv-expense-manager.component.scss']
})
export class InvExpenseManagerComponent extends PageMonthYearView implements OnInit {

  isAdmin: boolean;
  isPayHeroIntegrated: boolean;

  currencySymbol: string;

  allTasks: any[] = [];
  monthsExpenses: any[] = [];

  currentMonthHasExpenses: boolean = false;

  constructor(
    public stateService: StateService,
    public stateDataService: StateDataService,
    public invExpenseService: InvExpenseService,
    public invProjectService: InvProjectService,
    public invTaskService: InvTaskService,
    public utilService: UtilService,
    public invCompanyService: InvCompanyService,
    public invIntegrationService: InvIntegrationService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.isAdmin = this.invCompanyService.isAdminOrOwner();
    this.isPayHeroIntegrated = this.invIntegrationService.isPayHeroIntegrated() && this.invIntegrationService.hasPayHeroLogin();

    this.currencySymbol = this.utilService.currencySymbol;
    this.allTasks = this.invTaskService.getAllExpenseTasks();
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;

    this.loadCurrentMonthOfExpenses();
  }

  viewExpense(expense: any) {
    if (!!expense.segment_key) {
      this.stateService.go('app.inv.expense.edit', {
        expense,
        segment_key: expense.segment_key
      });
    }
  }

  newExpense() {
    const expense_date = _.cloneDeep(this.currentMonth);

    if (moment(expense_date).isSame(moment(), 'month')) {
      const today = new Date();
      expense_date.setDate(today.getDate());
    }

    this.stateService.go('app.expense.camera', {
      expense_date
    });
  }

  loadCurrentMonthOfExpenses() {
    return new Promise<void>((resolve, reject) => {
      this.monthChanging = true;

      this.invExpenseService.loadMonthOfExpenses(this.currentMonth)
        .then((expenses: any[]) => {
          if (this.isPayHeroIntegrated) {
            this.monthsExpenses = InvExpenseService.groupExpensesByPhStatus(expenses);
          }
          else {
            this.monthsExpenses = InvExpenseService.groupExpensesByInvStatus(expenses);
          }
          this._checkCurrentMonthHasExpenses();
          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          this.monthsExpenses = [];
          this.currentMonthHasExpenses = false;
          this.monthChanging = false;
          reject();
        });
    });
  }

  refreshExpenses(event: any) {
    this.loadCurrentMonthOfExpenses()
      .finally(() => {
        event.target.complete();
      });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfExpenses();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfExpenses();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfExpenses();
  }

  private _checkCurrentMonthHasExpenses() {
    let current_month_has_expenses = false;

    for (const group of this.monthsExpenses) {
      if (group.expenses.length) {
        current_month_has_expenses = true;
      }
    }

    this.currentMonthHasExpenses = current_month_has_expenses;
  }

}
