<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>Timesheets</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshWeeksTime($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-spinner"
       *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && activeResources.length === 0">
    <i class="ion ion-ios-contacts"></i>
    <div>No active resources in your team</div>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="!loading && activeResources.length !== 0 && filteredResources.length === 0">
    <i class="ion ion-ios-search"></i>
    <div>No resources match your search</div>
  </div>

  <div class="inv-timeManager-periodSelector">
    <button class="-color-default-clear -type-icon"
            [disabled]="loading"
            (click)="goBack()">
      <i class="ion ion-ios-arrow-back"></i>
    </button>

    <div>
      <span>
        {{weekStart | date: 'd'}} - {{weekEnd | date: 'd LLLL,'}}
      </span>
      <span>
        {{weekEnd | date: 'yyyy'}}
      </span>
    </div>

    <button class="-color-default-clear -type-icon"
            [disabled]="loading"
            (click)="goForward()">
      <i class="ion ion-ios-arrow-forward"></i>
    </button>
  </div>

  <div class="ph-search">
    <i class="ion ion-ios-search"></i>
    <input type="search"
           placeholder="Search placements, resources or work..."
           [(ngModel)]="search"
           (ngModelChange)="searchUpdated()">
    <i class="ion ion-ios-close"
       *ngIf="search.length"
       (click)="clearSearch()">
    </i>
  </div>

  <div class="inv-timeManager-weekContainer"
       *ngIf="!loading">

    <div class="ph-groupedList-container -timeManager"
         *ngFor="let user of filteredResources"
         (click)="viewResourceTime(user)">

      <div class="ph-groupedList-groupHeader">
        <div class="ph-groupedList-groupHeaderTitle">
          {{user.display_name}}
        </div>
        <div class="ph-groupedList-groupHeaderLine"></div>
      </div>

      <div class="ph-groupedList-item"
           *ngFor="let userProjectTask of timeSummaryMap[user.user_key]">

        <div class="ph-groupedList-itemColor"
             [ngStyle]="{'background-color': userProjectTask.task.task_colour}">
        </div>

        <div class="ph-groupedList-itemDescription">
          {{userProjectTask.project.project_name}}
        </div>

        <div class="ph-groupedList-itemTitle">
          <span>{{userProjectTask.task.task_display_name}}</span>
          <i *ngIf="userProjectTask.task.admin_only_flag"
             class="ion ion-md-lock"></i>
        </div>

        <div class="ph-groupedList-itemDetails"
             *ngIf="userProjectTask.unit_type === 'hours'">
          <div>{{userProjectTask.total | hoursToTimePipe}} hours</div>
          <span>({{userProjectTask.segments.length}} Record{{userProjectTask.segments.length > 1 ? 's' : ''}})</span>
        </div>
        <div class="ph-groupedList-itemDetails"
             *ngIf="userProjectTask.unit_type === 'expense'">
          <div>{{userProjectTask.total | currency : currencySymbol}}</div>
          <span>({{userProjectTask.segments.length}} Record{{userProjectTask.segments.length > 1 ? 's' : ''}})</span>
        </div>
        <div class="ph-groupedList-itemDetails"
             *ngIf="userProjectTask.unit_type !== 'expense' && userProjectTask.unit_type !== 'hours'">
          <div>{{userProjectTask.total}} {{userProjectTask.unit_type}}</div>
          <span>({{userProjectTask.segments.length}} Record{{userProjectTask.segments.length > 1 ? 's' : ''}})</span>
        </div>

      </div>

      <div class="ph-groupedList-item -itemEmpty"
           *ngIf="timeSummaryMap[user.user_key].length === 0">
        No Time
      </div>

      <i class="ion ion-ios-arrow-forward"></i>
    </div>
  </div>

</ion-content>
