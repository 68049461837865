import { ApiService } from './../api/api.service';
import { Injectable, EventEmitter } from '@angular/core';

import { UtilService } from '../util/util.service';
import { CompanyService } from '../company/company.service';
import { StateDataService } from './../state-data/state-data.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  teams: any[] = [];
  managerTeams: any[] = [];
  teamsMap: any = {};

  teamEmployeeMap: any = {};
  employeeTeamMap: any = {};
  employeeDefaultTeamProjectKeyMap: any = {};

  selectedTeam: any = null;

  serviceSetup: boolean = false;

  public selectedTeamUpdated$: EventEmitter<string>;

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService,
    public stateDataService: StateDataService,
    public apiService: ApiService
  ) {
    this.selectedTeamUpdated$ = new EventEmitter();
  }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadEmployeeTeams()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.teams = [];
    this.managerTeams = [];
    this.teamsMap = {};
    this.teamEmployeeMap = {};
    this.employeeTeamMap = {};
    this.employeeDefaultTeamProjectKeyMap = {};
    this.selectedTeam = null;

    this.serviceSetup = false;
  }

  getAllTeams(): any[] {
    return _.cloneDeep(this.teams);
  }

  getManagerTeams(): any[] {
    return _.cloneDeep(this.managerTeams);
  }

  getSelectedTeam(): any {
    return _.cloneDeep(this.selectedTeam);
  }

  getSelectedTeamKey(): number {
    return this.selectedTeam ? this.selectedTeam.team_key : null;
  }

  setSelectedTeam(team: any) {
    this.selectedTeam = team;
    this.stateDataService.selectedTeamKey = this.selectedTeam ? this.selectedTeam.team_key : null;
    this.selectedTeamUpdated$.emit();
  }

  getSelectedTeamEmployeeMap() {
    if (this.selectedTeam === null) {
      return null;
    }
    else {
      return this.teamEmployeeMap[this.selectedTeam.team_key];
    }
  }

  employeeHasTeam(employee_key: number): boolean {
    return !!this.employeeTeamMap[employee_key];
  }

  initSelectedTeam() {
    const cachedTeamKey = this.stateDataService.selectedTeamKey;
    this.selectedTeam = this.teamsMap[cachedTeamKey] || null;
  }

  /**
   * Returns an array of default_project_keys for a single employee
   */
  getSingleEmployeeDefaultTeamProjectKeys(employee_key: number): number[] {
    return this.employeeDefaultTeamProjectKeyMap[employee_key] || [];
  }

  loadEmployeeTeams() {
    return new Promise((resolve, reject) => {

      const employee_key = this.companyService.getEmployeeKey();
      const params = employee_key ? { employee_key } : {};

      this.apiService.phGet('team', params)
        .then((data) => {
          this.teams = [];
          this.teamsMap = {};

          for (let team of data) {
            team = TeamService.setupTeam(team);

            this.teamsMap[team.team_key] = team;
            this.teams.push(team);
          }

          this.initSelectedTeam();
          this.setupManagerTeams();
          this.setupTeamEmployeeMap();
          this.setupEmployeeTeamMap();
          this.setupEmployeeDefaultTeamProjectKeyMap();

          resolve(this.teams);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Map of employee_keys to default team project_keys
   */
  setupEmployeeDefaultTeamProjectKeyMap() {
    const isTeamManager = this.companyService.getCurrentCompany().team_manager_flag;
    const isAdmin = this.companyService.isAdmin();

    this.employeeDefaultTeamProjectKeyMap = {};

    for (const team of this.teams) {
      for (const employee of team.employees) {
        const default_project_key = employee.default_project_key || team.default_project_key;

        if (!employee.disabled_time_flag || isTeamManager || isAdmin) {

          if (this.employeeDefaultTeamProjectKeyMap[employee.employee_key]) {
            this.employeeDefaultTeamProjectKeyMap[employee.employee_key].push(default_project_key);
          }
          else {
            this.employeeDefaultTeamProjectKeyMap[employee.employee_key] = [default_project_key];
          }
        }
      }
    }
  }

  /**
   * Determines whether or not the given employee has access
   * to record time on any of the teams they are apart of
   */
  timeDisabledOnAllEmployeesTeams(employee_key: number): boolean {
    // If the employee isn't on any teams,
    // then they should have access to time recording by default
    if (this.teams.length === 0) {
      return false;
    }
    else {
      let foundTeamWithTimeEnabled = false;

      for (const team of this.teams) {
        for (const emp of team.employees) {

          if (emp.employee_key === employee_key && !emp.disabled_time_flag) {
            foundTeamWithTimeEnabled = true;
          }
        }
      }

      return !foundTeamWithTimeEnabled;
    }
  }

  /**
   * Map of team_key's to employee_key's on each given team
   */
  setupTeamEmployeeMap() {
    this.teamEmployeeMap = {};

    for (const team of this.teams) {
      this.teamEmployeeMap[team.team_key] = {};

      for (const employee of team.employees) {
        this.teamEmployeeMap[team.team_key][employee.employee_key] = true;
      }
    }
  }

  /**
   * Map of employee_key's to team_key's for each employee on a team
   */
  setupEmployeeTeamMap() {
    this.employeeTeamMap = {};

    for (const team of this.teams) {
      for (const employee of team.employees) {

        if (!this.employeeTeamMap[employee.employee_key]) {
          this.employeeTeamMap[employee.employee_key] = {};
        }

        this.employeeTeamMap[employee.employee_key][team.team_key] = true;
      }
    }
  }

  /**
   * Setup a filtered list of teams that the logged in employee is a manager of
   */
  setupManagerTeams() {
    this.managerTeams = [];

    for (const team of this.teams) {
      for (const employee of team.employees) {

        if (employee.employee_key === this.companyService.getEmployeeKey()) {
          if (employee.manager_flag) {

            this.managerTeams.push(team);
          }
        }
      }
    }
  }

  /**
   * Formats non primitive team properties
   */
  static setupTeam(team: any): any {
    if (!team.project) {
      team.project = [];
    }
    if (!team.employees) {
      team.employees = [];
    }
    team.default_project_colour = UtilService.intToHexColor(team.default_project_colour);

    for (const project of team.projects) {
      project.project_colour = UtilService.intToHexColor(project.project_colour);
      project.deleted_flag = false;
    }

    for (const employee of team.employees) {
      employee.deleted_flag = false;
    }

    return team;
  }

}
