<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>{{isInvoxyUser ? '' : 'Team '}}Leave</ion-title>
  </ion-toolbar>
</ion-header>

<button class="ph-toggleAddBtn"
        (click)="newLeave()"
        *ngIf="!yearGridVisible && allLeaveTypes.length">
  <i class="ion ion-md-add"></i>
</button>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshLeave($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-leaveManager-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible && !monthChanging &&
                !monthsLeave[0].leaveRequests.length &&
                !monthsLeave[1].leaveRequests.length &&
                !monthsLeave[2].leaveRequests.length">
      <i class="ion ion-md-calendar"></i>
      <div>No leave this month</div>
    </div>

    <div class="ph-leaveManager-monthContainer"
         *ngIf="!yearGridVisible">

      <div class="ph-groupedList-container -leaveManager"
           *ngFor="let group of monthsLeave"
           [hidden]="!group.leaveRequests.length">

        <div class="ph-groupedList-groupHeader">
          <div class="ph-groupedList-groupHeaderTitle">
            {{group.status}}
          </div>
          <div class="ph-groupedList-groupHeaderLine"></div>
        </div>

        <div class="ph-groupedList-item"
             *ngFor="let leaveRequest of group.leaveRequests"
             (click)="viewLeave(leaveRequest)">

          <div class="ph-groupedList-itemColor"
               [ngStyle]="{'background-color': leaveRequest.leaveType.leave_type_colour }">
          </div>
          <div class="ph-groupedList-itemTitle">
            {{leaveRequest.employee_code}}
          </div>
          <div class="ph-groupedList-itemDescription"
               *ngIf="leaveRequest.notes">
            {{leaveRequest.notes}}
          </div>

          <div class="ph-groupedList-itemDetails"
               *ngIf="leaveRequest.singleDay">
            <div>{{leaveRequest.start_date | date : 'd MMM yy'}}</div>
            <span>({{leaveRequest.leaveType.leave_type_name}})</span>
          </div>
          <div class="ph-groupedList-itemDetails"
               *ngIf="!leaveRequest.singleDay">
            <div>{{leaveRequest.start_date | date : 'd MMM'}} - {{leaveRequest.end_date | date : 'd MMM yy'}}</div>
            <span>({{leaveRequest.leaveType.leave_type_name}})</span>
          </div>

          <i class="ion ion-ios-arrow-forward"></i>
        </div>

      </div>

    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
