import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateDataService } from './../../../services/state-data/state-data.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { TimeService } from './../../../services/time/time.service';
import { ProjectService } from './../../../services/project/project.service';
import { TimesheetApprovalsService } from 'src/app/services/timesheet-approvals/timesheet-approvals.service';
import { UtilService } from 'src/app/services/util/util.service';
import { CompanyService } from 'src/app/services/company/company.service';

import { PhSegment } from './../../../models/segment/ph-segment';

@Component({
  selector: 'app-time-manager',
  templateUrl: './time-manager.component.html',
  styleUrls: ['./time-manager.component.scss']
})
export class TimeManagerComponent implements OnInit {

  weekStart: Date;
  weekEnd: Date;

  activeEmployees: any[];
  filteredEmployees: any[];
  timeSummaryMap: any;
  currentEmployeeKey: number;

  search: string = '';

  loading: boolean = true;

  selectedEmployees: any = {};
  filtersOpen: boolean = false;
  approvalsEnabledFlag = this.companyService.timesheetApprovalsEnabled();

  constructor(public stateService: StateService,
              public stateAccessService: StateAccessService,
              public stateDataService: StateDataService,
              public employeeService: EmployeeService,
              public timeService: TimeService,
              public projectService: ProjectService,
              public timesheetApprovalsService: TimesheetApprovalsService,
              public utilService: UtilService,
              public companyService: CompanyService) {
  }

  ngOnInit(){
    this.weekStart = this.stateDataService.selectedWeekStart;
    this.loadWeeksTime();
    this.setupSelectedEmployees();
    this.currentEmployeeKey = this.companyService.getEmployeeKey();
  }

  searchUpdated(){
    this.filteredEmployees = this.getFilteredEmployees();
  }

  clearSearch(){
    this.search = '';
    this.searchUpdated();
  }

  viewEmployeeTime(employee: any){
    this.stateService.go('app.time.employee', {
      employee_key: employee.employee_key
    });
  }

  refreshWeeksTime(event: any) {
    this.loadWeeksTime()
      .then(() => { event.target.complete(); })
      .catch(() => { event.target.complete(); });
  }

  loadWeeksTime(){
    return new Promise((resolve, reject) => {
      this.loading = true;

      this.activeEmployees = this.employeeService.getActiveEmployees(this.weekStart, this.weekEnd);
      this.filteredEmployees = this.getFilteredEmployees();
      this.weekEnd = TimeUtilService.getWeekEnd(this.weekStart);

      this.timeService.loadSegments(this.weekStart, this.weekEnd)
        .then((segments) => {
          this.loadSegmentsIntoTimeSummaryMap(segments);
          this.loading = false;
          resolve();
        })
        .catch(() => {
          this.loading = false;
          reject();
        });
    });
  }

  getFilteredEmployees(){
    const filtered = [];
    const search = this.search.toUpperCase();

    for (const employee of this.activeEmployees){
      if (employee.employee_code.toUpperCase().indexOf(search) !== -1){
        filtered.push(employee);
      }
      else {
        const employeeProjects = this.timeSummaryMap[employee.employee_key];

        for (const employeeProject of employeeProjects){
          if (employeeProject.project.project_title.toUpperCase().indexOf(search) !== -1){
            filtered.push(employee);
            break;
          }
        }
      }
    }

    return filtered;
  }

  loadSegmentsIntoTimeSummaryMap(segments: PhSegment[]){
    this.timeSummaryMap = {};

    for (const employee of this.activeEmployees){
      this.timeSummaryMap[employee.employee_key] = [];
    }

    for (const segment of segments){

      const employeeProjects = this.timeSummaryMap[segment.employee.employee_key];
      let foundMatchingProject = false;

      for (const employeeProject of employeeProjects){
        if (employeeProject.project.project_key === segment.ph_project.project_key){

          employeeProject.segments.push(segment);
          employeeProject.total += (segment.unit_flag ? segment.units : segment.duration);
          foundMatchingProject = true;
        }
      }

      if (!foundMatchingProject){
        employeeProjects.push({
          project: this.projectService.getSingleProject(segment.ph_project.project_key),
          segments: [ segment ],
          total: (segment.unit_flag ? segment.units : segment.duration)
        });
      }
    }

    for (const employee_key of Object.keys(this.timeSummaryMap)){
      const employeeInMap = this.timeSummaryMap[employee_key];
      // Sort employee projects
      employeeInMap.sort((a: any, b: any) => {
        const projectTitleA = a.project.project_title.toUpperCase();
        const projectTitleB = b.project.project_title.toUpperCase();

        if (projectTitleA > projectTitleB){
          return 1;
        }
        else if (projectTitleA < projectTitleB){
          return -1;
        }
        return 0;
      });
    }
  }

  goForward(){
    this.forwardWeek();
  }

  goBack(){
    this.backWeek();
  }

  forwardWeek(){
    this.weekStart = TimeUtilService.incrementDate(this.weekStart, 7);
    this.stateDataService.selectedWeekStart = this.weekStart;
    this.loadWeeksTime();
  }

  backWeek(){
    this.weekStart = TimeUtilService.incrementDate(this.weekStart, -7);
    this.stateDataService.selectedWeekStart = this.weekStart;
    this.loadWeeksTime();
  }

  setupSelectedEmployees() {
    this.selectedEmployees = {};

    this.activeEmployees.forEach((employee) => {
      this.selectedEmployees[employee.employee_key] = false;
    })
  }

  openFiltersPopup() {
    this.filtersOpen = true;
  }

  closeFilterPopup() {
    this.filtersOpen = false;
  }

  approveSelected(approving: boolean) {
    let selectedEmployeeKeys = [];
    this.activeEmployees.forEach((employee) => {
      if (this.selectedEmployees[employee.employee_key]) {
        selectedEmployeeKeys.push(employee.employee_key);
      }
    });

    if (selectedEmployeeKeys.length === 0) {
      this.utilService.toastMessage('No employees selected.');
    } else {
      this.timesheetApprovalsService.approveTime(selectedEmployeeKeys, this.weekStart, this.weekEnd, approving);
    }

    this.closeFilterPopup();
  }
}
