import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { UtilService } from './../../../services/util/util.service';
import { InvApprovalService } from './../../../services/inv-approval/inv-approval.service';
import { InvApprovalUtilService } from './../../../services/inv-approval-util/inv-approval-util.service';
import { StateDataService } from './../../../services/state-data/state-data.service';

import { PageMonthYearView } from '../../../classes/abstract/page-month-year-view/page-month-year-view';

@Component({
  selector: 'app-inv-approval-manager',
  templateUrl: './inv-approval-manager.component.html',
  styleUrls: ['./inv-approval-manager.component.scss']
})
export class InvApprovalManagerComponent extends PageMonthYearView implements OnInit {

  currencySymbol: string;

  monthsApprovalRequests: any[] = null;
  selectedApprovalRequests: any = {};
  selectedApprovalRequestCount: number = 0;

  menuItems: any[] = [
    {
      label: 'Select All Pending Requests',
      icon_class: 'ion-md-checkbox',
      color_class: '-color-primary-clear',
      select_value: 'TOGGLEALL',
      disabled: false
    },
    null,
    {
      label: 'Approve',
      icon_class: 'ion-md-checkmark',
      color_class: '-color-success-clear',
      select_value: 'APPROVE',
      disabled: false
    },
    {
      label: 'Reject',
      icon_class: 'ion-md-close',
      color_class: '-color-danger-clear',
      select_value: 'REJECT',
      disabled: false
    },
  ];

  constructor(
    public invApprovalService: InvApprovalService,
    public stateDataService: StateDataService,
    public stateService: StateService,
    public utilService: UtilService
  ) {
    super(stateDataService);
  }

  ngOnInit() {
    this.currencySymbol = this.utilService.currencySymbol;
    this.currentMonth = this.stateService.params.currentMonth || this.stateDataService.selectedMonth;

    this.loadCurrentMonthOfApprovalRequests();
  }

  menuItemSelected(event: any) {
    switch (event.select_value) {
      case 'TOGGLEALL':
        this.toggleAllPendingApprovalReqests();
        break;
      case 'APPROVE':
        this.approveSelected();
        break;
      case 'REJECT':
        this.rejectSelected();
        break;
    }
  }

  toggleAllPendingApprovalReqests(toggleValue: boolean = null) {
    const pendingRequestKeys = Object.keys(this.selectedApprovalRequests);
    toggleValue = toggleValue === null ? this.selectedApprovalRequestCount !== pendingRequestKeys.length : toggleValue;

    for (const approval_request_key of pendingRequestKeys) {
      this.selectedApprovalRequests[approval_request_key] = toggleValue;
    }
    this.updateSelectedApprovalRequestCount();
  }

  approveSelected() {
    const approvalRequests = this.getSelectedApprovalRequests();

    this.invApprovalService.respondToApprovalRequests(approvalRequests, true)
      .finally(() => {
        this.refreshApprovalRequests();
      });
  }

  rejectSelected() {
    const approvalRequests = this.getSelectedApprovalRequests();

    this.invApprovalService.respondToApprovalRequests(approvalRequests, false)
      .finally(() => {
        this.refreshApprovalRequests();
      });
  }

  viewApprovalRequest(approval_project: any) {
    this.invApprovalService.loadApprovalRequestProjectSegments(
      approval_project.approval_request_key,
      approval_project.project_key
    )
      .then((approval_segments) => {
        this.stateService.go('app.inv.approval.view', {
          approval_project,
          approval_segments
        });
      })
      .catch(() => {

      });
  }

  loadCurrentMonthOfApprovalRequests() {
    return new Promise<void>((resolve, reject) => {
      this.monthChanging = true;

      this.invApprovalService.loadMonthOfApprovalRequests(this.currentMonth)
        .then((approvalRequests) => {
          this.monthsApprovalRequests = InvApprovalUtilService.groupApprovalRequestsByStatus(approvalRequests);
          this.initSelectedPendingApprovalRequests();

          this.monthChanging = false;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  initSelectedPendingApprovalRequests() {
    this.selectedApprovalRequests = {};

    for (const approvalRequest of this.monthsApprovalRequests[0].approvalRequests) {
      this.selectedApprovalRequests[approvalRequest.approval_request_key] = false;
    }
    this.updateSelectedApprovalRequestCount();
  }

  getSelectedApprovalRequests(): any[] {
    const approvalRequests = [];
    for (const approvalRequest of this.monthsApprovalRequests[0].approvalRequests) {
      if (this.selectedApprovalRequests[approvalRequest.approval_request_key] === true) {
        approvalRequests.push(approvalRequest);
      }
    }
    return approvalRequests;
  }

  updateSelectedApprovalRequestCount() {
    this.selectedApprovalRequestCount = 0;
    const pendingRequestKeys = Object.keys(this.selectedApprovalRequests);

    for (const approval_request_key of pendingRequestKeys) {
      if (this.selectedApprovalRequests[approval_request_key] === true) {
        this.selectedApprovalRequestCount++;
      }
    }
    this.menuItems[0].label = (this.selectedApprovalRequestCount === pendingRequestKeys.length ?
      'Deselect' : 'Select') + ' All Pending Requests';
    this.menuItems[2].disabled = this.selectedApprovalRequestCount === 0;
    this.menuItems[3].disabled = this.selectedApprovalRequestCount === 0;
  }

  refreshApprovalRequests(event: any = null) {
    this.loadCurrentMonthOfApprovalRequests()
      .finally(() => {
        if (event) {
          event.target.complete();
        }
      });
  }

  forwardMonth() {
    super.forwardMonth();
    this.loadCurrentMonthOfApprovalRequests();
  }

  backMonth() {
    super.backMonth();
    this.loadCurrentMonthOfApprovalRequests();
  }

  showCalendarMonth(event: any) {
    super.showCalendarMonth(event);
    this.loadCurrentMonthOfApprovalRequests();
  }

}
