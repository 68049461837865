<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <menu-button></menu-button>
    </ion-buttons>
    <ion-title>My Expenses</ion-title>
  </ion-toolbar>
</ion-header>

<button class="ph-toggleAddBtn"
        (click)="newExpense()"
        *ngIf="!yearGridVisible && allProjects.length">
  <i class="ion ion-md-add"></i>
</button>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshExpenses($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="inv-expenseResource-container">

    <period-selector [currentMonth]="currentMonth"
                     [showYearSelector]="yearGridVisible"
                     [monthChanging]="monthChanging"
                     (selectorToggled)="toggleYearGridVisibility()"
                     (goForward)="goForward()"
                     (goBack)="goBack()">
    </period-selector>

    <div class="ph-spinner"
         *ngIf="monthChanging">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage"
         *ngIf="!yearGridVisible && !monthChanging &&
            !monthsExpenses.length">
      <i class="ion ion-md-paper"></i>
      <div>No expenses this month</div>
    </div>

    <div class="inv-expenseResource-monthContainer"
         *ngIf="!yearGridVisible">

      <div class="ph-groupedList-container">
        <div class="ph-groupedList-item"
             *ngFor="let expense of monthsExpenses"
             (click)="editExpense(expense)">

          <div class="ph-groupedList-itemColor"
               [ngStyle]="{ 'background-color': expense.task.task_colour }">
          </div>

          <div class="ph-groupedList-itemTitle">
            <span>{{expense.task.task_name}}</span>
            <i class="ion ion-md-lock"
               *ngIf="expense.inv_locked || expense.task.admin_only_flag">
            </i>
            <span class="ph-paidTag"
                  *ngIf="expense.paid_flag">
              Paid
            </span>
            <i class="ion ion-ios-camera"
               *ngIf="expense.timesheet_attachment_key">
            </i>
          </div>

          <div class="ph-groupedList-itemDescription"
               *ngIf="expense.description">
            {{expense.description}}
          </div>

          <div class="ph-groupedList-itemDetails">
            {{expense.expense_date | date: "d MMM yy"}}
          </div>

          <div class="ph-groupedList-itemValueRight">
            {{expense.expense_amount | currency: currencySymbol}}
          </div>

          <i class="ion ion-ios-arrow-forward"></i>

        </div>

      </div>

    </div>

    <calendar-month-grid *ngIf="yearGridVisible"
                         (monthSelected)="showCalendarMonth($event)">
    </calendar-month-grid>

  </div>

</ion-content>
