import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';

@Component({
  selector: 'onboard-video',
  templateUrl: './onboard-video.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-video.component.scss']
})
export class OnboardVideoComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  loading: boolean = false;

  sanitizedVideoUrl: SafeUrl;
  videoWatched: boolean = false;

  constructor(public invOnboardService: InvOnboardService,
              public domSanitizer: DomSanitizer){

  }

  ngOnInit(){
    this.sanitizedVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.stepTemplate.url);
    this.videoWatched = this.stepAnswer !== null;
  }

  videoWatchedToggled(){
    this.updateStepAnswer();
  }

  stepAnswerNeedsUpdating(): boolean {
    if (this.stepAnswer){
      return this.videoWatched === false;
    }
    else {
      return this.videoWatched === true;
    }
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key
    };
    if (this.stepAnswer){
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (!this.videoWatched){
      stepData.deleted_flag = true;
    }

    return stepData;
  }

  updateStepAnswer(){
    if (this.stepAnswerNeedsUpdating()){

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
