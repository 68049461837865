import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { InvCompanyService } from './../inv-company/inv-company.service';
import { InvProjectService } from './../inv-project/inv-project.service';
import { InvUserUtilService } from './../inv-user-util/inv-user-util.service';
import { AlertService } from './../alert/alert.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InvUserService {

  userAccountDetails: any;
  allUsers: any[];
  allUsersMap: any;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public invProjectService: InvProjectService,
    public invCompanyService: InvCompanyService,
    public alertService: AlertService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadAllUsers()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  clearServiceData() {
    this.userAccountDetails = null;
    this.allUsers = null;
    this.allUsersMap = null;
    this.serviceSetup = false;
  }

  getUserAccountDetails(): any {
    return _.cloneDeep(this.userAccountDetails);
  }

  getSingleUser(user_key: number): any {
    return _.cloneDeep(this.allUsersMap[user_key]) || null;
  }

  getAllUsers(): any[] {
    return _.cloneDeep(this.allUsers);
  }

  getAllResources(): any[] {
    const resources = [];

    for (const user of this.allUsers) {
      if (user.resource_flag) {
        resources.push(_.cloneDeep(user));
      }
    }

    return resources;
  }

  getAllActiveResources(periodStart: Date, periodEnd: Date): any[] {
    const activeProjects = this.invProjectService.getAllActiveProjects(
      periodStart, periodEnd
    );
    const activeResourcesMap = {};

    for (const project of activeProjects) {
      for (const u of project.users) {
        const user = this.allUsersMap[u.user_key] || null;

        if (user && user.resource_flag && !activeResourcesMap[user.user_key]) {
          activeResourcesMap[user.user_key] = true;
        }
      }
    }

    const activeResources = [];
    for (const user_key of Object.keys(activeResourcesMap)) {
      activeResources.push(this.allUsersMap[user_key]);
    }

    return activeResources;
  }

  loadAllUsers() {
    return new Promise((resolve, reject) => {
      this.apiService.invGet('user/stub')
        .then((users) => {
          this.allUsers = [];
          this.allUsersMap = {};

          for (let user of users) {
            user = InvUserUtilService.setupUser(user);

            this.allUsers.push(user);
            this.allUsersMap[user.user_key] = user;
          }

          InvUserUtilService.sortUsersByName(this.allUsers);

          resolve(this.allUsers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadUserAccount() {
    return new Promise((resolve, reject) => {

      const params = {
        user_key: this.invCompanyService.getUserKey()
      };

      this.apiService.invGet('user/account', params)
        .then((userDetails) => {
          this.userAccountDetails = userDetails[0];
          resolve(this.userAccountDetails);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postUserAccount(data: any) {
    return new Promise<void>((resolve, reject) => {
      this.apiService.invPost('user/account', data)
        .then((result) => {
          this.loadUserAccount()
            .finally(() => {
              if (result.length && result[0].verification_flag) {
                this.alertService.messageAlert(
                  'An email has been sent to ' + this.userAccountDetails.verification_email,
                  'Verification Email Sent'
                );
              }
              else {
                this.utilService.toastMessage('Login details successfully updated');
              }
              resolve();
            });
        })
        .catch(() => {
          this.loadUserAccount()
            .finally(() => {
              reject();
            });
        });
    });
  }

  emailPasswordReset(email: string) {
    return new Promise<void>((resolve, reject) => {
      if (!email) {
        this.utilService.toastMessage('Please enter your login email address');
      }

      const data = {
        email_address: email
      };

      reject();
    });
  }

}
