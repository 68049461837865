import { TimeUtilService } from './../../services/time-util/time-util.service';

import * as _ from 'lodash';

export abstract class Segment {

  segment_key: number;
  deleted_flag: boolean;
  row_version: string;

  credit_flag: boolean;
  unit_flag: boolean; // Set in extended class

  private _segment_date: Date;
  private _start_time: Date;
  private _end_time: Date;
  private _duration: number;
  private _break_duration: number;
  private _units: number;

  constructor(
    segment_key: number, deleted_flag: boolean, row_version: string, unit_flag: boolean, credit_flag: boolean,
    segment_date: Date, start_time: Date, end_time: Date, break_duration: number, units: number
  ) {
    this.segment_key = segment_key;
    this.deleted_flag = deleted_flag;
    this.row_version = row_version;

    this.unit_flag = unit_flag;
    this.credit_flag = credit_flag;

    this._segment_date = this._cleanDate(segment_date);
    this._start_time = this._cleanTime(start_time);
    this._end_time = this._cleanTime(end_time);
    this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - break_duration;
    this._break_duration = break_duration;
    this._units = units;
  }

  get segment_date(): Date {
    return this._segment_date;
  }
  set segment_date(segment_date: Date) {
    if (TimeUtilService.dateIsValid(segment_date)) {
      segment_date = this._cleanDate(segment_date);

      this._segment_date = segment_date;

      this._updateTimesOnDateChange();
    }
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    if (TimeUtilService.dateIsValid(start_time)) {
      start_time = this._cleanTime(start_time);

      this._start_time = TimeUtilService.updateDatePortionOfDateTime(start_time, this.segment_date);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this._start_time);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
    }
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    if (TimeUtilService.dateIsValid(end_time)) {
      end_time = this._cleanTime(end_time);

      this._end_time = TimeUtilService.updateDatePortionOfDateTime(end_time, this.segment_date);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
    }
  }

  get duration(): number {
    return this._duration;
  }
  set duration(duration: number) {
    if (TimeUtilService.numberIsValid(duration)) {
      if (duration > 24) {
        duration = 24;
      }
      else if (duration < 0) {
        duration = 0;
      }

      this._duration = duration;

      if (this._start_time && this._end_time) {
        // If sum of duration and break are more than 24 hours,
        // reduce duration so that the sum is exactly 24 hours
        if (this._duration + this._break_duration > 24) {
          this._duration = 24 - this._break_duration;
        }

        // Update end time to match new duration values
        const totalHoursMins = TimeUtilService.hoursDecimalAsHoursAndMinutes(this._duration + this._break_duration);
        const totalHours = totalHoursMins[0];
        const totalMins = totalHoursMins[1];

        let newEnd = _.cloneDeep(this._start_time);
        newEnd = TimeUtilService.incrementHours(newEnd, totalHours);
        newEnd = TimeUtilService.incrementMinutes(newEnd, totalMins);

        this._end_time = newEnd;
      }
    }
  }

  get break_duration(): number {
    return this._break_duration;
  }
  set break_duration(break_duration: number) {
    if (TimeUtilService.numberIsValid(break_duration)) {
      const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);
      // Ensure break duration isn't greater than the total segment duration
      if (break_duration > totalDurationDec) {
        break_duration = totalDurationDec;
      }

      this._break_duration = break_duration;
      this._duration = totalDurationDec - this._break_duration;
    }
  }

  get units(): number {
    return this._units;
  }
  set units(units: number) {
    if (TimeUtilService.numberIsValid(units)) {
      if (
        (this.credit_flag && units > 0) ||
        (!this.credit_flag && units < 0)
      ) {
        units = 0;
      }
      this._units = units;
    }
  }

  private _updateDurationOnTimeChange() {
    const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);

    // If new total duration is less than break duration,
    // we need to reset break duration
    if (totalDurationDec < this._break_duration) {
      this._break_duration = 0;
    }

    this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - (this._break_duration);
  }
  private _updateTimesOnDateChange() {
    this._start_time = TimeUtilService.updateDatePortionOfDateTime(this._start_time, this.segment_date);
    this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this.segment_date);
    this._checkEndTimeValidIfMultiDaySegment();
  }
  private _checkEndTimeValidIfMultiDaySegment() {
    if (TimeUtilService.endTimeLessThanStartTime(this._start_time, this._end_time)) {
      const next_day = TimeUtilService.incrementDate(this._segment_date, 1);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, next_day);
    }
  }

  private _cleanDate(date: Date): Date {
    try {
      date.setHours(0, 0, 0, 0);
      return date;
    }
    catch (err) {
      return date;
    }
  }
  private _cleanTime(time: Date): Date {
    try {
      time.setSeconds(0, 0);
      return time;
    }
    catch (err) {
      return time;
    }
  }

}
