import { StateService } from '@uirouter/angular';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { UtilService } from '../../../services/util/util.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';

@Component({
  selector: 'app-expense-camera',
  templateUrl: './expense-camera.component.html',
  styleUrls: ['./expense-camera.component.scss']
})
export class ExpenseCameraComponent implements OnInit, AfterViewInit {

  @ViewChild('cameraInput') cameraInput: ElementRef;

  isInvoxyUser: boolean;
  expenseEditState: string;

  expense: any;

  fromExpenseEdit: boolean = false;
  pageInitialised: boolean = false;
  fileSizeError: boolean = false;
  uploadingPhoto: boolean = false;

  imageBlob: any = null;
  imageUrl: any = null;

  fileInput: any;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public stateChangeService: StateChangeService,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;
    this.expenseEditState = this.isInvoxyUser ? 'app.inv.expense.edit' : 'app.expense.edit';

    this.fileInput = document.getElementById('expenseCameraInput');
    this.fromExpenseEdit = this.stateChangeService.previousStateName === this.expenseEditState;
    this.pageInitialised = true;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.cameraInput.nativeElement.click();
    });
  }

  imageUploaded(event: any) {
    this.uploadingPhoto = true;
    this.fileSizeError = false;

    if (this.fileInput.files.length) {
      const blob: File = this.fileInput.files[0];

      if (blob.size > 4000000) {
        this.uploadingPhoto = false;
        this.fileSizeError = true;
      }
      else {
        this.imageBlob = blob;
        this.imageUrl = URL.createObjectURL(blob);

        this.confirmExpense(event, false);
      }
    }
    else {
      this.uploadingPhoto = false;
    }
  }

  clearErrors() {
    this.fileSizeError = false;
  }

  confirmExpense(event: any, skipPhoto: boolean) {
    event.stopPropagation();

    if (this.imageUrl) {

      if (this.fromExpenseEdit) {
        this.stateChangeService.back(null, {
          imageUrl: this.imageUrl,
          expense: this.stateService.params.expense
        });
      }
      else {
        this.stateService.go(this.expenseEditState, {
          imageUrl: skipPhoto ? null : this.imageUrl,
          expense_date: this.stateService.params.expense_date,
          employee_key: this.stateService.params.employee_key,
          user_key: this.stateService.params.user_key,
          expense: this.stateService.params.expense
        });
      }
    }
    else {
      this.stateService.go(this.expenseEditState, {
        expense_date: this.stateService.params.expense_date,
        employee_key: this.stateService.params.employee_key,
        user_key: this.stateService.params.user_key,
        expense: this.stateService.params.expense
      });
    }
  }

  goBack() {
    this.stateChangeService.back();
  }

}
