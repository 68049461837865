import { UtilService } from 'src/app/services/util/util.service';
import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Meta } from '@angular/platform-browser';

import { AuthService } from '../../../services/auth/auth.service';
import { CompanyService } from '../../../services/company/company.service';
import { InvCompanyService } from '../../../services/inv-company/inv-company.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { TeamService } from '../../../services/team/team.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  isInvoxyUser: boolean = false;

  isAdmin: boolean = false;
  isManager: boolean = false;

  nav: any[];

  currentCompany: any;
  allTeams: any[] = [];
  selectedTeam: any;

  versionNumber: any;

  menuOpen: boolean = true;

  constructor(public stateService: StateService,
    public authService: AuthService,
    public menuController: MenuController,
    public companyService: CompanyService,
    public invCompanyService: InvCompanyService,
    public stateAccessService: StateAccessService,
    public teamService: TeamService,
    private meta: Meta
  ) { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  ngOnInit() {
    this.isInvoxyUser = this.stateAccessService.isInvoxyUser;

    this.stateAccessReloaded();
    this.stateAccessService.stateAccessReloaded$.subscribe(() => {
      this.stateAccessReloaded();
    });

    if (!this.isInvoxyUser) {
      this.teamService.selectedTeamUpdated$.subscribe(() => {
        this.selectedTeamUpdated();
      });
    }
  }

  stateAccessReloaded() {
    if (this.isInvoxyUser) {
      this.currentCompany = this.invCompanyService.getCurrentCompany();
    }
    else {
      this.currentCompany = this.companyService.getCurrentCompany();

      this.isAdmin = this.companyService.isAdmin();
      this.isManager = this.companyService.isManager();

      this.allTeams = this.teamService.getAllTeams();
      this.selectedTeam = this.teamService.getSelectedTeam();
    }

    this.versionNumber = this.meta.getTag('name=appVersion').content;
    this.nav = this.stateAccessService.getNav();
  }

  selectedTeamUpdated() {
    this.selectedTeam = this.teamService.getSelectedTeam();
    this.nav = this.stateAccessService.getNav();
  }

  goToState(state: string, params: any) {
    if (state.indexOf('SUBSCRIPTION') === 0){
      const dot_index = UtilService.indexOfNthOccurance(state, '.', 1);
      const subscription_state = state.slice(dot_index + 1);

      this.authService.goApplet(subscription_state, params, true);
    }
    else {
      this.stateService.go(state, params, { reload: true, inherit: false });
      this.menuController.close();
    }
  }

  goToCompanySelect() {
    this.authService.goApplet('companySelect', { stopAutoSelect: true });
  }

  manualLogout() {
    this.menuController.close();
    this.authService.logout(true);
  }
}
