import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent {

  @Input() currentMonth: Date;

  @Input() showYearSelector: boolean = false;
  @Input() monthChanging: boolean = false;
  @Input() yearChanging: boolean = false;

  @Output() selectorToggled = new EventEmitter();
  @Output() goForward = new EventEmitter();
  @Output() goBack = new EventEmitter();

  forward(){
    this.goForward.emit();
  }

  back(){
    this.goBack.emit();
  }

  toggleSelector(){
    this.selectorToggled.emit();
  }
}
