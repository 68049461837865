import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { InvTimeService } from './../../../services/inv-time/inv-time.service';
import { InvApprovalService } from './../../../services/inv-approval/inv-approval.service';
import { InvCompanyService } from './../../../services/inv-company/inv-company.service';
import { UtilService } from './../../../services/util/util.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';
import { InvProjectService } from './../../../services/inv-project/inv-project.service';
import { InvUserService } from './../../../services/inv-user/inv-user.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';
import { InvClockService } from './../../../services/inv-clock/inv-clock.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-inv-time-resource',
  templateUrl: './inv-time-resource.component.html',
  styleUrls: ['./inv-time-resource.component.scss']
})
export class InvTimeResourceComponent implements OnInit {

  readonly backSkipStates: string[] = ['app.inv.time.work', 'app.inv.time.edit'];

  user: any = null;
  loggedInUserKey: number = null;

  timeHistory: any[] = [];
  groupedTimeHistory: any[] = [];

  activeProjects: any = [];
  publicHolidaysMap: any;

  loadFromDate: Date = null;

  currencySymbol: string;

  clock: any = null;

  isAdmin: boolean = false;
  isManager: boolean = false;

  menuItems: any[];
  private _requestMenuItem: any = {
    label: 'REQUEST APPROVAL',
    icon_class: 'ion-ios-paper-plane',
    color_class: '-color-primary-clear',
    select_value: 'REQUEST',
    disabled: false
  };
  private _copyMenuItem: any = {
    label: 'COPY LAST WEEK\'S TIME',
    icon_class: 'ion-ios-copy',
    color_class: '-color-primary-clear',
    select_value: 'COPY',
    disabled: false
  };
  private _recordMenuItem: any = {
    label: 'RECORD TIME',
    icon_class: 'ion-ios-add-circle',
    color_class: '-color-success-clear',
    select_value: 'RECORD',
    disabled: false
  };
  private _clockMenuItem: any = {
    label: 'START CLOCK',
    icon_class: 'ion-ios-play',
    color_class: '-color-success-clear',
    select_value: 'CLOCK',
    disabled: false
  };

  loading: boolean = true;

  constructor(
    public invTimeService: InvTimeService,
    public invApprovalService: InvApprovalService,
    public invCompanyService: InvCompanyService,
    public stateService: StateService,
    public utilService: UtilService,
    public invProjectService: InvProjectService,
    public invUserService: InvUserService,
    public stateChangeService: StateChangeService,
    public invClockService: InvClockService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.isAdmin = this.invCompanyService.isAdminOrOwner();
    this.isManager = this.invCompanyService.isTimeManager();
    this.loggedInUserKey = this.invCompanyService.getUserKey();

    const user_key = this.stateService.params.user_key || this.loggedInUserKey;
    this.user = this.invUserService.getSingleUser(user_key);

    this.activeProjects = this.invProjectService.getAllActiveProjects(null, null, true);
    this.publicHolidaysMap = this.invCompanyService.getPublicHolidaysMap(this.user);

    this.currencySymbol = this.utilService.currencySymbol;

    forkJoin([
      this.loadResourceClock(),
      this.loadTimeHistory()
    ])
      .toPromise()
      .finally(() => {
        this.updateMenuItems();
        this.loading = false;
      });
  }

  back() {
    this.stateChangeService.back(this.backSkipStates);
  }

  toggleMenu() {
    this.stateChangeService.toggleMenu();
  }

  updateMenuItems() {
    this._clockMenuItem.disabled = this._checkClockMenuDisabled();
    this._copyMenuItem.disabled = this._checkCopyWeekDisabled();
    this._recordMenuItem.disabled = this._checkRecordTimeDisabled();

    this.menuItems = [
      this._requestMenuItem,
      this._copyMenuItem,
      null,
      this._recordMenuItem,
      this._clockMenuItem
    ];
  }

  menuItemSelected(event: any) {
    switch (event.select_value) {
      case 'REQUEST':
        this.requestApproval();
        break;
      case 'COPY':
        this.copyLastWeek();
        break;
      case 'RECORD':
        this.newSegment();
        break;
      case 'CLOCK':
        this.clockIn();
        break;
    }
  }

  editSegment(segment: any) {
    this.stateService.go('app.inv.time.edit', {
      segment,
      segment_key: segment.segment_key,
      user_key: segment.user.user_key,
      segments: this.timeHistory
    });
  }

  newSegment() {
    if (this.activeProjects.length === 1) {
      this.stateService.go('app.inv.time.edit', {
        project_key: this.activeProjects[0].project_key,
        user_key: this.user.user_key,
        segments: this.timeHistory
      });
    }
    else {
      this.stateService.go('app.inv.time.work', {
        segments: this.timeHistory,
        user_key: this.user.user_key
      });
    }
  }

  clockIn() {
    if (!this.loading) {
      this.loading = true;

      this.invClockService.clockIn(this.user.user_key)
        .finally(() => {
          this.loadResourceClock();
        });
    }
  }

  clockOut(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.invClockService.clockOut(clock)
        .finally(() => {
          this.refreshTime();
          this.updateMenuItems();
        });
    }
  }

  breakIn(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.invClockService.breakIn(clock)
        .finally(() => {
          this.loadResourceClock();
        });
    }
  }

  breakOut(event: any) {
    const clock = event.clock;

    if (!this.loading) {
      this.loading = true;

      this.invClockService.breakOut(clock)
        .finally(() => {
          this.loadResourceClock();
        });
    }
  }

  viewClock(event: any) {
    const clock = event.clock;

    this.stateService.go('app.inv.time.clock', {
      clock,
      clock_key: clock.clock_key
    });
  }

  loadResourceClock() {
    return new Promise<void>((resolve, reject) => {
      this.invClockService.loadUserClock(this.user.user_key)
        .then((clock) => {
          this.clock = clock;
          this.loading = false;
          resolve();
        })
        .catch((err) => {
          this.loading = false;
          reject();
        });
    });
  }

  requestApproval() {
    this.utilService.showLoadingSpinner();

    this.invApprovalService.loadApprovalSegments(this.user.user_key)
      .then((segments) => {

        if (segments.length === 0) {
          this.utilService.toastMessage('All time is already approved or pending approval');
        }
        else {
          this.stateService.go('app.inv.approval.request', {
            user_key: this.user.user_key,
            segments
          });
        }
      })
      .finally(() => {
        this.utilService.hideLoadingSpinner();
      });
  }

  copyLastWeek() {
    this.utilService.showLoadingSpinner();

    this.invTimeService.copyPreviousWeeksSegments(this.user.user_key)
      .then(() => {
        this.groupedTimeHistory = [];

        this.loadTimeHistory()
          .finally(() => {
            this.utilService.hideLoadingSpinner();
          });
      })
      .catch(() => {
        this.utilService.hideLoadingSpinner();
      });
  }

  refreshTime(event: any = null) {
    forkJoin([
      this.loadResourceClock(),
      this.loadTimeHistory()
    ])
      .toPromise()
      .finally(() => {
        if (event) {
          event.target.complete();
        }
        this.updateMenuItems();
        this.loading = false;
      });
  }

  loadTimeHistory() {
    return new Promise<void>((resolve, reject) => {

      // Start of previous month
      this.loadFromDate = new Date();
      this.loadFromDate.setMonth(this.loadFromDate.getMonth() - 1);
      this.loadFromDate.setDate(1);

      // End of next month
      const toDate = new Date();
      toDate.setMonth(toDate.getMonth() + 2);
      toDate.setDate(0);

      this.invTimeService.loadSegments(this.loadFromDate, toDate, this.user.user_key)
        .then((history) => {
          this.timeHistory = history;
          this.groupedTimeHistory = TimeUtilService.setupGroupedHistory(history, this.publicHolidaysMap);

          this.ensureEnoughTimeLoaded()
            .then(() => {
              resolve();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Load another full month of time
   */
  loadMoreTimeHistory(event: any = null) {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      // End of previous month
      const toDate = _.cloneDeep(this.loadFromDate);
      toDate.setDate(0);

      // Start of previous month
      this.loadFromDate.setMonth(this.loadFromDate.getMonth() - 1);
      this.loadFromDate.setDate(1);

      this.invTimeService.loadSegments(this.loadFromDate, toDate, this.user.user_key)
        .then((history) => {
          this.timeHistory = this.timeHistory.concat(history);
          const groupedMonths = TimeUtilService.setupGroupedHistory(history, this.publicHolidaysMap);

          // Length will be 0 if there are no segments in the month
          if (groupedMonths.length) {
            this.groupedTimeHistory.push(groupedMonths[0]);
          }
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          this.loading = false;

          if (event !== null) {
            event.target.complete();
          }
        });
    });
  }

  /**
   * If we get less than 8 time segments back on initial time history load,
   * try to load up to two more months of time history to try and fill the page
   */
  ensureEnoughTimeLoaded() {
    return new Promise<void>((resolve) => {
      if (this.timeHistory.length < 8) {

        this.loadMoreTimeHistory()
          .then(() => {

            if (this.timeHistory.length < 8) {
              this.loadMoreTimeHistory()
                .then(() => { resolve(); })
                .catch(() => { resolve(); });
            }
            else {
              resolve();
            }
          })
          .catch(() => {
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  private _checkClockMenuDisabled() {
    const today = new Date();
    const todaysActiveProjects =
      this.invProjectService.getAllActiveProjects(
        today, today, true, true, false, this.user.user_key
      );

    return todaysActiveProjects.length === 0 || this.clock !== null;
  }

  private _checkCopyWeekDisabled() {
    const currentMonday = moment().day('Monday').toDate();
    const previousMonday = TimeUtilService.incrementDate(currentMonday, -7);
    const nextMonday = TimeUtilService.incrementDate(currentMonday, 7);

    const activeProjects =
      this.invProjectService.getAllActiveProjects(
        previousMonday, nextMonday, true, false, false, this.user.user_key
      );

    return activeProjects.length === 0;
  }

  private _checkRecordTimeDisabled() {
    const allProjects =
      this.invProjectService.getAllActiveProjects(
        null, null, true, false, false, this.user.user_key
      );

    return allProjects.length === 0;
  }

}
