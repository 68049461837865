import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { TimeUtilService } from './../time-util/time-util.service';
import { AuthService } from './../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InvIntegrationService {

  static readonly integrationTypeDisplay: any = {
    'FLEXITIME': 'FlexiTime',
    'KEYPAY': 'Employment Hero',
    'XERO PAYROLL': 'Xero Payroll',
    'PAYHERO': 'PayHero',
    'BULLHORN': 'Bullhorn',
    'JOBADDER': 'JobAdder',
    'XERO': 'Xero',
    'POWERBI': 'Power BI',
    'APOSITIVE': 'APositive'
  };
  static readonly payrollIntegrationTypes: any = {
    'FLEXITIME': true,
    'KEYPAY': true,
    'XERO PAYROLL': true,
    'PAYHERO': true
  };
  static readonly atsIntegrationTypes: any = {
    'BULLHORN': true,
    'JOBADDER': true
  };

  currentIntegrations: any;
  currentPayrollIntegration: any;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public apiService: ApiService
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadIntegrations()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.currentIntegrations = null;
    this.currentPayrollIntegration = null;

    this.serviceSetup = false;
  }

  hasPayHeroLogin(): boolean {
    return this.authService.hasSession('PH');
  }

  isPayHeroIntegrated(): boolean {
    return this.currentPayrollIntegration ? this.currentPayrollIntegration.integration_type === 'PAYHERO' : false;
  }

  getPayrollIntegration(): string {
    return this.currentPayrollIntegration ? this.currentPayrollIntegration.integration_type : null;
  }

  loadIntegrations() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invGet('integration')
        .then((integrations) => {
          this.currentIntegrations = InvIntegrationService.setupIntegrations(integrations);

          this.loadPayrollIntegration()
            .then(() => {
              resolve();
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  loadPayrollIntegration() {
    return new Promise<void>((resolve, reject) => {
      let foundPayrollIntegration = false;

      for (const integration of this.currentIntegrations) {
        if (InvIntegrationService.payrollIntegrationTypes[integration.integration_type]) {

          this.currentPayrollIntegration = integration;
          foundPayrollIntegration = true;
          break;
        }
      }

      if (foundPayrollIntegration) {
        switch (this.currentPayrollIntegration.integration_type) {
          case 'PAYHERO':
            this.loadPayHeroIntegration()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
            break;

          default:
            resolve();
        }
      }
      else {
        resolve();
      }

      if (!foundPayrollIntegration) {
        resolve();
      }
    });
  }

  loadPayHeroIntegration() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.invGet('payhero/session', null, { hide_error: true })
        .then((ph_auth) => {
          this.authService.login(
            null,
            {
              session_key: ph_auth.session_key,
              company_code: ph_auth.company_code
            }
          );
          resolve();
        })
        .catch(() => {
          resolve(); // user can still login if there is no payher access
        });
    });
  }

  static setupIntegrations(integrations: any[]): any[] {
    for (const integration of integrations) {
      integration.integrationTypeDisplay = InvIntegrationService.integrationTypeDisplay[integration.integration_type];
      integration.updated_date = TimeUtilService.dateTimeStringToDate(integration.updated_date, null, true);
    }
    return integrations;
  }

}
