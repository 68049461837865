import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { StateChangeService } from './../../../services/state-change/state-change.service';
import { UtilService } from './../../../services/util/util.service';
import { InvApprovalService } from './../../../services/inv-approval/inv-approval.service';

import * as moment from 'moment';
import 'moment-duration-format';

@Component({
  selector: 'app-inv-approval-request',
  templateUrl: './inv-approval-request.component.html',
  styleUrls: ['./inv-approval-request.component.scss']
})
export class InvApprovalRequestComponent implements OnInit {

  currencySymbol: string;
  user_key: number;

  approvalSegments: any[] = [];
  groupedSegments: any[] = [];

  totalHours: number = 0;
  totalDays: number = 0;

  from_date: Date = new Date();
  to_date: Date = new Date();

  loading: boolean = false;

  constructor(
    public stateService: StateService,
    public stateChangeService: StateChangeService,
    public utilService: UtilService,
    public invApprovalService: InvApprovalService
  ) { }

  ngOnInit() {
    this.currencySymbol = this.utilService.currencySymbol;
    this.user_key = this.stateService.params.user_key;
    this.approvalSegments = this.stateService.params.segments;

    if (this.user_key && this.approvalSegments && this.approvalSegments.length) {
      this.initGroupedSegments();
    }
    else {
      this.back();
    }
  }

  back() {
    this.stateChangeService.back(['app.inv.time.work']);
  }

  requestApproval() {
    this.utilService.showLoadingSpinner();

    this.invApprovalService.requestApproval(this.user_key, this.from_date, this.to_date)
      .then(() => {
        this.utilService.toastMessage('Approval request sent successfully');
      })
      .finally(() => {
        this.utilService.hideLoadingSpinner();
        this.back();
      });
  }

  initGroupedSegments() {
    let earliestDate = null;
    let latestDate = null;

    for (const segment of this.approvalSegments) {
      const projectIndex = this.getProjectIndexInGroupedSegments(segment.inv_project.project_key);
      const segDate = moment(segment.segment_date);

      if (earliestDate === null) {
        earliestDate = segDate;
        latestDate = segDate;
      }
      else {
        if (segDate.isBefore(earliestDate, 'days')) {
          earliestDate = segDate;
        }
        if (segDate.isAfter(latestDate, 'days')) {
          latestDate = segDate;
        }
      }

      // Found the project grouping
      if (projectIndex !== null) {
        const dayIndex = this.getDayIndexInGroupedSegmentsProject(this.groupedSegments[projectIndex], segment.segment_date);

        // Found the project day grouping
        if (dayIndex !== null) {
          this.groupedSegments[projectIndex].days[dayIndex].segments.push(segment);
        }
        // Need to create new project day grouping
        else {
          this.groupedSegments[projectIndex].days.push({
            date: segment.segment_date,
            segments: [segment]
          });
        }
      }
      // Need to create new project grouping
      else {
        this.groupedSegments.push({
          project_name: segment.inv_project.project_name,
          project_key: segment.inv_project.project_key,
          client_name: segment.inv_project.client_name,
          days: [{
            date: segment.segment_date,
            segments: [segment]
          }]
        });
      }
    }

    for (const project of this.groupedSegments) {
      project.days.sort((a: any, b: any) => {
        if (a.date < b.date) {
          return 1;
        }
        else if (a.date > b.date) {
          return -1;
        }
        return 0;
      });
    }

    this.updateTotals();
    this.from_date = earliestDate.toDate();
    this.to_date = latestDate.toDate();
  }

  updateTotals() {
    this.totalHours = 0;
    this.totalDays = 0;

    for (const segment of this.approvalSegments) {
      if (segment.unit_type === 'hours') {
        this.totalHours += (segment.unit_flag ? segment.units : segment.duration);
      }
      else if (segment.unit_type === 'days') {
        this.totalDays += segment.units;
      }
    }
  }

  getProjectIndexInGroupedSegments(project_key: number) {
    for (let i = 0; i < this.groupedSegments.length; i++) {
      if (this.groupedSegments[i].project_key === project_key) {
        return i;
      }
    }

    return null;
  }

  getDayIndexInGroupedSegmentsProject(project: any, date: Date) {
    for (let i = 0; i < project.days.length; i++) {
      if (project.days[i].date.getDate() === date.getDate()) {
        return i;
      }
    }

    return null;
  }

}
