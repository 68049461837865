import { StateService } from '@uirouter/angular';
import { Component, OnInit } from '@angular/core';

import { LeaveService } from '../../../services/leave/leave.service';
import { StateAccessService } from '../../../services/state-access/state-access.service';
import { StateChangeService } from './../../../services/state-change/state-change.service';

@Component({
  selector: 'app-leave-balances',
  templateUrl: './leave-balances.component.html',
  styleUrls: ['./leave-balances.component.scss']
})
export class LeaveBalancesComponent implements OnInit {

  leaveDetails: any;
  leaveBalances: any[];

  constructor(public stateService: StateService,
              public stateAccessService: StateAccessService,
              public stateChangeService: StateChangeService,
              public leaveService: LeaveService) {


  }

  ngOnInit() {
    this.leaveDetails = this.leaveService.getLeaveDetails();
    this.setupLeaveBalanceRows();
  }

  back(){
    this.stateChangeService.back();
  }

  setupLeaveBalanceRows() {
    const stats = this.leaveDetails;
    const unit = stats.week_definition_unit;

    const annualWeeksDue = stats.annual_leave_weeks_due;
    const annualHoursDaysDue = stats.annual_leave_weeks_due * stats['leave_' + unit + '_per_week'];

    const estimatedWeeksAvailable = stats.estimated_leave_weeks_available;
    const estimatedDaysAvailable = stats.estimated_leave_weeks_available * stats['leave_' + unit + '_per_week'];

    this.leaveBalances = [
      {
        title: 'Holiday Leave Available:',
        stats: [
          {
            label: 'Current Leave Due:',
            value: LeaveBalancesComponent.maxTwoDp(stats['leave_' + unit + '_per_week'] ? annualHoursDaysDue : annualWeeksDue),
            unit: stats['leave_' + unit + '_per_week'] ? unit : 'weeks'
          }
        ]
      },
      {
        title: 'Other Leave Available:',
        stats: [
          {
            label: 'Sick:',
            value: LeaveBalancesComponent.maxTwoDp(stats.sick_leave_due_days ? stats.sick_leave_due_days : 0),
            unit: 'days'
          },
          {
            label: 'Alternative:',
            value: LeaveBalancesComponent.maxTwoDp(stats.alternate_leave_due_days ? stats.alternate_leave_due_days : 0),
            unit: 'days'
          }
        ]
      }
    ];

    // Don't show estimated leave if the user is on HPAYG
    if (!stats.holiday_pay_as_you_go_flag) {
      this.leaveBalances[0].stats.push({
        label: 'Estimated Leave In Advance:',
        value: LeaveBalancesComponent.maxTwoDp(stats['leave_' + unit + '_per_week'] ? estimatedDaysAvailable : estimatedWeeksAvailable),
        unit: stats['leave_' + unit + '_per_week'] ? unit : 'weeks'
      });
    }
  }

  static maxTwoDp(value: any) {
    return parseFloat(value.phRound(2));
  }

}
