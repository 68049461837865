import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { OnboardStepInterface } from './../onboard-step.interface';
import { InvOnboardService } from './../../../services/inv-onboard/inv-onboard.service';
import { UtilService } from './../../../services/util/util.service';
import { TimeUtilService } from './../../../services/time-util/time-util.service';

@Component({
  selector: 'onboard-download',
  templateUrl: './onboard-download.component.html',
  styleUrls: ['../onboard.component.scss', './onboard-download.component.scss']
})
export class OnboardDownloadComponent implements OnInit, OnboardStepInterface {

  @Input() onboard_key: number;
  @Input() stepAnswer: any;
  @Input() stepTemplate: any;

  @Output() stepAnswerUpdated = new EventEmitter();

  @Input() display_name: string;

  loading: boolean = false;

  inv_blob_url: string;

  downloadClicked: boolean = false;
  signatureProvided: boolean = false;
  signatureDate: Date = null;

  constructor(
    public invOnboardService: InvOnboardService,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.inv_blob_url = this.utilService.inv_blob_url;

    this.downloadClicked = this.stepAnswer !== null;

    if (this.stepAnswer && this.stepAnswer.signature_data) {
      this.signatureProvided = this.stepAnswer.signature_data.signed_flag;
      this.signatureDate = this.stepAnswer.signature_data.signature_date;
    }
  }

  downloadClickedToggled() {
    this.downloadClicked = true;
    this.updateStepAnswer();
  }

  signatureProvidedToggled() {
    if (this.signatureProvided) {
      this.signatureDate = new Date();
    }
    this.updateStepAnswer();
  }

  stepAnswerNeedsUpdating(): boolean {
    if (this.stepAnswer) {
      if (this.stepTemplate.signature_flag) {
        return this.signatureProvided === false;
      }
      else {
        return false;
      }
    }
    else {
      if (this.stepTemplate.signature_flag) {
        return this.downloadClicked === true &&
          this.signatureProvided === true;
      }
      else {
        return this.downloadClicked === true;
      }
    }
  }

  getStepAnswerData(): any {
    const stepData: any = {
      onboard_key: this.onboard_key,
      onboard_template_step_key: this.stepTemplate.onboard_template_step_key,
    };
    if (this.stepAnswer) {
      stepData.onboard_step_key = this.stepAnswer.onboard_step_key;
    }

    if (this.stepTemplate.signature_flag) {
      stepData.signature_data = JSON.stringify({
        signed_flag: this.signatureProvided,
        signed_date: this.signatureProvided ? TimeUtilService.formatDateForPosting(new Date(), true) : null,
        signed_document_key: this.stepTemplate.document_key,
        signed_description: this.stepTemplate.signature_description
      });
      stepData.deleted_flag = !this.signatureProvided;
    }

    return stepData;
  }

  updateStepAnswer() {
    if (this.stepAnswerNeedsUpdating()) {

      this.loading = true;
      const stepData = this.getStepAnswerData();

      this.invOnboardService.postOnboardStep(stepData)
        .then(() => {
          this.stepAnswerUpdated.emit();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
